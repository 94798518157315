import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End
import { apiCall } from "../../../../framework/src/apicall";
export const configJSON = require("../config.js");
let getuseDetails = localStorage.getItem("userDetails");
let userDetails = getuseDetails ? JSON.parse(getuseDetails) : null;
import { CometChat } from "@cometchat-pro/chat";
let config = require("../../../../framework/src/config");

interface User {
  attributes: {
    id: string | number;
    first_name: string;
    last_name: string;
    birthday: string;
    mobile_number: string;
    gender: string;
    email: string;
    parents_email: string;
    status: string;
    school_class: string;
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    pincode: number | null;
    registered: boolean;
    bio: string | null;
    image_url: string;
    role: string;
    aadhar_card_number: string | null;
    aadhar_photo_url: string | null;
    aadhar_card_file_name: string | null;
    school_name: string;
  };
  id: string;
  type: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  cometChatIds: Array<string>;
  isAdmin: boolean;
  isUserLogin: boolean;
  localStorageUserDetails: User | null;
  // Customizable Area End
}
interface SS {}
export default class SeekHelpController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cometChatIds: [],
      isAdmin: false,
      isUserLogin:false,
      localStorageUserDetails: null,
    };
  }

  async componentDidMount() {
    let getuseDetails = localStorage.getItem("userDetails");
    this.setState({
      localStorageUserDetails: getuseDetails ? JSON.parse(getuseDetails) : null,
    });
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let admin_id = params.get("admin_id");

    if (admin_id) {
      this.setState({ isAdmin: true });
      const token = localStorage.getItem("user_token");

      if (token) {
        await localStorage.removeItem("userDetails");
        await localStorage.removeItem("user_token");
        window.location.reload();
        return;
      }
      this.loginCometChatByAdmin(admin_id).then((response) => {
        console.log("adminlogin");
      });
    } else {
      this.loginCometChat();
      await this.getCometChatUsers();
    }
  }
  loginCometChat = async () => {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(config.region)
      .autoEstablishSocketConnection(true)
      .build();
    CometChat.init(config.appID, appSetting);
    /* istanbul ignore next*/

    CometChat.login(userDetails?.id, config.authKey)
      .then(() => {
        this.setState({ isUserLogin: true });
        console.log("Login successful seek help");
      })
      .catch((error: any) => {
        this.setState({ isUserLogin: true });
        console.log("Login failed with error:", error);
      });
  };

  loginCometChatByAdmin = async (admin_id: any) => {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(config.region)
      .autoEstablishSocketConnection(true)
      .build();
      CometChat.init(config.appID, appSetting)
      .then(() => {
        return CometChat.login(admin_id, config.authKey);
      })
      .then(() => {
        this.setState({ isUserLogin: true });
        console.log("Login successful. Seek help");
        // Rest of your code that should execute after successful login
      })
      .catch((error) => {
        console.log("Login failed with error:", error);
        alert("Something went wrong, need to contact admin team");
      });
   };

  getCometChatUsers = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      Authorization: localStorage.getItem("user_token"),
    };

    await apiCall(
      configJSON.dashboardGetApiMethod,
      configJSON.getComentChatId,
      header,
      ""
    ).then((response) => {
      if (response) {
        const userListIds = response.user_list.map(
          (user: User) => user.attributes.id
        );
        this.setState({ cometChatIds: userListIds });
        if (!response.chat_group_details) {
          if (
            !response?.admin_user_list?.id &&
            !response?.super_admin_details?.super_admin_id
          ) {
            alert("something went wrong need to contact admin team sdf");
          }
        }
        this.createGroup(response);
        return true;
      } else {
        return false;
      }
    });
  };

  createGroup = async (res: any) => {
    // Create a new private group
    const localStorageUserDetails = this.state.localStorageUserDetails;
    const group = new CometChat.Group(
      configJSON.groupPrefix + localStorageUserDetails?.id.toString(),
      localStorageUserDetails?.attributes?.first_name +
        " " +
        localStorageUserDetails?.attributes?.last_name,
      CometChat.GROUP_TYPE.PRIVATE
    );
    if (localStorageUserDetails?.attributes?.image_url) {
      group.setIcon(localStorageUserDetails?.attributes?.image_url as string);
    }

    const subAdmin: any = await CometChat.getUser(res.admin_user_list.id).catch(error => console.error(error))    

    let membersList = [
      new CometChat.GroupMember(
        configJSON.adminPrefix +
        res.super_admin_details.super_admin_id.toString(),
        CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ),
      new CometChat.GroupMember(
        localStorageUserDetails?.id.toString() as string,
        CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ),
    ];

    if (subAdmin?.role === 'admin') {
      membersList.push(new CometChat.GroupMember(
        configJSON.adminPrefix + subAdmin.uid,
        CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ))
    }

    /* istanbul ignore next*/
    CometChat.createGroupWithMembers(group, membersList, []).then(
      (response: any) => {
        if (response?.group?.guid) {
          this.sendGroupIDToBackend(
            localStorageUserDetails?.id.toString(),
            res.super_admin_details.super_admin_id
          );
          console.log("Group creation success");
        } else {
          console.log(
            "Group creation response does not contain a valid group ID"
          );
        }
      },
      (error) => {
        console.log("Some error occured while creating group", error);
      }
    );
  };
  sendGroupIDToBackend = async (
    guid: string | undefined,
    admin_user_id: string
  ) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      Authorization: localStorage.getItem("user_token"),
    };
    const attrs = {
      admin_user_id: parseInt(admin_user_id),
      comet_chat_group_id: guid,
    };
    const loginResult = await apiCall(
      configJSON.dashboardPostApiMethod,
      configJSON.submitGroupID,
      header,
      JSON.stringify(attrs)
    );
    if (loginResult) {
      return true;
    } else {
      return false;
    }
  };

  sendNotificationTotrainerOnMail = async (id: number) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      Authorization: localStorage.getItem("user_token"),
    };
    const attrs = {
      trainer_id: id,
    };
    const notificationStatus = await apiCall(
      configJSON.dashboardPostApiMethod,
      configJSON.sendNotification,
      header,
      JSON.stringify(attrs)
    );
    if (notificationStatus) {
      return true;
    } else {
      return false;
    }
  };
}
