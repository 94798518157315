import Point from "../Point";

class Segment {
  p1: Point;
  p2: Point;

  constructor(p1: { x: any; y: any }, p2: { x: any; y: any }) {
    this.p1 = new Point(p1.x, p1.y);
    this.p2 = new Point(p2.x, p2.y);
  }

  length() {
    const dx = this.p1.x - this.p2.x;
    const dy = this.p1.y - this.p2.y;
    return Math.hypot(dx, dy);
  }

  dx() {
    return this.p2.x - this.p1.x;
  }

  dy() {
    return this.p2.y - this.p1.y;
  }

  pointOnRelative(coeff: number) {
    const dx = this.p2.x - this.p1.x;
    const dy = this.p2.y - this.p1.y;
    return new Point(this.p1.x + coeff * dx, this.p1.y + coeff * dy);
  }
}

export default Segment;
