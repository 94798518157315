import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { setStorageData } from "../../../../../framework/src/Utilities";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

// Customizable Area Start

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  studentReportData: any;
  user_token: any;
  publishSuccess: boolean;
  publishFailure: boolean;
  appliedFilters: any;
  isLoading: boolean;
  page: number;
  successMessage: string
  yearFilterOption: any;
  courseFilterOption: any;
  schoolFilterOption: any;
  gradeFilterOption: any;
  yearFilterValue: any;
  courseFilterValue: any;
  schoolFilterValue: any;
  gradeFilterValue: any;
  // Customizable Area End
}
interface SS { id: any }

export default class TrainerStudentReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetIndexDataApiCallId: any;
  PublishApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handlePageChange = this.handlePageChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      studentReportData: [],
      user_token: "",
      publishSuccess: false,
      publishFailure: false,
      isLoading: false,
      appliedFilters: {},
      yearFilterValue: '',
      courseFilterValue: '',
      schoolFilterValue: '',
      gradeFilterValue: '',
      yearFilterOption: [],
      courseFilterOption: [],
      schoolFilterOption: [],
      gradeFilterOption: [],
      page: 1,
      successMessage: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorMsg) {
        console.log(errorMsg);
      }
      if (responseJson && !responseJson.errors) {
        this.ApiCallSuuccess(responseJson, apiRequestCallId);

      } else if (responseJson && responseJson.errors) {
        console.log(responseJson, "Error");
      }
    }
  }

  ApiCallSuuccess(responseJson: any, apiRequestCallId: any) {
    if (apiRequestCallId === this.GetIndexDataApiCallId) {
      this.setState({
        studentReportData: responseJson
      }, () => this.getYearFilterOptions())
    }
    if (apiRequestCallId === this.PublishApiCallId) {
      if (responseJson.message === configJSON.StudentsReport.OnlyWhenMsg) {
        this.setState({
          publishFailure: true
        })
      } else {
        this.setState({
          publishSuccess: true,
          successMessage: responseJson.message.includes("already") ? configJSON.StudentsReport.AlreadyPublished : configJSON.StudentsReport.PublishedForAll
        })
      }
    }
  }
  // Customizable Area End


  goTo = async (module: string, params: Object = {}, studentData?: any,) => {
    this.props.navigation.navigate(module, { ...params });
    await setStorageData("student_data", JSON.stringify(studentData));
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  getToken = () => {
    return localStorage.getItem('user_token')
  }

  closeModal = (modalType: string) => {
    if (modalType === configJSON.StudentsReport.Failure) {
      this.setState({ publishFailure: false })
    }
    if (modalType === configJSON.StudentsReport.Success) {
      this.setState({ publishSuccess: false })
    }
  }

  handleDropdownChange = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value })
  }

  handleClearFilter = (name: string) => {
    this.setState({ ...this.state, [name]: '' })
  }

  handlePublishClick = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.PublishApiCallId = apiRequest.messageId;
    let endPoint = configJSON.postPublishEndPoint;
    endPoint = this.getEndPoint(`${endPoint}`);
    endPoint = endPoint.replace("&", "?")
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.httpPostMethod,
      endPoint,
      header,
    );
  }

  //Calculate Filter Options
  getYearFilterOptions() {
    const yearFilterOptions = this.state.studentReportData?.filters?.academic_year.map((el: any) => {
      return { title: el, value: el, id: el };
    }) || [];

    const courseFilterOptions = this.state.studentReportData?.filters?.courses.map((el: any) => {
      return { ...el, value: el.id, id: el.id };
    }) || [];

    const schoolFilterOptions = this.state.studentReportData?.filters?.schools.map((el: any) => {
      return { ...el, value: el.id, id: el.id };
    }) || [];

    const gradeFilterOptions = this.state.studentReportData?.filters?.grades.map((el: any) => {
      return { ...el, value: el.id, id: el.id };
    }) || [];

    this.setState({
      yearFilterOption: yearFilterOptions,
      courseFilterOption: courseFilterOptions,
      schoolFilterOption: schoolFilterOptions,
      gradeFilterOption: gradeFilterOptions
    })

  }

  handlePageChange(page: any) {
    this.setState({ page: page })
  }

  getEndPoint = (endPoint: string) => {
    if (this.state.courseFilterValue) {
      endPoint = `${endPoint}&course_id=${this.state.courseFilterValue}`
    }
    if (this.state.schoolFilterValue) {
      endPoint = `${endPoint}&school_id=${this.state.schoolFilterValue}`
    }
    if (this.state.gradeFilterValue) {
      endPoint = `${endPoint}&grade_id=${this.state.gradeFilterValue}`
    }
    return endPoint
  }

  getStudentReportIndex() {
    this.setState({ studentReportData: [] })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetIndexDataApiCallId = apiRequest.messageId;
    let endPoint = `${configJSON.StudentsReport.GetReportsEndPoint}?page=${this.state.page}`
    if (this.state.yearFilterValue) {
      endPoint = `${endPoint}&academic_year=${this.state.yearFilterValue}`
    }
    endPoint = this.getEndPoint(endPoint);
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  async makeApiCall(
    uniqueApiCallID: string,
    Method: string,
    endpoint: string,
    Headers: any,
    Body?: any,
  ) {
    // Customizable Area Start
    let FullURL = endpoint.indexOf("://") === -1 ? config.baseURL + "/" + endpoint : endpoint;
    let apiResponseMessage = new Message(getName(MessageEnum.RestAPIResponceMessage));
    apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceDataMessage), uniqueApiCallID);
    try {
      this.setState({ isLoading: true })
      let response = await fetch(FullURL, { method: Method.toUpperCase(), headers: Headers, body: Body })
      if (response.status === 401) {
        localStorage.removeItem('userDetails')
        this.goTo('LoginForm')
      }
      this.setState({ isLoading: false })
      let responseJson = await response.json();
      if (responseJson.data || responseJson.message) {
        this.setState({ isLoading: false })
      }
      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson);
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceErrorMessage), "An error has occuured. Please try again later.");
    }
    this.send(apiResponseMessage);
  }

  // Customizable Area End
}
