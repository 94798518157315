import React from 'react'
import TrainerMpowerYouMatterController, { AddFormData, Fields, S } from "./TrainerMpowerYouMatterController.web";
import SidebarLayout from '../../SidebarLayout.web';
import PageContentLayout from '../../PageContentLayout.web';
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { addFields, removeFieldsIcon, scheduleImg } from '../../assets';
import CommonDatePicker from '../../CommonComponents/DatePicker.web';
import InfoModal from '../../CommonComponents/InformationModal.web';
import CommonSelect, { MenuList } from '../../CommonComponents/CommonSelect.web'
import CommonTextField from '../../CommonComponents/CommonTextField.web'
import RootContext from '../../../../../components/src/RootContext';
import Loader from '../../../../../components/src/Loader.web';

const configJSON = require("../../config.js");

export const useStyles = makeStyles(() => ({
    pageWrapper: {
        padding: '32px 24px 24px 24px'
    },
    dropDownWrapper: {
        marginBottom: '40px'
    },
    formTitles: {
        color: '#0056A3',
        fontSize: '24px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        marginBottom: '28px',
        '@media(max-width:600px)': {
            fontSize: '20px',
            marginBottom: '20px'
        }
    },

    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    nextBtn: {
        "&.MuiButton-root": {
            height: '48px',
            width: '160px',
            borderRadius: "8px",
            backgroundColor: "#0056A3",
            display: 'block',
            marginLeft: 'auto'
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
    addBtn: {
        "&.MuiButton-root": {
            height: '48px',
            minWidth: 'auto',
            width: '48px',
            borderRadius: "8px",
            backgroundColor: "#0056A3"
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            opacity: '0.5'
        }
    },
    noOfSectionWrapper: {
        '&.MuiGrid-container': {
            marginBottom: '20px'
        }
    },
    sectionTitle: {
        marginBottom: '24px',
        height: '56px',
        borderRadius: '8px',
        color: '#FFF',
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '24px',
        backgroundColor: '#0056A3',
        '@media(max-width:400px)': {
            fontSize: '14px',
            paddingLeft: '14px',
        }
    },
    datePickerWrapper: {
        '&.MuiGrid-container': {
            marginBottom: '20px',
            '@media(max-width:600px)': {
                marginBottom: '0px'
            }
        }
    }
}))

interface AddMpowerYouMatterFormProps {
    states: S;
    goTo: (module: string, params: Object) => void;
    handleFormNext: (isYouMatter: boolean, context: any) => void;
    isYouMatter: boolean;
    handleSectionsQuestions: (event: any, name: string, id: number) => void;
    handleAddFields: (remaining: any) => void;
    handleChange: (event: React.ChangeEvent<{ value: any, name: any }>) => void;
    handleOpenSchedule: (modalType: string) => void;
    handleRemoveField: (id: number) => void;
    clearSelect: (name: any, type: any) => void;
    readyToSchedule: boolean;
    context: any;
}

interface DropdownProps {
    id: string;
    name: string;
    value: any;
    label: string;
    placeholder: string;
    dataTestId: string;
    menuList: MenuList[];
}

export const AddMpowerYouMatterForm = (props: AddMpowerYouMatterFormProps) => {
    const classes = useStyles();

    const isSectionORQuestionsValid = props.states.addMpowerYouMatter.fields.every((element: any) =>
        (element.sectionName !== "" && element.totalQuestions !== "") && (element.errors?.section === "" && element.errors?.questions === "")
    );

    const courseDropdownList: MenuList[] = configJSON.formatDropdownList(props.states.addFormCourseList);
    const sessionsDropdownList: MenuList[] = configJSON.formatDropdownList(props.states.addFormSessionList);
    const schoolsDropdownList: MenuList[] = configJSON.formatDropdownList(props.states.addFormSchoolList);
    const sectionsDropdownList: MenuList[] = configJSON.formatDropdownList(props.states.addFormSectionList);

    const requiredFields: (keyof AddFormData)[] = ['title', 'courseName', 'sessionName', 'schoolName', 'sectionName', 'activateAfter', 'duration'];

    const allFieldsFilled = requiredFields.every((fieldName: (keyof AddFormData)) => {
        const value: any = props.states.addMpowerYouMatter[fieldName];
        return value !== "" && (props.states.timeClockErrors.activateAfter === '' && props.states.timeClockErrors.duration === '');
    });

    const cumulativeAns = props.isYouMatter ? !allFieldsFilled : !allFieldsFilled || props.states.addMpowerYouMatter.difficulty === "";
    const difficultyDropdownList = configJSON.TrainerMPowerYouMatter.DifficultyDropdownList;
    const gridItems = [
        { id: configJSON.TrainerMPowerYouMatter.Course, label: `${configJSON.TrainerMPowerYouMatter.CourseInCaps}*`, name: configJSON.TrainerMPowerYouMatter.CourseName, value: props.states.addMpowerYouMatter.courseName, menuList: courseDropdownList, placeholder: configJSON.TrainerMPowerYouMatter.SelectCourse, dataTestId: 'course' },
        { id: configJSON.TrainerMPowerYouMatter.Session, label: `${configJSON.TrainerMPowerYouMatter.SessionInCaps}*`, name: configJSON.TrainerMPowerYouMatter.SessionName, value: props.states.addMpowerYouMatter.sessionName, menuList: sessionsDropdownList, placeholder: configJSON.TrainerMPowerYouMatter.SelectSession, dataTestId: 'session' },
        { id: configJSON.TrainerMPowerYouMatter.School, label: `${configJSON.TrainerMPowerYouMatter.SchoolInCaps}*`, name: configJSON.TrainerMPowerYouMatter.SchoolName, value: props.states.addMpowerYouMatter.schoolName, menuList: schoolsDropdownList, placeholder: configJSON.TrainerMPowerYouMatter.SelectSchool, dataTestId: 'school' },
        { id: configJSON.TrainerMPowerYouMatter.Section, label: `${configJSON.TrainerMPowerYouMatter.Grade}*`, name: configJSON.TrainerMPowerYouMatter.SectionName, value: props.states.addMpowerYouMatter.sectionName, menuList: sectionsDropdownList, placeholder: configJSON.TrainerMPowerYouMatter.SelectGrade, dataTestId: 'section' }
    ];

    const totalAddedQuestion = props.states.addMpowerYouMatter.fields.reduce((sum: any, field: any) => sum + Number(field.totalQuestions), 0);
    const fieldsLength = props.states.addMpowerYouMatter.fields.length;

    return <Box className={classes.pageWrapper}>
        <Typography className={classes.formTitles}> {props.isYouMatter ? configJSON.TrainerMPowerYouMatter.YouMatterDetails : configJSON.TrainerMPowerYouMatter.MpowerDetails} </Typography>

        <CommonTextField
            data-test-id='title'
            label={`${props.isYouMatter ? configJSON.TrainerMPowerYouMatter.YouMatterTitle : configJSON.TrainerMPowerYouMatter.MpowerTitle}*`}
            handleChange={props.handleChange}
            name={configJSON.TrainerMPowerYouMatter.Title}
            disabled={props.readyToSchedule}
            value={props.states.addMpowerYouMatter.title}
            placeholder={props.isYouMatter ? configJSON.TrainerMPowerYouMatter.EnterYouMatterTitle : configJSON.TrainerMPowerYouMatter.EnterMpowerTitle}
        />

        <Grid container className={classes.dropDownWrapper} spacing={2}>
            <React.Fragment>
                {gridItems.map((item: DropdownProps) => (
                    <Grid item lg={3} xl={3} md={3} sm={12} xs={12} key={item.id}>
                        <CommonSelect
                            data-test-id={item.dataTestId}
                            handleChange={props.handleChange}
                            value={item.value}
                            name={item.name}
                            menuList={item.menuList}
                            label={item.label}
                            placeholder={item.placeholder}
                            onClear={() => props.clearSelect(item.name, configJSON.TrainerMPowerYouMatter.Form)}
                            disabled={props.readyToSchedule}
                        />
                    </Grid>
                ))}
            </React.Fragment>
        </Grid>

        {<Typography className={classes.formTitles}>{!props.isYouMatter ? configJSON.TrainerMPowerYouMatter.MpowerDescription : configJSON.TrainerMPowerYouMatter.YouMatterDescription} </Typography>}

        <Grid container className={classes.noOfSectionWrapper} spacing={2}>
            {!props.isYouMatter && <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                <CommonSelect
                    data-test-id='difficulty'
                    disabled={props.readyToSchedule}
                    handleChange={props.handleChange}
                    name={configJSON.TrainerMPowerYouMatter.Difficulty}
                    placeholder={configJSON.TrainerMPowerYouMatter.DifficultyInCaps}
                    value={props.states.addMpowerYouMatter.difficulty}
                    menuList={difficultyDropdownList}
                    onClear={() => props.clearSelect(configJSON.TrainerMPowerYouMatter.Difficulty, configJSON.TrainerMPowerYouMatter.Form)}
                    label={`${configJSON.TrainerMPowerYouMatter.DifficultyInCaps}*`} />
            </Grid>}
            <Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
                <CommonTextField
                    data-test-id='activation'
                    type={configJSON.TrainerMPowerYouMatter.Number}
                    handleChange={props.handleChange}
                    name={configJSON.TrainerMPowerYouMatter.ActivateAfter}
                    value={props.states.addMpowerYouMatter.activateAfter}
                    label={configJSON.TrainerMPowerYouMatter.ActivateLabel}
                    error={Boolean(props.states.timeClockErrors.activateAfter)}
                    helperText={props.states.timeClockErrors.activateAfter}
                    placeholder={configJSON.TrainerMPowerYouMatter.ActivateAfterPlaceholder}
                    disabled={props.readyToSchedule}
                />
            </Grid>
            <Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
                <CommonTextField
                    data-test-id='duration'
                    type={configJSON.TrainerMPowerYouMatter.Number}
                    value={props.states.addMpowerYouMatter.duration}
                    handleChange={props.handleChange}
                    name={configJSON.TrainerMPowerYouMatter.Duration}
                    error={Boolean(props.states.timeClockErrors.duration)}
                    helperText={props.states.timeClockErrors.duration}
                    label={configJSON.TrainerMPowerYouMatter.DurationLabel}
                    placeholder={configJSON.TrainerMPowerYouMatter.DurationPlaceholder}
                    disabled={props.readyToSchedule}
                />
            </Grid>
        </Grid>

        <Typography className={classes.formTitles}>{configJSON.TrainerMPowerYouMatter.NoOfSections}</Typography>
        <Grid container className={classes.noOfSectionWrapper} spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography className={classes.sectionTitle}>{configJSON.TrainerMPowerYouMatter.SectionsName}</Typography>
                {props.states.addMpowerYouMatter.fields.map((element: Fields) => {
                    return <CommonTextField
                        key={element.id}
                        name="section"
                        data-test-id='section-name'
                        handleChange={(e: any) => props.handleSectionsQuestions(e, configJSON.TrainerMPowerYouMatter.Section, element.id)}
                        value={element.sectionName}
                        error={element.errors.section !== ""}
                        helperText={element.errors.section}
                        placeholder={configJSON.TrainerMPowerYouMatter.EnterSectionName}
                        disabled={props.readyToSchedule}
                    />
                })}
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography className={classes.sectionTitle}>Total Questions</Typography>
                {props.states.addMpowerYouMatter.fields.map((element: Fields) => {
                    return <CommonTextField
                        key={element.id}
                        data-test-id='total-question'
                        name={configJSON.TrainerMPowerYouMatter.Questions}
                        type={configJSON.TrainerMPowerYouMatter.NumberType}
                        handleChange={(e: any) => props.handleSectionsQuestions(e, configJSON.TrainerMPowerYouMatter.Questions, element.id)}
                        error={element.errors.questions !== ""}
                        helperText={element.errors.questions}
                        value={element.totalQuestions}
                        disabled={props.readyToSchedule}
                        placeholder={configJSON.TrainerMPowerYouMatter.EnterTotalQuestions}
                    />
                })}
            </Grid>
        </Grid>

        <Box className={classes.footer}>
            <Box>
                {!props.readyToSchedule && <> <Button
                    className={classes.addBtn}
                    data-test-id='add'
                    disabled={!isSectionORQuestionsValid || totalAddedQuestion >= props.states.addMpowerYouMatter.totalQuestionsInQuestionBank}
                    onClick={() => props.handleAddFields(Math.abs(totalAddedQuestion - props.states.addMpowerYouMatter.totalQuestionsInQuestionBank))}>
                    <img src={addFields} alt="add_icon" />
                </Button>
                    {fieldsLength > 1 && <Button data-test-id='remove' onClick={() => props.handleRemoveField(fieldsLength)} >
                        <img src={removeFieldsIcon} alt="" />
                    </Button>}
                </>}
            </Box>
            <Button
                className={classes.nextBtn}
                data-test-id='next'
                disabled={cumulativeAns || !isSectionORQuestionsValid}
                onClick={() => props.readyToSchedule ? props.handleOpenSchedule(configJSON.TrainerMPowerYouMatter.Confirm) : props.handleFormNext(props.isYouMatter, props.context)} >
                {props.readyToSchedule ? configJSON.TrainerMPowerYouMatter.PublishNow : configJSON.TrainerMPowerYouMatter.Next}
            </Button>
        </Box>
    </Box >
}

export default class AddMpowerYouMatter extends TrainerMpowerYouMatterController {
    static contextType = RootContext;
    async componentDidMount() {
        window.addEventListener("beforeunload", this.unloadCallback);
        window.addEventListener("unload", this.handleUnload);
        const isReload = localStorage.getItem(configJSON.TrainerMPowerYouMatter.IsReload)
        if (isReload) {
            localStorage.removeItem(configJSON.TrainerMPowerYouMatter.IsReload)
            const path = window.location.pathname;
            const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
            this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheck : configJSON.TrainerMPowerYouMatter.TrainerMpowerCheck)
        }
        const addFormData = this.context?.formData?.addFormData;
        const addFormCourseList = this.context?.formData?.addFormCourseList
        const addFormSessionList = this.context?.formData?.addFormSessionList
        const addFormSchoolList = this.context?.formData?.addFormSchoolList
        const addFormSectionList = this.context?.formData?.addFormSectionList
        if (addFormData && !this.state.isContextRestored) {
            this.setState({
                addFormCourseList: addFormCourseList,
                addFormSessionList: addFormSessionList,
                addFormSchoolList: addFormSchoolList,
                addFormSectionList: addFormSectionList,
                addMpowerYouMatter: addFormData,
                isContextRestored: true
            })
        } else {
            this.getAddFormDropdownData(configJSON.TrainerMPowerYouMatter.SelectCourse, this.state.addFormCourseList, this.state.addFormSchoolList);
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        const isCourseOrSchoolFilterChanged = prevState.addMpowerYouMatter.courseName !== this.state.addMpowerYouMatter.courseName || prevState.addMpowerYouMatter.schoolName !== this.state.addMpowerYouMatter.schoolName

        if (isCourseOrSchoolFilterChanged && !this.state.isContextRestored) {
            this.getAddFormDropdownData(prevState.addMpowerYouMatter.courseName, this.state.addMpowerYouMatter.courseName, this.state.addMpowerYouMatter.schoolName);
        }
    }

    async componentWillUnmount() {
        this.setState({ isContextRestored: false })
        localStorage.removeItem(configJSON.TrainerMPowerYouMatter.IsReload)
        window.removeEventListener("beforeunload", this.unloadCallback);
        window.removeEventListener("unload", this.handleUnload);
    }

    render() {
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)

        const addYouMatterProps = {
            goTo: this.goTo,
            handleFormNext: this.handleFormNext,
            isYouMatter: isYouMatter,
            handleSectionsQuestions: this.handleNoOfSections,
            handleAddFields: this.handleAddFields,
            states: this.state,
            handleChange: this.handleOnChangeFormField,
            handleOpenSchedule: this.handleOpenModal,
            context: this.context,
            readyToSchedule: this.context?.formData?.readyToSchedule,
            handleRemoveField: this.handleRemoveField,
            clearSelect: this.handleClearSelect
        }

        const close = (type: string) => {
            if (type === configJSON.ModalTypes.Success) {
                return this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheck : configJSON.TrainerMPowerYouMatter.TrainerMpowerCheck);
            } else {
                this.handleClose()
            }
        }
        const youMatterMpowerText = isYouMatter ? configJSON.TrainerMPowerYouMatter.YouMatter : configJSON.TrainerMPowerYouMatter.MpowerCheck;
        const successText = `Your ${youMatterMpowerText} has been scheduled successfully!`
        const confirmText = `${configJSON.TrainerMPowerYouMatter.ConfirmPublishMsg} ${youMatterMpowerText}?`
        const renderInfo = (type: string) => {
            switch (type) {
                case configJSON.ModalTypes.Success: return successText;
                case configJSON.ModalTypes.Confirm: return confirmText;
                case configJSON.ModalTypes.Warning: return configJSON.TrainerMPowerYouMatter.NoQuestionBankAlert;
            }
        }

        const renderTitle = (modalType: string) => {
            switch (modalType) {
                case configJSON.ModalTypes.Success: return configJSON.TrainerMPowerYouMatter.Scheduled;
                case configJSON.ModalTypes.Confirm: return configJSON.TrainerMPowerYouMatter.Schedule;
                default: return ''
            }
        }
        return <>
            <SidebarLayout {...this.props}>
                <PageContentLayout goBack={this.goBack} title={isYouMatter ? configJSON.TrainerMPowerYouMatter.CreateNewYouMatter : configJSON.TrainerMPowerYouMatter.CreateNewMpower}>
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <AddMpowerYouMatterForm data-test-id='add-form' {...addYouMatterProps} />
                    <InfoModal
                        title={renderTitle(this.state.modalType)}
                        data-test-id='info-modal'
                        modalType={this.state.modalType}
                        confirmImage={scheduleImg}
                        disabledDate={isYouMatter}
                        submitBtnText={configJSON.ButtonTexts.Yes}
                        canceBtnText={configJSON.ButtonTexts.No}
                        open={this.state.openModal}
                        info={this.state.modalInfo || renderInfo(this.state.modalType)}
                        closeModal={() => close(this.state.modalType)}
                        submit={() => this.handleSubmitYouMatterMpower(this.context)}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
}

