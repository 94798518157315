import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Dropzone from 'react-dropzone'
import { uploadIcon, closeFile } from '../assets'
import LinearProgress from '@material-ui/core/LinearProgress';

const configJSON = require("../config.js");

interface Props {
    states: any;
    handleCloseDropZone: () => void;
    handleOnDrop: (e: any) => void;
    handleCancel: () => void;
    handleDone: () => void;
}

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root': {
            padding: '24px 48px 48px 48px',
            color: '#0056A3',
            fontFamily: "Poppins",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '12px'
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '715px',
            width: '100%'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: 'none'
        }
    },
    dropZone: {
        position: 'relative',
        height: '202px',
        background: 'rgba(0, 86, 163,0.12)',
        borderRadius: '16px',
        marginBottom: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px'
    },
    dropZoneInnerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    },
    uploadProgressBar: {
        "&.MuiLinearProgress-root": {
            height: "48px",
        },
        "&.MuiLinearProgress-colorPrimary": {
            background: "#E0EAF4",
            borderRadius: "12px"
        },
        "& .MuiLinearProgress-barColorPrimary": {
            background: 'linear-gradient(127deg, #B3DBFF -41.4%, #4B90CD 15.03%, #0156A2 95.71%)',
            height: "48px",
            borderRadius: "12px"
        }
    },
    uploadDoneBtn: {
        "&.MuiButton-root": {
            height: '48px',
            width: '175px',
            borderRadius: "12px",
            backgroundColor: "#0056A3"
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        }
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogTitleText: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '48px',
        '@media(max-width:575px)': {
            fontSize: '22px',
            lineHeight: '26px',
        }
    },
    uploadProgressSubWrapper: {
        position: "absolute",
        left: "16px",
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '65%',
        width: '100%'
    },
    progressText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    progressBarWrapper: {
        position: 'relative',
        marginBottom: '24px'
    },
    fileName: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        color: 'white',
        fontFamily: "Poppins",
    },
    fileSize: {
        fontWeight: 300,
        fontSize: '10px',
        lineHeight: '14px',
        color: 'white',
        fontFamily: "Poppins",
        fontStyle: 'italic'
    },
    uploadData: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        fontSize: '14px'
    },
    uploadCancelBtn: {
        background: 'transparent',
        border: 'none',
        color: '#0056A3',
        fontSize: '14px',
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        fontWeight: 500,
        cursor: 'pointer'
    },
    closeChosenFile: {
        background: 'transparent',
        border: 'none',
        position: 'relative',
        right: '22px',
        cursor: 'pointer'
    },
    hiddenDiv: {
        content: '',
        visibility: 'hidden'
    },
    uploadedImg: {
        maxWidth: '350px',
        height: '100%',
        '@media(max-width:575px)': {
            maxWidth: '200px'
        }
    },
    uploadedPdf: {
        width: '325px',
        height: '100%'
    },
    backdrop: {
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(8, 77, 149, 0.481206)'
        }
    },
});


const UploadAttachment = (props: Props) => {
    const classes = useStyles();
    const { handleCloseDropZone, handleOnDrop, handleDone, handleCancel, states } = props;

    const fileExtension = states.attachmentName.split('.').pop();
    return (
        <div>
            <Dialog className={classes.dialog} data-test-id="dialogWrapper" onClose={handleCloseDropZone} BackdropProps={{ className: classes.backdrop }} aria-labelledby="simple-dialog-title" open={props.states.openAttachmentDropZone}>
                <DialogTitle id="simple-dialog-title" className={classes.dialogTitle} >
                    <Typography className={classes.dialogTitleText} > {`Q${props.states.attachmentQuestionNumber}` } {configJSON.YouMatterTexts.YourSolution} : </Typography>
                </DialogTitle>
                <Dropzone data-test-id="dropzone" onDrop={handleOnDrop} accept={{
                    'image/jpeg': [],
                    'image/png': [],
                    'application/pdf': [],
                    'application/msword': []
                }} >
                    {({ getRootProps, getInputProps }) => (
                        <section className={classes.dropZone}>
                            {!props.states.tempAttachmentUrl &&
                                <div {...getRootProps()} className={classes.dropZoneInnerWrapper}>
                                    <img src={uploadIcon} alt="" />
                                    <input {...getInputProps()} />
                                    <p>{configJSON.YouMatterTexts.DragAndDropText}</p>
                                </div>}
                            {states.tempAttachmentUrl && fileExtension !== configJSON.YouMatterTexts.PDF && fileExtension !== configJSON.YouMatterTexts.DOC && <img className={classes.uploadedImg} src={states.tempAttachmentUrl} alt='' />}
                            {states.tempAttachmentUrl && fileExtension === configJSON.YouMatterTexts.PDF && <object className={classes.uploadedPdf} data={states.tempAttachmentUrl} type={configJSON.YouMatterTexts.ApplicationPdf}></object>}
                            {states.tempAttachmentUrl && fileExtension === configJSON.YouMatterTexts.DOC &&  <span>Your doc file is uploaded</span> }
                        </section>
                    )}
                </Dropzone>
                {states.attachmentName && <Box className={classes.progressBarWrapper}>
                    <Box className={classes.uploadData}>
                        {`${states.progress < 100 ? 0 : 1}`} {configJSON.YouMatterTexts.Of1Uploaded}
                        {states.progress < 100 && <button data-test-id='cancelUpload' className={classes.uploadCancelBtn} onClick={handleCancel} >{configJSON.YouMatterTexts.Cancel}</button>}
                    </Box>
                    <Box className={classes.uploadProgressSubWrapper}>
                        <Box className={classes.progressText}>
                            <Typography className={classes.fileName} >{states.attachmentName}</Typography>
                            <Typography className={classes.fileSize}>{states.attachmentSize}</Typography>
                        </Box>
                        {states.progress === 100 && <button data-test-id='cancelUpload' className={classes.closeChosenFile} onClick={handleCancel} ><img src={closeFile} /></button>}
                    </Box>
                    <LinearProgress className={classes.uploadProgressBar} variant='determinate' value={states.progress} />
                </Box>}
                <Box className={classes.dialogFooter}>
                    <div className={classes.hiddenDiv}></div>
                    <Button data-test-id='uploadDone' className={classes.uploadDoneBtn} onClick={handleDone} >{configJSON.YouMatterTexts.Done}</Button>
                </Box>
            </Dialog>
        </div>
    );
}
export default UploadAttachment