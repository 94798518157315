import React from "react";
import { Box, Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../../components/src/Banner";
import CopyRightFooter from "../../../../components/src/CopyRightFooter.web";
import Shop from "./Shop.web";
import FlotingButton from "../../../../components/src/FlotingButton.web";
const configJSON = require("../config");
const { shopBannerImage } = require("../assets");
import Footer from "../../../../components/src/Footer.web";
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}
const { heading, content } = configJSON.ShopPageContent;
const bannerContent: Content = {
  headerText: heading,
  contentText: content,
  fontSize: "48px",
  color: "#FFFFFF",
  secondColor: "#FFFFFF",
  paragraphFontSize: "1rem"
};

const useStyles = makeStyles(() => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  }
}));
const ShopPage = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.relativeParent}>
        <Banner
          content={bannerContent}
          bannerImage={shopBannerImage}
          showBtn={false}
        />
        <FlotingButton ourPartner={false} />
        <Box>
          <Container>
            <Grid container>
              <Shop navigation={undefined} id={""} />
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box >
        <Footer />
      </Box>
      {/* <CopyRightFooter /> */}
    </>
  );
};

export default ShopPage;
