// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import './App.css';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import Navigation from '../../components/src/Navigation.web';
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import Footer from '../../components/src/Footer.web';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LoginForm from '../../blocks/email-account-login/src/LoginForm.web';
import CourseDetails from '../../blocks/dashboard/src/MindMastery/CourseDetails.web';
import MindMastery from '../../blocks/dashboard/src/MindMastery/MindMastery.web';
import ProgramScheduler from '../../blocks/dashboard/src/ProgramScheduler/ProgramScheduler.web';
import Profile from '../../blocks/dashboard/src/Profile.web';
import SessionsList from '../../blocks/dashboard/src/MindMastery/SessionsList.web';
import FeedBack from '../../blocks/dashboard/src/FeedBack/Feedback.web';
import AddReaderForm from '../../blocks/dashboard/src/Report/AnalysisReport/AnalysisForm.web';
import EditReaderForm from '../../blocks/dashboard/src/Report/AnalysisReport/EditAnalysis.web';
import AnalysisReport from '../../blocks/dashboard/src/Report/AnalysisReport/AnalysisReport.web';
import SchoolFeedback from '../../blocks/dashboard/src/SchoolFeedback/SchoolFeedback.web';
import SessionAttachmentList from '../../blocks/dashboard/src/MindMastery/SessionAttachmentList.web';
import SignupPage from '../../blocks/email-account-registration/src/Signup.web';
import Mpower from '../../blocks/dashboard/src/mpower/Mpower.web';
import VideoView from '../../blocks/dashboard/src/MindMastery/VideoView.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web.tsx';
import QuestionsView from '../../blocks/dashboard/src/mpower/QuestionsView.web';
import ProgramsPage from '../../blocks/landingpage/src/Programs/ProgramsPage.web';
import ParentPage from '../../blocks/landingpage/src/Programs/ParentPage.web';
import EducatorPage from '../../blocks/landingpage/src/Programs/EducatorPage.web';
import CustomPage from '../../blocks/landingpage/src/Programs/CustomPage.web';
import BlossomPage from '../../blocks/landingpage/src/Programs/BlossomPage.web';
import BloomPage from '../../blocks/landingpage/src/Programs/BloomPage.web';
import BudPage from '../../blocks/landingpage/src/Programs/BudPage.web';
import YouMatter from '../../blocks/dashboard/src/YouMatter/YouMatter.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import ProgramForm from '../../blocks/dashboard/src/mpower/ProgramForm.web';
import YouMatterQuestions from '../../blocks/dashboard/src/YouMatter/YouMatterQuestions.web';
import AboutUsPage from '../../blocks/landingpage/src/AboutUs/AboutUsPage.web';
import PartnershipPage from '../../blocks/landingpage/src/Partnership/PartnershipPage.web';
import MpowerYouMatterDashboard from '../../blocks/dashboard/src/Trainer/MpowerYouMatter/MpowerYouMatterDashboard.web';
import AddMpowerYouMatter from '../../blocks/dashboard/src/Trainer/MpowerYouMatter/AddMpowerYouMatter.web';
import TrainerMpowerYouMatterQuestionList from '../../blocks/dashboard/src/Trainer/MpowerYouMatter/TrainerMpowerYouMatterQuestionList.web';
import ShopPage from '../../blocks/landingpage/src/Shop/ShopPage.web';
import SafetyIQPage from '../../blocks/landingpage/src/SafetyIQ/SafetyIQPage.web';
import ProductDetails from '../../blocks/landingpage/src/Shop/ProductDetails.web';
import TrainerMpowerYouMatterQuestionBank from '../../blocks/dashboard/src/Trainer/MpowerYouMatter/TrainerMpowerYouMatterQuestionBank.web';
import TrainerMpowerGuidelines from '../../blocks/dashboard/src/Trainer/MpowerYouMatter/TrainerMpowerGuidelines.web';
import MpowerYouMatterChecks from '../../blocks/dashboard/src/Trainer/MpowerYouMatter/MpowerYouMatterChecks.web';
import RootContextWrapper from '../../components/src/RootContextWrapper';
import ChildsReport from '../../blocks/dashboard/src/Parent/ChildsReport/ChildsReport.web';
import Material from '../../blocks/dashboard/src/Trainer/TrainerMaterial/Material.web';
import VideoViewMaterial from '../../blocks/dashboard/src/Trainer/TrainerMaterial/VideoViewMaterial.web';
import ImpactReport from '../../blocks/dashboard/src/Report/ImpactReport.web';
import Reports from '../../blocks/dashboard/src/Report/Report.web';
import EditStudentReport from '../../blocks/dashboard/src/Trainer/StudentsReport/EditStudentReport.web';
import ParentGuide from '../../blocks/dashboard/src/Trainer/TrainerMaterial/ParentingGuide.web';
import StudentReportList from '../../blocks/dashboard/src/Trainer/StudentsReport/StudentsReportList.web';
import TimelyProgressReport from '../../blocks/dashboard/src/Trainer/TimelyProgressReport/TimelyProgressReport.web';
import ProgramImpactReport from '../../blocks/dashboard/src/Trainer/ProgramImpactReport/ProgramImpactReport.web';
import SeekHelp from '../../blocks/dashboard/src/SeekHelpModule/SeekHelp.web';
import SchoolTimelyReport from '../../blocks/dashboard/src/Report/TimelyProgressReport/SchoolTimelyReport.web';
import SessionMaterial from '../../blocks/dashboard/src/Trainer/TrainerMaterial/SessionMaterial.web';

const navigationHeadersPath = [
  '/',
  '/aboutus',
  '/partnership',
  '/programs',
  '/programs/parent',
  '/programs/educator',
  '/programs/custom',
  '/programs/blossom',
  '/programs/bloom',
  '/programs/bud',
  '/resources',
  '/student/seek-help',
  '/trainer/queries',
  '/parent/assistance',
  '/safetyiq',
  `/details/${window.location.pathname.split('/').pop()}`
];
const navigationFootersPath = [];
const routeMap = {
  SignupPage: {
    component: SignupPage,
    path: '/signup'
  },
  MindMasteryPage: {
    component: MindMastery,
    path: '/mind-mastery',
    exact: true
  },

  TrainerReports: {
    component: Reports,
    path: '/trainer/report',
    exact: true
  },

  SchoolReports: {
    component: Reports,
    path: '/school/report',
    exact: true
  },

  ImpactReport: {
    component: ImpactReport,
    path: '/school/report/impact-report',
    exact: true
  },

  ProgramSchedulerPage: {
    component: ProgramScheduler,
    path: '/student/program-schedule',
    exact: true
  },
  TrainerProgramSchedulerPage: {
    component: ProgramScheduler,
    path: '/trainer/program-schedule',
    exact: true
  },
  SchoolProgramSchedulerPage: {
    component: ProgramScheduler,
    path: '/school/program-schedule',
    exact: true
  },
  ParentProgramSchedulerPage: {
    component: ProgramScheduler,
    path: '/parent/program-schedule',
    exact: true
  },
  Profile: {
    component: Profile,
    path: '/dashboard/profile',
    exact: true
  },
  FeedBack: {
    component: FeedBack,
    path: '/student/feedback-ratings',
    exact: true
  },
  FeedBackReport: {
    component: SchoolFeedback,
    path: '/school/feedback-report',
    exact: true
  },
  DashboardPage: {
    component: Dashboard,
    path: '/dashboard',
    exact: true
  },
  CourseDetailsPage: {
    component: CourseDetails,
    path: '/mind-mastery/courses/:courseId',
    exact: true
  },
  SessionAttachmentListPage: {
    component: SessionAttachmentList,
    path: '/mind-mastery/courses/:courseId/sessions/:sessionId/:listType',
    exact: true
  },
  SessionsListPage: {
    component: SessionsList,
    path: '/mind-mastery/courses/:courseId/sessions',
    exact: true
  },
  VideoPageMindMastery: {
    component: VideoView,
    path: '/mind-mastery/courses/:courseId/sessions/:sessionId/videos/:videoId',
    exact: true
  },
  VideoPage: {
    component: VideoViewMaterial,
    path: '/VideoViewMaterial',
    exact: true
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  LoginForm: {
    component: LoginForm,
    path: '/LoginForm'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },

  YouMatter: {
    component: YouMatter,
    path: '/student/you-matter',
    exact: true
  },
  YouMatterQuestions: {
    component: YouMatterQuestions,
    path: '/student/you-matter/questions/:youMatterId',
    exact: true
  },
  ProgramForm: {
    component: ProgramForm,
    path: '/student/mpower/forms/:mPowerFormType'
  },
  AboutUsPage: {
    component: AboutUsPage,
    path: '/aboutus',
    exact: true
  },
  Mpower: {
    component: Mpower,
    path: '/student/Mpower',
    exact: true
  },
  QuestionsView: {
    component: QuestionsView,
    path: '/student/Mpower/QuestionsView/:sessionId',
    exact: true
  },
  ProgramsPage: {
    component: ProgramsPage,
    path: '/Programs',
    exact: true
  },
  ParentPage: {
    component: ParentPage,
    path: '/Programs/Parent',
    exact: true
  },
  EducatorPage: {
    component: EducatorPage,
    path: '/Programs/Educator',
    exact: true
  },
  CustomPage: {
    component: CustomPage,
    path: '/Programs/Custom',
    exact: true
  },
  BlossomPage: {
    component: BlossomPage,
    path: '/Programs/Blossom',
    exact: true
  },
  BloomPage: {
    component: BloomPage,
    path: '/Programs/Bloom',
    exact: true
  },
  BudPage: {
    component: BudPage,
    path: '/Programs/Bud',
    exact: true
  },
  PartnershipPage: {
    component: PartnershipPage,
    path: '/partnership',
    exact: true
  },
  ShopPage: {
    component: ShopPage,
    path: '/resources',
    exact: true
  },
  ProductDetails: {
    component: ProductDetails,
    path: '/details/:id'
  },
  SafetyIQPage: {
    component: SafetyIQPage,
    path: '/safetyiq'
  },
  ContactUsPage: {
    component: LandingPage,
    path: '/#contact-us',
    exact: true
  },
  AnalysisReport: {
    component: AnalysisReport,
    path: '/trainer/report/needanalysis-report',
    exact: true
  },
  SchoolReport: {
    component: AnalysisReport,
    path: '/school/report/needanalysis-report',
    exact: true
  },
  AddReaderForm: {
    component: AddReaderForm,
    path: '/trainer/needanalysis-report/add',
    exact: true
  },
  EditReaderForm: {
    component: EditReaderForm,
    path: '/trainer/needanalysis-report/Edit/:EditId'
  },
  TrainerYouMatterCheck: {
    component: MpowerYouMatterDashboard,
    path: '/trainer/you-matter',
    exact: true
  },
  TrainerMaterial: {
    component: Material,
    path: '/trainer/trainer-material',
    exact: true
  },
  TrainerSessionWiseMaterial: {
    component: SessionMaterial,
    path: '/trainer/trainer-material/session-material/:courseId',
    exact: true
  },
  CreateYouMatter: {
    component: AddMpowerYouMatter,
    path: '/trainer/you-matter/add',
    exact: true
  },
  TrainerYouMatterQuestionList: {
    component: TrainerMpowerYouMatterQuestionList,
    path: '/trainer/you-matter/question-list',
    exact: true
  },
  TrainerYouMatterQuestionBank: {
    component: TrainerMpowerYouMatterQuestionBank,
    path:
      '/trainer/you-matter/question-bank/section/:sectionId/total/:totalQuestions',
    exact: true
  },
  TrainerYouMatterEvaluation: {
    component: MpowerYouMatterChecks,
    path:
      '/trainer/you-matter/checks/you-matter-id/:mpowerYouMatterId/student/:studentId',
    exact: true
  },
  TrainerYouMatterUpdateEvaluation: {
    component: MpowerYouMatterChecks,
    path:
      '/trainer/you-matter/update-checks/you-matter-id/:mpowerYouMatterId/student/:studentId',
    exact: true
  },
  TrainerYouMatterCheckDashoard: {
    component: MpowerYouMatterDashboard,
    path:
      '/trainer/you-matter/checks-dashboard/you-matter-id/:mpowerYouMatterId/school/:schoolName/grade/:gradeName/section/:sectionName',
    exact: true
  },
  TrainerMpowerGuidelines: {
    component: TrainerMpowerGuidelines,
    path: '/trainer/Mpower/guide-lines',
    exact: true
  },
  TrainerMpowerCheck: {
    component: MpowerYouMatterDashboard,
    path: '/trainer/Mpower',
    exact: true
  },
  CreateMpower: {
    component: AddMpowerYouMatter,
    path: '/trainer/Mpower/add',
    exact: true
  },
  TrainerMpowerQuestionList: {
    component: TrainerMpowerYouMatterQuestionList,
    path: '/trainer/Mpower/question-list',
    exact: true
  },
  TrainerMpowerQuestionBank: {
    component: TrainerMpowerYouMatterQuestionBank,
    path:
      '/trainer/Mpower/question-bank/section/:sectionId/total/:totalQuestions',
    exact: true
  },
  TrainerMpowerEvaluation: {
    component: MpowerYouMatterChecks,
    path:
      '/trainer/Mpower/checks/mpower-id/:mpowerYouMatterId/student/:studentId',
    exact: true
  },
  TrainerMpowerUpdateEvaluation: {
    component: MpowerYouMatterChecks,
    path:
      '/trainer/Mpower/update-checks/mpower-id/:mpowerYouMatterId/student/:studentId',
    exact: true
  },
  TrainerMpowerCheckDashoard: {
    component: MpowerYouMatterDashboard,
    path: '/trainer/Mpower/checks-dashboard/mpower-id/:mpowerYouMatterId/school/:schoolName/grade/:gradeName/section/:sectionName',
    exact: true
  },
  ChildsReport: {
    component: ChildsReport,
    path: '/parent/child-report',
    exact: true
  },
  ParentMaterial: {
    component: Material,
    path: '/parent/parent-material',
    exact: true
  },
  ParentSessionWiseMaterial: {
    component: SessionMaterial,
    path: '/parent/parent-material/session-material/:courseId',
    exact: true
  },
  SeekHelp: {
    component: SeekHelp,
    path: '/student/seek-help'
  },
  SeekHelpTrainer: {
    component: SeekHelp,
    path: '/trainer/queries'
  },
  SeekHelpParent: {
    component: SeekHelp,
    path: '/parent/assistance'
  },
  TrainerStudentReport: {
    component: StudentReportList,
    path: '/trainer/report/student-report',
    exact: true
  },
  EditStudentReport: {
    component: EditStudentReport,
    path: '/trainer/report/editStudentReport/:studentId',
    exact: true
  },
  ProgramImpactReport: {
    component: ProgramImpactReport,
    path: '/trainer/report/program-impact-report',
    exact: true
  },
  TimelyProgressReport: {
    component: TimelyProgressReport,
    path: '/trainer/report/timely-progress-report',
    exact: true
  },
  ParentGuideOption: {
    component: ParentGuide,
    path: '/parent/guide',
    exact: true
  },
  SchoolTimelyReport: {
    component: SchoolTimelyReport,
    path: '/school/report/timely-progress-report',
    exact: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

const font = "'Poppins', sans-serif";
const theme = createTheme({
  typography: {
    fontFamily: font
  }
});
class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    // return (
    //   <View>
    //     {location.pathname === '/' ? <TopNav /> : null}
    //     {WebRoutesGenerator({ routeMap })}
    //     {/* <ModalContainer /> */}
    //   </View>
    //   )

    return (
      <ThemeProvider theme={theme}>
        <View>
          {navigationHeadersPath.includes(location.pathname) ? (
            <Navigation />
          ) : null}
          {/* <Navigation /> */}
          <RootContextWrapper>
            {WebRoutesGenerator({ routeMap })}
          </RootContextWrapper>
          {/* <ModalContainer /> */}
          {navigationFootersPath.includes(location.pathname) ? (
            <Footer />
          ) : null}
        </View>
      </ThemeProvider>
    );
  }
}

export default App;
