import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "react-native-elements";

interface Props {
    fontSize?: string;
    background?: string;
    height?: string;
    fontWeight?: number;
    padding?: string;
    borderRadius?: string;
    color?: string;
    hoverColor?: string;
    text: string;
    icon?: string;
    boxShadow?:string
    callback?:any

}

const useStyles = makeStyles<Theme, Props>((theme) => ({
    btn: {
        fontSize: (props) => props.fontSize ?? '16px',
        background: (props) => props.background ?? '#052B62',
        height: (props) => props.height ?? '64px',
        fontWeight: (props) => props.fontWeight ?? 600,
        padding: (props) => props.padding ?? '0 20px',
        borderRadius: (props) => props.borderRadius ?? '10px',
        color: (props) => props.color ?? '#fff',
        boxShadow:(props) => props.boxShadow??`none`,
        textTransform: 'capitalize',
        letterSpacing: '1px',
        '&:hover': {
            background: (props) => props.hoverColor ?? '#052B62',
        }
    },
    arrowIcon: {
        paddingLeft: '15px',
    }
}));


const GenericWebButton = (props: Props) => {
    const classes = useStyles(props);
    return <>
        <Button className={classes.btn} onClick={props.callback}>
            {props.text}
            {props.icon != undefined && <img src={props.icon} width="33px" height='33px' className={classes.arrowIcon} />}
        </Button>
    </>
}
export default GenericWebButton;
