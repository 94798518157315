import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";


let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

// Customizable Area Start
const CommonState = {
  selectedSchool: { name: "", id: 0 },
  selectedCourse: { name: "", id: 0, overview: "" },
  academic_year: "",
  impactMeasurement: "",
  stories_of_impact: [{ id: 1, story: "" }],
  pre_quanti: "",
  post_quanti: "",
  pre_story_of_impact: "",
  post_story_of_impact: "",
  message_from_our_educators: [{ id: 1, message: "" }, { id: 2, message: "" }, { id: 3, message: "" }],
  feedback_and_improvements: "",
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentPage: string;
  pageNo: any;
  reportData: any;
  user_token: any;
  schoolCourseData: any;
  schoolList: any;
  courseList: any;
  selectedSchool: any;
  selectedCourse: any;
  academic_year: string;
  impactMeasurement: string;
  stories_of_impact: any;
  pre_quanti: string;
  post_quanti: string;
  pre_story_of_impact: string;
  post_story_of_impact: string;
  message_from_our_educators: any;
  feedback_and_improvements: string;
  reportSubmittedModal: boolean;
  deleteModal: boolean;
  snackBarIsOpen: boolean;
  snackBarMsgPir: string;
  deleteReportId: string;
  editReportId: any;
  editMode: boolean;
  validationErrors: any;
  // Customizable Area End
}
interface SS { id: any }

export default class ProgramImpactReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCourseListApiCallId: any;
  PostSubmitFormapiCallId: any;
  GetReportDataApiCallId: any;
  DeleteReportApiCallId: any;
  EditReportApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      ...CommonState,
      currentPage: "pirList",
      pageNo: 1,
      reportData: {},
      user_token: "",
      schoolCourseData: {},
      schoolList: [],
      courseList: [],
      reportSubmittedModal: false,
      deleteModal: false,
      snackBarIsOpen: false,
      snackBarMsgPir: "",
      deleteReportId: "",
      validationErrors: {
        selectedCourse: false,
        selectedSchool: false,
        academicYear: false,
        impactMeasurement: false,
        storisOfImpact: false,
        preQuanti: false,
        postQuanti: false,
        preStory: false,
        postStory: false,
        messages: false,
        feedback: false
      },
      editReportId: "",
      editMode: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const user_token = await localStorage.getItem("user_token") ?? "";
    this.setState({
      user_token: user_token
    });
    this.getCourseList();
    this.getReportDataPir();
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorMsg) {
        this.setState({ snackBarIsOpen: true, snackBarMsgPir: "Error: Unable to create report." });
        console.log(errorMsg);
      }
      if (responseJson && !responseJson.errors) {
        this.ApiCallSuuccessPir(responseJson, apiRequestCallId);
      } else if (responseJson && responseJson.errors) {
        this.setState({ snackBarIsOpen: true, snackBarMsgPir: responseJson.errors[0] });
        console.log(responseJson, "Error");
      }
    }

  }

  ApiCallSuuccessPir(responseJson: any, apiRequestCallIdPir: any) {

    if (apiRequestCallIdPir === this.GetReportDataApiCallId) {
      this.setState({ reportData: responseJson });
    }

    if (apiRequestCallIdPir === this.GetCourseListApiCallId) {
      const courseDataPir = responseJson.mind_mastery_courses.data.map((courseObjPir: any) => {
        return { title: courseObjPir.attributes.title, value: courseObjPir.attributes.title, id: courseObjPir.attributes.id }
      })

      const schoolDataPir = responseJson.schools.data.map((schoolObjPir: any) => {
        return { title: schoolObjPir.attributes.name, value: schoolObjPir.attributes.name, id: schoolObjPir.attributes.id }
      })
      this.setState({
        schoolList: [...schoolDataPir],
        schoolCourseData: responseJson,
        courseList: [...courseDataPir]
      })
    }

    if (apiRequestCallIdPir === this.PostSubmitFormapiCallId) {
      this.setState({ reportSubmittedModal: true, editMode: false, editReportId: "" });
    }
    if (apiRequestCallIdPir === this.DeleteReportApiCallId) {
      this.setState({ deleteModal: false });
      this.getReportDataPir();
    }

    if (apiRequestCallIdPir === this.EditReportApiCallId) {
      const school = this.state.schoolCourseData.schools.data.filter((el: any) => {
        return el.attributes.id === responseJson.report.attributes.school_id
      })

      const course = this.state.schoolCourseData.mind_mastery_courses.data.filter((course: any) => {
        return course.attributes.id === responseJson.report.attributes.mind_mastery_course_id;
      })

      const storiesOfImpact = responseJson.report.attributes.stories_of_impact.map((el: any, index: number) => {
        return { id: index + 1, story: el }
      })

      const messages = responseJson.report.attributes.message_from_our_educators.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      this.handleInputChange({ target: { value: course[0]?.attributes.title } }, "coursePir");
      this.handleInputChange({ target: { value: school[0]?.attributes.name } }, "schoolPir");
      this.setState({
        academic_year: responseJson.report.attributes.academic_year,
        impactMeasurement: responseJson.report.attributes.impact_measurement,
        stories_of_impact: storiesOfImpact,
        pre_quanti: responseJson.report.attributes.comparative_analysis.pre_quanti,
        post_quanti: responseJson.report.attributes.comparative_analysis.post_quanti,
        pre_story_of_impact: responseJson.report.attributes.comparative_analysis.pre_story_of_impact,
        post_story_of_impact: responseJson.report.attributes.comparative_analysis.post_story_of_impact,
        message_from_our_educators: messages,
        feedback_and_improvements: responseJson.report.attributes.feedback_and_improvements
      })
    }
  }
  // Customizable Area End


  goBack = () => {
    if (this.state.currentPage === configJSON.ImpactReport.PirList) {
      this.props.navigation.goBack();
    } else {
      this.setState({ currentPage: configJSON.ImpactReport.PirList, editMode: false })
      this.handleClearState();
    }
  };

  handleClearState() {
    this.setState({
      ...CommonState
    })
  }

  handlePublishClickPir = () => {
    this.setState({ currentPage: "form" })
  }

  handleInputChange(event: any, targetPir: string) {
    switch (targetPir) {
      case configJSON.ImpactReport.CoursePir:
        const idOverviewPir = this.state.schoolCourseData.mind_mastery_courses.data.filter((coursePir: any) => {
          return coursePir.attributes.title === event.target.value;
        })
        this.setState({
          selectedCourse: { name: event.target.value, id: idOverviewPir[0]?.id, overview: idOverviewPir[0]?.attributes.overview },
          validationErrors: { ...this.state.validationErrors, selectedCourse: false }
        });
        break;

      case configJSON.ImpactReport.PreQuantiName:
        this.setState({ pre_quanti: event.target.value, validationErrors: { ...this.state.validationErrors, preQuanti: false } });
        break;

      case configJSON.ImpactReport.YearPir:
        this.setState({ academic_year: event.target.value, validationErrors: { ...this.state.validationErrors, academicYear: false } });
        break;

      case configJSON.ImpactReport.ImpactMeasurementKey:
        this.setState({ impactMeasurement: event.target.value, validationErrors: { ...this.state.validationErrors, impactMeasurement: false } });
        break;

      case configJSON.ImpactReport.SchoolPir:
        const school = this.state.schoolCourseData.schools.data.filter((school: any) => {
          return school.attributes.name === event.target.value;
        })
        this.setState({
          selectedSchool: { name: event.target.value, id: school[0]?.id },
          validationErrors: { ...this.state.validationErrors, selectedSchool: false }
        });
        break;

      case configJSON.ImpactReport.PostQuantiName:
        this.setState({ post_quanti: event.target.value, validationErrors: { ...this.state.validationErrors, postQuanti: false } });
        break;

      case configJSON.ImpactReport.PreStory:
        this.setState({ pre_story_of_impact: event.target.value, validationErrors: { ...this.state.validationErrors, preStory: false } });
        break;

      case configJSON.ImpactReport.PostStory:
        this.setState({ post_story_of_impact: event.target.value, validationErrors: { ...this.state.validationErrors, postStory: false } });
        break;

      case configJSON.ImpactReport.Feedback:
        this.setState({ feedback_and_improvements: event.target.value, validationErrors: { ...this.state.validationErrors, feedback: false } });
        break;
    }
  }

  handleStoryChange(event: any, id: any) {
    const storyToUpdate = this.state.stories_of_impact.find((story: any) => story.id === id);
    storyToUpdate.story = event.target.value;
    const updatedArray = this.state.stories_of_impact.map((story: any) => (story.id === id ? storyToUpdate : story));
    this.setState({ stories_of_impact: [...updatedArray], validationErrors: { ...this.state.validationErrors, storisOfImpact: false } });
  }

  handleEducatorMessageChange(event: any, id: any) {
    const messageToUpdate = this.state.message_from_our_educators.find((message: any) => message.id === id);
    messageToUpdate.message = event.target.value;
    const updatedArray = this.state.message_from_our_educators.map((message: any) => (message.id === id ? messageToUpdate : message));
    this.setState({ message_from_our_educators: [...updatedArray], validationErrors: { ...this.state.validationErrors, messages: false } });
  }

  handleAddNewStory() {
    if (this.state.stories_of_impact.length < 6) {
      let newId = this.state.stories_of_impact.length + 1;
      this.setState({ stories_of_impact: [...this.state.stories_of_impact, { id: newId, story: "" }] })
    }
  }

  handleCreateClick() {
    let stories_of_impact = this.state.stories_of_impact.map((story: any) => {
      return story.story.trim();
    }).filter((item: any) => item !== "")
    let messagesArray = this.state.message_from_our_educators.map((el: any) => {
      return el.message;
    })

    let body = {
      school_id: parseInt(this.state.selectedSchool.id),
      mind_mastery_course_id: parseInt(this.state.selectedCourse.id),
      academic_year: this.state.academic_year,
      overview: this.state.selectedCourse.overview,
      stories_of_impact: [
        ...stories_of_impact
      ],
      comparative_analysis: {
        pre_quanti: this.state.pre_quanti,
        post_quanti: this.state.post_quanti,
        pre_story_of_impact: this.state.pre_story_of_impact,
        post_story_of_impact: this.state.post_story_of_impact
      },
      message_from_our_educators: [...messagesArray],
      feedback_and_improvements: this.state.feedback_and_improvements,
      impact_measurement: this.state.impactMeasurement
    }
    if (this.validateForm()) {
      const header = {
        "Content-Type": configJSON.dashboardContentType,
        Authorization: this.state.user_token
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.PostSubmitFormapiCallId = apiRequest.messageId;
      this.makeApiCallPir(
        apiRequest.messageId,
        this.state.editMode ? configJSON.updateMethod : configJSON.dashboardPostApiMethod,
        this.state.editMode ? configJSON.getprogramImpactReportsEndPoint + `/${this.state.editReportId}` : configJSON.getprogramImpactReportsEndPoint,
        header,
        JSON.stringify(body)
      );
    }
  }


  validateForm() {
    let updateErrorStatus = {
      selectedCourse: false,
      selectedSchool: false,
      academicYear: false,
      impactMeasurement: false,
      storisOfImpact: false,
      preQuanti: false,
      postQuanti: false,
      preStory: false,
      postStory: false,
      messages: false,
      feedback: false
    }
    if (this.state.selectedCourse.name === "") {
      updateErrorStatus.selectedCourse = true
    }
    if (this.state.selectedSchool.name === "") {
      updateErrorStatus.selectedSchool = true
    }
    const regex = /^\d{4}-\d{4}$/;
    const isMatch = regex.test(this.state.academic_year.trim());
    if (!isMatch) {
      updateErrorStatus.academicYear = true
    }
    if (this.state.impactMeasurement.trim() === "") {
      updateErrorStatus.impactMeasurement = true
    }
    const hasOneStory = this.state.stories_of_impact.filter((story: any) => story.story.trim() !== "");
    if (hasOneStory.length < 1) {
      updateErrorStatus.storisOfImpact = true
    }
    if (this.state.pre_quanti.trim() === "") {
      updateErrorStatus.preQuanti = true
    }
    if (this.state.post_quanti.trim() === "") {
      updateErrorStatus.postQuanti = true
    }
    if (this.state.pre_story_of_impact.trim() === "") {
      updateErrorStatus.preStory = true
    }
    if (this.state.post_story_of_impact.trim() === "") {
      updateErrorStatus.postStory = true
    }
    if (this.state.feedback_and_improvements.trim() === "") {
      updateErrorStatus.feedback = true
    }
    const hasOneMessage = this.state.message_from_our_educators.filter((message: any) => message.message.trim() !== "");
    if (hasOneMessage.length < 3) {
      updateErrorStatus.messages = true
    }
    this.setState({ validationErrors: { ...updateErrorStatus } });
    const valuesArray = Object.values(updateErrorStatus);
    const hasValidationError = valuesArray.filter((el: any) => {
      return el === true
    })
    if (hasValidationError.length < 1) {
      return true
    } else return false;
  }

  closeSuccessModalPir = () => {
    this.getReportDataPir();
    this.setState({ reportSubmittedModal: false, currentPage: "pirList" });
    this.handleClearState();
  }

  closeDeleteModalPir = () => {
    this.setState({ deleteModal: false })
  }

  onSnackbarClose() {
    this.setState({ snackBarIsOpen: false })
  }

  handleDeleteIconClick(id: any) {
    this.setState({ deleteModal: true, deleteReportId: id })
  }

  handleEditIconClick(id: any) {
    this.setState({ currentPage: "form", editMode: true, editReportId: id });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.EditReportApiCallId = apiRequest.messageId;
    this.makeApiCallPir(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getprogramImpactReportsEndPoint + `/${id}/edit`,
      header,
    );
  }

  handleDeleteCallPir() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.DeleteReportApiCallId = apiRequest.messageId;
    this.makeApiCallPir(
      apiRequest.messageId,
      configJSON.deleteMethod,
      configJSON.getprogramImpactReportsEndPoint + `/${this.state.deleteReportId}`,
      header,
    );
  }

  handlePageChange(page: any) {
    this.setState({ pageNo: page }, () => this.getReportDataPir())
  }

  getCourseList() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetCourseListApiCallId = apiRequest.messageId;
    this.makeApiCallPir(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getCourseListApiEndPoint,
      header,
    );
  }

  getReportDataPir() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequestPir = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetReportDataApiCallId = apiRequestPir.messageId;
    this.makeApiCallPir(
      apiRequestPir.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getprogramImpactReportsEndPoint,
      header,
      undefined,
      { page: this.state.pageNo }
    );
  }

  async makeApiCallPir(
    uniqueApiCallIdPir: string,
    methodPir: string,
    endpointPir: string,
    headersPir: any,
    bodyPir?: any,
    paramsPir?: any,
  ) {
    let fullURLPir =
      endpointPir.indexOf("://") === -1
        ? config.baseURL + "/" + endpointPir
        : endpointPir;

    let apiResponseMessagePir = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessagePir.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallIdPir
    );
    try {
      const actualUrl = paramsPir ? fullURLPir + "?" + new URLSearchParams({ ...paramsPir }).toString() : fullURLPir;
      let response = await fetch(actualUrl, {
        method: methodPir.toUpperCase(),
        headers: headersPir,
        body: bodyPir
      });
      let responseJsonPir = await response.json();
      //setting Response
      apiResponseMessagePir.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonPir
      );

      apiResponseMessagePir.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonPir
      );
    } catch (errorPir) {
      runEngine.debugLog("RestApiClient Error", errorPir);
      //setting Error
      console.log("Api Error" + JSON.stringify(errorPir));
      apiResponseMessagePir.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessagePir);
  }


  // Customizable Area End
}
