import React from "react";
const configJSON = require("../config");
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const { heading, paragraphs } = configJSON.PartnershipPageContent.welcomeText;

const useStyles = makeStyles(theme => ({
  paragraph: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  breakPara: {
    marginBottom: "8px"
  },
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "44px"
  }
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" className={classes.heading}>
        {heading}
      </Typography>

      <Box sx={{ py: "15px" }}>
        {paragraphs.map((para: string, index: number) => (
          <Box key={index} className={classes.breakPara}>
            <Typography variant="body1" className={classes.paragraph}>
              {para}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Welcome;
