import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import {
	CometChatMessageActions,
	CometChatThreadedMessageReplyCount,
	CometChatReadReceipt,
} from "../";
import { CometChatMessageReactions } from "../Extensions";
import { CometChatAvatar } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import { checkMessageForExtensionsData } from "../../../util/common";

import { theme } from "../../../resources/theme";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageThumbnailStyle,
	messageDetailStyle,
	nameWrapperStyle,
	nameStyle,
	messageFileContainerStyle,
	messageFileWrapperStyle,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	iconStyle,
} from "./style";

import fileIcon from "./resources/file-upload.svg";
import moment from 'moment'
import 'moment-timezone'

class CometChatReceiverFileMessageBubble extends React.Component {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.state = {
			isHovering: false,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		return (
			currentMessageStr !== nextMessageStr ||
			this.state.isHovering !== nextState.isHovering
		);
	}

	handleMouseHover = () => {
		this.setState(this.toggleHoverState);
	};

	toggleHoverState = (state) => {
		return {
			isHovering: !state.isHovering,
		};
	};

	dateShow = (date) => {
		const indianTime = moment.unix(date).tz('Asia/Kolkata');
		const formattedDate = indianTime.format('Do MMM YYYY, h:mm A');
		return <div>{formattedDate}</div>;
	};

	render() {
		const { message } = this.props;
		const pinnedMessages = localStorage.getItem("pinMessages");
		const pinMessages = pinnedMessages ? JSON.parse(pinnedMessages) : null;
		const isPinned = pinMessages?.some((msg) => msg.data.id === message.id);
		let avatar = null;

		const someDate = this.props.message.sentAt
		const formattedDate = this.dateShow(someDate);

		if (this.props.message.receiverType === CometChat.RECEIVER_TYPE.USER) {
			avatar = (
				<div css={messageThumbnailStyle()} className='message__thumbnail'>
					<CometChatAvatar user={this.props.message.sender} />
				</div>
			);

		
		}

		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(
			this.props.message,
			"reactions"
		);
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div
						css={messageReactionsWrapperStyle()}
						className='message__reaction__wrapper'
					>
						<CometChatMessageReactions
							message={this.props.message}
							actionGenerated={this.props.actionGenerated}
						/>
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = (
				<CometChatMessageActions
					message={this.props.message}
					actionGenerated={this.props.actionGenerated}
				/>
			);
		}

		return (
			<div
				css={messageContainerStyle()}
				className='receiver__message__container message__file'
				onMouseEnter={this.handleMouseHover}
				onMouseLeave={this.handleMouseHover}
			>
				<div css={messageWrapperStyle()} className='message__wrapper'>
					<div css={messageDetailStyle()} className='message__details'>
					<div style={{ display: "flex", justifyContent: "flex-end", position: "relative", top: "35px", right: "10px" }}>{toolTipView}</div>

						<div style={{ height: "24px",display: "flex", gap: "10px",marginTop:"25px",marginLeft:"15px" }}>
							<div style={{position:"relative", bottom:"18px", width:"36px",height:"36px",borderRadius:"50%"}} className='chat__thumbnail'>
								{avatar}
							</div>
							<div style={{ color: "#4C4B48", fontWeight: "bold",marginLeft:"10px" }}>{this.context.item.name}</div>
							<div style={{ color: "#808080", fontSize: "12px", marginLeft: "10px" }}>{formattedDate}</div>
							{isPinned ? <div style={{ background: "#1067B5", borderRadius: "3px", fontWeight: "bold", color: "white", fontSize: "12px", padding: "5px", marginLeft: "20px" }}>Pinned</div> : null}
						</div>

						<div
							css={messageFileContainerStyle()}
							className='message__file__container'
						>
							<div
								css={messageFileWrapperStyle(this.context)}
								className='message__file__wrapper'
							>
								<a
									href={this.props.message.data.attachments[0].url}
									target='_blank'
									rel='noopener noreferrer'
								>
									<i css={iconStyle(fileIcon, this.context)}></i>
									<label>{this.props.message.data.attachments[0].name}</label>
								</a>
							</div>
						</div>

						{messageReactions}

						<div
							css={messageInfoWrapperStyle()}
							className='message__info__wrapper'
						>
							<CometChatReadReceipt message={this.props.message} />
							<CometChatThreadedMessageReplyCount
								message={this.props.message}
								actionGenerated={this.props.actionGenerated}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatReceiverFileMessageBubble.defaultProps = {
	theme: theme,
	actionGenerated: () => { },
};

CometChatReceiverFileMessageBubble.propTypes = {
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatReceiverFileMessageBubble };
