import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CopyRightFooter from "../../../../components/src/CopyRightFooter.web";
const { bookDetail } = require("../assets");
import { ArrowBackIos } from "@material-ui/icons";
import ProductDetailsController, {
  Props
} from "./ProductDetailsController.web";
import parse from "html-react-parser";

const useStyles = makeStyles(theme => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  bottomContentBox: {
    paddingTop: "15px"
  },
  breakPara: {
    marginBottom: "8px"
  },
  paragraph: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  boldParagraph: {
    fontWeight: 600
  },
  imageBox: {
    transform: "translate(-20px, 0)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0, 0)",
      marginBottom: "30px"
    }
  },
  childrenImg: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600,
    marginBottom: "20px"
  },
  list: {
    listStyleType: "disc",
    listStylePosition: "inside",
    margin: 0,
    padding: 0
  },
  listBox: {
    margin: "20px 0"
  },
  listItem: {
    marginTop: "10px",
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  ratingBox: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "250px"
  },
  price: {
    fontWeight: 600,
    fontSize: "35px",
    color: "rgb(254, 64, 89)",
    marginTop: "60px"
  },
  listHeading: {
    color: "#000",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 600,
    margin: "10px 0"
  },
  backBtn: {
    backgroundColor: "#0056A3",
    padding: "10px 0px",
    color: "white"
  },
  detailBox: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    fontFamily: "Poppins"
  }
}));
const BookDetails = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ pb: "100px" }}>
        <Container className={classes.containerStyle}>
          <Box sx={{ py: "100px" }}>
            <Link href={"/resources"}>
              <Button variant="contained" className={classes.backBtn}>
                <ArrowBackIos />
              </Button>
            </Link>
          </Box>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box className={classes.imageBox}>
                <img
                  className={classes.childrenImg}
                  src={bookDetail}
                  width="100%"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box>
                <Typography variant="h3" className={classes.heading}>
                  {props.bookDetails.attributes.title}
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  {props.bookDetails.attributes.description}
                </Typography>
                <Box className={classes.detailBox}>
                  {parse(props.bookDetails.attributes.detail)}
                </Box>
                <Typography variant="body1" className={classes.price}>
                  ₹ {props.bookDetails.attributes.price}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <CopyRightFooter />
    </>
  );
};
class ProductDetails extends ProductDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <BookDetails bookDetails={this.state.bookDetails} />;
  }
}
export default ProductDetails;
