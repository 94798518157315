import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UtilityInfo from "../../../components/src/UtilityInfo.web";
import ReadMore from "../../../components/src/ReadMore";
const configJSON = require("./config");
const feedBackImage = require("./assets");

interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  descriptionWidth: string;
}
const hapchiFamilyContent = configJSON.LandingPageContent.hapchiFamily;
const content: Content = {
  headerText: hapchiFamilyContent.headerText,
  contentText: hapchiFamilyContent.contentText,
  fontSize: "48px",
  color: "#fff",
  secondColor: "rgba(255, 255, 255, 0.6);",
  paragraphFontSize: "14px",
  descriptionWidth: "299px"
};
const feedback = [
  {
    feedbackPara:
      "Children usually do not open up quickly on these topics. But with Dr. Kanika, they are comfortable and participative. She takes care to build a rapport through small activities and then discusses by showing short video clips and elucidating reactions and responses from students. This weaving in of student participation makes her sessions lively and thought-provoking. Students look forward to interacting with her.",
    userName: "Mrs Rajal Chakrabarty",
    relation: "Principal, Eklavya School, Ahmedabad",
    feedbackimage: feedBackImage.feedback1
  },
  {
    feedbackPara:
      "Dear Team members, Your report was well appreciated by the children and the teacher group. I personally feel it was well documented. We will look forward to seeing how we can continue to have a long connection with your organization.",
    userName: "Ms. Saira Bhanu",
    relation: "Principal,  Poorna School",
    feedbackimage: feedBackImage.feedback2
  },
  {
    feedbackPara:
      "The Hapchi workshop was a big success, the children were introduced to the concepts in a subtle, fun and not frightening manner which helped in their understanding and awareness. The parents were very happy that we had such a wonderful workshop which was informative as well as helpful.",
    userName: "Ms. Nishanthi",
    relation: "Co-founder Tapas Progressive School",
    feedbackimage: feedBackImage.feedback3
  }
];
const useStyles = makeStyles(() => ({
  feedbackCard: {
    background: "#fff",
    borderRadius: "12px",
    position: "relative",
    overflow: "hidden"
  },
  whiteBox: {
    width: "100%",
    height: "150px",
    position: "relative",
    borderBottom: "6px solid  #052B62"
  },
  content: {
    paddingTop: "40px",
    padding: "40px 18px",
    minHeight: "212px"
  },
  contentPara: {
    fontSize: "12px",
    color: "#052B62",
    mixBlendMode: "normal",
    opacity: 0.74
  },
  contentUserName: {
    fontSize: "17px",
    color: "#052B62",
    fontWeight: "bold",
    marginTop: "10px",
    overflow: "hidden",
    lineClamp: 2,
    boxOrient: "vertical",
    display: "-webkit-box"
  },
  contentRelation: {
    fontSize: "15px",
    color: "#052B62",
    marginTop: "5px"
  },
  blueBox: {
    background:
      " linear-gradient(90deg, #09549F 0%, #09539E 0.54%, #000428 106.13%)",
    width: "65px",
    height: "65px",
    borderRadius: "100%",
    position: "absolute",
    bottom: "-31px",
    right: " -25px"
  },
  gradientBox: {
    position: "absolute",
    bottom: "-25px",
    left: "20px"
  }
}));
const HapchiFamily = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <UtilityInfo {...content} lineHeight="75px" />
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Grid container spacing={2}>
          {feedback.map((feedbackItem, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Box className={classes.feedbackCard}>
                  <Box className={classes.whiteBox}>
                    <img
                      src={feedbackItem.feedbackimage}
                      width="100%"
                      height={"100%"}
                    />
                    <Box className={classes.gradientBox}>
                      <img
                        src={feedBackImage.qort}
                        width="46px"
                        height="46px"
                      />
                    </Box>
                  </Box>
                  <Box className={classes.content}>
                    <Typography className={classes.contentPara}>
                      <ReadMore sliceLength={190}>
                        {feedbackItem.feedbackPara}
                      </ReadMore>
                    </Typography>
                    <Typography className={classes.contentUserName}>
                      {feedbackItem.userName}
                    </Typography>
                    <Typography className={classes.contentRelation}>
                      {feedbackItem.relation}
                    </Typography>
                  </Box>
                  <Box className={classes.blueBox} />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HapchiFamily;
