import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const { storyImage, ourStoryBg, storyBottomRightShape } = require("../assets");
const configJSON = require("../config");
const storyText = configJSON.AboutUsPageContent.storyText;

const {
  rightParagraphs,
  rightBottomQuotes,
  leftParagraphs,
  heading
} = storyText;

const useStyles = makeStyles(theme => ({
  storyBoxImage: {
    marginBottom: "24px",
    position: "relative",
    "&::after": {
      content: '""',
      width: "77px",
      height: "77px",
      border: "4px solid #fff",
      position: "absolute",
      bottom: "-17px",
      right: "-21px",
      borderRadius: "100%"
    }
  },
  ourStoryItems: {
    backgroundImage: `url(${ourStoryBg})`,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px",
      backgroundPosition: "center"
    },
    display: "flex",
    padding: "0 50px"
  },
  storyImage: {
    position: "relative"
  },
  quoteHeading: {
    color: "#FFF",
    fontSize: "23px",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "34.5px",
    marginBottom: "16px",
    padding: "0 34px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    }
  },
  quotePara: {
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: 1.5,
    fontWeight: 500,
    padding: "8px 34px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
      paddingBottom: "20px"
    }
  },
  rightPara: {
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px"
    }
  },
  paragraph: {
    padding: "0 34px",
    fontWeight: 500,
    fontSize: "16px",
    color: "rgba(255, 255, 255, 0.6)"
  },
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600,
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px"
    }
  },
  bottomRightBg: {
    width: "200px",
    position: "absolute",
    right: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const OurStory = () => {
  const classes = useStyles();
  const [readMoreStatus, readMoreStatusFunction] = React.useState(false);
  const readMoreStatusClick = () => {
    readMoreStatusFunction(!readMoreStatus);
  };
  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3" className={classes.heading}>
            {heading}
          </Typography>
        </Grid>
        <Grid container className={classes.ourStoryItems}>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: "20px" }}>
              <Box className={classes.storyBoxImage}>
                <img
                  className={classes.storyImage}
                  src={storyImage}
                  width="100%"
                />
              </Box>
              <Box className={classes.bottomRightBg}>
                <img
                  src={storyBottomRightShape}
                  alt="story background image"
                  width="100%"
                />
              </Box>
             
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ pt: "20px" }}>
              <Typography variant="body1" className={classes.paragraph} >
                  {leftParagraphs}
              </Typography>
              <Typography variant="h3" className={classes.quoteHeading}>
                {storyText.quote}
              </Typography>
              {readMoreStatus && (
                <>
                  {rightParagraphs.map((para: string, index: number) => (
                    <Box key={index} className={classes.rightPara}>
                      <Typography variant="body1" className={classes.paragraph}>
                        {para}
                      </Typography>
                    </Box>
                  ))}
                  {rightBottomQuotes.map((quote: string, index: number) => (
                    <Typography key={index} className={classes.quotePara}>
                      {quote}
                    </Typography>
                  ))}
                </>
              )}
              <Typography data-test-id={"showMoreOrLess"} onClick={readMoreStatusClick} className={classes.quoteHeading}>
                <span style={{ cursor: 'pointer' }}>
                  {readMoreStatus ? "...Show less" : "...Read more"}
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurStory;
