import React from "react";
const configJSON = require("../config");
const {
  programEducatorBannerImage,
  programEducatorImage
} = require("../assets");
import ProgramLayout from "./GenericComponents/ProgramLayout.web";

const educatorPageContent = configJSON.ProgramsPageContent.educatorPage;
const makeBoldParaNo = 4;
const EducatorPage = () => {
  return (
    <ProgramLayout
      makeBoldParaNo={makeBoldParaNo}
      bannerImage={programEducatorBannerImage}
      contentImage={programEducatorImage}
      programContent={educatorPageContent}
    />
  );
};

export default EducatorPage;
