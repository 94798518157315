import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Collaborator from "../../../components/src/Collaborator.web";
const workWithImg = require("./assets");

const useStyles = makeStyles(() => ({
  workHeading: {
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "72px",
    color: "#052B62"
  },
  borderRight: {
    borderRight: "2px solid #052B62",
    height: "100%",
    display: "flex",
    alignItems: "center"
  }
}));

const worksWith = [
  { title: "Preschools", workImage: workWithImg.work1, link:"/programs/bud" },
  { title: "Highschools", workImage: workWithImg.work2, link:"/programs/blossom"},
  { title: "Educators", workImage: workWithImg.work3, link:"/programs/educator" },
  { title: "Parents", workImage: workWithImg.work4, link:"/programs/parent"}
];
const WorkWith = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Box className={classes.borderRight}>
          <Typography className={classes.workHeading}>
            We work with :{" "}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container spacing={2} className={classes.workHeading}>
          {worksWith.map((workWith, index) => (
            <Collaborator
              key={index}
              link={workWith.link}
              title={workWith.title}
              workImage={workWith.workImage}
              rightArrowIcon={workWithImg.rightArrowIcon}
            />
          ))}
        </Grid>
      </Grid>
      </Grid>
    </>
  );
};
export default WorkWith;
