import React from "react";
import {
  Grid,
  Box,
  Hidden,
  Typography,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
const { valuesImage } = require("../assets");
const configJSON = require("../config");
const valuesText = configJSON.AboutUsPageContent.values;
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "40px",
    fontWeight: 600,
    color: "#052B62"
  },
  content: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  imageBox: {
    maxWidth: "350px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px"
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px"
    }
  },
  bulletPoint: {
    position: "relative",
    margin: "0px",
    "&::before": {
      content: '"\\2022"', // Bullet point character
      position: "absolute",
      left: "-1.5em" // Adjust the position
    }
  }
}));

const Values = () => {
  const classes = useStyles();
  const pointArray = valuesText.contentText
    .split(",")
    .map((point: any) => point.trim());
  return (
    <Box style={{ width: "100%" }} sx={{ pt: "100px" }}>
      <Grid container>
        <Hidden xsDown smDown>
          <Grid item xs={12} md={5}>
            <Box className={classes.imageBox}>
              <img src={valuesImage} width="100%" />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={7}>
          <Box>
            <Typography className={classes.heading}>
              {valuesText.headerText}
            </Typography>
            <List style={{ listStyle: "none" }} className={classes.content}>
              {pointArray.map((point: any, index: any) => (
                <ListItem
                  style={{
                    marginLeft: "1.5em",
                    position: "relative",
                    paddingTop: "unset",
                    paddingBottom: "unset"
                  }}
                  key={index}
                >
                  <ListItemText
                    className={classes.bulletPoint}
                    primary={point}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12} md={5}>
            <Box className={classes.imageBox}>
              <img src={valuesImage} width="100%" />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default Values;
