import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link, List, ListItem, Box, Container } from "@material-ui/core";
import CopyRightFooter from "./CopyRightFooter.web";
const footerImg = require("./assets")
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundImage: `url(${footerImg.footerBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: "64px 80px",
    [theme.breakpoints.down('sm')]: {
      padding: "64px 10px",
    },
  },
  logo: {
    height: '69px',
    width: '232px',
    marginRight: theme.spacing(1),
  },
  social: {
    width: '137px',
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '22px',
  },
  map: {
    width: "100%",
    marginRight: theme.spacing(1),
  },
  ftTitle: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: " 16px",
    textTransform: "uppercase",
    color: "#fff",
    marginBottom: "24px",
  },
  listLink: {
    color: "#fff",
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  listLinkone: {
    color: "#fff",
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    mixBlendMode: 'normal',
    opacity: 0.7,
    '&:hover': {
      color: '#fff',
      opacity: 1,
    }
  },
  listItems: {
    paddingTop: "0",
  },

}));

const footerDetails = {
  "quickLinks": ["Home"],
  "contactUs": [{ image: footerImg.call, content: "+91-6364004764" }, { image: footerImg.message, content: "care@hapchi.in" }],
}

function Footer() {
  const classes = useStyles();
  return (
    <>
      <footer className={classes.footer}>
        <Container>
          <Grid container spacing={4} justifyContent="space-evenly" id="contact-us">
            <Grid item xs={12} sm={12} md>
              <img src={footerImg.footerlogo} alt="Logo" className={classes.logo} />
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.ftTitle}
              >
                Quick Link
              </Typography>
              <List>
                {footerDetails.quickLinks.map((quickLink,index:number) => {
                  return (
                    <ListItem key ={index} className={classes.listItems} disableGutters>
                      <Link className={classes.listLinkone} href="#">
                        <Typography variant="body2" >{quickLink}</Typography>
                      </Link>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.ftTitle}
              >
                Contact us
              </Typography>
              <List>
                {footerDetails.contactUs.map((val,index:number) => {
                  return (
                    <ListItem key={index} className={classes.listItems} disableGutters>
                        <Typography variant="subtitle1" className={classes.listLink}><img src={val.image} alt="call" />&nbsp; {val.content}</Typography>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.ftTitle}
              >
                Address
              </Typography>
              <Typography variant="subtitle1" className={classes.listLink}>
                91 springboard JP Nagar 175 & 176, Bannerghatta Main Rd, Dollars
                Colony, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.ftTitle}
              >
                Map
              </Typography>
              <Link target="_blank" href="https://www.google.co.in/maps/place/91springboard/@12.9036148,77.5986037,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae1519380fc7ad:0x8195f8645dab7f8c!8m2!3d12.9036148!4d77.6011786!16s%2Fg%2F11df29vs4q?entry=ttu">
              <img src={footerImg.map} alt="map" className={classes.map} />
              </Link>
            </Grid>
          </Grid>
        </Container>
      </footer>
      <CopyRightFooter />
    </>
  );
}

export default Footer;
