import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { profile } from "../assets";

interface EventAttributes {
  date: string;
  time: string;
  name: string;
  organizer: Organizer
}

interface Organizer {
  name: string;
  profile_photo_url: string;
}


interface E {
  id: string;
  type: string;
  attributes: EventAttributes;
}

interface UpcomingEventsProps {
  eventsData: E;
  openModal: (id: string) => void;
}

const useStyles = makeStyles(() => ({
  upcomingEventsBox: {
    display: 'flex',
    background: "#FFFFFF",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    marginTop: "12px",
    padding: "12px",
    border: '0.2px solid #e3e3e3'
  },
  layout: {
    flexDirection: "row",
    display: "flex",
    minHeight: "100%"
  },
  dateBox: {
    maxWidth: "54px",
    minWidth: "54px",
    background:
      "linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "8px",    
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF"
  },
  dayLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center"
  },
  dateLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    textAlign: "center"
  },
  timeLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#717171",
    '@media(max-width:767px)': {
      fontSize: '13px'
    }
  },
  eventName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0056A3",
    cursor: "pointer"
  },
  organizerName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#0056A3"
  },
  guestProfilePhoto: {
    height: "24px",
    width: "24px",
    border: "0.5px solid #297FCA",
    borderRadius: "100%",
    marginRight: 10
  },
  rightContent: {
    paddingTop: 8,
    marginLeft: 10
  }
}));

export const UpcomingEvents = (props: UpcomingEventsProps) => {
  const classes = useStyles();
  const evt = props.eventsData;
  const dateString = evt.attributes.date;
  const startTimeString = evt.attributes.time.slice(1, -1).split(" - ")[0];

  const dateStartTimeString = `${dateString} ${startTimeString}`;
  const formatString = "MM-DD-YYYY h:mm a";

  const start = moment(dateStartTimeString, formatString).toDate();
  const day = start.toLocaleString("en-us", { weekday: "short" });
  const date = start.getDate();

  return (
    <>
      <Box className={classes.upcomingEventsBox}>
        <Box className={classes.dateBox}>
          <Typography className={classes.dayLabel}>{day}</Typography>
          <Typography className={classes.dateLabel}>{date}</Typography>
        </Box>

        <Box className={classes.rightContent}>
          {props.eventsData.attributes.name ? (
            <Typography className={classes.eventName} onClick={() => {
              props.openModal(props.eventsData.id);
            }}>
              {props.eventsData.attributes.name}
            </Typography>
          ) : (
            <Skeleton />
          )}
          <Typography className={classes.timeLabel}>
            {evt.attributes.time.slice(1, -1)}
          </Typography>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {props.eventsData.attributes.organizer ? (
              <img
                className={classes.guestProfilePhoto}
                src={props.eventsData.attributes.organizer.profile_photo_url ? props.eventsData.attributes.organizer.profile_photo_url : profile}
              />
            ) : (
              <Skeleton variant="circle" width={40} height={40} />
            )}
            {props.eventsData.attributes.organizer && props.eventsData.attributes.organizer.name ? (
              <Typography className={classes.organizerName}>
                {props.eventsData.attributes.organizer.name}
              </Typography>
            ) : (
              <Skeleton />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
