import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

let config = require("../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
  courseDetailsPageData: any;
  videoViewPageData: any;
  mindMasteryPageData: any;
  sessionListPageData: any;
  sessionAttachmentPageData: any;
  page: number;
  isLoading: boolean;
  videoPlayerDuration: any;
  openModal: boolean;
  modalType: "success" | "warning" | "confirm" | "schedule";
  modalInfo: string;
}
interface SS { }

export default class MindMasteryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  courseDetailsApiCallId: any = "";
  courseListApiCallId: any = "";
  sessionListApiCallId: any = "";
  sessionAttachmentApiCallId: any = "";
  videoDetailApiCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      courseDetailsPageData: [],
      videoViewPageData: [],
      mindMasteryPageData: { data: [], page_options: {} },
      sessionListPageData: { data: [], page_options: {} },
      sessionAttachmentPageData: { data: [], page_options: {} },
      page: 1,
      isLoading: false,
      videoPlayerDuration: 0,
      openModal: false,
      modalType: 'warning',
      modalInfo: ''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.courseListApiCallId:
              this.setState({ mindMasteryPageData: responseJson });
              break;
            case this.sessionListApiCallId:
              this.setState({ sessionListPageData: responseJson });
              break;
            case this.sessionAttachmentApiCallId:
              this.setState({ sessionAttachmentPageData: responseJson });
              break;
          }
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  getToken = () => {
    return localStorage.getItem("user_token");
  };

  handlePage = (page: number) => {
    this.setState({ page: page });
  };

  handleOpenModal = (type: "success" | "warning" | "confirm" | "schedule", info: string) => {
    this.setState({ openModal: true, modalType: type, modalInfo: info })
  }

  handleClose = () => {
    this.setState({ openModal: false })
  }

  async getCourses(page: number) {
    this.setState({ mindMasteryPageData: {} });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseListApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}?page=${page}&per_page=6`,
      header
    );
  }

  async getCourseDetails(courseId: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseDetailsApiCallId = apiRequest.messageId;

    const response = await this.videoApiCall(
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}/${courseId}`,
      header,
      configJSON.MindMasteryTexts.CourseDetailsPageData
    );
    return response;
  }

  getSessionList(courseId: number, page: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sessionListApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}/${courseId}/course_sessions?page=${page}&per_page=6`,
      header
    );
  }

  getSessionAttachmentList(
    courseId: number,
    sessionId: number,
    attachmentType: string,
    page: number
  ) {
    this.setState({ sessionAttachmentPageData: {} })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sessionAttachmentApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}/${courseId}/course_sessions/${sessionId}?attachment_type=${attachmentType}&page=${page}&per_page=6`,
      header
    );
  }

  async getVideoDetails(sessionId: number, videoId: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.videoDetailApiCallId = apiRequest.messageId;

    const response = await this.videoApiCall(
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.CourseSessions}/${sessionId}/${configJSON.MindMasteryTexts.VideoAttachments}/${videoId}`,
      header,
      configJSON.MindMasteryTexts.VideoViewPageData
    );
    return response;
  }

  async videoApiCall(
    method: string,
    endpoint: string,
    headers: any,
    state: string,
    body?: any
  ) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;
    let responseJson = null;

    try {
      this.setState({ isLoading: true });
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      responseJson = await response.json();
      if (responseJson.data) {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
    }
    this.setState({ [state]: responseJson.data } as any);
    return responseJson.data;
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    // Customizable Area Start
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      this.setState({ isLoading: true });
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        localStorage.removeItem('userDetails')
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();
      if (responseJson.data || responseJson.message) {
        this.setState({ isLoading: false });
      }
      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      // if token expires then redirect to login
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessage);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
