import React from "react";
import { Grid, Card, CardContent, Box, Typography } from "@material-ui/core";
const teamMemberImage = require("../assets");
const configJSON = require("../config");
import { makeStyles } from "@material-ui/core/styles";
import AboutUsPageController, { Props } from "./AboutUsPageController";
const ourTeamHeading = configJSON.AboutUsPageContent.ourTeamHeading;

const useStyles = makeStyles(theme => ({
  card: {
    "&.MuiCard-root": {
      maxWidth: "328px",
      minHeight: "380px",
      perspective: "500px",
      textAlign: "center",
      position: "relative",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3",
      [theme.breakpoints.down("xs")]: {
        height: "390px"
      }
    },
    "&.MuiPaper-elevation1": {
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
    }
  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    boxShadow: "0 0 15px rgba(0,0,0,0.1)",
    transition: "transform 1s",
    transformStyle: "preserve-3d",
    "&:hover": {
      transform: "rotateY( 180deg )",
      transition: " transform 0.5s"
    }
  },
  front: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "white",
    lineHeight: "300px",
    color: "#03446A",
    textalign: "center",
    fontSize: "60px",
    borderRadius: "5px",
    backfaceVisibility: "hidden",
    paddingTop: "15px"
  },
  back: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "white",
    borderRadius: "5px",
    padding: "10px",
    backfaceVisibility: "hidden",
    "&": {
      background: "#FFF",
      transform: "rotateY( 180deg )"
    }
  },
  backContainer: {
    textAlign: "left",
    margin: "30px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  backImage: {
    position: "absolute",
    bottom: "20px",
    right: "0",
    left: "0"
  },
  backHeading: {
    fontSize: "24px",
    color: "#0056A3",
    fontWeight: 600,
    paddingBottom: "10px"
  },
  frontHeading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#0056A3",
    lineHeight: '30px'
  },
  backSubHeading: {
    fontSize: "14px",
    color: "#0056A3",
    fontFamily: "Poppins",
    fontWeight: 400,
    paddingBottom: "20px"
  },
  paragraph: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600,
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px"
    }
  }
}));

const TeamLayout = (props: any) => {
  const classes = useStyles();
  return (
    <Box sx={{ py: "100px" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3" className={classes.heading}>
            {ourTeamHeading}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {props?.teamsData?.map((member: any, index: number) => (
          <Grid key={index} item xs={12} md={3} sm={6}>
            <Card className={classes.card}>
              <Box className={classes.content}>
                <Box className={classes.front}>
                  <img src={member.attributes.photo_url} width={"100%"} />
                  <CardContent>
                    <Typography variant="h3" className={classes.frontHeading}>
                      {member.attributes.full_name}
                    </Typography>
                  </CardContent>
                </Box>
                <Box className={classes.back}>
                  <Box className={classes.backContainer}>
                    <Box>
                      <Typography variant="h3" className={classes.backHeading}>
                        {member.attributes.full_name}
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.backSubHeading}
                      >
                        {member.attributes.role}
                      </Typography>
                      <Typography variant="body1" className={classes.paragraph}>
                        {member.attributes.description}
                      </Typography>
                    </Box>
                    <Box className={classes.backImage}>
                      <img src={teamMemberImage.cardBack} width="100%" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

class Team extends AboutUsPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <TeamLayout teamsData={this.state.teamsData?.our_teams?.data} />
  }
}

export default Team;
