/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */

let options: {
    image: { src: string | ArrayBuffer | null };
    readMethod: string;
    accept: string;
  },
  callBack: (arg0: any) => void;

let elFile = document.createElement("input");
elFile.setAttribute("type", "file");
elFile.style.display = "none";
elFile.addEventListener("change", getFile);

function getFile(this: any) {
  if (this.files.length == 0) {
    returnLoadFile({ fail: "no file" });
    return;
  }
  let file = this.files[0];
  let reader = new FileReader();

  reader.addEventListener("load", () => {
    if (options.image) options.image.src = reader.result;
    returnLoadFile({ success: reader.result, file: file });
  });
  reader.addEventListener("abort", () => {
    returnLoadFile({ fail: "abort" });
  });
  reader.addEventListener("error", () => {
    returnLoadFile({ fail: "error" });
  });

  if (options.image || options.readMethod == "readAsDataURL")
    reader.readAsDataURL(this.files[0]);
  else reader.readAsText(this.files[0]);
} // getFile

function returnLoadFile(returnedValue: {
  fail?: string;
  success?: string | ArrayBuffer | null;
  file?: any;
}) {
  callBack(returnedValue);
}

export const uploadFile = (ocallBack: any, ooptions: any) => {
  /* loads a file asynchronously
at the end of the process, calls the function 'callBack' with an object :

{fail: string} in case of failure, where string gives the reason of the failure
or
{success : string, file: file} where string is the content of the image file
   file represents the loaded file, and may be tested for file.type, file.name...

CAUTION ! If the user clicks 'cancel' when loading a file, nothing happens.

options is an object, with 0, one or more of the following properties :
accept : string to pass as "accept" attribute to the load file button, such as '.txt' or 'image/*'
            default : no value (will accept  * . * )
readMethod : 'readAsText' or 'readAsDataURL' - default is readAsText
image: if provided, must be an Image element. If possible, the data is loaded
with readAsDataURL, no matter the value of readMethod, and option.image.src is set to the data.
The function then returns normally as defined above.
Normally, a 'load' event should be triggered on the image.
*/

  options = ooptions;
  callBack = ocallBack;
  if (options.accept) elFile.setAttribute("accept", options.accept);
  else elFile.removeAttribute("accept");
  elFile.click();
};
