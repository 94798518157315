import React, { Component } from "react";


interface OtpInputProps {
  length: number;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  otpError: boolean;
}



class OtpInput extends Component<OtpInputProps> {
  private inputRefs: Array<HTMLInputElement | null>;
  constructor(props: OtpInputProps) {
    super(props);
    this.inputRefs = Array(props.length).fill(null);
  }

  componentDidMount() {
      this.inputRefs[0]?.focus();
  }

  handleChange = (index: number, value: string) => {
    const { length, onChange } = this.props;
    const newValues = [...Array(length)].map((val, i) =>
      i === index ? value : this.state.values[i]
    );
    this.setState({ values: newValues });
    onChange(newValues.join(""));
    if (value && this.inputRefs[index + 1]) {
      this.inputRefs[index + 1]!.focus();
    } else if (!value && this.inputRefs[index - 1]) {
      this.inputRefs[index - 1]!.focus();
    }
  };

  handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const { length, onChange } = this.props;
    const { values } = this.state;

    if (event.key === "Backspace" && !values[index]) {
      event.preventDefault();
      const newValues = [...Array(length)].map((val, i) =>
        i === index - 1 ? "" : values[i]
      );
      this.setState({ values: newValues });
      onChange(newValues.join(""));
      if (this.inputRefs[index - 1]) {
        this.inputRefs[index - 1]!.focus();
      }
    } else if (event.key === "ArrowLeft" && this.inputRefs[index - 1]) {
      this.inputRefs[index - 1]!.focus();
    } else if (event.key === "ArrowRight" && this.inputRefs[index + 1]) {
      this.inputRefs[index + 1]!.focus();
    }
  };

  state = {
    values: Array(this.props.length).fill("")
  };

  render() {
    const { length } = this.props;
    const { values } = this.state;
    return (
      <>
        {values.map((value, index) => (
          <div  key={index} >
          <input
            data-test-id="textOpt"
            type="text"
            maxLength={1}
            value={value}
            onChange={(event: any) => this.handleChange(index, event.target.value)}
            onKeyDown={(event: any) => this.handleKeyDown(event, index)}
            ref={(ref: any) => {
              this.inputRefs[index] = ref;
            }}
            style={{textAlign: 'center',...webStyles.otpInputs, border:this.props.otpError ? '2px solid red' : '2px solid #ccc'}}
          />
          </div>
        ))}
      </>
    );
  }
}

export default OtpInput;
const webStyles = {
  otpInputs: {
      width: '50px',
      height: '50px',
      margin: '0 8px',
      fontSize: '24px',
      borderRadius: '5px',
      outline: 'none',   
  },
  
}