import React from "react";
// Customizable Area Start
import ForgotPassword from "./ForgotPasswordController";
import { InputAdornment, Box, Button, TextField, Grid, Typography, IconButton } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {
    authentication,
} from "./assets";
// Customizable Area End

interface Props {
    handleSubmit: () => void;
    updateNewPass: (newPass: string) => void;
    confirmPass: (confirmPass: string) => void;
    passMismatchError: boolean;
    passLengthError: boolean;
    enablePasswordForgotField: boolean;
    handleClickForgotShowPassword: () => void;
    enableConfirmPassForgotField: boolean;
    handleClickForgotShowConfirmPass: () => void;
    passExists: boolean;
}
const CreatePassword = (props: Props) => {

    const { stepOne, stepHeading, stepPara, btnRight, stepImage, formBox, iconColor, inputStyle, stepBtn, errorMessage, errorField, instructionBox } = webStyles;
    return (
        <Box style={stepOne}>
            <Box>
                <Box style={{ textAlign: 'center' }} >
                    <Typography style={stepHeading}>Create New Password</Typography>
                    <Typography style={stepPara}>Your new password must be different from previously used password.</Typography>
                </Box>
                <Box style={stepImage}><img src={authentication} /></Box>
                <Box style={formBox}>
                    <Grid  alignItems="center" spacing={3}>
                        <Grid item md={12}>
                            <TextField
                                type={props.enablePasswordForgotField ? "password" : "text"}
                                data-test-id="txtInputPass1"
                                fullWidth={true}
                                placeholder="Enter New Password"
                                variant="outlined"
                                onChange={(e) => props.updateNewPass(e.target.value)}
                                style={props.passMismatchError ? errorField : inputStyle}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={iconColor} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={props.handleClickForgotShowPassword}
                                            edge="end"
                                            data-test-id="btneye"
                                          >
                                            {props.enablePasswordForgotField ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                }}

                            />
                        </Grid>
                        <Grid item md={12} style={formBox}>
                            <TextField
                                type={props.enableConfirmPassForgotField ? "password" : "text"}
                                data-test-id="txtInputPass2"
                                fullWidth={true}
                                placeholder="Enter Confirm Password"
                                variant="outlined"
                                onChange={(e) => props.confirmPass(e.target.value)}
                                style={props.passMismatchError ? errorField : inputStyle}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={iconColor} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={props.handleClickForgotShowConfirmPass}
                                            edge="end"
                                            data-test-id="btneye1"
                                          >
                                            {props.enableConfirmPassForgotField ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                }}

                            />
                            {props.passMismatchError && (
                                <p data-test-id="miss-match-pass" style={errorMessage}>Password is not matching</p>
                            )}
                            {props.passLengthError && (
                                <p data-test-id="pass-not-valid" style={errorMessage}>Please enter a valid password</p>
                            )}
                            {props.passExists && (
                                <p data-test-id="miss-match-pass" style={errorMessage}>New password can't be your old password</p>
                            )}
                             <Box  style={instructionBox}>
                                Note: Password must have at least one uppercase letter, one lowercase letter, one digit, one special character and should be minimum 8 characters in length.
                            </Box>
                        </Grid>

                        <Grid container item md={12} justifyContent="flex-end" style={formBox}>
                            <Box style={btnRight}><Button style={{ textTransform: 'capitalize', ...stepBtn }} fullWidth onClick={props.handleSubmit} data-test-id="btnSubmitNewPass">Submit</Button></Box>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    );
    // }

}
export default CreatePassword;
const webStyles = {
    stepHeading: {
        fontSize: '40px',
        color: '#0056A3',
        fontWeight: 'bold',
    },
    stepPara: {
        color: '#0056A3',
    },
    instructionBox: {
        color: '#4F4F4F',
        padding: '3px',
        fontSize: '9px',
        borderRadius: '4px',
    },
    btnRight: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    checkIcon: {
        color: '#979797',
        width: '50px',
        fontSize: '33px',
    },
    stepImage: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    formBox: {
        maxWidth: '450px',
        margin: 'auto',
        paddingTop: '30px',
    },
    iconColor: {
        color: '#0056A3',
    },
    inputStyle: {
        backgroundColor: '#fff',
    },
    stepBtn: {
        backgroundColor: '#0056A3',
        color: '#fff',
        borderRadius: '10px',
        width: '175px',
    },
    stepOne: {
        maxWidth: '700px',
        margin: 'auto',
    },
    errorMessage: {
        color: "red",
        fontSize: "small",
    },
    errorField: {
        border: "1px solid red",
        backgroundColor: "#fff",
        borderRadius: "5px",
    },
}
