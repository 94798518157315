import { ErrorMessage, Field } from "formik";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Box } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  selectInput: {
    padding: "15px 10px",
    borderRadius: "5px",
    width: "100%",
    border: "1px solid #0056A3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#3B3B3B",
    background: "white",
    WebkitAppearance: "none"
  },
  label: {
    fontSize: "20px",
    fontWeight: 500,
    margin: "8px 0",
    fontFamily: "Poppins"
  },
  errorMessage: {
    color: "red"
  },
  options: {
    display: "flex",
    jsutifycontent: "space-between",
    alignItems: "center"
  },
  selectBtn: {
    width: "100%"
  },
  fieldInput: {
    padding: "15px 10px",
    borderRadius: "5px",
    width: "100%",
    border: "1px solid #0056A3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#3B3B3B"
  },
  selectBox: {
    position: "relative"
  },
  dropDownIcon: {
    position: "absolute",
    right: "5px",
    top: "45px"
  }
}));
enum FormType {
  PARENT = 1,
  CHILDREN,
  SCHOOL
}
const CustomSelect = (props: any) => {
  const [toggle, setToggle] = React.useState<boolean>(false);
  const { changeBanner, changeQuestion, setFieldValue }: any = props;
  const classes = useStyles();
  const handleChange = (event: any) => {
    setFieldValue('age', event.target.value);
    switch (event.target.value) {
      case "ageGroup1":
        changeBanner(0);
        changeQuestion('','3-8');
        break;
      case "ageGroup2":
        changeBanner(3);
        changeQuestion('','8-12');
        break;
      case "ageGroup3":
        changeBanner(4);
        changeQuestion('','12-16');
        break;
      default:
        break;
    }
  };
  const handleChangeChildren = (event:any) => {
    setFieldValue('age', event.target.value);
    switch (event.target.value) {
      case "ageGroup4":
        changeQuestion('','6-10');
        break;
      case "ageGroup5":
        changeQuestion('','10-14');
        break;
      case "ageGroup6":
        changeQuestion('','14-16');
        break;
      default:
        break;
    }
  }
  let returnComponent: any;
  switch (props.formType) {
    case FormType.PARENT:
      returnComponent = (
        <>
          <Box className={classes.selectBox}>
            <Box>
              <label className={classes.label} htmlFor="childAge">
                Child's Age*
              </label>
            </Box>
            <Box className={classes.dropDownIcon}>
              {toggle ? <ArrowDropUp /> : <ArrowDropDown />}{" "}
            </Box>
            <Field
              data-test-id="customSelectClick"
              as="select"
              name="age"
              className={classes.selectInput}
          
              onChange={handleChange}
              onClick={() => setToggle(!toggle)}
            >
              <option value="ageGroup1">3-8 Years </option>
              <option value="ageGroup2">8-12 Years</option>
              <option value="ageGroup3">12-16 Years</option>
             
            </Field>
            <ErrorMessage
              className={classes.errorMessage}
              name="age"
              component="Box"
            />
          </Box>
        </>
      );
      break;
    case FormType.CHILDREN:
      returnComponent = (
        <>
          <Box className={classes.selectBox}>
            <Box>
              <label className={classes.label} htmlFor="age">
                Age*
              </label>
            </Box>
            <Box className={classes.dropDownIcon}>
              {toggle ? <ArrowDropUp /> : <ArrowDropDown />}{" "}
            </Box>
            <Field
              data-test-id="customSelectClickChildren"
              as="select"
              name="age"
              className={classes.selectInput}
              onChange={handleChangeChildren}
              onClick={() => setToggle(!toggle)}
            >
              <option value="ageGroup4">6-10 Years </option>
              <option value="ageGroup5">10-14 Years </option>
              <option value="ageGroup6">14-16 Years </option>
            </Field>
            <ErrorMessage
              className={classes.errorMessage}
              name="age"
              component="Box"
            />
          </Box>
        </>
      );
      break;
    case FormType.SCHOOL:
      returnComponent = (
        <>
          <Box className={classes.selectBox}>
            <Box>
              <label className={classes.label} htmlFor="schoolName">
                School Name*
              </label>
            </Box>
            {/* <Box className={classes.dropDownIcon}>
              {toggle ? <ArrowDropUp /> : <ArrowDropDown />}{" "}
            </Box> */}
            <Field
              placeholder={"Enter Your School Name"}
              className={classes.fieldInput}
              type="text"
              id="schoolName"
              name="schoolName"
            />
            {/* <Field
              as="select"
              name="schoolName"
              className={classes.selectInput}
              onClick={() => setToggle(!toggle)}
            >
              <option value="preparatory_school">Preparatory School</option>
              <option value="preparatory_school1">Preparatory School 1</option>
              <option value="preparatory_school2">Preparatory School 2</option>
            </Field> */}
           
            <ErrorMessage
              className={classes.errorMessage}
              name="schoolName"
              component="Box"
            />
          </Box>
        </>
      );
      break;
    default:
      break;
  }

  return returnComponent;
};

export default CustomSelect;
