import { BlockComponent } from "../../../../framework/src/BlockComponent";

export const configJSON = require("../config.js");
let config = require("../../../../framework/src/config");

interface Choice {
  id: number;
  content: string;
}
export interface QuestionAttributes {
  id: number;
  text: string;
  question_type: string;
  choice: Choice[];
  document_file: null | string;
}
export interface QuestionType {
  id: string;
  type: string;
  attributes: QuestionAttributes;
}

export interface RemainingSections {
  id: number,
  status: string,
}

interface DataAttributes {
  id: number;
  name: string;
  questions: {
    data: QuestionType[];
  };
  remaining_sections: RemainingSections[];
}

export interface Data {
  id: string;
  type: string;
  attributes: DataAttributes;
}

export interface Question {
  data: Data;
}

export interface Answer {
  question_id: number;
  question_type: string;
  choice_id?: number;
  solution?: string;
  mpower_checks_section_id: number;
  document_file?: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sessionQuestions: Question[];
  openAttachmentDropZone: boolean;
  progress: number;
  attachmentName: string;
  attachmentSize: any;
  tempAttachmentUrl: string;
  answerAttachment: any;
  attachmentQuestionNumber: any;
  attachmentQuestionId: any;
  answers: any;
  openFormSubmitModal: boolean;
  modalType: string;
  endSuccessModel: boolean
  // Customizable Area End
}
interface SS { }
export default class QuestionsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      sessionQuestions: [],
      openAttachmentDropZone: false,
      progress: 0,
      attachmentName: '',
      attachmentSize: '',
      tempAttachmentUrl: '',
      answerAttachment: '',
      attachmentQuestionNumber: '',
      attachmentQuestionId: '',
      answers: {},
      openFormSubmitModal: false,
      modalType: 'success',
      endSuccessModel: false,
    };
  }
  async componentDidMount() {

    // Customizable Area Start
    await this.getQuestionsList();
    // Customizable Area End
  }
  getQuestionsList = async () => {
    const existingDataLocalData = JSON.parse(localStorage.getItem('mpowerData') || "{}");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      Authorization: localStorage.getItem("user_token")
    };
    const res = await this.apiCall(
      configJSON.dashboarApiMethodType,
      `section/${existingDataLocalData.sectionId}?mpower_check_id=${existingDataLocalData.session_id}`,
      header,
      ''
    );
    if (res) {
      this.setState({ sessionQuestions: [res] });
    }
  }

  async apiCall(method: string, endpoint: string, headers: any, body: any) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;
    let response: any;
    try {
      if (headers && body) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers,
          body: body,
        });
      }
      else if (headers) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers
        });
      }
      let responseJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        return responseJson;
      }
    } catch (error) {
      console.log("Api Error", error);
      return false;
    }
  }

  handleMpowerOpenDropZone = (question_Id: any, questionIndex: any) => {
    this.setState(prevState => ({
      ...prevState,
      openAttachmentDropZone: true,
      attachmentQuestionId: question_Id,
      attachmentQuestionNumber: questionIndex
    }));
  }

  handleMpowerCloseDropZone = () => {
    this.setState({ openAttachmentDropZone: false, attachmentName: '', attachmentSize: '', progress: 0, tempAttachmentUrl: '' })
  }

  handleMpowerUploadAttachment = async (event: any) => {
    const file = event[0];
    const fileSize = file.size;
    const fileName = file.name;
    const size = this.formatMpowerFileSize(fileSize);
    const url = URL.createObjectURL(file);
    this.setState((prevState) => {
      const updatedAnswer = prevState.answers[this.state.attachmentQuestionId] || {};
      return {
        tempAttachmentUrl: url,
        attachmentName: fileName,
        attachmentSize: size,
        progress: 0,
        answers: {
          ...prevState.answers,
          [this.state.attachmentQuestionId]: {
            ...updatedAnswer,
            questionId: this.state.attachmentQuestionId,
            attachment: file
          }
        },
      }
    }, () => this.simulateMpowerProgress());
  }

  handleMpowerAttachmentUploadDone = () => {
    this.handleMpowerCloseDropZone();
  }

  simulateMpowerProgress = () => {
    let progressCounter = 0;
    const progressInterval = setInterval(() => {
      progressCounter += 10;
      if (progressCounter <= 100) {
        this.setState({ progress: progressCounter });
      } else {
        clearInterval(progressInterval);
      }
    }, 10);
  };

  handleMpowerCancelUpload = () => {
    this.setState((prevState) => {
      const updatedAnswer = prevState.answers[this.state.attachmentQuestionId] || {};
      return {
        attachmentName: '',
        attachmentSize: '',
        progress: 0,
        tempAttachmentUrl: '',
        answers: {
          ...prevState.answers,
          [this.state.attachmentQuestionId]: {
            ...updatedAnswer,
            attachmentName: '',
            attachment: null
          }
        }
      }
    })
  }

  formatMpowerFileSize = (size: number) => {
    if (size < 1024) {
      return size + " bytes";
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + " Kb";
    } else if (size < 1073741824) {
      return (size / 1048576).toFixed(2) + " Mb";
    }
  }

  submitSection = async (finalAnswers: any) => {
    this.setState({ openFormSubmitModal: false })
    const header = {
      Authorization: localStorage.getItem("user_token")
    };
    const existingDataLocalData = JSON.parse(localStorage.getItem('mpowerData') || "{}");
    const formData = new FormData();
    finalAnswers.answers.map((answerObj: any) => {
      formData.append(`answers[][question_id]`, answerObj.question_id);
      formData.append(`answers[][mpower_checks_section_id]`, answerObj.mpower_checks_section_id);
      if (answerObj?.document_file) {
        formData.append(`answers[][document_file]`, answerObj.document_file);
      }
      if (answerObj.question_type == "text_based" || answerObj.question_type == "puzzle") {
        formData.append(`answers[][solution]`, answerObj.solution);
      } else {
        formData.append(`answers[][choice_id]`, answerObj.choice_id);
      }
      formData.append(`answers[][question_type]`, answerObj.question_type);
    })

    const res = await this.apiCall(
      configJSON.httpPostMethod,
      `${configJSON.submitAnswer}?mpower_check_id=${existingDataLocalData.session_id}`,
      header,
      formData
    );
    if (res) {
      const newSection = this.state.sessionQuestions[0].data.attributes.remaining_sections.find((obj: any) => obj.status === configJSON.MPower.Pending && obj.id != existingDataLocalData.sectionId);
      if (newSection) {
        const newObj = {
          ...existingDataLocalData,
          sectionId: newSection?.id
        };
        localStorage.setItem('mpowerData', JSON.stringify(newObj));
        this.handleMpowerSections();
      } else {
        localStorage.removeItem('mpowerData');
        this.setState({ endSuccessModel: true });
        this.handleMpowerSections();
      }
    } else {
      return false;
    }
  }

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  handleMpowerCloseSubmitSuccessModal = () => {
    this.setState({ openFormSubmitModal: false })
    if (this.state.modalType === configJSON.ModalTypes.Success) {
      if (this.state.endSuccessModel) {
        this.setState({ endSuccessModel: false });
        this.goTo("Mpower");
      } else {
        window.location.reload();
      }

    }
  }

  handleMpowerOpenDialog = () => {
    this.setState({ openFormSubmitModal: true, modalType: configJSON.ModalTypes.Confirm })
  }

  handleMpowerSections = () => {
    this.setState({ openFormSubmitModal: true, modalType: configJSON.ModalTypes.Success })
  }
}