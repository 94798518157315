import React from "react";
// Customizable Area Start
// import { Stepper, Step } from "react-form-stepper";
// Customizable Area End

// Customizable Area Start
import {
  Box,
  FormLabel,
  Grid,
  TextField,
  InputAdornment,
  Link,
  Select,
  IconButton,
} from "@material-ui/core";
import PrimaryButton from "../../../components/src/PrimaryButtonCommonComponent.web";

import {
  imgProfile,
  arrowDown,
  lock,
  profileIcon,
  maleAndFemale,
  messageIcon,
  schoolIcon,
  calendarIcon
} from "./assets";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";

interface CreateAccountProps {
  handleSubmit: () => void;
  handleTnCPopup: (show?: boolean) => void;
  handleChange: (e: any) => void;
  handleChangeSelect: (menuType: string, value: any) => void;
  handleDOB: (date: any) => void;
  handleOpenDropZone: () => void;
  handleClickShowPwd: () => void;
  handleClickShowConfirmPwd: () => void;
  schools: any[];
  classList: any[];
  genderList: any[];
  states: any;
  handlePwdChange: (e: any) => void;
  disableFutureDates: (e: any) => boolean
}

const inputAdornmentIcon = (iconName: string) => {
  switch (iconName) {
    case "lock":
      return <img style={webStyles.addornmentIcon} src={lock} alt="" />;
    case "person":
      return <img style={webStyles.addornmentIcon} src={profileIcon} alt="" />;
    case "maleAndFemale":
      return (
        <img style={webStyles.addornmentIcon} src={maleAndFemale} alt="" />
      );
    case "message":
      return <img style={webStyles.addornmentIcon} src={messageIcon} alt="" />;
    case "school":
      return <img style={webStyles.addornmentIcon} src={schoolIcon} alt="" />;

  }
};

export const CalendarIcon = () => {
  return <img src={calendarIcon} alt="" />;
};

const useStyles = makeStyles((theme) => ({
  cursor: {
    cursor: "pointer"
  },
  textField: {
    "&.MuiFormControl-root.MuiTextField-root": {
      [theme.breakpoints.down(600)]: {
        minWidth: "320px",
      },
      minWidth: "400px",
      width: "100%"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "& .MuiOutlinedInput-root": {
      height: "64px",
      width: "100%",
      [theme.breakpoints.down(600)]: {
        minWidth: "320px",
        maxWidth: "320px",
      },
      minWidth: "400px",
      maxWidth: "400px"
    }
  },
  formHeaderLabel: {
    color: "#0056A3",
    fontSize: "40px",
    [theme.breakpoints.down(600)]: {
      fontSize: '32px',
    },
    fontWeight: "bold",
    margin: "24px auto"
  },
  dropDown: {
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "&.MuiOutlinedInput-root": {
      [theme.breakpoints.down(600)]: {
        width: "320px",
      },
      width: "400px",
      height: "64px",
      margin: "16px 0 8px 0"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "22px 0 22px 5px"
    }
  },
  sectionDropDown: {
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "&.MuiOutlinedInput-root": {
      [theme.breakpoints.down(600)]: {
        width: "320px",
      },
      width: "400px",
      height: "64px",
      margin: "16px 0 8px 0"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "22px 0 22px 25px"
    }
  },
  menuItem: {
    "& .MuiFormControlLabel-root": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%"
    }
  },
  showAPIError: {
    color: 'red',
    margin: "10px 0 10px 0",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    mixBlendMode: "normal" as "normal",
    opacity: 0.74
  },
  gridContainer: {
    "&.MuiGrid-container": {
      maxWidth: "824px",
      margin: "16px auto 0 auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      "@media(max-width:815px)": {
        padding: "0 10px",
        justifyContent: "center"
      }
    }
  },
  gridItem: {
    "&.MuiGrid-item": {
      flexBasis: "auto"
    }
  },
  pwdGridItem: {
    "&.MuiGrid-item": {
      flexBasis: "auto",
      [theme.breakpoints.down(600)]: {
        width: "320px"
      }
    }
  },
  dayWithDotContainer: {
    "& button": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#3F526D",
      mixBlendMode: "normal",
      opacity: 0.7
    },
    "& button.MuiPickersDay-daySelected": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#FFFFFF",
      background:
        "linear-gradient(90deg, #09549F 0%, #09539E 0.54%, #000428 106.13%)",
      opacity: "1 !important",
      borderRadius: "4%"
    },
    "& button.MuiPickersDay-hidden": {
      opacity: "0.3 !important"
    }
  },
  createAccount: {
    "&.MuiButton-root": {
      position: "absolute",
      right: "0",
      borderRadius: "12px",
      height: "48px",
      width: "175px",
      backgroundColor: "#0056A3",
      color: "white",
      textTransform: "capitalize",
      display: "block",
      "@media(max-width:815px) and (min-width:580px)": {
        right: "38%",
        bottom: -65
      },
      "@media(max-width:580px)": {
        left: "28%",
        bottom: -65,
        right: "28%"
      }
    }
  },
  instructionBox: {
    color: '#4F4F4F',
    padding: '3px',
    fontSize: '9px',
    borderRadius: '4px',
  },
  uploadBtn: {
    "&.MuiButton-root": {
      width: "144px",
      height: "40px",
      borderRadius: "20px",
      backgroundColor: "#0056A3",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      top: "-24px",
      color: "white",
      position: "relative" as "relative"
    },
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
  datePicker: {
    "& .MuiInput-root": {
      top: "16px",
      "@media(max-width:818px) and (min-width:580px)": {
        right: "2px",
        width: 400,
        marginBottom: "33px"
      },
      "@media(max-width:580px)": {
        top: "0",
        margin: "18 0",
      },
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "8px"
    },
    "& .MuiInputBase-root": {
      flexDirection: "row-reverse"
    },
    "& .MuiInputBase-input": {
      paddingRight: "20px"
    },
    "& .MuiFormHelperText-root": {
      position: "relative",
      top: "33px",
      "@media(max-width:818px)": {
        top: "0"
      }
    },
    "& .MuiInput-underline::before": {
      content: "",
      position: "absolute",
      height: "64px",
      bottom: "-18px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "5px",
      [theme.breakpoints.down(600)]: {
        display: 'block',
        margin: '0 auto',
        minWidth: "320px",
        maxWidth: "320px",
        flexBasis: "80%"
      },
      minWidth: "400px"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "1px solid #066CD2"
    },
    "& .MuiInput-underline::after": {
      content: "",
      position: "absolute",
      height: "64px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "5px",
      bottom: "-18px",
      transform: "scaleX(0)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
    },
    "& .MuiInput-underline.Mui-focused::after": {
      border: "1px solid #066CD2"
    },
    "& .MuiInput-underline.Mui-error::after": {
      transform: "scaleX(1)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      border: "1px solid red"
    }
  }
}));

export const usePopoverStyles = makeStyles({
  paper: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#0056A3",
    border: "1px solid #0056a3a3",
    boxShadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
    borderRadius: "10px",
    "& .MuiPickersCalendarHeader-switchHeader": {
      borderBottom: "2px solid #0056a3a3",
      paddingTop: "12px",
      paddingBottom: "10px",
      flexDirection: "row-reverse"
    },
    "& .MuiPickersCalendarHeader-dayLabel": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "15px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#0056A3"
    },
    "& .MuiSvgIcon-root": {
      color: "#0056A3"
    },
    "& .MuiPickersCalendarHeader-iconButton:nth-of-type(1)": {
      order: 1
    },
    "& .MuiPickersCalendarHeader-transitionContainer": {
      order: 2
    }
  }
});

const SelectMenuIcon = () => {
  return <></>;
};



const CreateAccount = (props: CreateAccountProps) => {


  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const renderDayInPicker = (
    date: any,
    selectedDate: any,
    dayInCurrentMonth: any,
    dayComponent: any
  ) => {
    return <div className={classes.dayWithDotContainer}>{dayComponent}</div>;
  };
  const avatarImage = props.states.tempAvatarKey
    ? props.states.tempAvatarKey
    : imgProfile;
  const profileImage = props.states.displayProfileUrl
    ? props.states.displayProfileUrl
    : avatarImage;
  return (
    <Box style={webStyles.signupStepWrapper}>
      <FormLabel className={classes.formHeaderLabel}>
        Create {props.states.accountType} Account
      </FormLabel>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
          <div style={webStyles.picWrapper}>
            <img
              style={webStyles.uploadedImg}
              src={profileImage}
              alt="student-profile-img"
            />
          </div>
          <PrimaryButton
            data-test-id='uploadImage'
            className={classes.uploadBtn}
            onClick={props.handleOpenDropZone}
          >
            Upload Image
          </PrimaryButton>
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <TextField
            className={classes.textField}
            value={props.states.firstName}
            name="firstName"
            margin="normal"
            data-test-id='firstName'
            error={Boolean(props.states.errors.firstName)}
            helperText={props.states.errors.firstName}
            onChange={props.handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={webStyles.inputAdornment}
                  position="start"
                >
                  {inputAdornmentIcon("person")}
                </InputAdornment>
              )
            }}
            variant="outlined"
            fullWidth={false}
            placeholder="First Name"
            disabled={props.states.firstName?.length > 0}
          />
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <TextField
            value={props.states.lastName}
            className={classes.textField}
            name="lastName"
            data-test-id="lastName"
            margin="normal"
            onChange={props.handleChange}
            error={Boolean(props.states.errors.lastName)}
            helperText={props.states.errors.lastName}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={webStyles.inputAdornment}
                  position="start"
                >
                  {inputAdornmentIcon("person")}
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder="Last Name"
            disabled={props.states.lastName?.length > 0}
          />
        </Grid>
        <Grid
          item
          className={classes.pwdGridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.datePicker}
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              data-test-id='DOB'
              shouldDisableDate={props.disableFutureDates}
              PopoverProps={{ classes: popoverClasses }}
              renderDay={renderDayInPicker}
              error={Boolean(props.states.errors.dob)}
              helperText={props.states.errors.dob}
              keyboardIcon={<CalendarIcon />}
              placeholder="Date of Birth"
              value={props.states.datePickerProp}
              onChange={props.handleDOB}
              disabled={props.states.dateOfBirth?.length > 0}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Select
            name="gender"
            className={classes.dropDown}
            placeholder="Gender"
            margin="dense"
            variant="outlined"
            fullWidth
            data-test-id='gender'
            error={Boolean(props.states.errors.gender)}
            startAdornment={
              <InputAdornment style={webStyles.inputAdornment} position="start">
                {inputAdornmentIcon("maleAndFemale")}
              </InputAdornment>
            }
            IconComponent={SelectMenuIcon}
            value={props.states.gender}
            renderValue={() => props.states.gender}
            disabled={props.states.gender?.length > 0}
          >
            <RadioGroup value={props.states.gender}>
              {props.genderList.map(name => {
                return (
                  <MenuItem
                    data-test-id='gender-menu'
                    key={name}
                    className={classes.menuItem}
                    value={name}
                    onClick={() => props.handleChangeSelect("gender", name)}
                  >
                    <FormControlLabel
                      checked={props.states.gender === name}
                      value={name}
                      control={<Radio color="primary" />}
                      label={name}
                      labelPlacement="start"
                    />
                  </MenuItem>
                );
              })}
            </RadioGroup>
          </Select>

          <FormHelperText style={webStyles.error}>
            {props.states.errors.gender}
          </FormHelperText>
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <TextField
            value={props.states.email}
            className={classes.textField}
            name="email"
            margin="normal"
            onChange={props.handleChange}
            error={Boolean(props.states.errors.email)}
            helperText={props.states.errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={webStyles.inputAdornment}
                  position="start"
                >
                  {inputAdornmentIcon("message")}
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder="Email ID"
            disabled={props.states.email?.length > 0}
          />
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <TextField
            value={props.states.parentsEmail}
            className={classes.textField}
            name="parentsEmail"
            margin="normal"
            onChange={props.handleChange}
            error={Boolean(props.states.errors.parentsEmail)}
            helperText={props.states.errors.parentsEmail}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={webStyles.inputAdornment}
                  position="start"
                >
                  {inputAdornmentIcon("message")}
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder="Parent Email ID"
            disabled={props.states.parentsEmail?.length > 0}
          />
        </Grid>
        <Grid
          item
          className={classes.pwdGridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <TextField
            className={classes.textField}
            name="tempPwd"
            data-test-id='tempPwd'
            type={props.states.showTempPwd ? "text" : "password"}
            margin="normal"
            onChange={props.handlePwdChange}
            error={Boolean(props.states.errors.passwordField)}
            helperText={props.states.errors.passwordField}
            inputProps={{
              minLength: 8,
              maxLength: 30
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={webStyles.inputAdornment}
                  position="start"
                >
                  {inputAdornmentIcon("lock")}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={props.handleClickShowPwd}
                    edge="end"
                    id="btneye1"
                  >
                    {props.states.showTempPwd ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Password"
          />
          <Box className={classes.instructionBox}>
            At least <text style={{ color: props.states.rules.uppercase ? '#0056A3' : 'inherit' }}>one uppercase letter</text>, <text style={{ color: props.states.rules.lowercase ? '#0056A3' : 'inherit' }}>one lowercase letter</text>, <text style={{ color: props.states.rules.digit ? '#0056A3' : 'inherit' }}>one digit</text> & <text style={{ color: props.states.rules.special ? '#0056A3' : 'inherit' }}>one special character</text>
          </Box>
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <TextField
            className={classes.textField}
            type={props.states.showConfirmPwd ? "text" : "password"}
            margin="normal"
            name="confirmPassword"
            data-test-id='confirmPassword'
            onChange={props.handleChange}
            error={Boolean(
              props.states.errors.confirmPassword ||
              props.states.errors.notmatching
            )}
            helperText={
              props.states.errors.confirmPassword ||
              props.states.errors.notmatching
            }
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={webStyles.inputAdornment}
                  position="start"
                >
                  {inputAdornmentIcon("lock")}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={props.handleClickShowConfirmPwd}
                    edge="end"
                    id="btneye2"
                  >
                    {props.states.showConfirmPwd ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Confirm Password"
          />
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Select
            name="school"
            className={classes.dropDown}
            placeholder="School Name"
            margin="dense"
            variant="outlined"
            fullWidth
            data-test-id='school'
            IconComponent={SelectMenuIcon}
            error={Boolean(props.states.errors.school)}
            startAdornment={
              <InputAdornment style={webStyles.inputAdornment} position="start">
                {inputAdornmentIcon("school")}
              </InputAdornment>
            }
            value={props.states.school}
            renderValue={() => props.states.school}
            disabled={props.states.school?.length > 0}
          >
            <RadioGroup value={props.states.school}>
              {props.schools.map(e => {
                const { id, name } = e.attributes;
                return (
                  <MenuItem
                    key={e.id}
                    className={classes.menuItem}
                    value={name}
                    data-test-id={'school-menu'}

                    onClick={() =>
                      props.handleChangeSelect("school", { [name]: id })
                    }
                  >
                    <FormControlLabel
                      checked={props.states.school === name}
                      value={name}
                      control={<Radio color="primary" />}
                      label={name}
                      labelPlacement="start"
                    />
                  </MenuItem>
                );
              })}
            </RadioGroup>
          </Select>
          <FormHelperText style={webStyles.error}>
            {props.states.errors.school}
          </FormHelperText>
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          sm={12}
          xs={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Select
            name="class"
            className={classes.sectionDropDown}
            placeholder="Class"
            margin="dense"
            variant="outlined"
            fullWidth
            data-test-id='class'
            error={Boolean(props.states.errors.section)}
            IconComponent={SelectMenuIcon}
            value={props.states.section}
            renderValue={() => props.states.section}
            disabled={props.states.section?.length > 0}
          >
            <RadioGroup value={props.states.section}>
              {props.classList.map(e => {
                const { name } = e.attributes;
                return (
                  <MenuItem
                    key={e.id}
                    data-test-id='class-menu'
                    className={classes.menuItem}
                    value={name}
                    onClick={() => props.handleChangeSelect("class", name)}
                  >
                    <FormControlLabel
                      checked={props.states.section === name}
                      value={name}
                      control={<Radio color="primary" />}
                      label={name}
                      labelPlacement="start"
                    />
                  </MenuItem>
                );
              })}
            </RadioGroup>
          </Select>
          <FormHelperText style={webStyles.error}>
            {props.states.errors.section}
          </FormHelperText>
        </Grid>
        <Grid item xl={12} sm={12} lg={12} md={12}>
          <div style={webStyles.privacyPolicy}>
            By clicking the Create Account button, you agree to our{" "}
            <Link className={classes.cursor} color="inherit" onClick={() => props.handleTnCPopup()}>
              Terms
              <span> &amp;</span> Conditions
            </Link>
            and <Link className={classes.cursor} color="inherit" onClick={() => props.handleTnCPopup(true)}>Privacy Policy.</Link>
          </div>
          {props.states.showAPIError && <Box className={classes.showAPIError}>{props.states.userRegistered ? "User has already been registered" : "We seem to be facing some issues, please try again"}</Box>}
        </Grid>
        <Grid item xl={12} sm={12} lg={12} md={12}>
          <PrimaryButton
            onClick={props.handleSubmit}
            variant="contained"
            color="primary"
            disabled={props.states.userRegistered}
            data-test-id='submit'
            className={classes.createAccount}
          >
            Create Account
          </PrimaryButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateAccount;

const webStyles = {
  privacyPolicy: {
    color: "#0056A3",
    margin: "10px 0 48px 0",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    mixBlendMode: "normal" as "normal",
    opacity: 0.74
  },
  addornmentIcon: { width: "24px" },
  signupStepWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    alignItems: "center"
  },
  error: { color: "red" },
  customRadioLabelImg: { width: "45px", height: "45px" },
  uploadedImg: { width: "64px", height: "64px", borderRadius: "50%" },
  picWrapper: {
    width: "64px",
    height: "64px",
    borderRadius: "50%",
    border: "1px solid #0056A3",
    display: "inline-block",
    marginRight: "25px"
  },
  fileUploadInput: { display: "none" },
  uploadLabel: {
    width: "144px",
    height: "40px",
    borderRadius: "20px",
    backgroundColor: "#0056A3",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    top: "-24px",
    color: "white",
    position: "relative" as "relative"
  },
  inputAdornment: {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center"
  },
  arrowDownImage: {
    width: "16px",
    height: "9px",
    position: "absolute" as "absolute",
    right: "27px"
  }
};
