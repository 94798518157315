import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Typography, Box, Button, Theme } from '@material-ui/core';
import moment from 'moment';
import { Attributes } from './YouMatter.web';
import { profile } from '../assets';

const configJSON = require("../config.js");

const useStyles = makeStyles({
    dialog: {
        '& .MuiPaper-root': {
            padding: '28px 32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#F0F4F6',
            '@media(max-width:575px)': {
                padding: '15px',
            }
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '715px',
            width: '100%'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: 'none'
        }
    },

    divider: {
        height: '2px',
        width: '100%',
        backgroundImage: 'linear-gradient(137.61deg, #0056A3 0%, #09539E 14.18%, #000428 143.69%)',
        borderRadius: '3.5px',
        margin: '15px 0',
        color: 'transparent',
        fontFamily: "Poppins",
    },
    dialogTitle: {
        fontWeight: 900,
        fontSize: '20px',
        lineHeight: '36px',
        color: '#0056A3',
        fontFamily: "Poppins"
    },
    startYouMatterBtn: {
        "&.MuiButton-root": {
            height: '48px',
            width: '192px',
            borderRadius: '12px',
            backgroundColor: '#0056A3',
            margin: '0 auto'
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        }
    },

    youMatterLastSeen: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        marginLeft: 'auto',
        '@media(max-width:575px)': {
            fontSize: '12px',
        }
    },
    youMatterTitle: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '36px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        marginBottom: '10px',
        '@media(max-width:575px)': {
            fontSize: '20px',
        }
    },
    key: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '30px',
        color: "#3B3B3B",
        fontFamily: "Poppins",
        '@media(max-width:575px)': {
            fontSize: '14px',
            lineHeight: '18px',
        }
    },
    value: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '27px',
        color: "#3B3B3B",
        fontFamily: "Poppins",
        marginLeft: '12px',
        '@media(max-width:575px)': {
            fontSize: '14px',
            lineHeight: '18px',
        }
    },
    youMatterDetails: {
        padding: '16px',
        minHeight: '100px',
        overflowY: 'auto',
        backgroundColor: 'white',
        borderRadius: '8px',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        margin: '14px 0 30px 0',
        '@media(max-width:575px)': {
            marginBottom: '10px'
        }
    },
    backdrop: {
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(8, 77, 149, 0.481206)'
        }
    },
    keyValueWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    trainerImgWrapper: {
        border: '1px solid #0056A3',
        height: '48px',
        width: '48px',
        borderRadius: '50%',
        marginRight: '16px',
        '@media(max-width:575px)': {
            marginRight: '8px',
            height: '40px',
            width: '40px',
        }
    },
    trainerImg: {
        height: '48px',
        width: '48px',
        borderRadius: '50%',
        '@media(max-width:575px)': {
            height: '40px',
            width: '40px',
        }
    },
    trainerCardWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    trainerCardTitle: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '26px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        '@media(max-width:575px)': {
            fontSize: '14px',
        }
    },
    trainerCardSubTitle: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        '@media(max-width:575px)': {
            fontSize: '12px',
        }
    },
    trainerCardTitleWrapper: {

    },
    gridLayout: {
        display: 'grid',
        gridTemplateColumns: '1fr  1fr',
        gridGap: '20px',
        marginBottom: '25px',
        '@media(max-width:767px)': {
            gridTemplateColumns: '1fr'
        },
        '@media(max-width:575px)': {
            gridGap: '10px',
        }
    }
})

const statusStyles = makeStyles<Theme, any>(() => ({
    status: {
        height: '36px',
        width: '95px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 900,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        fontFamily: "Poppins",
        borderRadius: '8px',
        textTransform: 'capitalize',
        backgroundColor: props => `${props.data.submission_status === configJSON.YouMatterTexts.Published ? '#6F6C6C' : '#EFD154'}`,
        '@media(max-width:575px)': {
            height: '30px',
            fontSize: '14px',
        }
    }
}))
interface KeyValueProps {
    title: string;
    value: any;
}

export const KeyValue = (props: KeyValueProps) => {
    const classes = useStyles();
    const { title, value } = props;
    return <Box className={classes.keyValueWrapper}>
        <Typography className={classes.key}>{title}:</Typography>
        <Box className={classes.value}>{value}</Box>
    </Box>
}

interface TrainerInfoProps {
    img: string;
    name: string;
    email: string
}

export const TrainerInfo = (props: TrainerInfoProps) => {
    const classes = useStyles();
    return <Box className={classes.trainerCardWrapper}>
        <Box className={classes.trainerImgWrapper}>
            <img className={classes.trainerImg} src={props.img || profile} alt="" />
        </Box>
        <Box className={classes.trainerCardTitleWrapper}>
            <Typography className={classes.trainerCardTitle}>{props.name}</Typography>
            <Typography className={classes.trainerCardSubTitle}>{props.email}</Typography>
        </Box>
    </Box>
}

interface YouMatterDetailsDialogProps {
    data: Attributes;
    closeYouMatterDetailsModel: (id: any, status: any) => void;
    open: boolean,
    youMatterId: any;
}
const YouMatterDetailsDialog = (props: YouMatterDetailsDialogProps) => {
    const classes = useStyles();
    const { data, closeYouMatterDetailsModel, youMatterId, open } = props;
    const statusClasses = statusStyles(props)
    const status = data.submission_status
    const youMatterDetails = data.you_matter_details;
    const youMatterTitle = data.title;
    const courseName = data.course_name;
    const dueDate = moment(data.due_date, 'DD-MM-YYYY')
    const formattedDueDate = dueDate.format('D MMM YYYY');
    const trainer = data.trainer;
    const trainerName = trainer?.full_name
    const youMatterInfo = [
        { title: configJSON.YouMatterTexts.CourseName, value: courseName },
        { title: configJSON.YouMatterTexts.Trainer, value: <TrainerInfo img={trainer?.profile_photo} name={trainerName} email={trainer?.email} /> },
        { title: configJSON.YouMatterTexts.DueDate, value: formattedDueDate },
        { title: configJSON.YouMatterTexts.SubmissionStatus, value: <Typography className={statusClasses.status}>{status}</Typography> }
    ]
    const handleCloseModal = () => {
        closeYouMatterDetailsModel(youMatterId, status);
    }
    return (
        <Dialog data-test-id='box' className={classes.dialog} BackdropProps={{ className: classes.backdrop }} open={open} onClose={closeYouMatterDetailsModel}>
            <Typography className={classes.dialogTitle}>{configJSON.YouMatterTexts.YouMatterDetails}</Typography>
            <Box className={classes.divider}>.</Box>
            <Typography className={classes.youMatterTitle}>{youMatterTitle}</Typography>
            <Box className={classes.gridLayout}>
                {youMatterInfo.map((element => {
                    return <KeyValue title={element.title} key={element.title} value={element.value} />
                }))}
            </Box>
            <Typography className={classes.key}>{configJSON.YouMatterTexts.YouMatterDetails} :</Typography>
            <Typography className={classes.youMatterDetails}>
                {youMatterDetails}
            </Typography>
            <Button data-test-id='startYouMatter' className={classes.startYouMatterBtn} onClick={handleCloseModal} > {status === configJSON.YouMatterTexts.Published ? configJSON.YouMatterTexts.StartYouMatter : configJSON.YouMatterTexts.Close}</Button>
        </Dialog>
    );
}
export default YouMatterDetailsDialog;