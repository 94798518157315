import React from "react";
import { Container, Box, Grid, Typography,styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import LandingPageController, { Props } from "./LandingPageController";
const configJSON = require("./config");

const useStyles = makeStyles(theme => ({
  partnerHeadingAlign: {
    textAlign: "center"
  },
  partnerHeading: {
    fontSize: "48px",
    color: "#052B62",
    lineHeight: "72px",
    fontWeight: 600,
    marginBottom: "12px"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: "relative"
  },
  slider: {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth",
    width: "100%"
  },
  sliderItem: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2)
  },
  image: {
    width: "200px",
    height:"104px",
    margin:'auto',
    objectFit:"scale-down"
  },
  iconButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    color: "#fff",
    background: "#052B62",
    height: "30px",
    width: "30px",
    "&:hover": {
      color: "#fff",
      background: "#052B62"
    }
  },
  leftIconButton: {
    left: 0
  },
  rightIconButton: {
    right: 0
  },
  gridContainer: {
    padding: "5px 0"
  }
}));

const OurPartnersDetails = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.partnerHeadingAlign}>
        <Typography variant="h3" className={classes.partnerHeading}>
          Our Partners
        </Typography>
      </Box>
      <Box className="landingPageSlider">
        <Container maxWidth="lg" className={classes.container}>
          <Grid container alignItems="center">
            <div className={classes.slider}>
              <Grid
                container
                alignItems="center"
                className={classes.gridContainer}
              >
                 <SliderComponent {...configJSON.LandingPageContent.settings}>
                  {props.ourPartnerLogos.map((ourPartner: any) => (
                    <Box
                      key={ourPartner.id}
                    
                    >
                      <img
                        src={ourPartner.attributes.image_url}
                        alt={ourPartner.id}
                        className={classes.image}
                      />
                    </Box>
                  ))}
                </SliderComponent> 
              </Grid>
            </div>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

class OurPartners extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <OurPartnersDetails ourPartnerLogos={this.state.ourPartnerLogos} />;
  }
}
export default OurPartners;

const SliderComponent=styled(Slider)({
  "& .slick-track":{
    display:"flex",
    alignItems:"center"
  }
})