import React from 'react'
import TrainerMpowerYouMatterController, { S } from './TrainerMpowerYouMatterController.web'
import SidebarLayout from '../../SidebarLayout.web';
import PageContentLayout from '../../PageContentLayout.web';
import { Box, Button, Typography, Grid, TextField, Tabs, Tab, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { optionName } from './TrainerMpowerYouMatterQuestionBank.web'
import { falseIcon, trueIcon } from '../../assets';
import CommonTextField from '../../CommonComponents/CommonTextField.web';
import CommonSelect from '../../CommonComponents/CommonSelect.web';
import { useStyles as youMatterQuestionStyles } from '../../YouMatter/YouMatterQuestions.web';
import InfoModal from '../../CommonComponents/InformationModal.web';
import Loader from '../../../../../components/src/Loader.web';
import commonStyles from '../../commonStyles.web';

const configJSON = require('../../config')
const config = require('../../../../../framework/src/config')

const useStyles = makeStyles(() => ({
    pageWrapper: {
        padding: '48px 24px 32px 24px'
    },
    questionNumber: {
        minHeight: '56px',
        minWidth: '56px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontSize: '20px',
        fontFamily: "Poppins",
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px'
    },
    answerWrapper: {
        marginTop: '48px'
    },
    questionCard: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
        borderRadius: '8px',
        border: '0.2px solid #e3e3e3',
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            padding: '0',
            minHeight: 'auto',
            display: 'block',
            margin: '7px 0'
        },
        '&.MuiAccordionSummary-root': {
            padding: '0'
        }
    },
    saveBtn: {
        "&.MuiButton-root": {
            height: '48px',
            width: '175px',
            borderRadius: "8px",
            backgroundColor: "#0056A3",
            '@media(max-width:575px)': {
                width: '100px'
            }
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        }
    },
    responseWrapper: {
        marginBottom: '40px'
    },
    footerActionWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    fieldLabel: {
        color: '#3B3B3B',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px'
    },
    inputField: {
        '&.MuiFormControl-root': {
            width: '100px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        '& .MuiOutlinedInput-input': {
            padding: '0 14px 0 18px',
            height: '100%'
        },
        "& .MuiOutlinedInput-root": {
            width: "100%",
            height: "56px",
            backgroundColor: '#ffffff4f',
            borderRadius: '8px',
            marginLeft: 'auto',
            marginBottom: '24px',
            color: 'black',
            fontSize: '14px',
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            paddingRight: '2px'
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: '0',
            marginTop: '-20px'
        }
    },
    topActionWrapper: {
        backgroundColor: '#0056a31a',
        padding: '48px 24px 32px 24px'
    },
    slash: {
        color: '#3B3B3B',
        fontSize: '30px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        lineHeight: '60px',
        margin: '0 10px'
    },
    scoreFieldWrapper: {
        display: 'flex',
    },
    solution: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '27px',
        color: '#0056A3',
        marginBottom: '10px',
        marginTop: '20px',
    },
    option: {
        minHeight: '48px',
        minWidth: '48px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontSize: '24px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '20px',
        fontFamily: "Poppins",
    },
    attachmentBtn: {
        "&.MuiButton-root": {
            height: '36px',
            width: '144px',
            borderRadius: "8px",
            border: '1px solid #0056A3',
            backgroundColor: "white",
            marginLeft: 'auto',
            display: 'flex',
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "#0056A3",
            fontFamily: "Poppins",
            '& img': {
                marginRight: '10px'
            }
        }
    },
    selectOptionText: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '27px',
        color: '#0056A3'
    },
    placeholder: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        opacity: 0.5907738208770752,
        '&:hover': {
            overflow: 'visible',
        }
    }
}))

interface ObjectiveQuestion {
    id: any;
    text: string;
    question_type: "objective" | "true_false" | "puzzle";
    student_selected_choice_id: number;
    choices: Choice[];
    document_file: any;
    answer_document_file: any;
}

interface Choice {
    id: number;
    content: string;
    is_correct: boolean;
}

interface TextBasedQuestion {
    id: any;
    text: string;
    question_type: 'text_based';
    student_answered: string;
    solution: string;
    document_file: any;
    answer_document_file: any;
}

type Questions = ObjectiveQuestion | TextBasedQuestion;

interface MpowerYouMatterChecksPageProps {
    states: S;
    isYouMatter: boolean;
    isUpdateCheck: boolean;
    openModal: (modalType: string) => void;
    handleTab: (_event: React.ChangeEvent<{}>, newValue: number) => void;
    handleChange: (event: React.ChangeEvent<{ value: any, name: any }>) => void;
    handleClear: (name: any, type: any) => void;
}

export const MpowerYouMatterChecksPage = (props: MpowerYouMatterChecksPageProps) => {
    const classes = useStyles()
    const commonQuestionClasses = youMatterQuestionStyles(props)
    const commonClasses = commonStyles({});

    const a11yProps = (index: any) => {
        return {
            id: `you-matter-tab-${index}`,
            'aria-controls': `you-matter-tabpanel-${index}`,
        };
    }

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const badgeDropdownList = props.states.badgesList.map((element: any) => {
        return {
            id: element.attributes.id,
            title: element.attributes.title,
            value: element.attributes.id,
            image: element.attributes.image
        }
    })

    const handleOpenAttachment = (url: any) => {
        if (config.baseURL === configJSON.devURL) {
            url = url.replace('http://', 'https://')
        }
        if (url.includes('.docx')) {
            url = `https://docs.google.com/viewer?url=${encodeURIComponent(url)}`
        }
        window.open(url, "_blank");
    }

    const isAllSectionChecked = props.states.checksPageData.length === props.states.tab + 1;
    const isBadgeConsider = props.isYouMatter ? true : props.states.badgeId !== '' && props.states.badgeId !== undefined;
    const isScoreValid = `${props.states.score}` !== '' && (props.states.score >= 0 && props.states.score <= props.states.totalScore);
    const isDisabled = isAllSectionChecked ? !(isScoreValid && isBadgeConsider) : false;
    const lgSize = props.isYouMatter ? 4 : 3;
    return <>
        <Box className={classes.topActionWrapper} data-test-id='box'>
            <Grid container spacing={2}>
                <Grid item lg={lgSize} sm={6} xl={3} md={6} xs={12}>
                    <CommonTextField value={props.states.studentData.student_name} label={`${configJSON.TrainerMPowerYouMatter.StudentName}*`} disabled={true} />
                </Grid>
                <Grid item lg={lgSize} sm={6} xl={3} md={6} xs={12}>
                    <CommonTextField value={props.states.studentData.grade} label={`${configJSON.TrainerMPowerYouMatter.Grade}*`} disabled={true} />
                </Grid>
                {!props.isYouMatter && <Grid item lg={3} sm={6} xl={3} md={6} xs={12}>
                    <CommonSelect placeholderStyle={classes.placeholder} label={`${configJSON.TrainerMPowerYouMatter.SuperPower}*`} onClear={() => props.handleClear('badgeId', 'checks')} menuList={badgeDropdownList} name='badgeId' handleChange={props.handleChange} value={props.states.badgeId || ""} placeholder={`${configJSON.TrainerMPowerYouMatter.SelectSuperPower}`} />
                </Grid>}
                <Grid item lg={lgSize} sm={6} xl={3} md={6} xs={12}>
                    <>
                        <Typography className={classes.fieldLabel}>{`${configJSON.TrainerMPowerYouMatter.Scores}*`}</Typography>
                        <Box className={classes.scoreFieldWrapper}>
                            <CommonTextField halfWidth={true} type='number' disabled={props.isUpdateCheck} value={props.states.score} name={'score'} handleChange={props.handleChange} />
                            <Typography className={classes.slash}>/</Typography>
                            <CommonTextField halfWidth={true} type="number" value={Number(props.states.totalScore)} disabled />
                        </Box>
                    </>
                </Grid>
            </Grid>
        </Box>

        <Box className={classes.pageWrapper}>
            <Box className={classes.responseWrapper}>
                <Tabs className={commonQuestionClasses.tabs} value={props.states.tab} data-test-id='tabs' onChange={props.handleTab} variant="scrollable" scrollButtons="auto">
                    {props.states.checksPageData?.map((element: any, index: number) => {
                        return <Tab className={commonQuestionClasses.tab} key={element} label={`Section - ${index + 1}`} {...a11yProps(index)} />
                    })}
                </Tabs>
                <Box className={classes.answerWrapper}>
                    {
                        props.states.checksPageData?.length > 0 && props.states.checksPageData[props.states.tab].answers.map((element: Questions, index: number) => {
                            return <Accordion key={element.id} data-test-id='accordion' className={commonQuestionClasses.accordion} expanded={expanded === `${index + 1}`} onChange={handleChangeAccordion(`${index + 1}`)}>
                                <AccordionSummary className={classes.accordionSummary} >
                                    <Box className={classes.questionCard}>
                                        <Box className={classes.questionNumber}>{`Q${index + 1}.`}</Box>
                                        <Typography className={commonQuestionClasses.questionText}>{element.text}</Typography>
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails className={commonQuestionClasses.accordionDetails}>
                                    {element.question_type === configJSON.QuestionTypes.Puzzle && <Typography className={classes.selectOptionText}>Solution</Typography>}
                                    {element.document_file && <Box className={commonQuestionClasses.questionImagePlaceholder}> <img src={element.document_file} alt="question details pic" /></Box>}
                                    <Box className={classes.footerActionWrapper}>
                                        {element.question_type !== configJSON.QuestionTypes.Puzzle && <Typography className={classes.selectOptionText}>Solution</Typography>}
                                        {element.answer_document_file &&
                                            <Button data-test-id='attachmentOpener' className={classes.attachmentBtn} onClick={() => handleOpenAttachment(element.answer_document_file)}>
                                                Attachement
                                            </Button>
                                        }
                                    </Box>
                                    {(element.question_type === 'objective' || element.question_type === 'true_false') && <Grid container spacing={2}>
                                        {
                                            element.choices.map((option: Choice, index: number) => {
                                                const elseColor = element.student_selected_choice_id === option.id ? '#F3C0C2' : 'white'
                                                const elseImg = element.student_selected_choice_id === option.id ? <img style={webStyles.icon} src={falseIcon} alt="" /> : null
                                                return <Grid key={option.id} item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Box style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
                                                        borderRadius: '8px',
                                                        padding: '4px 16px 4px 4px',
                                                        backgroundColor: option.is_correct ? '#D0F7DD' : elseColor
                                                    }}>
                                                        <Box className={classes.option}>{optionName(index + 1)}</Box>
                                                        <Typography className={commonQuestionClasses.optionContent} >{option.content}</Typography>
                                                        {option.is_correct ? <img style={webStyles.icon} src={trueIcon} alt="" /> : elseImg}
                                                    </Box>
                                                </Grid>
                                            })
                                        }
                                    </Grid>}
                                    {element.question_type === 'text_based' && <>
                                        <TextField className={commonQuestionClasses.subjectiveInput} disabled value={element.student_answered} multiline minRows={5} />
                                        <Typography className={classes.solution}>{configJSON.TrainerMPowerYouMatter.CorrectAnswer}</Typography>
                                        <TextField className={commonQuestionClasses.subjectiveInput} value={element.solution} disabled multiline minRows={5} />
                                    </>}
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                </Box>
            </Box>
            <Box className={classes.footerActionWrapper}>
                <Button data-test-id='cancel' disabled={props.states.tab === 0} style={{ marginRight: '20px' }} className={commonClasses.primaryButton} onClick={(e) => props.handleTab(e, props.states.tab - 1)} >{configJSON.ButtonTexts.Prev}</Button>
                <Button data-test-id='save' disabled={isDisabled} className={commonClasses.primaryButton} onClick={(e) => isAllSectionChecked ? props.openModal('confirm') : props.handleTab(e, props.states.tab + 1)} >{isAllSectionChecked ? configJSON.ButtonTexts.Save : configJSON.ButtonTexts.Next}</Button>
            </Box>
        </Box >
    </>
}

export default class MpowerYouMatterChecks extends TrainerMpowerYouMatterController {
    async componentDidMount() {
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
        const mpowerYouMatterId = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.MPowerYouMatterId)
        const studentId = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.StudentId)
        if (!isYouMatter && mpowerYouMatterId) {
            this.getBadgesList();
        }
        this.getStudentResponse(mpowerYouMatterId, studentId, isYouMatter)
    }
    render() {
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath);
        const isUpdateCheck = path.includes(configJSON.TrainerMPowerYouMatter.UpdateChecks);
        const mpowerYouMatterId = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.MPowerYouMatterId)
        const studentId = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.StudentId)
        const navigatePath = isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheckDashoard : configJSON.TrainerMPowerYouMatter.TrainerMpowerCheckDashoard;
        const gradeData = this.state.studentData.grade.split('-');
        const gradeName = gradeData[0].trim();
        const sectionName = gradeData[1]?.trim();
        return <>
            <SidebarLayout {...this.props} data-test-id='box'>
                <PageContentLayout goBack={this.goBack} title={isYouMatter ? configJSON.TrainerMPowerYouMatter.YouMatterChecks : configJSON.TrainerMPowerYouMatter.MpowerChecks}>
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <MpowerYouMatterChecksPage
                        states={this.state}
                        isYouMatter={isYouMatter}
                        isUpdateCheck={isUpdateCheck}
                        handleChange={this.handleDropdownChange}
                        handleTab={this.handleTabChange}
                        openModal={this.handleOpenModal}
                        handleClear={this.handleClearSelect}
                    />
                    <InfoModal
                        title={this.state.modalType === configJSON.ModalTypes.Success ? configJSON.TrainerMPowerYouMatter.Evaluated : ''}
                        open={this.state.openModal}
                        modalType={this.state.modalType}
                        info={this.state.modalType === configJSON.ModalTypes.Success ? configJSON.TrainerMPowerYouMatter.SuccessFulEvaluationMsg : configJSON.TrainerMPowerYouMatter.ConfirmEvaluationMsg}
                        closeModal={() => { this.state.modalType === configJSON.ModalTypes.Success ? this.goTo(navigatePath, { mpowerYouMatterId: mpowerYouMatterId,schoolName: this.state.studentData.school_name, gradeName: gradeName, sectionName: sectionName }) : this.handleClose() }}
                        submit={() => this.submitEvaluation(mpowerYouMatterId, studentId, isYouMatter)}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
}

const webStyles = {
    icon: {
        width: '24px',
        height: '24px',
        marginLeft: 'auto'
    }
}