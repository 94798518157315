import React from "react";
import { Box, Grid, Container, Typography }  from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CopyRightFooter from "../../../../../components/src/CopyRightFooter.web";
import FlotingButton from "../../../../../components/src/FlotingButton.web";
import BannerWithSpan from "./BannerWithSpan";
import LeftRightLayout from "./LeftRightLayout.web";
import RightLeftLayout from "./RightLeftLayout.web";
import Footer from "../../../../../components/src/Footer.web";

const configJSON = require("../../config");
const {
  heading,
  rightParagraphsMain,
  bottomParagraphsMain
} = configJSON.ProgramsPageContent.mainPage;

const {
  programMainBannerImage,
  programMainChlidren,
  welcomeLeftBg
} = require("../../assets");

interface Feature {
  heading: string;
  content: string;
  image: string;
}
interface ProgramPageContent {
  heading: string;
  content: string;
  headingSpan: string;
  features: Feature[];
}
interface Props {
  bannerImage: any;
  programContent: ProgramPageContent;
}

const useStyles = makeStyles(theme => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  featureBox: {
    marginBottom: "100px"
  },
  ProgramWelcomeBg: {
    backgroundImage: `url(${welcomeLeftBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -280px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  },
  ProgramBottomContentBox: {
    paddingTop: "15px"
  },
  ProgramBreakPara: {
    marginBottom: "8px"
  },
  programParagraph: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  boldParagraph: {
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#3B3B3B",
    opacity: "0.74"
  },
  programImageBox: {
    transform: "translate(-20px, 0)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0, 0)",
      marginBottom: "30px"
    }
  },
  programChildrenImg: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));
const StudentProgramLayout = (props: Props) => {
  const classes = useStyles();
  const { bannerImage, programContent } = props;

  const { heading, content, features, headingSpan } = programContent;
  return (
    <>
      <Box className={classes.relativeParent}>
        <BannerWithSpan
          heading={heading}
          content={content}
          bannerImage={bannerImage}
          showBtn={true}
          headingSpan={headingSpan}
        />
      </Box>
      <FlotingButton ourPartner={false} /><Box sx={{ pt: "100px" }} className={classes.ProgramWelcomeBg}>
          <Container className={classes.containerStyle}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box className={classes.programImageBox}>
                  <img
                    className={classes.programChildrenImg}
                    src={programMainChlidren}
                    width="525px"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  {rightParagraphsMain.map((paragraph: string, index: number) => (
                    <Box key={index} className={classes.ProgramBreakPara}>
                      <Typography
                        variant="body1"
                        className={
                          index === rightParagraphsMain.length - 1
                            ? `${classes.boldParagraph}`
                            : `${classes.programParagraph}`
                        }
                      >
                        {paragraph}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box className={classes.ProgramBottomContentBox}>
                  {bottomParagraphsMain.map((paragraph: string, index: number) => (
                    <Box key={index} className={classes.ProgramBreakPara}>
                      <Typography
                        variant="body1"
                        className={
                          index === 0
                            ? `${classes.programParagraph} ${classes.boldParagraph}`
                            : `${classes.programParagraph}`
                        }
                      >
                        {paragraph}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.featureBox}>
            {features.map((feature: Feature, index: number) =>
              index % 2 === 0 ? (
                <LeftRightLayout key={index} feature={feature} index=""/>
              ) : (
                <RightLeftLayout key={index} feature={feature} />
              )
            )}
          </Box>
          </Container>
        </Box>
        <Box >
        <Footer />
      </Box>
    </>
  );
};

export default StudentProgramLayout;
