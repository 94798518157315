import React from "react";
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TableBody,
  Link,
} from "@material-ui/core";
import { sheetBlue, sheetGrey } from '../assets'
import SchoolReportController from "./SchoolReportController.web";
import { PageContentLayout } from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import CommonPagination from "../../../../components/src/Pagination.web";
import { makeStyles } from "@material-ui/core/styles";
import CommonSelect from "../CommonComponents/CommonSelect.web";

export const useStyles = makeStyles(() => ({
  tableContainer: {
    '&.MuiTableContainer-root': {
      maxWidth: 'calc(100vw - 127px)',
      marginBottom: '36px',
      '@media(max-width:600px)': {
        maxWidth: 'calc(100vw - 84px)',
      },
      overflowX: 'auto'
    },
  },
  tableWrapper: {
    '&.MuiTable-root': {
      width: '100%',
      borderSpacing: "0 10px",
      borderCollapse: "initial",
      borderRadius: "20px",
      padding: "1px",
      '@media(max-width:767px)': {
        minWidth: '767px'
      }
    }
  }
}))

export const configJSON = require("../config.js");

export const ImpactHeading = configJSON.ImpactReport.ImpactHeading;

const ROW_PER_PAGE = 10;

export const SelectImpact = (props: any) => {
  const ImpactcourseFilterList = props.filterimpactCourseList?.map((element: any) => {
    return {
      title: element,
      value: element,
      id: element,
    }
  })

  const ImpactYear = props.ImpactlistYear?.map((element: any) => {
    return {
      id: element,
      title: element,
      value: element
    }
  })

  return (
    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
      <Grid item xs={12} sm={6}>
        <CommonSelect
          name='yearFilterImpact'
          menuList={ImpactYear}
          handleChange={props.handleFilterYearChange}
          value={props.ImpactYearFilter}
          placeholder={configJSON.ImpactReport.SelectAcademicYear}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonSelect
          name='ImpactcourseFilter'
          menuList={ImpactcourseFilterList}
          handleChange={props.handleFilterCourseChange}
          value={props.ImpactcourseFilter}
          placeholder={configJSON.ImpactReport.SelectCourse}
        />
      </Grid>
    </Grid>
  )
}


class ImpactReport extends SchoolReportController {

  goToNextPage = (page: number) => {
    this.setState({ firstRowIndex: (page - 1) * ROW_PER_PAGE, pageNumber: page, lastRowIndex: page * ROW_PER_PAGE });
  };

  render() {
    const ImpactProps = {
      reader_list: this.state.impactReportList,
      goTo: this.goTo
    }

    return <>
      <SidebarLayout {...this.props}>
        <PageContentLayout goBack={this.goBack} title={configJSON.ImpactReport.ImpactReport} >
          <Box style={tableStyle.boxPadding}>
            <SelectImpact
              filterimpactCourseList={this.state.filterimpactCourseList}
              ImpactcourseFilter={this.state.ImpactcourseFilter?.title}
              handleFilterCourseChange={this.handleFilterCourseChange}
              ImpactlistYear={this.state.ImpactlistYear}
              ImpactYearFilter={this.state.ImpactYearFilter?.title}
              handleFilterYearChange={this.handleFilterYearChange}
            />
            <TableWrapper ImpactProps={ImpactProps} />
            <CommonPagination pageCount={Math.ceil(
              this.state.totalReports / ROW_PER_PAGE
            )} page={this.state.pageNumber} handlePage={this.hanlePageChange} />
          </Box>
        </PageContentLayout>
      </SidebarLayout >
    </>
  }
}

export const TableWrapper = (props: any) => {
  const classes = useStyles()
  return <TableContainer className={classes.tableContainer} data-test-id='table'>
    <Table className={classes.tableWrapper}  >
      <TableHead>
        <ImpactTitle
          ImpactHeading={ImpactHeading}
          tableHeader={tableStyle.tableHeader}
          tableHeadCell={tableStyle.tableHeadCell}
        />
      </TableHead>
      <Tablecontent
        {...props.ImpactProps}
      />
    </Table>
  </TableContainer>
}

export const Tablecontent = (props: any) => {
  return (
    <>
      <TableBody data-test-id='table'>
        {props.reader_list?.map((listData: any) => {
          return (
            <TableRow key={listData.id} style={tableStyle.tableRow}>
              <TableCell align="left"> {listData?.attributes?.mind_mastery_course?.name}</TableCell>
              <TableCell align="left">{listData?.attributes?.academic_year}</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">
                <Box display={"flex"} style={{ gap: "20px" }}>
                  <Link target="_blank" href={listData?.attributes?.pdf_url}><img src={listData?.attributes?.pdf_url ? sheetBlue : sheetGrey} alt="share" /></Link>
                </Box>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}

export const tableStyle = {
  tableRow: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    background: "#fff",
    borderRadius: "10px",
    outline: "1px solid #ddd",
  },
  tableHeader: {
    backgroundColor: "0056A3",
    color: "#fff",
    borderRadius: "10px",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  headerBox: {

    alignItems: "center",
    display: "flex",
    background:
      "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
    borderRadius: "12px 12px 0px 0px",
    padding: "16px 24px",


  },
  mainBox: {
    maxWidth: "1108px",
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "12px",

  },
  boxPadding: {
    padding: "24px",
  },
  mainTable: {
    borderCollapse: "separate",
    borderSpacing: "0px 4px"

  },
  tableHeadCell: {
    color: "#fff",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },

};
export const ImpactTitle = (props: any) => {
  return (
    <TableRow style={props.tableHeader}>
      {props.ImpactHeading?.map((val: any) => {
        return (
          <TableCell style={props.tableHeadCell} key={val}>{val}</TableCell>
        );
      })}
    </TableRow>
  )
}

export default ImpactReport;




