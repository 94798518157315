import React, { ReactNode } from "react"
import SidebarLayout from "../SidebarLayout.web";
import PageContentLayout from "../PageContentLayout.web";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, Theme, RadioGroup, Radio, FormControlLabel, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Loader from "../../../../components/src/Loader.web";
import InfoModal from "../CommonComponents/InformationModal.web";
import CommonSnackBar from "../CommonComponents/CommonSnackBar.web";
import { CheckedIcon, Icon, Option } from "../YouMatter/YouMatterQuestions.web";
import CommonPagination from "../../../../components/src/Pagination.web";
import MPowerController, { S } from "./MPowerController";

const configJSON = require("../config.js");

const useStyles = makeStyles<Theme>(() => ({
    programFormWrapper: {
        padding: '24px 32px'
    },
    pageTitle: {
        fontWeight: 700,
        fontSize: "32px",
        color: "#0056A3",
        fontFamily: "Poppins",
        textTransform: 'capitalize',
        "@media(max-width:767px)": {
            fontSize: "24px"
        }
    },
    questionCard: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
        borderRadius: '8px',
        border: '0.2px solid #e3e3e3',
        marginBottom: '16px'
    },
    questionNumber: {
        minHeight: '56px',
        minWidth: '56px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontFamily: "Poppins",
        fontSize: '20px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px'
    },
    questionText: {
        fontWeight: 500,
        marginRight: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '&:hover': {
            '-webkitBoxOrient': 'inherit',
            wordBreak: 'break-word',
        }
    },
    radioGroup: {
        '&.MuiFormGroup-root': {
            flexDirection: 'row',
            "@media(max-width:575px)": {
                flexDirection: 'column',
            }
        }
    },
    submitForm: {
        "&.MuiButton-root": {
            height: '48px',
            maxWidth: '208px',
            width: '100%',
            borderRadius: "12px",
            backgroundColor: "#0056A3",
            display: 'block',
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontSize: '16px',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
    formDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        marginBottom: '32px'
    },
    questionInputWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    optionWrapper: {
        padding: "4px",
        borderRadius: "8px",
        "&:has(.Mui-checked)": {
            border: "0.75px solid #0056A3",
        },
        border: "0.2px solid #e3e3e3",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    },
    optionFormLabel: {
        "&.MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "100%",
            justifyContent: "space-between",
        },
    },
    submitPaginationWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        "@media(max-width:600px)": {
            flexDirection: 'column-reverse'
        }
    },
    paginationWrapper: {
        "@media(max-width:600px)": {
            marginTop: '25px'
        }
    },
    accordionSummary: {
        "& .MuiAccordionSummary-content": {
            minHeight: "auto",
            padding: "0",
            margin: "7px 0",
            display: "block",
        },
        "&.MuiAccordionSummary-root": {
            padding: "0",
        },
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            display: "none",
        },
        "&.MuiAccordion-root": {
            boxShadow: "none",
        },
    },
    accordionDetails: {
        "&.MuiAccordionDetails-root": {
            padding: "10px 0 0 0",
            display: "block",
        },
    },
}))

interface Choice {
    id: number;
    content: string;
}
interface QuestionCardProps {
    questionId: any;
    questionIndex: number;
    question: string;
    choices: Choice[];
    questionType: string;
    selectedAnswers: any;
    handleAnswer: (id: any, value: any, questionType: string) => void;
    accordionState: string;
    index: number;
    handleChangeAccordion: (panel: string) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

export const QuestionCard = (props: QuestionCardProps) => {
    const classes = useStyles()
    const { questionId, question, handleAnswer, selectedAnswers, questionIndex, choices, questionType, index, accordionState, handleChangeAccordion } = props;
    const isBoolean = questionType === configJSON.QuestionTypes.TrueFalse;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isBoolean ? (event.target as HTMLInputElement).value === 'true' : (event.target as HTMLInputElement).value;
        handleAnswer(questionId, value, questionType)
    };
    const size4boolean = isBoolean ? 6 : 3;
    return <Accordion
        data-test-id="accordion"
        className={classes.accordion}
        expanded={accordionState === `${index + 1}`}
        onChange={handleChangeAccordion(`${index + 1}`)}
    >
        <AccordionSummary className={classes.accordionSummary}>
            <Box className={classes.questionCard} data-test-id='box'>
                <Box className={classes.questionNumber}>{`Q${questionIndex}.`}</Box>
                <Box className={classes.questionInputWrapper}>
                    <Typography className={classes.questionText}>{question}</Typography>
                </Box>
            </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            <RadioGroup className={classes.radioGroup} data-test-id='radio' value={`${selectedAnswers[questionId]?.value}`} onChange={handleChange}>
                <Grid container spacing={2}>
                    {choices?.map((element: any, index: number) => (
                        <Grid
                            key={element.id}
                            item
                            xl={size4boolean}
                            lg={size4boolean}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <Box className={classes.optionWrapper}>
                                <FormControlLabel
                                    className={classes.optionFormLabel}
                                    value={`${element.id}`}
                                    labelPlacement="start"
                                    control={<Radio color="primary" icon={<Icon />} checkedIcon={<CheckedIcon />} />}
                                    label={<Option option={index + 1} content={element.content} isListView={true} />}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </RadioGroup>
        </AccordionDetails>
    </Accordion>
}

interface ProgramFormPageProps {
    handleAnswer: (id: any, value: any, questionType: string) => void;
    states: S;
    formType: string;
    totalPages: number;
    handlePage: (page: number) => void;
    handleSubmit: () => void;
    closeSnackbar: () => void;
    handleChangeAccordion: (panel: string) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

export const ProgramFormPage = (props: ProgramFormPageProps) => {
    const classes = useStyles()
    const { states, formType, closeSnackbar, handleAnswer, handleSubmit, handlePage, totalPages, handleChangeAccordion } = props;
    const ansLength = Object.entries(states.programFormSubmitData).length;
    const noDataMsg = props.states.courseId ? configJSON.YouMatterTexts.NoQuestionsForCourse : configJSON.YouMatterTexts.PleaseSelectAssignedCourse
    return <Box className={classes.programFormWrapper} data-test-id='box'>
        {
            states.prePostFormData.length > 0 ? states.prePostFormData.map((element: any, index: number) => {
                const attributes = element?.attributes;
                const questionType = attributes.question_type;
                const choices = questionType === configJSON.QuestionTypes.TrueFalse ? configJSON.YouMatterTexts.ChoiceArr : attributes.choices;
                return <QuestionCard
                    index={index}
                    accordionState={states.accordionState}
                    handleChangeAccordion={handleChangeAccordion}
                    data-test-id='question'
                    handleAnswer={handleAnswer}
                    key={element.id}
                    choices={choices}
                    question={attributes?.text}
                    questionType={questionType}
                    questionId={attributes?.id}
                    selectedAnswers={states.programFormSubmitData}
                    questionIndex={index + 1} />
            }) : noDataMsg
        }

        {states.prePostFormData.length > 0 && <Box className={classes.submitPaginationWrapper} >
            <Box className={classes.paginationWrapper}>
                <CommonPagination
                    page={states.page}
                    handlePage={handlePage}
                    pageCount={totalPages}
                />
            </Box>
            <Button data-test-id='submit' disabled={ansLength < states.prePostFormData?.length} onClick={handleSubmit} className={classes.submitForm}>{formType === configJSON.YouMatterTexts.PreProgramType ? configJSON.YouMatterTexts.SubmitPreProgram : configJSON.YouMatterTexts.SubmitPostProgram}</Button>
        </Box>}
        <CommonSnackBar
            data-test-id='snackbar'
            type="error"
            verticalPosition="top"
            horizontalPosition="center"
            open={states.snackbar}
            onClose={closeSnackbar}
            message={states.snackbarMsg}
            duration={4000}
        />
    </Box>
}

const getTitle = (type: string) => {
    if (type === configJSON.YouMatterTexts.PreProgramType) {
        return configJSON.YouMatterTexts.PreProgramForm
    } else {
        return configJSON.YouMatterTexts.PostProgramForm
    }
}

export default class ProgramForm extends MPowerController {
    async componentDidMount(): Promise<void> {
        this.getAssignedCourses();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        if ((prevState.courseId !== this.state.courseId && !this.state.snackbar && this.state.courseId) || prevState.page !== this.state.page) {
            this.setState({ isPageChange: prevState.page !== this.state.page })
            this.getPrePostQuestions()
        }
    }

    render(): ReactNode {
        const formType = this.props.navigation.getParam && this.props.navigation.getParam(configJSON.YouMatterTexts.MPowerFormType)
        return <SidebarLayout {...this.props}>
            <PageContentLayout addPadding titleWrapperWidth={"100%"} goBack={this.goBack} onClearSelectedCourse={this.handleClearSelectedCourse} title={getTitle(formType)} handleSelectCourse={this.handleSelectCourse} courseList={this.state.courseList} courseId={this.state.courseId} >
                {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                <ProgramFormPage
                    data-test-id='form'
                    formType={formType}
                    states={this.state}
                    handleAnswer={this.handleProgramFormQuestions}
                    handleSubmit={this.handleOpenDialog}
                    closeSnackbar={this.closeSnackBar}
                    totalPages={this.state.totalPages}
                    handlePage={this.handlePage}
                    handleChangeAccordion={this.handleChangeAccordion}
                />
                <InfoModal
                    data-test-id='success'
                    info={this.state.modalType === configJSON.YouMatterTexts.Success ? configJSON.YouMatterTexts.SuccessMsg : `${configJSON.YouMatterTexts.ConfirmMsg} ${getTitle(formType)}?`}
                    modalType={this.state.modalType}
                    submit={() => this.submitPrePostForm(formType)}
                    open={this.state.openFormSubmitModal}
                    closeModal={this.handleCloseSubmitSuccessModal} />
            </PageContentLayout>
        </SidebarLayout>
    }
}
