import React from 'react'
import {
    Box,
    Grid,
    Typography,
    Theme,
    Link,
    Button

} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageContentLayout from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import { Need_report, Programe_report, student_report, time_report, backArrow } from '../assets';
import AnalysisReportController from './AnalysisReport/AnalysisReportController.web';

const configJSON = require("../config.js");

const useStyles = makeStyles<Theme>(() => ({
    backButton: {
        padding: "0",
        background: "#0156A2",
        minHeight: "48px",
        minWidth: "48px",
        opacity: 1,
        borderRadius: "8px",
        margin: "0 0 0 24px",
        "&:hover": {
            background: "#010E36",
            opacity: 0.8
        }
    },
    arrowRight: {
        width: "25px",
        transform: "rotate(180deg)"
    },
    nameStyle: {
        color: "#0056A3",
        fontSize: "24px",
        fontWeight: "bold",
        '@media(max-width:767px)': {
            fontSize: "20px",
        },
        '@media(max-width:600px)': {
            fontSize: "16px",
        }
    },
    imgeStyle: {
        minWidth: "55px",
        minHeight: "55px",
        borderRadius: "50px",
        border: "1px solid #0056A3",
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    img: {
        width: '25px'
    },
    navItem: {
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        border: '0.2px solid #e3e3e3',
        borderRadius: "8px"
    }
}))

const ReportData = [
    {
        name: configJSON.Report.ProgramImpactReport,
        link: configJSON.Report.ProgramImpactReportPath,
        image: Programe_report
    },
    {
        name: configJSON.Report.NeedAnalysisReport,
        link: configJSON.Report.NeedAnalysisReportPath,
        image: Need_report
    },
    {
        name: configJSON.Report.TimelyProgressReport,
        link: configJSON.Report.TimelyProgressReportPath,
        image: time_report
    },
    {
        name: configJSON.Report.StudentReport,
        link: configJSON.Report.StudentReportPath,
        image: student_report
    },
]

const StudentData = [
    {
        name: configJSON.Report.ProgramImpactReport,
        link: '',
        image: Programe_report
    },
    {
        name: configJSON.Report.NeedAnalysisReport,
        link: configJSON.Report.NeedAnalysisPath,
        image: Need_report
    },
    {
        name: configJSON.Report.TimelyProgressReport,
        link: '',
        image: time_report
    },
    {
        name: configJSON.Report.StudentReport,
        link: '',
        image: student_report
    },
]

const SchoolData = [
    {
        name: configJSON.Report.ImpactReport,
        link: configJSON.Report.ImpactReportPath,
        image: Programe_report
    },
    {
        name: configJSON.Report.StudentsAnalysisReport,
        link: configJSON.Report.SchoolAnalysisReportPath,
        image: student_report
    },
    {
        name: configJSON.Report.TimelyProgressReport,
        link: configJSON.Report.SchooolTimelyProgressReportPath,
        image: time_report
    },
]

interface ReportCardProps {
    report: any;
    goTo: (module: string, params?: Object) => void;
}

export const ReportCard = (props: ReportCardProps) => {
    const classes = useStyles()
    return (
        <Box p={4}>
            <Grid container spacing={4}>
                {
                    props.report?.map((item: any) => {
                        return (
                            <Grid item xs={12} key={item} >
                                <Box className={classes.navItem} p={2}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box display={"flex"} style={{ gap: "10px" }} alignItems={"center"}>
                                            <Box className={classes.imgeStyle}>
                                                <img src={item.image} alt="" className={classes.img} />
                                            </Box>
                                            <Typography className={classes.nameStyle}>{item.name}</Typography>
                                        </Box>
                                        <Button className={classes.backButton} onClick={() => props.goTo(item.link)} >
                                            <img src={backArrow} alt="" className={classes.arrowRight} />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default class Reports extends AnalysisReportController {

    render() {
        const user = JSON.parse(`${localStorage.getItem('userDetails')}`);
        const userRole = user?.attributes?.role
        const Data = this.getDataBasedOnUserRole(userRole, SchoolData, StudentData, ReportData);
        return <>
            <SidebarLayout {...this.props}>
                <PageContentLayout title={configJSON.Report.Reports}>
                    <ReportCard data-test-id='card' report={Data} goTo={this.goTo} />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
}


