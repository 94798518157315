import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

let config = require('../../../../framework/src/config');
// Customizable Area Start
// Customizable Area End

export const configJSON = require('../config.js');

interface Navigation {
    navigate?: (path: string, params: Object) => void;
    goBack?: () => void;
    getParam?: (param: any) => any
}
export interface Props {
    navigation: Navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    // Customizable Area End
    youMatterPageData: any;
    page: number;
    submissionStatus: string;
    openYouMatterDetailsModel: boolean;
    youMatterDetails: any;
    isLoading: boolean;
    listView: boolean;
    openAttachmentDropZone: boolean;
    progress: number;
    attachmentName: string;
    attachmentSize: any;
    tempAttachmentUrl: string;
    answerAttachment: any;
    attachmentQuestionNumber: any;
    attachmentQuestionId: any;
    questionNumber: number;
    accordionState: any;
    youMatterId: any;
    openFormSubmitModal: boolean;
    answers: any;
    sections: any;
    sectionTab: any;
    sectionData: any;
    modalType: "success" | "warning" | "confirm" | "schedule";
    sectionOver: boolean;
    youMatterTitle: any;
    snackbar: boolean;
    snackbarMsg: string;
    isPageChange: boolean;
    totalPages: number;
}
interface SS { }

export default class YouMatterController extends BlockComponent<
    Props,
    S,
    SS
> {
    youMatterApiCallId: any;
    youMatterDetailsApiCallId: any;
    programFormSubmitApiCallId: any;
    youMatterSectionsApiCallId: any;
    sectionDataApiCallId: any;
    sectionFormSubmitApiCallId: any;
    getPrePostFormDataApiCallId: any;
    getAssignedCoursesApiCallId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage)
        ];

        this.state = {
            youMatterPageData: {},
            youMatterDetails: {},
            page: 1,
            submissionStatus: 'published',
            openYouMatterDetailsModel: false,
            isLoading: false,
            listView: false,
            openAttachmentDropZone: false,
            progress: 0,
            attachmentName: '',
            attachmentSize: '',
            tempAttachmentUrl: '',
            answerAttachment: '',
            attachmentQuestionNumber: '',
            attachmentQuestionId: '',
            questionNumber: 1,
            accordionState: false,
            youMatterId: '',
            openFormSubmitModal: false,
            answers: {},
            sections: [],
            sectionTab: 0,
            sectionData: {},
            modalType: "success",
            sectionOver: false,
            youMatterTitle: '',
            snackbar: false,
            snackbarMsg: "",
            isPageChange: true,
            totalPages: 1
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (!responseJson.error) {
                    this.handleResponses(apiRequestCallId, responseJson)
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                }
            }
        }
    }

    handleResponses = (apiRequestCallId: any, responseJson: any) => {
        switch (apiRequestCallId) {
            case this.youMatterApiCallId: this.setState({ youMatterPageData: responseJson }); break;
            case this.youMatterSectionsApiCallId:
                const pendingSections = responseJson.data.attributes.sections.findIndex((item: any) => item.status === "pending");
                this.setState({ sections: responseJson.data.attributes.sections, youMatterTitle: responseJson.data.attributes.title, sectionTab: pendingSections });
                break;
            case this.sectionDataApiCallId: this.setState({ sectionData: responseJson.data }); break;
            case this.sectionFormSubmitApiCallId: this.handleYouMatterSections(); break;
        }
    }

    handleYouMatterSections = () => {
        this.setState({ openFormSubmitModal: true, modalType: configJSON.YouMatterTexts.Success, questionNumber: 1, sectionData: {}, answers: {}, attachmentQuestionId: '', attachmentQuestionNumber: '', accordionState: '' })
        if (this.state.sectionTab + 1 !== this.state.sections.length) {
            this.setState({ sectionTab: this.state.sectionTab + 1 });
        } else {
            this.setState({ sectionOver: true })
        }
    }

    goTo = (module: string, params: Object = {}) => {
        if (this.props.navigation.navigate) {
            this.props.navigation.navigate(module, { ...params });
        }
    };

    goBack = () => {
        if (this.props.navigation.goBack) {
            this.props.navigation.goBack();
        }
    };

    getToken = () => {
        return localStorage.getItem('user_token')
    }

    handlePage = (page: number) => {
        this.setState({ page: page })
    }

    handleTabChange = (value: number) => {
        if (value === 0) {
            this.setState({ submissionStatus: configJSON.YouMatterTexts.Published, page: 1 })
        }
        else {
            this.setState({ submissionStatus: configJSON.YouMatterTexts.Submitted, page: 1 })
        }
    };

    handleSectionTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ sectionTab: newValue })
    };

    openYouMatterDetailsModel = (attributes: any, youMatterId: any) => {
        this.setState({ openYouMatterDetailsModel: true, youMatterDetails: attributes, youMatterId: youMatterId });
    }

    closeYouMatterDetailsModel = (id: any, status: any) => {
        if (status === configJSON.YouMatterTexts.Published) {
            this.goTo(configJSON.YouMatterTexts.YouMatterQuestions, { youMatterId: id });
        }
        this.setState({ openYouMatterDetailsModel: false })
    }

    handleQuestionViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ listView: event.target.checked });
    };

    handleOpenDropZone = (questionId: any, questionIndex: any) => {
        this.setState({ openAttachmentDropZone: true, attachmentQuestionId: questionId, attachmentQuestionNumber: questionIndex });
    }

    handleCloseDropZone = () => {
        this.setState({ openAttachmentDropZone: false, attachmentName: '', attachmentSize: '', progress: 0, tempAttachmentUrl: '' })
    }

    handleCloseSubmitSuccessModal = () => {
        this.setState({ openFormSubmitModal: false })
        if (this.state.sectionOver) {
            this.setState({ sectionOver: false })
            this.goTo(configJSON.YouMatterTexts.YouMatterModule);
        }
    }

    handleOpenDialog = () => {
        this.setState({ openFormSubmitModal: true, modalType: 'confirm' })
    }

    handleQuestionAnswer = (questionId: any, ansObj: any, selectedOption: string, questionType: string) => {
        this.setState((prevState) => ({
            answers: {
                ...prevState.answers,
                [questionId]: {
                    questionId: questionId,
                    selectedOption: selectedOption,
                    value: ansObj[0].id,
                    content: ansObj[0].content,
                    questionType: questionType
                }
            },
        }))
    }

    handleSubjectiveQuestion = (questionId: any, value: any, questionType: any) => {
        this.setState((prevState) => {
            const updatedAnswer = prevState.answers[questionId] || {};
            return {
                answers: {
                    ...prevState.answers,
                    [questionId]: {
                        ...updatedAnswer,
                        questionId: questionId,
                        value: value,
                        questionType
                    }
                }
            }
        })
    }

    handleUploadAttachment = async (event: any) => {
        const file = event[0];
        const fileSize = file.size;
        const fileName = file.name;
        const size = this.formatFileSize(fileSize);
        const url = URL.createObjectURL(file);
        this.setState((prevState) => {
            const updatedAnswer = prevState.answers[this.state.attachmentQuestionId] || {};
            return {
                tempAttachmentUrl: url,
                attachmentName: fileName,
                attachmentSize: size,
                progress: 0,
                answers: {
                    ...prevState.answers,
                    [this.state.attachmentQuestionId]: {
                        ...updatedAnswer,
                        attachmentName: fileName,
                        questionId: this.state.attachmentQuestionId,
                        attachment: file
                    }
                },
            }
        }, () => this.simulateProgress());
    }

    handleAttachmentUploadDone = () => {
        this.handleCloseDropZone();
    }

    simulateProgress = () => {
        let progressCounter = 0;
        const progressInterval = setInterval(() => {
            progressCounter += 10;
            if (progressCounter <= 100) {
                this.setState({ progress: progressCounter });
            } else {
                clearInterval(progressInterval);
            }
        }, 10);
    };

    handleCancelUpload = () => {
        this.setState((prevState) => {
            const updatedAnswer = prevState.answers[this.state.attachmentQuestionId] || {};
            return {
                attachmentName: '',
                attachmentSize: '',
                progress: 0,
                tempAttachmentUrl: '',
                answers: {
                    ...prevState.answers,
                    [this.state.attachmentQuestionId]: {
                        ...updatedAnswer,
                        attachmentName: '',
                        attachment: null
                    }
                }
            }
        })
    }

    formatFileSize = (size: number) => {
        if (size < 1024) {
            return size + " bytes";
        } else if (size < 1048576) {
            return (size / 1024).toFixed(2) + " Kb";
        } else if (size < 1073741824) {
            return (size / 1048576).toFixed(2) + " Mb";
        }
    }

    handleQuestionChange = (page: number) => {
        window.scrollTo(0, 0)
        this.setState({ questionNumber: page, accordionState: `${page}` })
    }

    handleChangeAccordion = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({ accordionState: isExpanded ? panel : false, questionNumber: Number(panel) });
    };

    getYouMatterPageData = async (submissionStatus: string, page: number) => {
        this.setState({ youMatterPageData: {} })
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.youMatterApiCallId = apiRequest.messageId;
        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardGetApiMethod,
            `${configJSON.YouMatterTexts.YouMatters}?submission_status=${submissionStatus}&page=${page}`,
            header
        );
    }

    getYouMatterSections = async (youMatterId: any) => {
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.youMatterSectionsApiCallId = apiRequest.messageId;
        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardGetApiMethod,
            `${configJSON.YouMatterTexts.YouMatters}/${youMatterId}`,
            header
        );
        return true;
    }

    getQuestions = async (sectionId: number, youMatterId: number) => {
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sectionDataApiCallId = apiRequest.messageId;
        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardGetApiMethod,
            `${configJSON.YouMatterTexts.Section}/${sectionId}?you_matter_id=${youMatterId}`,
            header
        );
    }

    submitSectionQuestionForm = async (youMatterId: any, sectionId: any) => {
        this.setState({ openFormSubmitModal: false })
        const header = {
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sectionFormSubmitApiCallId = apiRequest.messageId;
        const formData = new FormData();

        Object.values(this.state.answers).forEach((answer: any) => {
            const {
                questionId,
                attachment,
                selectedOption,
                value,
                questionType
            } = answer;

            formData.append(`answers[][question_id]`, questionId);
            formData.append(`answers[][mpower_checks_section_id]`, sectionId);

            if (attachment) {
                formData.append(`answers[][document_file]`, attachment);
            }

            if (selectedOption) {
                formData.append(`answers[][choice_id]`, value);
                formData.append(`answers[][question_type]`, questionType);
            }

            if (value && !selectedOption) {
                formData.append(`answers[][solution]`, value);
                formData.append(`answers[][question_type]`, questionType);
            }
        });

        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardPostApiMethod,
            `${configJSON.YouMatterTexts.Answers}?you_matter_id=${youMatterId}`,
            header,
            formData
        );
    }

    async makeApiCall(
        uniqueApiCallId: string,
        method: string,
        endpoint: string,
        headers: any,
        body?: any,
    ) {
        // Customizable Area Start
        let fullURL =
            endpoint.indexOf("://") === -1
                ? config.baseURL + "/" + endpoint
                : endpoint;

        let apiResponseMessage = new Message(
            getName(MessageEnum.RestAPIResponceMessage)
        );
        apiResponseMessage.addData(
            getName(MessageEnum.RestAPIResponceDataMessage),
            uniqueApiCallId
        );

        try {
            this.setState({ isLoading: true })
            let response = await fetch(fullURL, {
                method: method.toUpperCase(),
                headers: headers,
                body: body
            })
            if (response.status === 401) {
                localStorage.removeItem('userDetails')
                this.goTo('LoginForm')
            }

            this.setState({ isLoading: false })

            let responseJson = await response.json();
            if (responseJson.data || responseJson.message) {
                this.setState({ isLoading: false })
            }
            //setting Response
            apiResponseMessage.addData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
                responseJson
            );
        } catch (error) {
            runEngine.debugLog("RestApiClient Error", error);
            //setting Error
            console.log("Api Error" + JSON.stringify(error));
            apiResponseMessage.addData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
                "An error has occuured. Please try again later."
            );
        }
        this.send(apiResponseMessage);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
}
