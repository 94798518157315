import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonIcon from '@material-ui/icons/Person';
import Visibility from "@material-ui/icons/Visibility";
import LockIcon from '@material-ui/icons/Lock';
import { loginBG } from "./assets";
import EmailAccountLoginController, {
  Props, configJSON
} from "./EmailAccountLoginController";
import CommonTextField from "../../dashboard/src/CommonComponents/CommonTextField.web";
import CommonSnackBar from "../../dashboard/src/CommonComponents/CommonSnackBar.web";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { mainContainer, forgotText, logoContainer, loginBox } = loginStyle;
    return (
      <Box style={mainContainer}  >
        <Container maxWidth="lg" >
          <CommonSnackBar
            textCenter={true}
            open={this.state.openSnackbar}
            message={this.state.snackbarMsg}
            duration={4000}
            fontWeight={600}
            onClose={() => this.setState({ openSnackbar: false })}
            verticalPosition="top"
            horizontalPosition="center"
            type="error"
          />
          <Box style={loginBox} sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: '16px'
          }}>
            <Box style={logoContainer}>
              <img src={this.state.loginLogo} width={'225px'} />
            </Box>
            <Box sx={{ width: "100%", }}>
              <CommonTextField
                labelFontColor="#0056A3"
                labelFontSize={'14px'}
                label={'Login ID'}
                data-test-id="txtInputEmail"
                startAdornment={<PersonIcon />}
                placeholder="Enter Your Login ID"
                value={this.state.email}
                handleChange={(e: any) => { this.clearErrors(configJSON.email); this.setEmail(e.target.value) }}
                error={this.state.emailError}
                helperText={this.state.emailError ? this.state.errorMessage : ""}
              />
              <CommonTextField
                labelFontColor="#0056A3"
                labelFontSize={'14px'}
                label={'Password'}
                mb={'10px'}
                type={this.state.enablePasswordField ? "password" : "text"}
                startAdornment={<LockIcon />}
                endAdornmentData={<IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  edge="end"
                  data-test-id="btneye"
                >
                  {this.state.enablePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>}
                data-test-id="txtInputPassword"
                placeholder={"Enter Your Password"}
                value={this.state.password}
                handleChange={(e: any) => { this.clearErrors(''); this.setPassword(e.target.value) }}
                error={this.state.passwordError}
                helperText={this.state.passwordError ? this.state.passErrorMessage : ""}
              />
              <Box
                sx={{ textAlign: 'right', marginBottom: '20px' }}
                data-test-id={"btnForgotPassword"}
              >
                <Typography variant="body2" style={forgotText} component={Link} to="/ForgotPassword">
                  Forgot Password?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    background: '#0056A3',
                    height: '50px',
                    borderRadius: '8px'
                  }}
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={() => this.submit()}
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
}

// Customizable Area Start
const loginStyle = {
  mainContainer: {
    height: '100%',
    backgroundImage: `url(${loginBG})`,
    backgroundPosition: ' center center',
    backgroundSize: 'contain',
    paddingTop: '90px',
  },
  loginBox: {
    backgroundColor: "#f0f4f6",
    padding: "10px 32px",
    maxHeight: '545px',
    height: '100%',
    maxWidth: '500px',
    boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    margin: 'auto',
  },
  forgotText: {
    fontSize: "14px",
    color: "#0056A3",
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none",
  },
  logoContainer: {
    padding: '50px 0 30px 0',
  }
};
// Customizable Area End
