import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { FormikHelpers } from "formik";

// Customizable Area Start
let config = require("../../../../framework/src/config");
// Customizable Area End

export const configJSON = require("../config");
interface AlertStatus {
  showAlertSuccess?: boolean;
  showAlertFailed?: boolean;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  alertStatus: AlertStatus;
  program:any;
  organisation:any;
  otherOrganisation:string;
  otherProgram:string;
  selectOptions:any;
  // Customizable Area End
}
interface MyFormValues {
  fullName: string;
  companyName: string;
  email: string;
  message: string;
  phoneNumber: string;
  programInterestedIn:string[];
  typeOfOrganisation:string[];
  
}

interface SS {
  id: any;
}

export default class PartnershipPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getOptionsApiCallId:any;
  postPartnershipApiCallID:any;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      alertStatus: {
        showAlertSuccess: false,
        showAlertFailed: false
      },
      program:[],
      organisation:[],
      otherOrganisation:"",
      otherProgram:"",
      selectOptions:{
        organsisations:[""],
        programs:[""]
      }

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if(errorMsg){
        console.log(errorMsg);
      }
      if (responseJson && !responseJson.errors) {
          this.ApiCallSuccess(apiRequestCallId, responseJson);
  
      } else if (responseJson && responseJson.errors) {
          console.log(responseJson, "Error");
          if(apiRequestCallId === this.postPartnershipApiCallID){

              this.setState({
                alertStatus: { showAlertSuccess: false, showAlertFailed: true }
              });
              setTimeout(() => {
                this.setState({
                  alertStatus: { showAlertSuccess: false, showAlertFailed: false }
                });
              }, 5000);
              return false;
          
          }
  
      }}

   
    // Customizable Area End
  }

  ApiCallSuccess(apiRequestCallId:any, responseJson:any){
    if(apiRequestCallId === this.getOptionsApiCallId){
      this.setState({selectOptions:{organisations:responseJson.data.attributes.partnership_questions[0].attributes.answers, 
        programs: responseJson.data.attributes.partnership_questions[1].attributes.answers}});
    }
    if(apiRequestCallId === this.postPartnershipApiCallID){
        this.setState({
          alertStatus: { showAlertSuccess: true, showAlertFailed: false }
        });
        setTimeout(() => {
          this.setState({
            alertStatus: { showAlertSuccess: false, showAlertFailed: false }
          });
        }, 5000);
        return true;
    
      
    }
  }
  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getOptionsData();
  }

  postPartnerData = async (
    values: MyFormValues,
    { resetForm }: FormikHelpers<MyFormValues>
  ) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const allPrograms = this.state.otherProgram ? [...this.state.program, this.state.otherProgram] : this.state.program;
    const allOrg = this.state.otherOrganisation ? [...this.state.organisation, this.state.otherOrganisation] : this.state.organisation;
    
    const attrs = {
      partner: {
        name: values.fullName,
        company_name: values.companyName,
        phone_number: values.phoneNumber,
        email: values.email,
        message: values.message,
        program: allPrograms,
        organisation: allOrg
      }
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postPartnershipApiCallID = apiRequest.messageId;
    this.makeApiCallPartnership(
      apiRequest.messageId,
      configJSON.exampleAPiMethod,
      configJSON.postPartnerData,
      header,
      JSON.stringify(attrs)
    );
    resetForm();
    this.setState({program:[], organisation:[]});
    
  };

  handleProgramChange= (event:any)=>{
    this.setState({program:event.target.value})
  }

  handleOrgChange= (event:any)=>{
    this.setState({organisation:event.target.value})
  }
  handleOtherOrgChange=(event:any)=>{
    this.setState({otherOrganisation:event.target.value})
  }

  handleOtherProgramChange=(event:any)=>{
    this.setState({otherProgram:event.target.value})
  }

  getOptionsData = ()=>{
    const headerPartnership = {
      "Content-Type": "application/json",
    };
    const apiRequestPartnership = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOptionsApiCallId = apiRequestPartnership.messageId;
    this.makeApiCallPartnership(
      apiRequestPartnership.messageId,
     "GET",
      configJSON.getPartnershipOptionsEndPoint,
      headerPartnership,
    );
  }


  async makeApiCallPartnership(
    uniqueApiCallIdPartnership: string,
    methodPartnership: string,
    endpointPartnership: string,
    headersPartnership: any,
    bodyPartnership?: any,
    paramsPartnership?: any,
  ) {
    let fullURLPartnership =
      endpointPartnership.indexOf("://") === -1
        ? config.baseURL + "/" + endpointPartnership
        : endpointPartnership;

    let apiResponseMessagePartnership = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessagePartnership.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallIdPartnership
    );
    
    try {
      let response = await fetch(fullURLPartnership + "?" + new URLSearchParams({...paramsPartnership }).toString(), {
        method: methodPartnership.toUpperCase(),
        headers: headersPartnership,
        body: bodyPartnership
      });
      if (response.status === 401) {
        console.log("401 Response", response);
      }
      let responseJsonPartnership = await response.json();
      
      //setting Response
      apiResponseMessagePartnership.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonPartnership
      );
    } catch (errorPartnership) {
      runEngine.debugLog("RestApiClient Error", errorPartnership);
      //setting Error
      console.log("Api Error" + JSON.stringify(errorPartnership));
      apiResponseMessagePartnership.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessagePartnership);
  }
  // Customizable Area End
}
