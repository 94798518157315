import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dropzone from "react-dropzone";
import {
  uploadIcon,
  closeFile,
} from "./assets";
import LinearProgress from "@material-ui/core/LinearProgress";


interface Props {
  states: any;
  handleCloseDropZone: () => void;
  handleOnDrop: (e: any) => void;
  handleCancel: () => void;
  handleDone: () => void;
}

const useStyles = makeStyles({
  dialog: {
    "& .MuiPaper-root": {
      padding: "24px 48px 48px 48px",
      color: "#0056A3",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "715px",
      width: "100%",
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none",
    },
  },
  dropZone: {
    position: "relative",
    height: "202px",
    background: "rgba(0, 86, 163,0.12)",
    borderRadius: "16px",
    width: "100%",
    marginBottom: "32px",
  },
  dropZoneInnerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    cursor:"pointer"
  },
  acceptTypeText: {
    marginBottom: "30px",
    alignSelf: "center",
  },
  progressBar: {
    "&.MuiLinearProgress-root": {
      height: "48px",
    },
    "&.MuiLinearProgress-colorPrimary": {
      background: "#E0EAF4",
      borderRadius: "12px",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      background:
        "linear-gradient(127deg, #B3DBFF -41.4%, #4B90CD 15.03%, #0156A2 95.71%)",
      height: "48px",
      borderRadius: "12px",
    },
  },
  avatarWrapper: {
    padding: "6px 22px",
    borderRadius: "32px",
    background: "#EBEBEB",
    border: "1px solid #0056A3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "min-content",
  },
  avatar: {
    "&.MuiAvatar-root": {
      width: "53px",
      height: "53px",
      marginRight: "4px",
      cursor: "pointer",
      "&>img": {
        width: "52px",
      },
    },
  },
  doneBtn: {
    "&.MuiButton-root": {
      height: "48px",
      width: "175px",
      borderRadius: "12px",
      backgroundColor: "#0056A3",
    },
    "& .MuiButton-label": {
      textTransform: "capitalize",
      color: "white",
    },
  },
  dialogFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogTitleText: {
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
  },
  Or: {
    marginBottom: "20px",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
  },
  selectAvatar: {
    marginBottom: "8px",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
  },
  progressSubWrapper: {
    position: "absolute",
    left: "16px",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "65%",
    width: "100%",
  },
  progressText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  progressBarWrapper: {
    position: "relative",
    marginBottom: "24px",
  },
  fileName: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    color: "white",
  },
  fileSize: {
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "14px",
    color: "white",
    fontStyle: "italic",
  },
  uploadData: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    fontSize: "14px",
  },
  cancelBtn: {
    background: "transparent",
    border: "none",
    color: "#0056A3",
    fontSize: "14px",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    fontWeight: 500,
    cursor: "pointer",
  },
  checkedAvatar: {
    "&.MuiAvatar-root": {
      width: "53px",
      height: "53px",
      marginRight: "4px",
      border: "1px solid #0056A3",
      cursor: "pointer",
      "&>img": {
        width: "52px",
      },
    },
  },
  closeFile: {
    background: "transparent",
    border: "none",
    position: "relative",
    right: "22px",
  },
  avatarRadioBtn: {
    "&.MuiRadio-root": {
      padding: "0",
    },
  },
  radioLabel: {
    "&.MuiFormControlLabel-root": {
      margin: "0",
    },
  },
  hiddenDiv: {
    content: "",
    visibility: "hidden",
  },
});

const AadharImageUpload = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        className={classes.dialog}
        onClose={props.handleCloseDropZone}
        aria-labelledby="simple-dialog-title"
        open={props.states.openAadharImageDropZone}
      >
        <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleText}>
            Upload your image
          </Typography>
        </DialogTitle>
        <Typography className={classes.acceptTypeText} variant="subtitle2">
          {" "}
          PNG, JPG and JPEG files are allowed{" "}
        </Typography>
        <Dropzone
          onDrop={props.handleOnDrop}
          accept={{
            "image/jpeg": [],
            "image/png": [],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className={classes.dropZone}>
              <div {...getRootProps()} className={classes.dropZoneInnerWrapper}>
                <img
                  style={{ height: 200 }}
                  src={
                    props.states.tempAadharUrl
                      ? props.states.tempAadharUrl
                      : uploadIcon
                  }
                  alt=""
                />
                <input {...getInputProps()} />
                {!props.states.tempAadharUrl && (
                  <p>Drag & drop a file here, or click to select a file</p>
                )}
              </div>
            </section>
          )}
        </Dropzone>
        {props.states.aadharImageName && (
          <Box className={classes.progressBarWrapper}>
            <Box className={classes.uploadData}>
              {`${props.states.progress < 100 ? 0 : 1}`} of 1 uploaded
              {props.states.progress < 100 && (
                <button
                  data-test-id="cancelUpload"
                  className={classes.cancelBtn}
                  onClick={props.handleCancel}
                >
                  cancel
                </button>
              )}
            </Box>
            <Box className={classes.progressSubWrapper}>
              <Box className={classes.progressText}>
                <Typography className={classes.fileName}>
                  {props.states.aadharImageName}
                </Typography>
                <Typography className={classes.fileSize}>
                  {props.states.aadharImageSize}
                </Typography>
              </Box>
              {props.states.progress === 100 && (
                <button
                  data-test-id="cancelUpload"
                  className={classes.closeFile}
                  onClick={props.handleCancel}
                >
                  <img src={closeFile} />
                </button>
              )}
            </Box>
            <LinearProgress
              className={classes.progressBar}
              variant="determinate"
              value={props.states.progress}
            />
          </Box>
        )}
        <Box className={classes.dialogFooter}>
          <div className={classes.hiddenDiv}></div>
          <Button
            data-test-id="uploadDone"
            className={classes.doneBtn}
            onClick={props.handleDone}
          >
            Done
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};
export default AadharImageUpload;
