import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { successfullyApprove } from "./assets";
import PrimaryButton from "../../../components/src/PrimaryButtonCommonComponent.web";
const configJSON = require("./config");

interface AccountCreationSuccessProps {
  handleSubmit: () => void;
}

const useStyles = makeStyles(theme => ({
  accCreationSuccessWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    alignItems: "center"
  },
  accCreationSuccessIcon: {
    width: "150px",
    margin: "10px auto"
  },
  accCreationSuccessButton: {
    display: "block",
    "& button": {
      width: "fit-content",
      textTransform: "initial !important",
      float: "right",
      marginRight: "87px",
      borderRadius: "12px",
      marginTop: "10px",
      color: "#fff",
      padding: "13px 66px",
      left: "125%",
      [theme.breakpoints.down(600)]: {
        left: "75%",
      },
      transform: "translate(-50%,0)",
      backgroundColor: "#0056A3",
      "& :hover": {
        color: "#fff",
        backgroundColor: "#0056A3"
      }
    },
    "& button:hover": {
      color: "#fff",
      backgroundColor: "#0056A3 !important"
    }
  },
  formHeaderLabel: {
    color: "#0056A3",
    fontSize: "40px",
    [theme.breakpoints.down(600)]: {
      fontSize: "29px",
      margin: "24px 24px"
    },
    fontWeight: 900,
    textAlign:'center',
    margin: "24px auto 0 auto"
  },
  subTitleLabel: {
    color: "#0056A3",
    textAlign:'center',
    fontSize: "16px",
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      margin: "2px 24px"
    }    
  }
}));

const AccountCreationSuccess = (props: AccountCreationSuccessProps) => {
  const webStyle = useStyles(props);

  return (
    <Box className={webStyle.accCreationSuccessWrapper}>
      <Typography className={webStyle.formHeaderLabel} variant="h5">
        {configJSON.AccountCreateSuccess.AccountCreatedSuccessfully}
      </Typography>
      <Typography variant="h6" className={webStyle.subTitleLabel}>
        {configJSON.AccountCreateSuccess.CongAccountCreatedSuccessfully}
      </Typography>
      <Typography variant="h6" className={webStyle.subTitleLabel}>
        {configJSON.AccountCreateSuccess.PleaseLogin}
      </Typography>
      <img
        className={webStyle.accCreationSuccessIcon}
        src={successfullyApprove}
        alt="Account Creation Successful"
      />
      <Box className={webStyle.accCreationSuccessButton}>
        <PrimaryButton
          className="done"
          onClick={props.handleSubmit}
          variant="contained"
          color="primary"
        >
          {configJSON.AccountCreateSuccess.Done}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default AccountCreationSuccess;
