import React from "react";
import { Grid, Card, CardContent,Container, Box, Typography } from "@material-ui/core";
const expertsImage = require("../assets");
const configJSON = require("../config");
import { makeStyles } from "@material-ui/core/styles";
import AboutUsPageController, { Props } from "./AboutUsPageController";

const { headerText, experts } = configJSON.AboutUsPageContent.expertsText;

const useStyles = makeStyles(theme => ({
  expertCardItems: {
    "&.MuiCard-root": {
      minHeight: "500px",
      maxWidth: "328px",
      borderRadius: "8px",
      paddingTop: "15px",
      border: "0.2px solid #e3e3e3"
    },
    "&.MuiPaper-elevation1": {
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
    }
  },
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600,
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px"
    }
  },
  nameHeading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#0056A3"
  },
  aboutContent: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  imageBox: {
    textAlign: "center"
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      padding: "30px 40px"
    }
  }
}));

const ExpertDetails = (props: any) => {
  const classes = useStyles();
  return (
    <Box sx={{ pt: "100px" }}>
      <Container>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3" className={classes.heading}>
            {headerText}
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          { props?.teamsData?.map((member: any, index: number) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Card className={classes.expertCardItems}>
                <Box className={classes.imageBox}>
                  <img src={member?.attributes?.photo_url} width={"100%"} />
                </Box>
                <CardContent className={classes.content}>
                  <Typography variant="h3" className={classes.nameHeading}>
                    {member?.attributes?.full_name}
                  </Typography>
                  <Typography variant="body1" className={classes.aboutContent}>
                    {member?.attributes?.role} {member?.attributes?.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      </Container>
    </Box>
  );
};


class Experts extends AboutUsPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <ExpertDetails teamsData={this.state.teamsData?.our_experts?.data} /> 
  }
}
export default Experts;
