import { IBlock } from "../../../../../framework/src/IBlock";

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../../framework/src/Message";
import { runEngine } from "../../../../../framework/src/RunEngine";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  // Customizable Area End
  isLoading: boolean;
  CoursesData: any;
  page: any;
  courseFilter: any;
  schoolFilter: any;
  sectionFilter: any;
  sectionList: any;
  schoolList: any;
  courseList: any;
  ParentGuideData: any;
  accordionState: boolean | string;
  sessionWiseMaterialPageData: any;
}
interface SS { }

export default class MaterialController extends BlockComponent<Props, S, SS> {

  materialAttachmentApiCallId: any;
  filterDropdownApiCallId: string = "";
  parentGuideApiCallId: string = "";
  sessionWiseMaterialApicallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      schoolFilter: "",
      sectionFilter: "",
      courseFilter: "",
      page: 1,
      isLoading: false,
      CoursesData: {},
      sectionList: [],
      schoolList: [],
      courseList: [],
      ParentGuideData: {},
      sessionWiseMaterialPageData: {},
      accordionState: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handlePage = (page: number) => {
    this.setState({ page: page });
  }

  handleClearSelect = (key: any) => {
    this.setState({ ...this.state, [key]: "" });
  }

  handleFilterChange = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value })
  }

  handleChangeAccordion = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ accordionState: isExpanded ? panel : false });
  };

  getParentGuideData() {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: localStorage.getItem("user_token")
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.parentGuideApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      `${configJSON.ParentGuideApiEndPoint}?page=${this.state.page}`,
      header
    );
  }

  getMaterialPageData(isParentsMaterial: boolean) {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: localStorage.getItem("user_token")
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.materialAttachmentApiCallId = apiRequest.messageId;
    const course = this.state.courseFilter;
    const school = this.state.schoolFilter;
    const section = this.state.sectionFilter;
    const page = this.state.page;
    const iscourseFilterStarted = course !== ""
    const isschoolFilterStarted = school !== ""
    const issectionFilterStarted = section !== ""
    if (issectionFilterStarted && !isschoolFilterStarted) {
      this.setState({ sectionFilter: "", sectionList: [] });
      return;
    }
    let endPoint = `${configJSON.Material.TrainingMaterialEndPoint}/assign_courses?page=${page}`;
    if (isParentsMaterial) {
      endPoint = `${configJSON.Material.ParentingMaterialEndPoint}/children_assign_courses?page=${page}`;
    }
    if (iscourseFilterStarted) {
      endPoint = `${endPoint}&course_id=${course}`;
      if (isschoolFilterStarted) {
        endPoint = `${endPoint}&school_id=${school}`
      }
      if (issectionFilterStarted) {
        endPoint = `${endPoint}&grade_id=${section}&school_id=${school}`
      }
    } else if (isschoolFilterStarted) {
      endPoint = `${endPoint}&school_id=${school}`
      if (issectionFilterStarted) {
        endPoint = `${endPoint}&grade_id=${section}`
      }
    }

    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      endPoint,
      header
    );
  }

  getSessionWiseMaterial = (courseId: number, isParentsMaterial: boolean) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: localStorage.getItem("user_token")
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sessionWiseMaterialApicallId = apiRequest.messageId;
    let endPoint = `${configJSON.Material.TrainingMaterialEndPoint}/course_sessions/${courseId}`;
    if (isParentsMaterial) {
      endPoint = `${configJSON.Material.ParentingMaterialEndPoint}/course_sessions/${courseId}`
    }
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  getFilterDropdownData(prevCourse: any, course: any, school: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: localStorage.getItem("user_token")
    };
    const iscourseFilterStarted = course !== prevCourse;
    const isschoolFilterStarted = school !== "";
    if (!iscourseFilterStarted && typeof course === 'number' && typeof school === 'string') {
      // don't call api if course is not change and school has string default value
      // this state will be true when we come back on the page to edit some dropdown data
      // upon hitting course change school state will change and componentupdate will hit this api
      return;
    }
    let endPoint = configJSON.Material.FilterResouresEndPoint
    if (iscourseFilterStarted) {
      endPoint = `${configJSON.Material.FilterResouresEndPoint}?mind_mastery_course_id=${course}`
      this.setState({ schoolFilter: "", sectionFilter: "", sectionList: [], schoolList: [] })
    } else if (isschoolFilterStarted) {
      endPoint = `${configJSON.Material.FilterResouresEndPoint}?mind_mastery_course_id=${course}&school_id=${school}`
      this.setState({ sectionFilter: "", sectionList: [] })
      if (course === "") {
        endPoint = `${configJSON.Material.FilterResouresEndPoint}?school_id=${school}`;
      }
    }
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.filterDropdownApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  goTo = (module: string, params: Object = {}) => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate(module, { ...params });
    }
  };

  setFilterList = (responseJson: any) => {
    const data = responseJson.data;
    const length = Object.keys(data).length;
    if (data?.length > 0 && data[0].type === 'school') {
      const schools = data.map((element: any) => {
        return { id: element.id, name: element?.attributes?.name }
      })
      let filteredSchools = [];

      if (this.state.schoolFilter) {
        filteredSchools = data.filter((element: any) => {
          return Number(element.id) === Number(this.state.schoolFilter);
        })
      }
      const grades = filteredSchools?.[0]?.attributes?.grades?.map((element: any) => {
        return {
          id: element.id,
          name: element.name
        }
      })
      this.setState({ schoolList: schools, sectionList: grades });
      return;
    }
    if (length === 3) {
      this.setState({ courseList: data.mind_mastery_courses, schoolList: data.schools })
    } else if (length === 2) {
      if (data.hasOwnProperty('schools')) {
        this.setState({ schoolList: data.schools })
      } else {
        this.setState({ sectionList: data.grades })
      }
    } else if (length === 1) {
      this.setState({ sectionList: data.grades })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      switch (apiRequestCallId) {
        case this.parentGuideApiCallId:
          this.setState({ ParentGuideData: responseJson });
          break;
        case this.filterDropdownApiCallId:
          this.setFilterList(responseJson);
          break;
        case this.materialAttachmentApiCallId:
          this.setState({ CoursesData: responseJson })
          break;
        case this.sessionWiseMaterialApicallId:
          this.setState({ sessionWiseMaterialPageData: responseJson });
          break;
      }
    }
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      this.setState({ isLoading: true });
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body,
      });
      if (response.status === 401) {
        localStorage.removeItem("userDetails");
      }
      let responseJson = await response.json();
      if (response.status === 404 || response.status === 500) {
        this.setState({ isLoading: false })
      }
      if (responseJson) {
        this.setState({ isLoading: false })
      }
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessage);
  }
}
