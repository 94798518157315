import React from 'react';
import {
    Box,
    Button,
    Typography,
    Dialog,
    makeStyles
} from "@material-ui/core";
import { deleteAlertIcon } from '../assets';
import commonStyles from '../commonStyles.web';

const configJSON = require("../config.js");

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-root": {
            padding: "24px",
            backgroundColor: "#F0F4F6",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "500px",
            width: "376px",
            height: "396px"
        },
        "& .MuiPaper-elevation24": {
            boxShadow: "none"
        }
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deleteImage: {
        height: 180,
        width: 150
    },
    warningText: {
        color: '#3B3B3B',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actionButton: {
        width: '48%',
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    },
    backdrop: {
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(8, 77, 149, 0.481206)'
        }
    },
});

export const ProgramDeleteModal = (props: any) => {
    const classes = useStyles();
    const commonClasses = commonStyles({});
    return (
        <Dialog className={classes.dialog} BackdropProps={{ className: classes.backdrop }} open={props.open} onClose={props.handleCancel}>
            <Box className={classes.imageContainer}>
                <img className={classes.deleteImage} src={deleteAlertIcon} />
            </Box>
            <Typography className={classes.warningText}>
                Are you sure you want to <br/> Delete this Event?
            </Typography>
            <Box className={classes.btnGroup}>
                <Button className={commonClasses.secondaryButton} onClick={props.handleCancel}  >{configJSON.ButtonTexts.Cancel}</Button>
                <Button className={commonClasses.primaryButton} onClick={props.handleDelete}  >{configJSON.ButtonTexts.Delete}</Button>
            </Box>
        </Dialog>
    );
}