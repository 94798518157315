import React from 'react'
import { configJSON } from './ProgramScheduler/ProgramSchedulerController.web';
import { Typography, Select, MenuItem, Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseRounded from "@material-ui/icons/CloseRounded";
import { darkArrowDown } from './assets';

type PlaceholderProps = { children: React.ReactNode };

const useStyles = makeStyles(() => ({
    dropDown: {
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "&.MuiOutlinedInput-root": {
            width: "100%",
            height: "56px",
            backgroundColor: 'white',
            borderRadius: '8px',
            marginLeft: 'auto',
            marginRight: '24px',
            color: 'black',
            fontSize: '14px',
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingLeft: '18px'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            paddingRight: '2px',
            width: 'calc(100% - 60px)',
            '&:hover': {
                width: '100%'
            }
        }
    },
    menuItems: {
        "&.MuiMenuItem-root": {
            display: "flex",
            width: "100%"
        },
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: '#0056a314'
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: '#0056a314'
        }
    },
    dropDownWrapper: {
        marginBottom: '40px'
    },
    fieldLabel: {
        color: '#3B3B3B',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px'
    },
    menu: {
        "& .MuiPopover-paper": {
            border: "1px solid #066CD2",
            boxShadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
            borderRadius: "8px"
        }
    },
    eventColor: {
        background: "#FFB74C",
        boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.159555)",
        borderRadius: "4px",
        width: "24px",
        height: "24px",
        marginTop: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    }
}))

const colorList = configJSON.ProgramColor;

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        opacity: 0.5907738208770752
    }
}));

const Placeholder: React.FC<PlaceholderProps> = (props) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{props.children}</div>;
};

const CommonSelect = (props: any) => {
    const classes = useStyles();
    
    const downArrow = () => {
        return <Box style={webStyles.cancelBox}>
            {props.canCancel && props.value ?
                <IconButton onClick={() => { props.onCancel() }}><CloseRounded /></IconButton>
                :
                <img className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' style={webStyles.arrowDownImage} src={darkArrowDown} alt="" />
            }
        </Box>;
    };
    return <>
        {props.label && <Typography className={classes.fieldLabel}>{props.label}</Typography>}
        <Select
            className={classes.dropDown}
            onChange={props.handleChange}
            margin="dense"
            variant="outlined"
            fullWidth
            IconComponent={downArrow}
            value={props.value}
            displayEmpty
            renderValue={
                props.value !== "" ? undefined : () => (<Placeholder><>{props.placeholder || ""}</></Placeholder>)
            }
            MenuProps={{ className: classes.menu }}
            data-test-id='filter-select'
            disabled={props.disabled}
        >
            {props?.menuList?.map((element: any) => {
                return (
                    <MenuItem key={element.id} className={classes.menuItems} value={element.value}>
                        {props.isColor ? (
                            <>
                                <Box className={classes.eventColor}
                                    style={{ backgroundColor: colorList[element.value].primary }}></Box>
                            </>
                        ) :
                            <>
                                <img style={webStyles.menuItemImg} src={element.img} alt="" />
                                <Typography>{element.title}</Typography>
                            </>}
                    </MenuItem>
                )
            })}
        </Select>
    </>
}

export default CommonSelect;

const webStyles = {
    arrowDownImage: {
        width: "16px",
        height: "9px",
        top: '25px',
        right: "27px"
    },
    cancelBox: {
        display: 'flex',
        flexDirection: 'row' as 'row'
    },
    menuItemImg: {
        marginRight: '8px'
    }
}