import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
const { kanikaImage } = require("../assets");
const configJSON = require("../config");
import { makeStyles } from "@material-ui/core/styles";
const aboutFounderText = configJSON.AboutUsPageContent.aboutFounder;
const { name, header, paragraphs } = aboutFounderText;

const useStyles = makeStyles(theme => ({
  changePara: {
    marginBottom: "8px"
  },
  paragraph: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  founderName: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "48px",
    color: "#052B62",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px"
    }
  },
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px"
    }
  },
  startPara: {
    padding: "15px 0"
  },
  contentBox: {
    marginLeft: "40px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0"
    }
  },
  imageBox: {
    transform: "translate(0px, 10px)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(-10px,5px)"
    }
  }
}));

const Founder = () => {
  const classes = useStyles();

  return (
    <Box sx={{ pt: "100px" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3" className={classes.heading}>
            {header}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className={classes.imageBox}>
            <img src={kanikaImage} width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className={classes.contentBox}>
            <Typography className={classes.founderName}>{name}</Typography>
            <Box className={classes.startPara}>
              {paragraphs.map((paragraph: string, index: number) => (
                <Box key={index} className={classes.changePara}>
                  <Typography variant="body1" className={classes.paragraph}>
                    {paragraph}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Founder;
