import React from "react";
import TrainerMpowerYouMatterController, { S } from "./TrainerMpowerYouMatterController.web";
import SidebarLayout from "../../SidebarLayout.web";
import PageContentLayout from "../../PageContentLayout.web";
import { Box, Button, Typography, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueCheckboxCheckedIcon, blueCheckboxIcon } from "../../assets";
import { useStyles as addMpowerYouMatterStyles } from "./AddMpowerYouMatter.web";
import Loader from "../../../../../components/src/Loader.web";

const configJSON = require("../../config.js");

const useStyles = makeStyles(() => ({
    questionCard: {
        display: 'flex',
        alignItems: 'center',

        borderRadius: '8px',
        background: '#FFF',
        border: '0.2px solid #e3e3e3',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        marginBottom: '16px',
    },
    guidelineNumber: {
        minHeight: '56px',
        minWidth: '56px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontFamily: "Poppins",
        fontSize: '20px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px'
    },
    questionText: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        marginRight: '10px',
        '&:hover': {
            '-webkitBoxOrient': 'inherit',
            wordBreak: 'break-word',
        }
    },
    questionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: '15px'
    },
    mpowerTitle: {
        color: '#0056A3',
        fontSize: '24px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        marginBottom: '28px'
    },
    pageWrapper: {
        padding: '32px 24px'
    },
    guidelinesWrapper: {
        marginBottom: '40px'
    }
}))

const renderIcon = (name: string) => {
    if (name === configJSON.TrainerMPowerYouMatter.CheckboxChecked) {
        return <img src={blueCheckboxCheckedIcon} alt="checked" />
    }
    else {
        return <img src={blueCheckboxIcon} alt="unchecked" />
    }
}

interface TrainerMpowerGuideLinesPageProps {
    states: S;
    goTo: (module: string, params: Object) => void;
    handleGuidelineClick: (event: any) => void;
    handleNextClick: (context: any) => void;
    context: any;
}

interface GuideLinesType {
    id: any;
    type: string;
    attributes: Attributes;
}

interface Attributes {
    description: string
}

export const TrainerMpowerGuideLinesPage = (props: TrainerMpowerGuideLinesPageProps) => {
    const classes = useStyles();
    const commonClasses = addMpowerYouMatterStyles()
    return <>
        <Box className={classes.pageWrapper} data-test-id='box'>
            <Typography className={classes.mpowerTitle}>{configJSON.TrainerMPowerYouMatter.MPowerGuidelines}</Typography>
            <Box className={classes.guidelinesWrapper}>
                {
                    props.states.guideLines?.map((element: GuideLinesType, index: number) => {
                        return <Box key={element.id} className={classes.questionCard}>
                            <Typography className={classes.guidelineNumber}>{index + 1}</Typography>
                            <Box className={classes.questionWrapper}>
                                <Typography className={classes.questionText}>{element.attributes.description}</Typography>
                                <Checkbox color="primary" data-test-id='check' value={element.id} onClick={props.handleGuidelineClick} checkedIcon={renderIcon(configJSON.TrainerMPowerYouMatter.CheckboxChecked)} icon={renderIcon(configJSON.TrainerMPowerYouMatter.CheckBox)} />
                            </Box>
                        </Box>
                    })
                }
            </Box>
            <Button className={commonClasses.nextBtn} disabled={props.states.selectedGuidelines.length === 0} data-test-id='next' onClick={() => props.handleNextClick(props.context)}>{configJSON.ButtonTexts.Next}</Button>
        </Box>
    </>
}

export default class TrainerMpowerGuideLines extends TrainerMpowerYouMatterController {
    async componentDidMount() {
        this.getGuideLines();
    }
    render() {
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout goBack={this.goBack} title={configJSON.TrainerMPowerYouMatter.StudentMPower}>
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <TrainerMpowerGuideLinesPage
                        data-test-id='guideLines'
                        goTo={this.goTo}
                        handleGuidelineClick={this.handleGuidelineClick}
                        states={this.state}
                        handleNextClick={this.handleGuidelinesNextClick}
                        context={this.context}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
}