import React from "react";
import {
    Box,
    Button,
    Typography,
    Dialog,
    makeStyles
} from "@material-ui/core";
import { profile } from "./assets";
import { configJSON } from "./DashboardController";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiPaper-root": {
            position: 'absolute',
            right: 10,
            top: 50,
            borderRadius: '12px',
            padding: "24px",
            background: 'linear-gradient(-135.14deg, #B3DBFF 0%, #4B90CD 41.16%, #0156A2 100%)',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        "& .MuiDialog-paperWidthSm": {
            width: "320px",
        },
        "& .MuiPaper-elevation24": {
            boxShadow: "none"
        }
    },
    imgBox: {
        backgroundColor: '#FFFFFF',
        height: 64,
        width: 64,
        borderRadius: '50%',
        alignSelf: 'center'
    },
    emailLabel: {
        alignSelf: 'center',
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#FFFFFF"
    },
    email: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        marginTop:"10px",
        lineHeight: "18px",
        color: "#FFFFFF"
    },
    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent:"center",
        alignItems:"center",
        marginTop: 20,
        marginBottom: 20
    },
    btnGrp: {
        display: 'flex',
        alignSelf: "center",
        gap: 20
    },
    ctaBtn: {
        background: "rgba(255, 255, 255, 0.24)",
        mixBlendMode: "normal",
        borderRadius: 8,
        textTransform: 'none',
        color: '#FFFFFF'
    },
    guestProfilePhoto: {
        width: "64px",
        height: "64px",
        borderRadius: "50%",
        border: "1px solid #0056A3",
        filter: "drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.246121))",
    }
}));

export const ProfileModal = (props: any) => {
    const classes = useStyles();
    const user = JSON.parse(`${localStorage.getItem(configJSON.Dashboard.UserDetails)}`)
    const profilePhoto = user?.attributes?.image_url ? user?.attributes?.image_url : profile;
    return (
        <Dialog
            className={classes.dialog}
            open={props.showProfileModal}
            onClose={props.onClose}
            hideBackdrop
        >
            <Box className={classes.imgBox}>
                <img className={classes.guestProfilePhoto} src={profilePhoto} />
            </Box>
            <Box className={classes.contentBox}>
                <Typography className={classes.emailLabel}>{user?.attributes?.first_name} {user?.attributes?.last_name} <span className={classes.email}>[{user?.attributes?.role}]</span></Typography>
                <Typography className={classes.email}>Email ID: {user?.attributes?.email}</Typography>
            </Box>
            <Box className={classes.btnGrp}>
                <Button className={classes.ctaBtn} onClick={props.openProfilePage}>View Profile</Button>
                <Button className={classes.ctaBtn} onClick={props.onLogOut}>Logout</Button>
            </Box>
        </Dialog>
    )
}