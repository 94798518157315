import React from "react";
import { Box, Container, Grid, Hidden, Typography } from "@material-ui/core";
const Image = require("../../assets");
import { makeStyles } from "@material-ui/core/styles";

interface Feature {
  heading: string;
  content: string;
  image: string;
}
interface Props {
  feature: Feature;
  index:any;
}

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "40px",
    fontWeight: 600,
    color: "#052B62"
  },
  content: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  imageBox: {
    transform: "translate(-20px,0px)",
    [theme.breakpoints.down("xs")]: {
      transform: "none",
      paddingTop: "30px"
    },
    [theme.breakpoints.down("md")]: {
      transform: "none",
      paddingTop: "30px"
    }
  },
  [theme.breakpoints.down("xs")]: {firstLeftRIghtLayoutChild: {
    paddingTop: "30px"
  }
  }
}));

const LeftRightLayout = (props: Props) => {
  const classes = useStyles();
  const { feature } = props;
  console.log(props.index, "sjhdkfkjsdhfkjsdhafkjhsdkjfhsdjkhf")
  return (
   
      <Grid container>
        <Box sx={{ pt: "100px" }} className={props.index === 0? classes.firstLeftRIghtLayoutChild : ""}>
          <Grid container>
            <Hidden xsDown smDown>
              <Grid item xs={12} md={5}>
                <Box className={classes.imageBox}>
                  <img src={Image[`${feature.image}`]} width="100%" />
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={7}>
              <Box>
                <Typography className={classes.heading}>
                  {feature.heading}
                </Typography>
                <Typography variant="body1" className={classes.content}>
                  {feature.content}
                </Typography>
              </Box>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={12} md={5}>
                <Box className={classes.imageBox}>
                  <img src={Image[`${feature.image}`]} width="100%" />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Grid>
   
  );
};

export default LeftRightLayout;
