import React from "react";
import DateCalendar from "react-calendar";
import { calendarArrowRight } from "../assets";

export const CustomPrevIcon = () => (
  <img
    style={{ transform: "scaleX(-1)" }}
    src={calendarArrowRight}
    alt="Prev Icon"
  />
);
export const CustomNextIcon = () => (
  <img src={calendarArrowRight} alt="Next Icon" />
);
const dayFormat = ["S", "M", "T", "W", "T", "F", "S"];

export const CalendarCard = () => {
  return (
    <DateCalendar
      prevLabel={<CustomPrevIcon />}
      nextLabel={<CustomNextIcon />}
      formatShortWeekday={(_locale: any, date: Date) =>
        dayFormat[date.getDay()]
      }
      value={new Date()}
      onChange={() => {}}
    />
  );
};
