import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");
import { participationIcon, focusIcon, collaborationIcon, respectIcon, initiativeIcon, positiveAttitudeIcon, criticalThinkingIcon, perseveranceIcon, creativityIcon, empathyIcon } from "../../assets";


// Customizable Area Start

const ratings = [
  { name: configJSON.TimelyProgress.Participation, rating: "1", icon: participationIcon, key: configJSON.TimelyProgress.ParticipationKey },
  { name: configJSON.TimelyProgress.Focus, rating: "1", icon: focusIcon, key: configJSON.TimelyProgress.FocusKey },
  { name: configJSON.TimelyProgress.Collaboration, rating: "1", icon: collaborationIcon, key: configJSON.TimelyProgress.FocusKey },
  { name: configJSON.TimelyProgress.Respect, rating: "1", icon: respectIcon, key: configJSON.TimelyProgress.RespectKey },
  { name: configJSON.TimelyProgress.Initiative, rating: "1", icon: initiativeIcon, key: configJSON.TimelyProgress.InitiativeKey },
  { name: configJSON.TimelyProgress.PositiveAttitude, rating: "1", icon: positiveAttitudeIcon, key: configJSON.TimelyProgress.PositiveAttitudeKey },
  { name: configJSON.TimelyProgress.CriticalThinking, rating: "1", icon: criticalThinkingIcon, key: configJSON.TimelyProgress.CriticalThinkingKey },
  { name: configJSON.TimelyProgress.Perseverance, rating: "1", icon: perseveranceIcon, key: configJSON.TimelyProgress.PerseveranceKey },
  { name: configJSON.TimelyProgress.Creativity, rating: "1", icon: creativityIcon, key: configJSON.TimelyProgress.CreativityKey },
  { name: configJSON.TimelyProgress.Empathy, rating: "1", icon: empathyIcon, key: configJSON.TimelyProgress.EmpathyKey },
]
const commonStateTpr = {
  selectedGradesAttendanceFirst: [{ id: 1, value: "", attendance: "" }],
  selectedGradesAttendanceSecond: [{ id: 1, value: "", attendance: "" }],
  academic_year: "",
  programTrack: "",
  module_names: [{ id: 1, module: "" }, { id: 2, module: "" }],
  studentsFeedback: [{ id: 1, message: "" }, { id: 2, message: "" }, { id: 3, message: "" }],
  parentsFeedback: [{ id: 1, message: "" }, { id: 2, message: "" }, { id: 3, message: "" }],
  educatorsFeedback: [{ id: 1, message: "" }, { id: 2, message: "" }, { id: 3, message: "" }],
  recommendations: "",
  acknowledgement: "",
  ratings: [...ratings],
  selectedSchool: { name: "", id: 0 },
  selectedCourse: { name: "", id: 0, overview: "" },
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentPage: string;
  pageNo: any;
  reportData: any;
  user_token: any;
  schoolCourseData: any;
  schoolList: any;
  courseList: any;
  gradeList: any;
  selectedGradesAttendanceFirst: any;
  selectedGradesAttendanceSecond: any;
  selectedSchool: any;
  selectedCourse: any;
  academic_year: string;
  programTrack: string;
  module_names: any;
  studentsFeedback: any;
  parentsFeedback: any;
  educatorsFeedback: any;
  recommendations: string;
  acknowledgement: string;
  ratings: any;
  reportSubmittedModal: boolean;
  deleteModal: boolean;
  snackBarIsOpen: boolean;
  snackBarMsgTpr: any;
  deleteReportId: string;
  editReportId: string;
  validationErrors: any;
  editMode: boolean;
  // Customizable Area End
}
interface SS { id: any }

export default class TimelyProgressReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCourseListApiCallId: any;
  PostSubmitFormapiCallId: any;
  GetReportDataApiCallId: any;
  DeleteReportApiCallId: any;
  EditReportApiCallId: any;
  GetGradeListApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      ...commonStateTpr,
      currentPage: "TprList",
      pageNo: 1,
      reportData: {},
      user_token: "",
      schoolCourseData: {},
      schoolList: [],
      courseList: [],
      gradeList: [],
      reportSubmittedModal: false,
      deleteModal: false,
      snackBarIsOpen: false,
      snackBarMsgTpr: "",
      deleteReportId: "",
      editReportId: "",
      validationErrors: {
        selectedCourse: false,
        selectedSchool: false,
        academicYear: false,
        programTrack: false,
        moduleNames: false,
        recommendations: false,
        acknowledgement: false,
        attendanceFirst: false,
        attendanceSecond: false,
        studentsFeedback: false,
        parentsFeedback: false,
        educatorsFeedback: false,
      },
      editMode: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const user_token = await localStorage.getItem("user_token") ?? "";
    this.setState({
      user_token: user_token
    });

    this.getCourseListTpr();
    this.getReportDataTpr();

  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorMsg) {

        this.setState({ snackBarIsOpen: true, snackBarMsgTpr: "Error: Unable to create report." });
      }
      if (responseJson && !responseJson.errors) {
        this.ApiCallSuuccess(responseJson, apiRequestCallId);

      } else if (responseJson && responseJson.errors) {
        console.log(responseJson, "Error");
        this.setState({ snackBarIsOpen: true, snackBarMsgTpr: responseJson.errors[0] });
      }
    }

  }

  ApiCallSuuccess(responseJson: any, apiRequestCallId: any) {
    if (apiRequestCallId === this.GetCourseListApiCallId) {
      const schoolDataTpr = responseJson.schools.data.map((schoolObjTpr: any) => {
        return { title: schoolObjTpr.attributes.name, value: schoolObjTpr.attributes.name, id: schoolObjTpr.attributes.id }
      })
      const courseDataTpr = responseJson.mind_mastery_courses.data.map((courseObjTpr: any) => {
        return { title: courseObjTpr.attributes.title, value: courseObjTpr.attributes.title, id: courseObjTpr.attributes.id }
      })

      this.setState({
        schoolCourseData: responseJson,
        schoolList: [...schoolDataTpr],
        courseList: [...courseDataTpr]
      })

    }
    if (apiRequestCallId === this.GetReportDataApiCallId) {

      this.setState({ reportData: responseJson });
    }

    if (apiRequestCallId === this.PostSubmitFormapiCallId) {
      this.setState({ reportSubmittedModal: true, editMode: false, editReportId: "" });
    }
    if (apiRequestCallId === this.DeleteReportApiCallId) {
      this.setState({ deleteModal: false });
      this.getReportDataTpr();
    }

    if (apiRequestCallId === this.GetGradeListApiCallId) {
      let newGradeList = responseJson.sections?.length && responseJson.sections.map((grade: string) => {
        return { title: grade, value: grade }
      })
      this.setState({ gradeList: newGradeList })
    }

    if (apiRequestCallId === this.EditReportApiCallId) {


      let attendanceFirst = Object.entries(responseJson.report.attributes.attendance_percent).map(([key, value], index) => {
        return { id: index + 1, value: key, attendance: value };
      });
      let attendanceSecond = Object.entries(responseJson.report.attributes.attendance_number).map(([key, value], index) => {
        return { id: index + 1, value: key, attendance: value };
      });

      const school = this.state.schoolCourseData.schools.data.filter((el: any) => {
        return el.attributes.id === responseJson.report.attributes.school_id
      })

      const course = this.state.schoolCourseData.mind_mastery_courses.data.filter((course: any) => {
        return course.attributes.id === responseJson.report.attributes.mind_mastery_course_id;
      })

      const moduleNmaes = responseJson.report.attributes.module_names.map((el: any, index: number) => {
        return { id: index + 1, module: el }
      })

      const studentFeedback = responseJson.report.attributes["360_feedback"].students.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      const parentsFeedback = responseJson.report.attributes["360_feedback"].parents.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      const educatorsFeedback = responseJson.report.attributes["360_feedback"].educators.map((el: any, index: number) => {
        return { id: index + 1, message: el }
      })

      const updatedRatings = ratings.map((ratingItem) => ({
        ...ratingItem,
        rating: responseJson.report.attributes.behaviour[ratingItem.key] || ratingItem.rating
      }));

      this.handleInputChange({ target: { value: course[0]?.attributes.title } }, "course");
      this.handleInputChange({ target: { value: school[0]?.attributes.name } }, "school");
      this.setState({
        selectedGradesAttendanceFirst: [...attendanceFirst],
        selectedGradesAttendanceSecond: [...attendanceSecond],
        academic_year: responseJson.report.attributes.academic_year,
        programTrack: responseJson.report.attributes.program_track,
        module_names: moduleNmaes,
        studentsFeedback: studentFeedback,
        parentsFeedback: parentsFeedback,
        educatorsFeedback: educatorsFeedback,
        recommendations: responseJson.report.attributes.recommendations_remarks,
        acknowledgement: responseJson.report.attributes.acknowledgement,
        ratings: updatedRatings,
      })
    }
  }

  goBack = () => {
    if (this.state.currentPage === "TprList") {
      this.props.navigation.goBack();
    } else {
      this.setState({ currentPage: "TprList", editMode: false });
      this.handleClearState();
    }
  };

  handleCreateTprClick = () => {
    this.setState({ currentPage: "form" })
  }

  handleInputChange(event: any, targetTpr: string) {
    switch (targetTpr) {
      case configJSON.TimelyProgress.Course:
        const idOverviewTpr = this.state.schoolCourseData.mind_mastery_courses.data.filter((course: any) => {
          return course.attributes.title === event.target.value;
        })
        this.setState({
          selectedCourse: { name: event.target.value, id: idOverviewTpr[0].id, overview: idOverviewTpr[0].attributes.overview },
          validationErrors: { ...this.state.validationErrors, selectedCourse: false }
        });
        break;

      case configJSON.TimelyProgress.School:
        const school = this.state.schoolCourseData.schools.data.filter((schoolTpr: any) => {
          return schoolTpr.attributes.name === event.target.value;
        })
        this.setState({
          selectedSchool: { name: event.target.value, id: school[0]?.id },
          validationErrors: { ...this.state.validationErrors, selectedSchool: false }
        }, () => this.getGradeList()
        );
        break;

      case configJSON.TimelyProgress.Year:
        this.setState({ academic_year: event.target.value, validationErrors: { ...this.state.validationErrors, academicYear: false } });
        break;

      case configJSON.TimelyProgress.ProgramTrackKey:
        this.setState({ programTrack: event.target.value, validationErrors: { ...this.state.validationErrors, programTrack: false } });
        break;

      case configJSON.TimelyProgress.Recommendations:
        this.setState({ recommendations: event.target.value, validationErrors: { ...this.state.validationErrors, recommendations: false } });
        break;
      case configJSON.TimelyProgress.Acknowledgement:
        this.setState({ acknowledgement: event.target.value, validationErrors: { ...this.state.validationErrors, acknowledgement: false } });
        break;
    }
  }

  handleAttendanceFirstChange(event: any, id: any, attendenceType: any) {
    let attendanceToUpdate: any;
    let updatedArray: any;
    switch (attendenceType) {
      case configJSON.TimelyProgress.AttendanceFirstDropDown:
        attendanceToUpdate = this.state.selectedGradesAttendanceFirst.find((module: any) => module.id === id);
        attendanceToUpdate.value = event.target.value;
        updatedArray = this.state.selectedGradesAttendanceFirst.map((module: any) => (module.id === id ? attendanceToUpdate : module));
        this.setState({ selectedGradesAttendanceFirst: [...updatedArray], validationErrors: { ...this.state.validationErrors, storisOfImpact: false } });
        break;
      case configJSON.TimelyProgress.AttendanceFirstInput:
        attendanceToUpdate = this.state.selectedGradesAttendanceFirst.find((module: any) => module.id === id);
        const regex = /^[0-9.]+$/;
        const isMatch = regex.test(event.target.value);
        attendanceToUpdate.attendance = isMatch ? event.target.value : event.target.value.slice(0, -1);
        updatedArray = this.state.selectedGradesAttendanceFirst.map((module: any) => (module.id === id ? attendanceToUpdate : module));
        this.setState({ selectedGradesAttendanceFirst: [...updatedArray], validationErrors: { ...this.state.validationErrors, attendanceFirst: false } });
        break;
    }

  }

  handleAttendanceSecondChange(event: any, id: any, attendenceType: any) {
    let attendanceToUpdate: any;
    let updatedArray: any;
    switch (attendenceType) {
      case configJSON.TimelyProgress.AttendanceSecondDropDown:
        attendanceToUpdate = this.state.selectedGradesAttendanceSecond.find((module: any) => module.id === id);
        attendanceToUpdate.value = event.target.value;
        updatedArray = this.state.selectedGradesAttendanceSecond.map((module: any) => (module.id === id ? attendanceToUpdate : module));
        this.setState({ selectedGradesAttendanceSecond: [...updatedArray], validationErrors: { ...this.state.validationErrors, storisOfImpact: false } });
        break;
      case configJSON.TimelyProgress.AttendanceSecondInput:
        attendanceToUpdate = this.state.selectedGradesAttendanceSecond.find((module: any) => module.id === id);
        const regex2 = /^[0-9.]+$/;
        const isMatch2 = regex2.test(event.target.value);
        attendanceToUpdate.attendance = isMatch2 ? event.target.value : event.target.value.slice(0, -1);
        updatedArray = this.state.selectedGradesAttendanceSecond.map((module: any) => (module.id === id ? attendanceToUpdate : module));
        this.setState({ selectedGradesAttendanceSecond: [...updatedArray], validationErrors: { ...this.state.validationErrors, attendanceSecond: false } });
        break;
    }
  }

  handleModuleChange(event: any, id: any) {
    const moduleToUpdate = this.state.module_names.find((module: any) => module.id === id);
    moduleToUpdate.module = event.target.value;
    const updatedArray = this.state.module_names.map((module: any) => (module.id === id ? moduleToUpdate : module));
    this.setState({ module_names: [...updatedArray], validationErrors: { ...this.state.validationErrors, moduleNames: false } });
  }

  handleEducatorMessageChange(event: any, id: any, field: string) {
    let messageToUpdate: any;
    let updatedArray: any;
    switch (field) {
      case configJSON.TimelyProgress.Students:
        messageToUpdate = this.state.studentsFeedback.find((message: any) => message.id === id);
        messageToUpdate.message = event.target.value;
        updatedArray = this.state.studentsFeedback.map((message: any) => (message.id === id ? messageToUpdate : message));
        this.setState({ studentsFeedback: [...updatedArray], validationErrors: { ...this.state.validationErrors, studentsFeedback: false } });
        break;
      case configJSON.TimelyProgress.Parents:
        messageToUpdate = this.state.parentsFeedback.find((message: any) => message.id === id);
        messageToUpdate.message = event.target.value;
        updatedArray = this.state.parentsFeedback.map((message: any) => (message.id === id ? messageToUpdate : message));
        this.setState({ parentsFeedback: [...updatedArray], validationErrors: { ...this.state.validationErrors, parentsFeedback: false } });
        break;
      case configJSON.TimelyProgress.Educators:
        messageToUpdate = this.state.educatorsFeedback.find((message: any) => message.id === id);
        messageToUpdate.message = event.target.value;
        updatedArray = this.state.educatorsFeedback.map((message: any) => (message.id === id ? messageToUpdate : message));
        this.setState({ educatorsFeedback: [...updatedArray], validationErrors: { ...this.state.validationErrors, educatorsFeedback: false } });
        break;
    }
  }

  handleAddNewStory() {
    if (this.state.module_names.length < 6) {
      let newId = this.state.module_names.length + 1;
      this.setState({ module_names: [...this.state.module_names, { id: newId, module: "" }] })
    }
  }

  handleAddNewAttendance(name: string) {
    switch (name) {
      case configJSON.TimelyProgress.AttendanceFirst:
        if (this.state.selectedGradesAttendanceFirst.length < 6) {
          let newId = this.state.selectedGradesAttendanceFirst.length + 1;
          this.setState({ selectedGradesAttendanceFirst: [...this.state.selectedGradesAttendanceFirst, { id: newId, value: "", attendance: "" }] })
        }
        break;
      case configJSON.TimelyProgress.AttendanceSecond:
        if (this.state.selectedGradesAttendanceFirst.length < 6) {
          let newId = this.state.selectedGradesAttendanceSecond.length + 1;
          this.setState({ selectedGradesAttendanceSecond: [...this.state.selectedGradesAttendanceSecond, { id: newId, value: "", attendance: "" }] })
        }
        break;
    }
  }

  handleCreateClick() {
    let module_names = this.state.module_names.map((module: any) => {
      return module.module.trim();
    }).filter((module: any) => module !== "")

    let attendance_percent = this.state.selectedGradesAttendanceFirst.reduce((acc: any, item: any) => {
      if (item.attendance.trim() !== "") {
        acc[item.value] = item.attendance;
      }
      return acc;
    }, {});
    let attendance_number = this.state.selectedGradesAttendanceSecond.reduce((acc: any, item: any) => {
      if (item.attendance.trim() !== "") {
        acc[item.value] = item.attendance;
      }
      return acc;
    }, {});

    let behaviour = this.state.ratings.reduce((acc: any, item: any) => {
      acc[item.key] = item.rating;
      return acc;
    }, {});

    let studentsFeedback = this.state.studentsFeedback.map((module: any) => {
      return module.message;
    })
    let parentsFeedback = this.state.parentsFeedback.map((module: any) => {
      return module.message;
    })
    let educatorsFeedback = this.state.educatorsFeedback.map((module: any) => {
      return module.message;
    })

    let body = {
      program_track: this.state.programTrack,
      school_id: parseInt(this.state.selectedSchool.id),
      mind_mastery_course_id: parseInt(this.state.selectedCourse.id),
      academic_year: this.state.academic_year,
      overview: this.state.selectedCourse.overview,
      attendance_percent: attendance_percent,
      attendance_number: attendance_number,
      behaviour: behaviour,
      module_names: [...module_names],
      "360_feedback": {
        students: studentsFeedback,
        parents: parentsFeedback,
        educators: educatorsFeedback
      },
      recommendations_remarks: this.state.recommendations,
      acknowledgement: this.state.acknowledgement,
    }

    if (this.validateForm()) {
      const header = {
        "Content-Type": configJSON.dashboardContentType,
        Authorization: this.state.user_token
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.PostSubmitFormapiCallId = apiRequest.messageId;
      this.makeApiCallListTpr(
        apiRequest.messageId,
        this.state.editMode ? configJSON.updateMethod : configJSON.dashboardPostApiMethod,
        this.state.editMode ? configJSON.timelyProgressReportEndPoint + `/${this.state.editReportId}` : configJSON.timelyProgressReportEndPoint,
        header,
        JSON.stringify(body)
      );
    }
  }

  validateForm() {
    let updateErrorStatus = {
      selectedCourse: false,
      selectedSchool: false,
      academicYear: false,
      programTrack: false,
      moduleNames: false,
      recommendations: false,
      acknowledgement: false,
      attendanceFirst: false,
      attendanceSecond: false,
      studentsFeedback: false,
      parentsFeedback: false,
      educatorsFeedback: false,
    }
    if (this.state.selectedCourse.name === "") {
      updateErrorStatus.selectedCourse = true
    }
    if (this.state.selectedSchool.name === "") {
      updateErrorStatus.selectedSchool = true
    }
    const regex = /^\d{4}-\d{4}$/;
    const isMatch = regex.test(this.state.academic_year.trim());
    if (!isMatch) {
      updateErrorStatus.academicYear = true
    }
    if (this.state.programTrack.trim() === "") {
      updateErrorStatus.programTrack = true
    }
    const hasOneStory = this.state.module_names.filter((module: any) => module.module.trim() !== "");
    if (hasOneStory.length < 1) {
      updateErrorStatus.moduleNames = true
    }
    if (this.state.recommendations.trim() === "") {
      updateErrorStatus.recommendations = true
    }
    if (this.state.acknowledgement.trim() === "") {
      updateErrorStatus.acknowledgement = true
    }

    const hasNoneAttendanceFirst = this.state.selectedGradesAttendanceFirst.filter((el: any) => el.attendance.trim() !== "");
    if (hasNoneAttendanceFirst.length < 1) {
      updateErrorStatus.attendanceFirst = true
    }

    const hasNoneAttendanceSecond = this.state.selectedGradesAttendanceSecond.filter((el: any) => el.attendance.trim() !== "");
    if (hasNoneAttendanceSecond.length < 1) {
      updateErrorStatus.attendanceFirst = true
    }

    const hasOneEmptyParentsFeedback = this.state.parentsFeedback.filter((el: any) => el.message.trim() !== "");
    if (hasOneEmptyParentsFeedback.length < 3) {
      updateErrorStatus.parentsFeedback = true
    }

    const hasOneEmptyTrainerFeedback = this.state.educatorsFeedback.filter((el: any) => el.message.trim() !== "");
    if (hasOneEmptyTrainerFeedback.length < 3) {
      updateErrorStatus.educatorsFeedback = true
    }

    const hasOneEmptyStudentFeedback = this.state.studentsFeedback.filter((el: any) => el.message.trim() !== "");
    if (hasOneEmptyStudentFeedback.length < 3) {
      updateErrorStatus.studentsFeedback = true
    }

    this.setState({ validationErrors: { ...updateErrorStatus } });
    const valuesArray = Object.values(updateErrorStatus);
    const hasValidationError = valuesArray.filter((el: any) => {
      return el === true
    })
    if (hasValidationError.length < 1) {
      return true
    } else return false;
  }

  closeSuccessModal = () => {
    this.getReportDataTpr();
    this.setState({ reportSubmittedModal: false, currentPage: "TprList" });
    this.handleClearState();
  }
  closeDeleteModal = () => {
    this.setState({ deleteModal: false })
  }

  onSnackbarClose() {
    this.setState({ snackBarIsOpen: false, snackBarMsgTpr: "" })
  }

  handleTprDeleteIconClick(id: any) {
    this.setState({ deleteModal: true, deleteReportId: id })
  }

  handleClearState() {
    this.setState({
      ...commonStateTpr
    })
  }

  handleTprEditIconClick(id: any) {
    this.setState({ currentPage: "form", editMode: true, editReportId: id });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.EditReportApiCallId = apiRequest.messageId;
    this.makeApiCallListTpr(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.timelyProgressReportEndPoint + `/${id}/edit`,
      header,
    );
  }

  handleDeleteCall() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.DeleteReportApiCallId = apiRequest.messageId;
    this.makeApiCallListTpr(
      apiRequest.messageId,
      configJSON.deleteMethod,
      configJSON.timelyProgressReportEndPoint + `/${this.state.deleteReportId}`,
      header,
    );
  }

  handleTprPageChange(page: any) {
    this.setState({ pageNo: page }, () => this.getReportDataTpr())
  }

  handleSkil1RatingChange = (value: any, skillName: string) => {
    let newRatings;
    newRatings = this.state.ratings.map((item: any) => {
      if (item?.name === skillName) {
        return { ...item, rating: value };
      }
      return item;
    });
    this.setState({ ratings: [...newRatings] });
  }

  getCourseListTpr() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequestTpr = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetCourseListApiCallId = apiRequestTpr.messageId;
    this.makeApiCallListTpr(
      apiRequestTpr.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getCourseListApiEndPoint,
      header,
    );
  }

  getGradeList() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const params = this.state.selectedSchool.id !== 0 ? { school_id: this.state.selectedSchool.id, course_id: this.state.selectedCourse.id } : undefined;
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetGradeListApiCallId = apiRequest.messageId;
    this.makeApiCallListTpr(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getGradeListEndPoint,
      header,
      undefined,
      params
    );
  }

  getReportDataTpr() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequestTpr = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetReportDataApiCallId = apiRequestTpr.messageId;
    this.makeApiCallListTpr(
      apiRequestTpr.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.timelyProgressReportEndPoint,
      header,
      undefined,
      { page: this.state.pageNo }
    );
  }

  async makeApiCallListTpr(
    uniqueApiCallIdTpr: string,
    methodTpr: string,
    endpointTpr: string,
    headersTpr: any,
    bodyTpr?: any,
    paramsTpr?: any,
  ) {
    let fullURLTpr =
      endpointTpr.indexOf("://") === -1
        ? config.baseURL + "/" + endpointTpr
        : endpointTpr;

    let apiResponseMessageTpr = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessageTpr.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallIdTpr
    );
    try {
      const actualUrl = paramsTpr ? fullURLTpr + "?" + new URLSearchParams({ ...paramsTpr }).toString() : fullURLTpr;
      let response = await fetch(actualUrl, {
        method: methodTpr.toUpperCase(),
        headers: headersTpr,
        body: bodyTpr
      });
      if (response.status === 401) {
        console.log("401 Response", response);
      }
      let responseJsonTpr = await response.json();
      //setting Response
      apiResponseMessageTpr.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonTpr
      );

      apiResponseMessageTpr.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonTpr
      );
    } catch (errorTpr) {
      runEngine.debugLog("RestApiClient Error", errorTpr);
      //setting Error
      console.log("Api Error" + JSON.stringify(errorTpr));
      apiResponseMessageTpr.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessageTpr);
  }


  // Customizable Area End
}
