import React from "react";
import { Box, Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const configJSON = require("../config");
const { partnershipBannerImage, contentLeftBg } = require("../assets");
import Welcome from "./Welcome.web";
import PartnerForm from "./PartnerForm.web";
import Banner from "../../../../components/src/Banner";
import FlotingButton from "../../../../components/src/FlotingButton.web";
import Footer from "../../../../components/src/Footer.web";
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}
const bannerText = configJSON.PartnershipPageContent.bannerText;
const content: Content = {
  headerText: bannerText.heading,
  contentText: bannerText.content,
  fontSize: "48px",
  color: "#FFFFFF",
  secondColor: "#FFFFFF",
  paragraphFontSize: "1rem"
};
const useStyles = makeStyles(theme => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  welcomeBg: {
    backgroundImage: `url(${contentLeftBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -280px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  },
  welcomeContainer:{
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  }
}));
const PartnershipPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.relativeParent}>
        <Banner
          content={content}
          bannerImage={partnershipBannerImage}
          showBtn={false}
        />
        <FlotingButton ourPartner={false} />
        <Box sx={{ py: "100px" }} className={classes.welcomeBg} id="">
          <Container className={classes.containerStyle} >
            <Grid container>
              <Grid className={classes.welcomeContainer} item xs={12} md={6}>
                <Welcome />
              </Grid>
              <Grid id="specific-section" item xs={12} md={6} >
                <PartnerForm navigation={undefined} id={""} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box >
        <Footer />
      </Box>
    </>
  );
};
export default PartnershipPage;
