/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatAvatar, CometChatUserPresence, CometChatBadgeCount } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
	listItem,
	itemThumbnailStyle,
	itemDetailStyle,
	itemNameStyle,
	itemDescStyle,
	userNotify,
} from "./style";
import { useContext } from "react";

const CometChatUserListItem = (props) => {
	const [users, setUsers] = useState('')

	useEffect(() => {
		const getUnreadMessageCount = async (uid) => {
			try {
			  const response = await CometChat.getUnreadMessageCountForUser(uid);
			  const count = response[uid] || 0; // Default to 0 if count is undefined
			  setUsers(count) 
			} catch (error) {
			  console.error('Error while fetching unread message count:', error);
			}
		  }
		  getUnreadMessageCount(props.user.uid)
		  props.clearUnreadMessage('')
	}, [props.uid,props.clearCount])

	const context = useContext(CometChatContext);
	let userPresence = <CometChatUserPresence status={props.user.status} />;

	const toggleTooltip = (event, flag) => {
		const elem = event.target;

		const scrollWidth = elem.scrollWidth;
		const clientWidth = elem.clientWidth;

		if (scrollWidth <= clientWidth) {
			return false;
		}

		if (flag) {
			elem.setAttribute("title", elem.textContent);
		} else {
			elem.removeAttribute("title");
		}
	};

	const handleName = (user) =>{
		const event = new CustomEvent('NOTIFICATION_ADD', { 
			detail: {
			  title:user.name,
			  status:user.status === 'online'
			}
		  });
		  window.dispatchEvent(event);
	};

	return (
		<div
			css={listItem(props, context)}
			onClick={() => {props.clickHandler(props.user); handleName(props.user);  } }
			className='list__item'
		>
			<div css={itemThumbnailStyle()} className='list__item__thumbnail'>
				<CometChatAvatar user={props.user} />
				{userPresence}
			</div>
			<div
				css={itemDetailStyle()}
				className='list__item__details'
				dir={Translator.getDirection(context.language)}
			>
				<div css={userNotify()}>
				<div
					css={itemNameStyle(context)}
					className='item__details__name'
					onMouseEnter={(event) => toggleTooltip(event, true)}
					onMouseLeave={(event) => toggleTooltip(event, false)}
				>
					{props.user.name}
				</div>
			
				<div>
					<CometChatBadgeCount
					count={users}
					/>
				</div>
				</div>
				<div css={itemDescStyle(context)} className='item__details__desc'></div>
			</div>
		</div>
	);
};

// Specifies the default values for props:
CometChatUserListItem.defaultProps = {
	theme: theme,
	user: {},
};

CometChatUserListItem.propTypes = {
	theme: PropTypes.object,
	user: PropTypes.shape(CometChat.User),
};

export { CometChatUserListItem };
