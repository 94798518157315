import React from "react";
import { Grid, Box, Typography, Card, Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const configJSON = require("../config");
const shopHeading = configJSON.ShopPageContent.shopHeading;
import ShopController, { Props } from "./ShopController.web";
import ReadMore from "../../../../components/src/ReadMore";
const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "48px",
    color: "#052B62",
    fontWeight: 600,
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px"
    }
  },
  expertCardItems: {
    "&.MuiCard-root": {
      margin:'0 auto',
      maxWidth: "328px",
      borderRadius: "8px",
      paddingBottom:'10px',
      border: "0.2px solid #e3e3e3"
    },
    "&.MuiPaper-elevation1": {
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
    }
  },
  imageBox: {
    textAlign: "center"
  },
  aboutBox: {
    paddingTop: "4px"
  },
  aboutContent: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 2,
    display: "-webkit-box",
  },
  nameHeading: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#052B62",
    display: "-webkit-box",
    webkitLineClamp: 3,
    overdlow: "hidden"
  },
  content: {
    padding:'0 15px'
  },
  starRating: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "30px",
    marginBottom: "15px"
  },
  price: {
    fontWeight: 600,
    fontSize: "20px",
    color: "rgb(254, 64, 89)"
  },
  viewBtn: {
    padding: "10px 35px",
    borderRadius: "10px"
  },
  btnText: {
    color: "#FFFFFF"
  },
  bottomPara: {
    fontWeight: 500,
    transform: "translateY(45px)",
    fontSize: "19px",
    color: "#4c495e",
  },
}));
const Books = (props: any) => {
  const classes = useStyles();
  return (
    <Box sx={{ py: "100px" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3" className={classes.heading}>
            {shopHeading}
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          {props.books.map((book: any, index: number) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Card className={classes.expertCardItems}>
                <Box className={classes.imageBox}>
                  <img src={book.attributes.book_image_url} width={"100%"} />
                </Box>
                <Box className={classes.content}>
                  <Grid container>
                    <Grid md={12} xs={12} sm={12}>
                      <Typography variant="h3" className={classes.nameHeading}>
                        {book.attributes.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box className={classes.aboutBox}>
                    <Typography
                      variant="body1"
                      className={classes.aboutContent}
                    >
                      <ReadMore sliceLength={30}>{book.attributes.description}</ReadMore>
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" className={classes.bottomPara}>
            Accepting Bulk Orders! 
            <Link color="primary" href="/partnership#specific-section"> Contact Us.</Link>
           
        </Typography>
      </Grid>
    </Box>
  );
};

class Shop extends ShopController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <Books books={this.state.books} />;
  }
}
export default Shop;
