import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { setStorageData } from "../../../../../framework/src/Utilities";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
}
interface S {
  timelyProgressReportData: any;
  user_token_SchTpr: any;
  dropDownValuesSchTpr: any;
  appliedFiltersSchTpr: any;
  filterOptionsSchTpr: any;
  pageNoSchTpr: number;
}
interface SS { id: any }

export default class SchoolTimelyReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  GetIndexDataApiCallId: any;
  PublishApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      timelyProgressReportData: [],
      user_token_SchTpr: "",
      dropDownValuesSchTpr: {
        yearValueSchTpr: "",
        courseValueSchTpr: "",
      },
      appliedFiltersSchTpr: {},
      filterOptionsSchTpr: {
        yearFilterOptionSchTpr: [],
        courseFilterOptionSchTpr: [],
      },
      pageNoSchTpr: 1
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const user_token_SchTpr = await localStorage.getItem("user_token") ?? "";
    this.setState({
      user_token_SchTpr
    });
    this.getSchoolTimleyProgressData({ page: "1", per_page: 10 });
    this.getYearFilterOptions();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.timelyProgressReportData !== this.state.timelyProgressReportData) {
      this.getYearFilterOptions();
    }
    if (prevState.appliedFiltersSchTpr !== this.state.appliedFiltersSchTpr) {
      this.getSchoolTimleyProgressData(this.filterParamsSchTpr());
    }
    if (prevState.pageNoSchTpr !== this.state.pageNoSchTpr) {
      this.getSchoolTimleyProgressData(this.filterParamsSchTpr());
    }
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdSchTpr = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonSchTpr = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorMsg) {
        console.log(errorMsg);
      }
      if (responseJsonSchTpr && !responseJsonSchTpr.errors) {
        this.ApiCallSuuccessSchTpr(responseJsonSchTpr, apiRequestCallIdSchTpr);
      } else if (responseJsonSchTpr && responseJsonSchTpr.errors) {
        console.log(responseJsonSchTpr, "Error");
      }
    }
  }

  ApiCallSuuccessSchTpr(responseJsonSchTpr: any, apiRequestCallIdSchTpr: any) {
    if (apiRequestCallIdSchTpr === this.GetIndexDataApiCallId) {
      this.setState({ timelyProgressReportData: responseJsonSchTpr })
    }
  }

  goTo = async (module: string, studentData: any, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
    await setStorageData("student_data", JSON.stringify(studentData));
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleDropDownValueChangeSchTpr = (event: any, fieldNameSchTpr: any) => {
    this.setState({ dropDownValuesSchTpr: { ...this.state.dropDownValuesSchTpr, [fieldNameSchTpr]: event.target.value } }, () => {
      switch (fieldNameSchTpr) {
        case configJSON.TimelyProgress.YearValueSchTpr:
          this.createFilterParamsSchTpr(configJSON.TimelyProgress.SelectYearSchTpr);
          break;
        case configJSON.TimelyProgress.CourseValueSchTpr:
          this.createFilterParamsSchTpr(configJSON.TimelyProgress.SelectCourseSchTpr);
          break;
      }
    })
  }

  //Calculate Filter Options
  getYearFilterOptions() {
    const yearFilterOptionsSchTpr = this.state.timelyProgressReportData?.filters?.academic_years.map((el: any) => {
      return { title: el, value: el, id: el };
    }) || [];

    const courseFilterOptionsSchTpr = this.state.timelyProgressReportData?.filters?.mind_mastery_courses.map((el: any) => {
      return { ...el, value: el.id };
    }) || [];

    this.setState({
      filterOptionsSchTpr: {
        yearFilterOptionSchTpr: [...yearFilterOptionsSchTpr],
        courseFilterOptionSchTpr: [...courseFilterOptionsSchTpr],
      }
    })

  }

  clearFilter = (name: any, key: any) => {
    this.setState({ appliedFiltersSchTpr: { ...this.state.appliedFiltersSchTpr, [name]: { isSelected: false } }, dropDownValuesSchTpr: { ...this.state.dropDownValuesSchTpr, [key]: "" } });
  }

  createFilterParamsSchTpr = (filterNameSchTpr: string) => {
    switch (filterNameSchTpr) {
      case configJSON.TimelyProgress.SelectYearSchTpr:
        this.setState({
          appliedFiltersSchTpr: {
            ...this.state.appliedFiltersSchTpr,
            selectYearSchTpr: { isSelected: true, academic_year: this.state.dropDownValuesSchTpr.yearValueSchTpr }
          }
        })
        break;
      case configJSON.TimelyProgress.SelectCourseSchTpr:
          const selectedFilterSchTpr = this.state.filterOptionsSchTpr.courseFilterOptionSchTpr.filter((el: any) =>
            el.id === this.state.dropDownValuesSchTpr.courseValueSchTpr
          );
          this.setState({
            appliedFiltersSchTpr: {
              ...this.state.appliedFiltersSchTpr,
              selectCourseSchTpr: { isSelected: true, mind_mastery_course_id: selectedFilterSchTpr[0]?.id }
            }
          })
        break;
      default:
        break;
    }
  }

  filterParamsSchTpr = () => {
    let params: any = {
      page: "" + this.state.pageNoSchTpr,
      per_page: "10"
    }
    for (let key in this.state.appliedFiltersSchTpr) {
      if (this.state.appliedFiltersSchTpr.hasOwnProperty(key)) {
        const valueSchTpr = this.state.appliedFiltersSchTpr[key];
        if (valueSchTpr.isSelected) {
          let key = Object.keys(valueSchTpr)[1];
          params = { ...params, [key]: '' + valueSchTpr[key] }
        }
      }
    }
    return params;
  }

  handlePageChangeSchTpr(page: any) {
    this.setState({ pageNoSchTpr: page })
  }

  getSchoolTimleyProgressData(paramsSchTpr: any) {
    const headerSchTpr = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token_SchTpr
    };
    const apiRequestSchTpr = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetIndexDataApiCallId = apiRequestSchTpr.messageId;
    this.makeApiCallSchTpr(
      apiRequestSchTpr.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.timelyProgressReportEndPoint,
      headerSchTpr,
      undefined,
      paramsSchTpr
    );
  }

  async makeApiCallSchTpr(
    uniqueApiCallIdSchTpr: string,
    methodSchTpr: string,
    endpointSchTpr: string,
    headersSchTpr: any,
    bodySchTpr?: any,
    paramsSchTpr?: any,
  ) {
    let fullURLSchTpr =
      endpointSchTpr.indexOf("://") === -1
        ? config.baseURL + "/" + endpointSchTpr
        : endpointSchTpr;

    let apiResponseMessageSchTpr = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessageSchTpr.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallIdSchTpr
    );
    try {
      let response = await fetch(fullURLSchTpr + "?" + new URLSearchParams({ ...paramsSchTpr }).toString(), {
        method: methodSchTpr.toUpperCase(),
        headers: headersSchTpr,
        body: bodySchTpr
      });
      if (response.status === 401) {
        console.log("401 Response", response);
      }
      let responseJsonSchTpr = await response.json();
      //setting Response
      apiResponseMessageSchTpr.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonSchTpr
      );

      apiResponseMessageSchTpr.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonSchTpr
      );
    } catch (errorSchTpr) {
      runEngine.debugLog("RestApiClient Error", errorSchTpr);
      //setting Error
      console.log("Api Error" + JSON.stringify(errorSchTpr));
      apiResponseMessageSchTpr.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessageSchTpr);
  }
  // Customizable Area End
}
