import React from "react";
import { Box, Container, Typography, Card, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChildrenQuizForm from "../ChildrenQuizFrom.web";
import SchoolQuizForm from "../SchoolQuizFrom.web";
import ParentQuizForm from "../ParentQuizFrom.web";
import { configJSON } from "../../LandingPageController";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "10px"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  quizHeading: {
    background:
      "linear-gradient(134.86deg, #B3DBFF -36.53%, #4B90CD 17.76%, #0156A2 95.37%)",
    padding: "25px",
    color: "#fff",
    fontWeight: 600,
    fontSize: "22px"
  },
  quizBox: {
    padding: "20px",
    overflow: "auto"
  },
  tabs: {
    "&.MuiTabs-root": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3"
    },
    "& .MuiTabs-indicator": {
      display: "none"
    }
  },
  tab: {
    "&.MuiTab-root": {
      textTransform: "capitalize",
      color: "#0056A3",
      fontWeight: 400,
      maxWidth: "none",
      width: "33%",
      fontSize: "18px",
      lineHeight: "27px"
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 700,
      color: "white",
      textTransform: "capitalize",
      backgroundColor: "#0056A3",
      borderRadius: "8px",
      fontSize: "18px",
      lineHeight: "27px"
    }
  }
}));
const TabSwitch = ({ changeBanner, safetyIQDetails, changeQuestion, submitSection, states }: any) => {
  const classes = useStyles();
  const a11yProps = (index: any) => {
    return {
      id: `m-power-tab-${index}`,
      "aria-controls": `m-power-tabpanel-${index}`
    };
  };
  const [value, setValue] = React.useState<number>(0);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    changeBanner(newValue);
    setValue(newValue);
    switch (newValue) {
      case 0:
        changeQuestion('parents','3-8');
      break;
      case 1:
        changeQuestion('children','6-10');
      break;  
      default:
        changeQuestion(configJSON.SafetyIQPageContent.SchoolOwners);
        break;
    }
  
  };
  const commonProps = {
    safetyIQDetails:safetyIQDetails,
    changeQuestion: changeQuestion, 
    submitSection: submitSection,
    states: states,
  }
  return (
    <Box sx={{ py: "100px" }}>
      <Container className={classes.containerStyle}>
        <Box>
          <Card className={classes.card}>
            <Typography className={classes.quizHeading}>
              Safety Knowledge Quiz
            </Typography>
            <Box className={classes.quizBox}>
              <Box>
                <Tabs
                  data-test-id="changeTheTab"
                  className={classes.tabs}
                  value={value}
                  onChange={handleTabChange}
                  variant="fullWidth"
                >
                  <Tab
                    className={classes.tab}
                    label="Parents"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tab}
                    label="Children"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={classes.tab}
                    label="School Owners"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <ParentQuizForm changeBanner={changeBanner} {...commonProps}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ChildrenQuizForm {...commonProps} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <SchoolQuizForm {...commonProps} />
              </TabPanel>
            </Box>
          </Card>
        </Box>
      </Container>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default TabSwitch;
