import React from "react";
import { Typography, Box, List, ListItem } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
const utilityImg = require("./assets")
interface Props {
    headerText: string,
    contentText: string,
    fontSize: string,
    color: string,
    secondColor: string,
    paragraphFontSize?: string,
    lineHeight?: string,
    descriptionWidth?: string,
    multiLine?: string[],
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
    boxForImage:{
        position:'relative',
        display:'inline-block',
        color: props => `${props?.color}`,
        fontSize: props => `${props?.fontSize}` ?? '48px',
        lineHeight: props => `${props?.lineHeight}` ?? '50px',
        fontWeight: 600,
    
        [theme.breakpoints.down('sm')]: {
            fontSize: props => '44px' ?? '48px',
            lineHeight: props => '50px' ?? '50px',
        },
    },
    imgBox: {
        position: 'absolute',
        top: '59px',
        left: '0px',
        width: '145px',
        [theme.breakpoints.down('sm')]: {
            top:' 50px',
            left:' 8px',
            width:'112px'
        }
         
    },
    noneClass:{
        display:'none',
    },
    mainHeading: {
        color: props => `${props?.color}`,
        fontSize: props => `${props?.fontSize}` ?? '48px',
        lineHeight: props => `${props?.lineHeight}` ?? '50px',
        fontWeight: 600,
        position:'relative',
        
        [theme.breakpoints.down('sm')]: {
            fontSize: props => '44px' ?? '48px',
            lineHeight: props => '50px' ?? '50px',
        },
        
    },
    secondHeading: {
        color: props => `${props?.secondColor}`,
        fontSize: props => `${props?.paragraphFontSize}`,
        padding: '15px 0',
        mixBlendMode: 'normal',
        opacity: 0.74,
        width: props => `${props.descriptionWidth}` ?? 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: props => "16px",
        },
    },
    listTypeHeading:{
        color:'#fff',
        margin:'15px 0'
    },
    listItemsAlign:{
        paddingLeft:'0px'
    },
    listType:{
        color:'rgba(255, 255, 255, 0.6);',
    
    },

}))

function UtilityInfo(props: Props) {
    const classes = useStyles(props);
    const { headerText, contentText, multiLine } = props;
    return <>
        {multiLine ?
            <Box>
                <Typography variant="h3" className={classes.mainHeading}>Ensuring <Typography className={classes.boxForImage}>Child  <img src={utilityImg.curve} className={ classes.imgBox}/></Typography> Safety, Fostering Happy Minds</Typography>
                
                {multiLine.map((item, index) => {
                    return (
                        <Typography  key={index} className={classes.listTypeHeading}> { item }</Typography>
                    )
                })}
            </Box>
            :
            <Box>  
                <Typography variant="h3" className={classes.mainHeading}>{headerText}  </Typography>
                <Typography variant="body1" className={classes.secondHeading}>
                    {contentText}
                </Typography>
            </Box>
        }

    </>
}
export default UtilityInfo;
