import React from 'react';
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import ChildsReportController, { S } from "./ChildsReportController.web";
import { Box, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommonSelect from '../../CommonComponents/CommonSelect.web';
import { reportFileIcon } from '../../assets';
import CommonPagination from '../../../../../components/src/Pagination.web';
import Loader from '../../../../../components/src/Loader.web';


const configJSON = require('../../config')
const config = require('../../../../../framework/src/config')

const useStyles = makeStyles(() => ({
    pageWrapper: {
        padding: '30px 24px'
    },
    tableHead: {
        '&.MuiGrid-container': {
            borderRadius: '8px',
            background: '#0056A3',
            color: 'white',
            fontWeight: 600,
            fontFamily: 'Poppins',
            lineHeight: '27px',
            fontSize: '15px',
            padding: '15px 24px',
            justifyContent: 'space-between',
            marginBottom: '16px',
            '@media(max-width:600px)': {
                minWidth: '767px'
            },
        }
    },
    tableRow: {
        '&.MuiGrid-container': {
            borderRadius: '8px',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
            justifyContent: 'space-between',
            border: '1px solid #e3e3e3',
            padding: '12px 24px',
            marginBottom: '8px',
            '@media(max-width:600px)': {
                minWidth: '767px'
            },
        }
    },
    headerCell: {
        '&.MuiGrid-item': {
            margin: 'auto',
            fontSize: '15px',
            padding: '0 10px',
        }
    },
    fileIconBtn: {
        '&.MuiIconButton-root': {
            padding: '0',
            marginLeft: 'auto',
            display: 'block'
        }
    },
    fileImage: {
        width: '20px'
    },
    tableWrapper: {
        marginBottom: '30px',
        overflowX: 'auto',
        maxWidth: 'calc(100vw - 84px)',
    }
}))

interface ChildsReportPageProps {
    states: S,
    handleFilter: (event: React.ChangeEvent<{ value: any, name: any }>) => void;
    handlePage: (page: number) => void;
    handleClear: () => void;
}

interface TableRow {
    data: {
        attributes: {
            student: {
                name: string;
            };
            mind_mastery_course: {
                name: string;
            };
            academic_year: string;
            pdf_url: string;
        };
    };
}

interface MenuItem {
    attributes: {
        first_name: string;
        last_name: string;
        id: number
    }
}


export const ChildsReportPage = (props: ChildsReportPageProps) => {
    const classes = useStyles();
    const tableHeader = [configJSON.YouMatterTexts.ChildName, configJSON.YouMatterTexts.CourseName, configJSON.YouMatterTexts.AcademicYear];

    const handleOpenReport = (url: any) => {
        if (config.baseURL === configJSON.devURL) {
            const formattedUrl = url.replace('http://', 'https://')
            window.open(formattedUrl, "_blank");
        } else {
            window.open(url, "_blank");
        }
    }

    const menuList = props.states.dropDownList.map((element: MenuItem) => {
        return { id: element.attributes.id, title: `${element.attributes.first_name} ${element.attributes.last_name}`, value: element.attributes.id };
    })

    const tableData = props.states.reportData;
    return <Box className={classes.pageWrapper} >
        <Grid container style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6}>
                <CommonSelect placeholder={configJSON.YouMatterTexts.SelectChild} data-test-id='select' onClear={props.handleClear} handleChange={props.handleFilter} value={props.states.dropDownFilterValue} menuList={menuList} />
            </Grid>
        </Grid>
        <Box className={classes.tableWrapper}>
            <Grid container className={classes.tableHead}>
                {tableHeader.map((element: any) => (<Grid key={element} item xs={3} className={classes.headerCell} >{element}</Grid>))}
                <Grid item xs={true}></Grid>
            </Grid>
            <Grid>
                {tableData?.length > 0 ? tableData?.map((element: TableRow) => {
                    const attributes = element?.data?.attributes;
                    return <Grid key={attributes.pdf_url} container className={classes.tableRow}>
                        <Grid item xs={3} className={classes.headerCell}>{attributes?.student.name}</Grid>
                        <Grid item xs={3} className={classes.headerCell}>{attributes?.mind_mastery_course?.name}</Grid>
                        <Grid item xs={3} className={classes.headerCell}>{attributes?.academic_year}</Grid>
                        <Grid item xs={true} className={classes.headerCell}>
                            <IconButton className={classes.fileIconBtn} data-test-id='report' onClick={() => handleOpenReport(attributes?.pdf_url)} >
                                <img className={classes.fileImage} src={reportFileIcon} alt="" />
                            </IconButton>
                        </Grid>
                    </Grid>
                }) : configJSON.YouMatterTexts.NoReports}
            </Grid>
        </Box>
        <CommonPagination data-test-id='pagination' page={props.states.page} pageCount={props.states.totalPages} handlePage={props.handlePage} />
    </Box>
}

export default class ChildsReport extends ChildsReportController {
    async componentDidMount() {
        this.getDropdownData();
        this.getReportData();
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<S>): void {
        if (prevState.page !== this.state.page || prevState.dropDownFilterValue !== this.state.dropDownFilterValue) {
            this.getReportData();
        }
    }
    render() {
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout title={configJSON.YouMatterTexts.ChildReport}>
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <ChildsReportPage
                        data-test-id='report-page'
                        handleClear={this.handleClear}
                        states={this.state}
                        handleFilter={this.handleFilterChange}
                        handlePage={this.handlePage} />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
}
