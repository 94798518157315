import React from "react";
import { Box, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UtilityInfo from "../../../components/src/UtilityInfo.web";
import GenericWebButton from "../../../components/src/GenericWebButton.web";
const configJSON = require("./config");
const assessmentImg = require("./assets");
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
}
const assessment = configJSON.LandingPageContent.assesment;
const content: Content = {
  headerText: assessment.headerText,
  contentText: assessment.contentText,
  fontSize: "48px",
  color: "#052B62",
  secondColor: "#052B62",
  paragraphFontSize: "16px"
};
const useStyles = makeStyles(theme => ({
  assessment: {
    padding: "100px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "100px 0"
    }
  },
  assignmentImgOne: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  assignmentImgTwo: {
    marginBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
}));

const Assessment = () => {
  const classes = useStyles();
  return (
    <Box className={classes.assessment}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.assignmentImgOne}>
            <img src={assessmentImg.assessment} width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box>
            <UtilityInfo {...content} secondColor="#052B62" />
            <Box className={classes.assignmentImgTwo}>
              <img src={assessmentImg.assessment} width="100%" />
            </Box>
            <Link href="/safetyiq">
              <GenericWebButton
                text={"Knowledge check"}
                icon={assessmentImg.rightArrow}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Assessment;
