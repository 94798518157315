import React, { ReactNode, useState } from "react";
import YouMatterController, { Props, S } from "./YouMatterController.web";
import SidebarLayout from "../SidebarLayout.web";
import PageContentLayout from "../PageContentLayout.web";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Tab, Tabs, Button, Grid, Theme } from '@material-ui/core';
import moment from "moment";
import YouMatterDetailsDialog from './YouMatterDetailsDialog.web'
import Loader from "../../../../components/src/Loader.web";
import CommonPagination from "../../../../components/src/Pagination.web";

const configJSON = require("../config.js");

const useStyles = makeStyles<Theme>((theme) => ({
    tab: {
        '&.MuiTab-root': {
            textTransform: 'capitalize',
            color: '#0056A3',
            fontFamily: "Poppins",
            fontWeight: 400,
            maxWidth: 'none',
            width: '50%',
            fontSize: '16px',
            lineHeight: '27px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px',
                lineHeight: '18px',
            }
        },
        '&.MuiTab-root.Mui-selected': {
            fontWeight: 700,
            color: 'white',
            fontFamily: "Poppins",
            textTransform: 'capitalize',
            backgroundColor: '#0056A3',
            borderRadius: '8px',
            fontSize: '16px',
            lineHeight: '27px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px',
                lineHeight: '18px',
            }
        }
    },
    tabs: {
        '&.MuiTabs-root': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
            borderRadius: '8px',
            border: "0.2px solid #e3e3e3"
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        }
    },
    youMatterTabViewWrapper: {
        padding: '24px',
    },
    tabContentWrapper: {
        paddingTop: '24px',
        marginBottom: '30px'
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            "& .MuiGrid-container": {
                justifyContent: "center"
            }
        }
    },
    paginationWrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row-reverse'
    },
    pagination: {
        '& .MuiPaginationItem-outlinedPrimary.Mui-selected': {
            color: 'white',
            border: '1px solid rgba(63, 81, 181, 0.5)',
            backgroundColor: '#0056A3'
        },
        '& button[aria-label="Go to previous page"]:not(.Mui-disabled)': {
            color: 'white',
            fontFamily: "Poppins",
            border: '1px solid rgba(63, 81, 181, 0.5)',
            backgroundColor: '#0056A3'
        },
        '& button[aria-label="Go to next page"]:not(.Mui-disabled)': {
            color: 'white',
            border: '1px solid rgba(63, 81, 181, 0.5)',
            backgroundColor: '#0056A3'
        }
    }
}));


const youMatterCardStyles = makeStyles<Theme, any>(() => ({
    youMatterCardWrapper: {
        borderRadius: '8px',
        backgroundColor: '#E0F0FF',
        fontFamily: "Poppins",
        height: 'auto',
        width: '100%',
        padding: '16px'
    },
    cardTitle: {
        color: '#0056A3',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '30px',
        marginBottom: '5px'
    },
    cardTime: {
        color: '#3B3B3B',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px'
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#0056A3',
        margin: '20px 0 '
    },
    cardInfoKey: {
        color: '#3B3B3B',
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: '14px',
        display: 'inline-block'
    },
    cardInfoValue: {
        color: '#3B3B3B',
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: '14px'
    },
    cardButton: {
        '&.MuiButton-root': {
            textTransform: 'capitalize',
            color: '#3B3B3B',
            fontFamily: "Poppins",
            fontWeight: 600,
            borderRadius: '8px',
            width: '100%',
            fontSize: '14px',
            background: '#E0F0FF',
            border: '1px solid #0056A3',
            height: '48px'
        }
    },
    submittedBtn: {
        '&.MuiButton-root': {
            textTransform: 'capitalize',
            fontFamily: "Poppins",
            fontWeight: 600,
            borderRadius: '8px',
            width: '100%',
            fontSize: '14px',
            background: '#EFD154',
            height: '48px',
            color: 'white'
        }
    },
    youMatterStatus: {
        backgroundColor: props => `${props.attributes && props.attributes.submission_status === configJSON.YouMatterTexts.Published ? '#6F6C6C' : '#EFD154'}`,
        color: 'white',
        fontFamily: "Poppins",
        textTransform: 'capitalize',
        width: '90px',
        height: '28px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        borderRadius: '4px'
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    keyValuePairWrapper: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkitLineClamp': 1,
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-all',
        '&:hover': {
            '-webkitBoxOrient': 'inherit',
            wordBreak: 'break-word',
        }
    },
    keyValuePairDummy: {}
}))

interface Trainer {
    id: number;
    full_name: string;
    profile_photo: any;
    email: any;
}

export interface Attributes {
    title: string;
    created_at: string;
    course_name: string;
    due_date: string;
    submission_status: string;
    submission_date: string;
    trainer: Trainer;
    you_matter_details: string;
}

interface YouMatterCardProps {
    attributes: Attributes;
    youMatterId: any;
    openYouMatterModel: (attributes: Object, id: any) => void,
}

export const YouMatterCard = (props: YouMatterCardProps) => {
    const classes = youMatterCardStyles(props);
    const { attributes, youMatterId } = props;
    const dueDate = moment(attributes?.due_date, 'DD-MM-YYYY')
    const formattedDueDate = dueDate.format('D MMM YYYY');
    const status = attributes?.submission_status;
    const date = status === configJSON.YouMatterTexts.Published ? formattedDueDate : attributes?.submission_date
    const CardDetailsInfo = [
        { key: configJSON.YouMatterTexts.CourseName, value: attributes?.course_name },
        { key: status === configJSON.YouMatterTexts.Published ? configJSON.YouMatterTexts.DueDate : configJSON.YouMatterTexts.SubmissionDate, value: date },
        { key: configJSON.YouMatterTexts.SubmissionStatus, value: attributes?.submission_status },
    ]

    const handleOpenDialog = () => {
        props.openYouMatterModel(attributes, youMatterId);
    }
    return (<Box className={classes.youMatterCardWrapper}>
        <Typography className={classes.cardTitle}>{attributes?.title}</Typography>
        <Box className={classes.divider}></Box>
        <Grid container spacing={1} className={classes.cardDetails}>
            {CardDetailsInfo.map((element) => {
                return (<>
                    {(element.value !== configJSON.YouMatterTexts.Submitted && element.value !== configJSON.YouMatterTexts.Evaluated) && <Grid item key={element.key} className={element.key === configJSON.YouMatterTexts.CourseName ? classes.keyValuePairWrapper : classes.keyValuePairDummy}>
                        <Typography className={classes.cardInfoKey}>{element.key} &nbsp;:&nbsp;
                        </Typography><span className={element.key === configJSON.YouMatterTexts.SubmissionStatus ? classes.youMatterStatus : classes.cardInfoValue}>{element.value}</span>
                    </Grid>}
                </>)
            })}
        </Grid>
        <Box className={classes.divider}></Box>
        {attributes?.submission_status === configJSON.YouMatterTexts.Published && <Button data-test-id='attemptYouMatter' className={attributes?.submission_status === configJSON.YouMatterTexts.Published ? classes.cardButton : classes.submittedBtn} onClick={handleOpenDialog} >{configJSON.YouMatterTexts.AttemptYouMatter}</Button>}
    </Box>)
}

interface Data {
    id: string;
    type: string;
    attributes: Attributes
}

interface YouMatterPageProps {
    handleTabChange: (value: number) => void;
    data: Data[];
    openYouMatterModel: (attributes: Object, id: any) => void,
    pageCount: number;
    page: number;
    handlePage: (page: number) => void;
    submissionStatus: string;
}

export const YouMatterPage = (props: YouMatterPageProps) => {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
        props.handleTabChange(newValue);
    };
    const a11yProps = (index: any) => {
        return {
            id: `you-matter-tab-${index}`,
            'aria-controls': `you-matter-tabpanel-${index}`,
        };
    }
    const { data, handlePage, pageCount, page, openYouMatterModel } = props;
    return (
        <Box className={classes.youMatterTabViewWrapper}>
            <Tabs className={classes.tabs} value={value} data-test-id='tabs' onChange={handleTabChange} variant="scrollable">
                <Tab className={classes.tab} label={configJSON.YouMatterTexts.PublishedYouMatter} {...a11yProps(0)} />
                <Tab className={classes.tab} label={configJSON.YouMatterTexts.SubmittedYouMatter} {...a11yProps(1)} />
            </Tabs>
            <Box data-test-id='tab-content' className={classes.tabContentWrapper}>
                {data?.length > 0 ? <Grid container className={classes.gridContainer} spacing={3}>
                    {data.map((element: any) => {
                        return (<Grid key={element.id} container item lg={4} xl={3} md={6} sm={6} xs={12} >
                            <YouMatterCard data-test-id='you-matter-card' youMatterId={element.id} openYouMatterModel={openYouMatterModel} attributes={element?.attributes} />
                        </Grid>)
                    })}
                </Grid> : configJSON.YouMatterTexts.NoYouMatterFound}
            </Box>
            <CommonPagination data-test-id='pagination-component' page={page} pageCount={pageCount} handlePage={handlePage} />
        </Box>
    )
}

export default class YouMatter extends YouMatterController {
    async componentDidMount(): Promise<void> {
        localStorage.removeItem(configJSON.YouMatterTexts.CurrentSection);
        await this.getYouMatterPageData(this.state.submissionStatus, this.state.page);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if (prevState.submissionStatus !== this.state.submissionStatus || prevState.page !== this.state.page) {
            this.getYouMatterPageData(this.state.submissionStatus, this.state.page);
        }
    }
    render(): ReactNode {
        const pageCount = this.state.youMatterPageData?.page_options?.total_pages;
        return (
            <SidebarLayout {...this.props}>
                <PageContentLayout addPadding title={configJSON.YouMatterTexts.YouMatter} data-test-id='page-layout' subtitle={configJSON.YouMatterTexts.FunChallenges} >
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <YouMatterPage data-test-id='you-matter-page' data={this.state.youMatterPageData?.data} handleTabChange={this.handleTabChange} page={this.state.page} pageCount={pageCount} submissionStatus={this.state.submissionStatus} handlePage={this.handlePage} openYouMatterModel={this.openYouMatterDetailsModel} />
                    <YouMatterDetailsDialog data-test-id='dialog' youMatterId={this.state.youMatterId} data={this.state.youMatterDetails} open={this.state.openYouMatterDetailsModel} closeYouMatterDetailsModel={this.closeYouMatterDetailsModel} />
                </PageContentLayout>
            </SidebarLayout>
        )
    }
}