Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getPartnerLogo = "partner_logos";
exports.postPartnerData = "partners";
exports.btnExampleTitle = "CLICK ME";
exports.getBooksData = "shop_books";
exports.getTeamsInfo = "our_teams";
exports.safetyIQDetails = "safety_iqs";
exports.contentTypeFormData = "FormData";
exports.getPartnershipOptionsEndPoint =  "partners/new";
exports.impactDetails="impact_details"
exports.LandingPageContent = {
  assesment: {
    headerText: "Safety IQ",
    contentText:
      "A fun quiz to understand your awareness level of important safety principles."
  },
  banner: {
    headerText: "Ensuring Child Safety, Fostering Happy Minds",
    contentText:
      "Safe and Sound, Happy and Free: Our Commitment to Child Safety"
  },
  aboutHapchi: {
    headerText: "Why Hapchi ",
    contentText:
      "India's first child safety digital platform focuses on every child safety concern that a child encounters during their journey from early childhood to adolescence. Our all-encompassing approach equips parents, educators and schools with the tools and knowledge to protect children's safety and mental health."
  },
  productHelp: {
    headerText: "How will our product help a child?",
    contentText:
      "Raising a more informed and empowered generation capable of navigating the complexities of the modern world with confidence and resilience."
  },
  howHapchiHelps: {
    headerText: "How Hapchi will help a School ",
    contentText:
      "We recognise that each school has specific needs and challenges. Therefore, our approach is tailored to each school, ensuring that each school receives the individualised support and attention necessary to flourish in a safe and healthy environment."
  },
  hapchiFamily: {
    headerText: "What Does The Hapchi Family Say",
    contentText:
      "Our Hapchi family, a constant source of inspiration and motivation, drives us to innovate, create, strive for excellence and we are fueled to do even more when supported by like-minded individuals who share our passion for making a positive difference in the world."
  },

  settings: {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
          arrows: true,
          autoplay: true
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
          autoplay: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
          autoplay: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
      }
    ]
  }
};

exports.AboutUsPageContent = {
  bannerText: {
    headerText: "About Us",
    contentText: `At Hapchi, we're dedicated to transforming child safety education. Empowering children, parents, and educators with essential tools, we address abuse prevention, bullying, digital safety, online addiction, and mental health. By blending technology with insights from safety experts, psychologists, educators, and digital artists, we aim to create a safer world for children – because their safety is our priority.`
  },
  storyText: {
    heading: "Our Story!",
    quote: `"Be the change you wish to see in the world”- Mahatma Gandhi`,
    rightParagraphs: [
      `Hoping that change is possible provided we take the first step together let us join hands and work towards creating a safer and happier world for children, one where they can grow and thrive without fear or harm.`,
      `Hapchi was born from this desire to fill up these gaps and bring about awareness through education. We believe that children deserve a safe and supportive environment to grow and thrive and it's our collective responsibility to make that a reality. We also understand that parents and schools play a critical role in this process, which is why we work closely with them to ensure that our platform meets the needs of everyone involved. Hence, our ultimate goal is to create a world where every child and adolescent feels safe, supported and equipped to thrive.`,
      `From online safety and cyberbullying to physical safety and emergency preparedness, we cover a range of topics to ensure that children are protected from all forms of harm. We believe that by providing tools, resources and education, we can empower parents, caregivers and educators to be proactive in safeguarding children from harm.`
    ],
    rightBottomQuotes: [
      `Let us become empowered and ensure a safe future for our children. Remember, child safety is not just a concern, it's our priority!`,
      `A step closer to a safer world for every child.`,
      `"Aware-Educate-Empower"`
    ],
    leftParagraphs: `Our journey began with a mother's concern for her child's safety, which led to the creation of our platform. In 2017, Dr. Kanika's four year old son came back from school and spoke two words good touch & bad touch. She realised that there were no age appropriate resources or programs available to guide parents through this concern. This incident marked her humble journey towards helping parents educate their children by authoring her first book on child safety (Be Smart Be Safe). Progressing through multiple workshops, she also realised that it was not sufficient. It was time to start the conversation and move on from stigmas and taboos associated with these issues. By breaking the barriers of denial and ignorance, everyone becomes more aware and educated.`
  },
  vision: {
    headerText: `Our Vision`,
    contentText: `A happy, healthy and safe childhood for every child!`
  },
  mission: {
    headerText: `Our Mission`,
    contentText: `​​To create a safe and supportive digital platform that guides children and adolescents through the challenges of growing up in a rapidly changing world. We aim to empower young people with the knowledge and tools they need to navigate important issues such as body safety, bullying, online addiction, digital exploitation, self-empowerment and mental health issues, working with parents and schools.`
  },
  values: {
    headerText: "Our Values",
    contentText:
      "Compassion, Empowerment, Transparency, Innovation, Child Safety, Inclusivity, Diversity, Community Engagement, Collaboration"
  },

  aboutFounder: {
    header: "Our Founder",
    name: "Dr. Kanika Sharma",
    paragraphs: [
      `With a passion for creating a safer world for every child, this Mompreneur is a multifaceted individual with a degree in Medicine and a Masters in Healthcare. As a children's book author and educator, she believes in building a protective environment that raises awareness about the dangers of abuse, bullying, cybercrimes and mental health issues.`,
      `Her programs and books are designed to help children develop crucial life skills like resilience, body autonomy, self-reliance and persistence. With a focus on age-appropriate content and involving parents, schools and educators, she has already conducted successful workshops and webinars for over a thousand children`,
      `It is easy to wait for change to happen, but it is the hardest to take an active step and put in the effort to make a difference. With Hapchi, she is determined to make a positive impact and bring about this change. `
    ]
  },
  expertsText: {
    headerText: "Our Experts",
    experts: [
      {
        name: "Mrs. Jyoti Sharma",
        about:
          "Principal, Greenwood Public School, Gurugram, Effective Global leader 2020.",
        image: "jyotiImage"
      },
      {
        name: "Ms. Nishita Bakshi",
        about: "Psychologist, Hypnotherapist and Graphologist.",
        image: "nishitaImage"
      },
      {
        name: "Ms. Chrissy Sykes",
        about: `Global Goodwill Ambassador, VP Child Advocacy, Creator – MBIMB (Animated child abuse prevention program).`,
        image: "chrissyImage"
      },
      {
        name: "Mr. Sukumar Ranga",
        about: `Business Mentor at IIMB, Nadathur S Raghavan Centre for Entrepreneurial Learning (NSRCEL), IIMB. Alumnus of IIT, Kgp (E&EC) and IIMB.`,
        image: "sukumarImage"
      }
    ]
  },
  ourTeamHeading: "Our Team"
};

exports.PartnershipPageContent = {
  welcomeText: {
    heading: `Welcome to our Partnership Page!`,
    paragraphs: [
      `We at Hapchi believe that protecting children is a shared responsibility. We are excited to offer partnership opportunities to schools, corporates, NGOs and government organisations that share our vision of safeguarding children.`,
      `Our platform offers innovative solutions to help safeguard children in both online and offline environments. With advanced tools and technologies, we empower parents, teachers and caregivers to provide a safe space for children to learn, play and grow.`,
      `Let's work together to make an impact and the world a safer place for our children.`,
      `If you're interested in partnering with us, please fill out the contact form below.`,
      `Thank you for your interest in Hapchi!!`
    ]
  },
  bannerText: {
    heading: `Partnership`,
    content: `Collaborate with us to strengthen child safety initiatives through meaningful partnerships, joint campaigns, and shared resources. Together, we can create a safer world for children.`
  },
  formText: {
    heading: `Become a partner`
  }
};

exports.ProgramsPageContent = {
  mainPage: {
    heading: `Empowering Children Ensuring Safety`,
    topParagraphs:`India's pioneering digital platform solely focused on reforming child safety education.  Our innovative approach strives to empower children with age-appropriate vital knowledge and skills by seamlessly integrating online resources with offline intervention. Together, we are dedicated to crafting a safer world for children by giving paramount importance to their well-being and nurturing a culture of safety.`,
    rightParagraphsMain: [
      `Hapchi is dedicated to promoting child safety and development through various programs that address a range of safety concerns. Our programs are designed to empower children with the knowledge and skills they need to protect themselves and stay safe.`,
      `The foundation of our program is rooted in the 4 C's: Communicate, Consistent, Creative, and Connect, where we work at three levels bud, bloom, and blossom.`,
      `We understand that safety is a top priority for parents and schools. With the programs we offer, you can rest assured that your children are in good hands.`,
      `“An ounce of prevention is worth a pound of cure.” -Benjamin Franklin`
    ],
    bottomParagraphsMain: [
      `We meet kids where they are.`,
      `Our holistic child safety programs are designed to provide a comprehensive approach by addressing physical, emotional, and social aspects of safety.`
    ],
    rightParagraphs: "Creating an impactful outcome through",
    bottomParagraphs: [
      `Join us in our mission to prioritize child safety and empower the next generation. Together, let's create a world where children can thrive, learn, and grow in a safe and protected environment.`,
      `Click here`
    ],
    features: [
      {
        heading: `Empowering Children`,
        content: [`Engaging modules, activities, and accessible resources. `,  `Reinforcement of concepts through the platform's interactive features. `,  `Providing a safe space to seek expert assistance when in need. `,  `Monitoring and tracking learning progress for personalised growth.`],
        image: `safetyChildChildren`
      },
      {
        heading: `Supporting Parents`,
        content: [`Raising awareness of child safety issues. `,
         `Equipping with essential knowledge and skills to safeguard their children. `,
         `Providing valuable resources to create a safe and secure environment. `,
         `Access to their child's progress and learning journey.`],
        image: `safetyChildParent`
      },
      {
        heading: `Equipping Schools`,
        content: [`Comprehensive customizable curricula for child safety education. `,
         `Educators training for creating a safe and inclusive learning environment. `, 
         `Monitoring tools to track student progress and ensure program effectiveness. `],
        image: `safetyChildSchool`
      },
  
     
    ]
  },
  parentPage: {
    heading: "Parents",
    content: "Worried about your child's safety and concerning behaviours?",
    rightParaHeading: `Worried about your child's safety and concerning behaviours?`,
    rightParagraphs: [
      `Your child's safety is our top priority, and we understand the worry and concern that comes with being a parent. You don't have to face these challenges alone.`,
      `Our compassionate team of experts is here to support you through personalised one-on-one consultation sessions, creating a customised plan that addresses your specific concerns and meets your needs.`,
      `Join the Hapchi community today!`,
      `Together, let's create a better, safer future for your child.`
    ]
  },
  bloomPage: {
    bloomHeading: `BLOOM`,
    bloomHeadingSpan: `[8 to 11 Years]`,
    bloomContent: `Primary years are the beginning of formal education. Bloom develops and enhances body safety and life skills by making them aware of challenging and unsafe situations by voicing up.`,
    bloomFeatures: [
      {
        bloomheading: `Be Smart Be Safe`,
        bloomcontent: `Teaches emotional intelligence, body safety skills, and strategies to keep children safe while promoting respect for personal space and awareness of potential risks.`,
        bloomimage: `programFeature9`
      },
      {
        bloomheading: `Compassionate Buddy`,
        bloomcontent: `Teaches children about bullying and its consequences, building empathy and taking personal responsibility while empowering them to report bullying.`,
        bloomimage: `programFeature10`
      },
      {
        bloomheading: `Mindful Gaming`,
        bloomcontent: `Raising awareness of gaming addiction and its consequences and promoting healthy habits around gaming.`,
        bloomimage: `programFeature11`
      },
      {
        bloomheading: `Online Smart`,
        bloomcontent: `Educates children about online dangers, internet safety rules, and making informed decisions while being a part of the online world.`,
        bloomimage: `programFeature12`
      },
      {
        bloomheading: `Power Of You`,
        bloomcontent: `Children discover their strengths, build self-esteem, learn how to overcome challenges, and develop positive relationships with themselves.`,
        bloomimage: `programFeature13`
      },
      {
        bloomheading: `The Power of Positive Language`,
        bloomcontent: `Creating an inclusive and respectful culture by understanding the importance of using appropriate language in communication.`,
        bloomimage: `programFeature14`
      },
      {
        bloomheading: `Together As One`,
        bloomcontent: `Celebrating differences, promoting understanding and respect, and creating a welcoming environment for all.`,
        bloomimage: `programFeature15`
      }
    ]
  },
  educatorPage: {
    heading: "Educators",
    content: `If you're passionate about child safety and want to make a positive change in your community, you've come to the right place.`,
    rightParaHeading: `Educators`,
    rightParagraphs: [
      `If you're passionate about child safety and want to positively change your community, you've come to the right place.`,
      `As an educator, your commitment to your student’s safety and well-being is essential to their success.`,
      `We believe the same, so join our team and work together to create a safe and positive learning environment.`,
      `Join the Hapchi family today and let's work together to empower young minds, creating a better and safer future for our children.`
    ]
  },
  blossomPage: {
    heading: `BLOSSOM`,
    headingSpan: `[11 to 16 years]`,
    content: `Primary years are the beginning of formal education. Bloom develops and enhances body safety and life skills by making them aware of challenging and unsafe situations by voicing up.`,
    features: [
      {
        heading: `Be Smart Be Safe`,
        content: `Teaches emotional intelligence, body safety skills, and strategies to keep children safe while promoting respect for personal space and awareness of potential risks.`,
        image: `programFeature1`
      },
      {
        heading: `Compassionate Buddy`,
        content: `Teaches children about bullying and its consequences, building empathy and taking personal responsibility while empowering them to report bullying.`,
        image: `programFeature2`
      },
      {
        heading: `Adol Ed`,
        content: `Addresses physical, emotional, and mental changes in adolescence, including infatuation, appropriate sexual knowledge, and building healthy relationships.`,
        image: `programFeature3`
      },
      {
        heading: `Online Smart`,
        content: `Educates children about online dangers, internet safety rules, and making informed decisions while being a part of the online world.`,
        image: `programFeature4`
      },
      {
        heading: `Power Of You`,
        content: `Children discover their strengths, build self-esteem, learn how to overcome challenges, and develop positive relationships with themselves.`,
        image: `programFeature5`
      },
      {
        heading: `Calm In The Storm`,
        content: `Helping children develop the skills to manage their anger in a healthy and productive way, fostering better communication and positive relationships.`,
        image: `programFeature6`
      },
      {
        heading: `Unlearning Bias`,
        content: `Promoting understanding, respect, and equality to create a safe and welcoming environment for all genders.`,
        image: `programFeature7`
      },
      {
        heading: `Teen Awake`,
        content: `Addresses adolescent issues such as anger management, self-esteem, identifying predators online and offline, and different types of bullying.`,
        image: `programFeature8`
      }
    ]
  },
  customPage: {
    heading: `Create Your Own Program`,
    content: `Looking for a personalised child safety program?`,
    rightParaHeading: `Looking for a personalised child safety program?`,
    rightParagraphs: [
      `With our customisable options, you can pick and choose from our wide range of programs to create a tailored package that meets your specific needs.`,
      `Make Hapchi a part of your school today and join our community to empower young minds.`,
      `Together, let's create a better, safer future for our children.`
    ]
  },
  budPage: {
    heading: `BUD`,
    headingSpan: `[3 to 8 Years]`,
    content: `Early childhood is the formative years of a child's life. Bud begins by laying a strong foundation for their life-long resilience and equipping them with body safety skills for appropriate cognitive, social, and emotional development in a fun and engaging way.`,
    features: [
      {
        heading: `Be Smart Be Safe`,
        content: `Teaches emotional intelligence, body safety skills, and strategies to keep children safe while promoting respect for personal space and awareness of potential risks.`,
        image: `programFeature16`
      },
      {
        heading: `Happy Body Happy Mind`,
        content: `Promotes body safety and teaches empathy, emotional intelligence, kindness, and personal responsibility while touching upon the concept of bullying.`,
        image: `programFeature17`
      },
      {
        heading: `Compassionate Buddy`,
        content: `Instilling empathy and kindness in children to help them build healthy relationships and take responsibility for their actions.`,
        image: `programFeature18`
      },
      {
        heading: `Screen Free Fun`,
        content: `Teaching healthy screen time habits and building a positive relationship with technology to maximise its benefits while minimising risks.`,
        image: `programFeature19`
      },
      {
        heading: `Together As One`,
        content: `Celebrating differences, promoting understanding and respect, and creating a welcoming environment for all.`,
        image: `programFeature20`
      }
    ]
  }
}

exports.ShopPageContent={
  heading:`Resources`,
  // content:`We at Hapchi believe that protecting children is shared responsibility. We are excited to offer partnership oppurtunities to schools, corporates, NGOs, and goverment organisations that share our vision of safeguarding children.`,
  content:`Explore our collection of empowering body safety series books designed to educate and empower children, equipping them with essential knowledge to stay safe.`,
  shopHeading:"Books"
}

exports.SafetyIQPageContent={
  heading:`Safety IQ`,
  // content:"We at Hapchi believe that protecting children is shared responsibility. We are excited to offer partnership oppurtunities to schools, corporates, NGOs, and goverment organisations that share our vision of safeguarding children."
  content:
  {
    parent: "Test and expand your safety knowledge with our interactive quiz designed for parents. Stay informed and enhance your ability to protect your children.",
    children: "Take our engaging quiz to assess your safety knowledge and learn valuable skills to stay safe in various situations. Challenge yourself and become a Safety IQ champion.",
    school_owners: "Test your safety knowledge and learn important skills to navigate potential risks within the school environment. Participate in our engaging quiz and become a safety-conscious school."
  },
  SchoolOwners:'school_owners'
}

// Customizable Area End
