import React from 'react';
import { Snackbar } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, any>((_theme) => ({
    snackBar: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: props => props.color
        },
        "& .MuiSnackbarContent-message": {
            fontWeight: props => props.fontWeight ? props.fontWeight : 500,
            margin: props => props.textCenter ? '0 auto' : "auto"
        }
    }
}))

interface CommonSnackBarProps {
    open: boolean;
    type: "error" | "success" | "warning" | "info"
    onClose: () => void;
    message: string;
    duration: number;
    verticalPosition: "top" | "bottom";
    horizontalPosition: "left" | "right" | "center";
    fontWeight?: number;
    textCenter?:boolean;
}

const CommonSnackBar = (props: CommonSnackBarProps) => {
    const renderColor = (type: string) => {
        switch (type) {
            case "info": return '#0056A3';
            case "success": return "#4caf50";
            case "error": return "#f44336";
            case "warning": return "#ff9800";
            default: return "white"
        }
    }
    const color = renderColor(props.type)
    const classes = useStyles({ ...props, color: color });
    return <Snackbar
        data-test-id='snackbar'
        className={classes.snackBar}
        anchorOrigin={{ vertical: props.verticalPosition, horizontal: props.horizontalPosition }}
        open={props.open}
        onClose={props.onClose}
        message={props.message}
        autoHideDuration={props.duration}
    />
}
export default CommonSnackBar;