import React from "react";
import { Grid, Box, Hidden, Typography } from "@material-ui/core";
const { visionImage } = require("../assets");
const configJSON = require("../config");
const visionText = configJSON.AboutUsPageContent.vision;
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "40px",
    fontWeight: 600,
    color: "#052B62"
  },
  content: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  imageBox: {
    maxWidth: "350px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px"
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px"
    }
  }
}));

const Vision = () => {
  const classes = useStyles();
  return (
    <Box sx={{ pt: "100px", width: "100%" }}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Hidden xsDown smDown>
            <Box className={classes.imageBox}>
              <img src={visionImage} width="100%" />
            </Box>
          </Hidden>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box>
            <Typography className={classes.heading}>
              {visionText.headerText}
            </Typography>
            <Typography variant="body1" className={classes.content}>
              {visionText.contentText}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Hidden mdUp>
            <Box className={classes.imageBox}>
              <img src={visionImage} width="100%" />
            </Box>
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Vision;
