import React from "react";
import { Box, Grid, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../../../components/src/Banner";
import CopyRightFooter from "../../../../../components/src/CopyRightFooter.web";
import FlotingButton from "../../../../../components/src/FlotingButton.web";
import Footer from "../../../../../components/src/Footer.web";

const { welcomeLeftBg } = require("../../assets");

interface ProgramPageContent {
  heading: string;
  content: string;
  rightParaHeading: string;
  rightParagraphs: string[];
}
interface Props {
  makeBoldParaNo: number;
  bannerImage: any;
  programContent: ProgramPageContent;
  contentImage: any;
}
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}

const useStyles = makeStyles(theme => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  welcomeBg: {
    backgroundImage: `url(${welcomeLeftBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -280px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  },
  breakPara: {
    marginBottom: "8px"
  },
  paragraph: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  boldParagraph: {
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#3B3B3B",
    opacity: "0.74"
  },
  parentImageBox: {
    transform: "translate(-20px, 0)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0, 0)",
      marginBottom: "30px"
    }
  },
  imageBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  heading: {
    fontSize: "32px",
    color: "#052B62",
    fontWeight: 600,
    marginBottom: "15px"
  }
}));
const ProgramLayout = (props: Props) => {
  const { makeBoldParaNo, bannerImage, contentImage, programContent } = props;
  const {
    heading,
    rightParagraphs,
    content,
    rightParaHeading
  } = programContent;
  const bannerText: Content = {
    headerText: heading,
    contentText: content,
    fontSize: "48px",
    color: "#FFFFFF",
    secondColor: "#FFFFFF",
    paragraphFontSize: "1rem"
  };
  const classes = useStyles();
  return (
    <>
      <Box className={classes.relativeParent}>
        <Banner content={bannerText} bannerImage={bannerImage} showBtn={true} />
        <Box sx={{ my: "100px" }} className={classes.welcomeBg}>
          <Container className={classes.containerStyle}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box className={classes.parentImageBox}>
                  <img
                    className={classes.imageBox}
                    src={contentImage}
                    width="525px"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h3" className={classes.heading}>
                    {rightParaHeading}
                  </Typography>
                  {rightParagraphs.map((paragraph: string, index: number) => (
                    <Box key={index} className={classes.breakPara}>
                      <Typography
                        variant="body1"
                        className={
                          index === makeBoldParaNo - 1
                            ? `${classes.boldParagraph}`
                            : `${classes.paragraph}`
                        }
                      >
                        {paragraph}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
          <FlotingButton ourPartner={false} />
        </Box>
      </Box>
      <Box >
        <Footer />
      </Box>
    </>
  );
};

export default ProgramLayout;
