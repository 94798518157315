import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import ForgotPassword, { Props } from "./ForgotPasswordController";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { successfully } from "./assets";
// Customizable Area End

export default class PasswordChanged extends ForgotPassword {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const {
      stepHeading,
      stepPara,
      btnRight,
      stepImage,
      formBox,
      stepBtn
    } = webStyles;
    return (
      <Box>
        <Box style={{ textAlign: "center" }}>
          <Typography style={stepHeading}>
            Password Changed Successfully{" "}
          </Typography>
          <Typography style={stepPara}>
            Congratulations! Your password has been successfully changed. Please
            login to access your account.
          </Typography>
        </Box>
        <Box style={stepImage}>
          <img src={successfully} />
        </Box>
        <Box style={formBox}>
          <Grid container alignItems="center" spacing={3}>
            <Grid container item md={12} justifyContent="flex-end">
              <Box style={btnRight}>
                <Button
                  data-test-id="btnGoTOLogin"
                  style={{ textTransform: "capitalize", ...stepBtn }}
                  fullWidth
                  component={Link}
                  to="/LoginForm"
                >
                  Back to Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

const webStyles = {
  stepHeading: {
    fontSize: "40px",
    color: "#0056A3",
    fontWeight: "bold"
  },
  stepPara: {
    color: "#0056A3"
  },
  btnRight: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  stepImage: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    padding: "30px 0"
  },
  formBox: {
    maxWidth: "450px",
    margin: "auto",
    paddingTop: "30px"
  },
  stepBtn: {
    backgroundColor: "#0056A3",
    color: "#fff",
    borderRadius: "10px",
    width: "175px"
  }
};
