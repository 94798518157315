import React from "react";
const configJSON = require("../config");
import StudentProgramLayout from "./GenericComponents/StudentProgramLayout.web";
const { programBlossomBannerImage } = require("../assets");

const blossomPageContent = configJSON.ProgramsPageContent.blossomPage;

const BlossomPage = () => {
  return (
    <StudentProgramLayout
      programContent={blossomPageContent}
      bannerImage={programBlossomBannerImage}
    />
  );
};

export default BlossomPage;
