import React, { useState } from 'react';
const {
  CometChatUI,
} = require('../../src/SeekHelpModule/CometChat/CometChatWorkspace/src');
import SeekHelpController, { Props } from './SeekHelpController';

interface UserDetails {
  isUserLogin:boolean;
  selectedUser: string[];
  isAdmin: boolean;
  notificationCall: (id: number) => void;
}
function SeekHelpSection(props: UserDetails) {
  let getuseDetails = localStorage.getItem("userDetails");
  let userDetails = getuseDetails ? JSON.parse(getuseDetails) : null;
  const [selectedRole, _] = useState(userDetails?.attributes?.role);
  const [ChatMode, setSelectedChatMode] = useState(
    userDetails?.attributes?.role
  );
  /* istanbul ignore next*/
  const selectUserForChat = (chatWith: string) => {
    if (chatWith === "Admin") {
      setSelectedChatMode(chatWith);
    } else {
      setSelectedChatMode(userDetails?.attributes.role);
    }
  };
  return (
    <>
    { props.isUserLogin && (
       <CometChatUI
       selectUserForChat={selectUserForChat}
       selectedUser={props.selectedUser}
       userRole={ChatMode}
       isAdmin={props.isAdmin}
       sendNotificationTotrainerOnMail={props.notificationCall}
     />
    ) }
    </>
  );
}

class SeekHelp extends SeekHelpController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <SeekHelpSection
        isUserLogin={this.state.isUserLogin}
        selectedUser={this.state.cometChatIds}
        isAdmin={this.state.isAdmin}
        notificationCall={this.sendNotificationTotrainerOnMail}
      />
    );
  }
}
export default SeekHelp;
