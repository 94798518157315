import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  Grid,
  Divider,
  IconButton,
  Theme,
  makeStyles
} from "@material-ui/core";
import CommonTextField from "../CommonComponents/CommonTextField.web";
import CommonSelect from "../CommonSelect.web";
import DatePicker from "../CommonComponents/DatePicker.web"
import LocationOn from "@material-ui/icons/LocationOn";
import moment from 'moment';
import { addFields, removeFieldsIcon, closeFile } from "../assets";

const configJSON = require("../config.js");

type Props = { children: React.ReactNode, isEnd: boolean };
const colors = ["yellow", "red", "blue", "purple", "indigo"] as const;
const colurNames = colors.map((c, id) => {
  return {
    id: id,
    value: c
  }
})
type ColorType = typeof colors[number];

interface Guest {
  id?: string;
  _destroy?: number;
  school_id: string;
  section_id: string;
  grade_id: string;
}

const useDividerStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "30%",
    marginLeft: ({ isEnd }) => isEnd ? 'auto' : 0
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 12,
    color: "#3B3B3B",
    textWrap: 'nowrap'
  },
  border: {
    borderBottom: "2px solid lightgray",
    width: "100%"
  },

}))

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      padding: "24px",
      backgroundColor: "#F0F4F6",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "52vw",
      width: "50vw",
      height: "790px",
      borderRadius: "12px",
      '@media(max-width:767px)': {
        maxWidth: 'none',
        width: '100%'
      }
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
    }
  },
  date: {
    marginTop: 8,
  },
  textField: {
    "&.MuiFormControl-root.MuiTextField-root": {
      [theme.breakpoints.down(600)]: {
        minWidth: "320px",
      },
      minWidth: "400px",
      width: "100%"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2"
    },
    "& .MuiOutlinedInput-root": {
      height: "64px",
      width: "100%",
      [theme.breakpoints.down(600)]: {
        minWidth: "320px",
        maxWidth: "320px",
      },
      minWidth: "400px",
      maxWidth: "400px"
    }
  },
  title: {
    color: '#0056A3',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'
  },
  topBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 40
  },
  closeIcon: {
    display: 'flex',
    marginLeft: 'auto'
  },
  saveBtn: {
    display: 'flex',
    marginLeft: 'auto',
    width: 178,
    height: 48,
    backgroundColor: "#0056A3",
    textTransform: 'none'
  },
  backdrop: {
    '&.MuiBackdrop-root': {
      backgroundColor: 'rgba(8, 77, 149, 0.481206)'
    }
  },
}));

const DividerWithText: React.FC<Props> = (props) => {
  const classes = useDividerStyles(props);
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{props.children}</span>
      <div className={classes.border} />
    </div>
  );
};

export const ProgramEditModal = (props: any) => {
  const classes = useStyles();

  const [guestList, setGuestList] = useState<Array<Guest>>([]);
  const [schoolList, setSchoolList] = useState<Array<any>>([]);
  const [eventTitle, setEventTitle] = useState<string>("")
  const [selectedColor, setSelectedColor] = useState<ColorType>(colors[2]);
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [selectedNotes, setSelectedNotes] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null);

  const user = JSON.parse(`${localStorage.getItem('userDetails')}`);
  const firstName = user?.attributes?.first_name;
  const lastName = user?.attributes?.last_name;

  const validateEventDetails = () => {
    if (!eventTitle || !selectedDate || !selectedEndTime || !selectedStartTime || !selectedLocation) {
      return true;
    }
    guestList.forEach((g, i) => {
      if (!g.school_id || !g.section_id) {
        return true;
      }
    })
    return false;
  }

  const editBody = () => {

    const guestObj: any = {};
    let emptyGuest: boolean = false;
    guestList.forEach((g, i) => {
      guestObj[i.toString()] = { ...g }
      if (!g.school_id || !g.section_id) {
        emptyGuest = true;
      }
    });

    if (emptyGuest) {
      alert(configJSON.ProgramSchedule.SchoolNameAndSectionNeeded);
      return;
    }

    const body = {
      name: eventTitle,
      date: moment(selectedDate).utcOffset("+05:30").format(),
      start_time: moment(selectedStartTime), // Format according to IST
      end_time: moment(selectedEndTime), // Format according to IST
      location: selectedLocation,
      notes: selectedNotes,
      program_guests_attributes: guestObj
    };
    if (props.addEvent) {
      const addBody = {
        ...body,
        mind_mastery_course_id: selectedCourse,
        program_colour_attributes: { colour: selectedColor }
      };
      props.addProgramSchedule(addBody);
    } else {
      props.editProgramSchedule({ ...body, id: props?.formDetails?.id });

      if (selectedColor !== props?.formDetails?.attributes?.colour?.name) {
        props.changeColor(props?.formDetails?.id, selectedColor);
      }
    }
  }

  const reset = () => {
    setEventTitle("");
    setSelectedColor(colors[2]);
    setSelectedCourse("");
    setSelectedDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setSelectedLocation("");
    setSelectedNotes("");
    setGuestList([{ school_id: '', section_id: '', grade_id: '' }]);
  }

  const removeGuest = () => {
    const _guestList = [...guestList];
    if (_guestList[_guestList.length - 1].id) {
      _guestList[_guestList.length - 1]._destroy = 1;
    } else {
      _guestList.pop();
    }
    setGuestList(_guestList);
  }


  const addGuest = () => {
    const _guestList = [...guestList];
    if (_guestList[_guestList.length - 1].id) {
      _guestList[_guestList.length - 1]._destroy = 0;
    }
    _guestList.push({ school_id: '', section_id: '', grade_id: '' });
    setGuestList(_guestList);
  }

  const changeSchool = (index: number, val: string) => {
    const _guestList = [...guestList];
    if (_guestList[_guestList.length - 1].id) {
      _guestList[_guestList.length - 1]._destroy = 0;
    }
    _guestList[index].school_id = val;
    setGuestList(_guestList);
  }

  const changeGrade = (index: number, val: string) => {
    const _guestList = [...guestList];
    _guestList[index].grade_id = val;
    setGuestList(_guestList);
  }

  const changeSection = (index: number, val: string) => {
    const _guestList = [...guestList];
    _guestList[index].section_id = val;
    setGuestList(_guestList);
  }

  useEffect(() => {
    if (props.addEvent) {
      reset();
    }
  }, [props.addEvent]);

  useEffect(() => {
    if (props?.formDetails?.attributes) {
      const formDetails = props?.formDetails?.attributes;
      const guests = formDetails['program_guests'].map((g: any) => {
        return {
          id: g.id,
          school_id: g.attributes.school_id,
          section_id: g.attributes.section_id,
          grade_id: g.attributes.grade_id,
          _destroy: 0
        }
      });

      const sTime = moment(formDetails['start_time']);
      const eTime = moment(formDetails['end_time']);
      const dateTime = moment(formDetails['date']);

      setGuestList(guests);
      setEventTitle(formDetails['name']);
      setSelectedColor(formDetails['colour']?.name);
      setSelectedDate(dateTime.toDate());
      setSelectedStartTime(sTime.toDate());
      setSelectedEndTime(eTime.toDate());
      setSelectedLocation(formDetails['location']);
      setSelectedNotes(formDetails['notes']);
    }
  }, [props?.formDetails?.attributes]);

  useEffect(() => {
    if (props?.allSchoolList?.length) {
      const _allSchoolList = props.allSchoolList.map((school: any) => {
        return {
          id: school.id,
          value: school.id,
          title: school.attributes.name,
          grades: school.attributes.grades?.map((grade: any) => {
            return {
              id: grade.id,
              value: grade.id,
              title: grade.name,
              sections: grade.sections.map((section: any) => {
                return {
                  id: section.id,
                  title: section.name,
                  value: section.id
                }
              })
            }
          })
        }
      });
      setSchoolList(_allSchoolList);
    }
  }, [props.allSchoolList]);

  return (
    <Dialog className={classes.dialog} open={props.open} BackdropProps={{ className: classes.backdrop }} onClose={props.handleCancel}>
      <Box>
        <Box className={classes.topBox}>
          <Typography className={classes.title}>{props.addEvent ? configJSON.ProgramSchedule.Add : configJSON.ProgramSchedule.Edit} Event</Typography>
          <IconButton
            className={classes.closeIcon}
            onClick={props.handleCancel}
          >
            <img src={closeFile} alt="Close Modal" />
          </IconButton>
        </Box>

        <Grid container>
          <Grid item xs={12}>
            <CommonTextField
              name={configJSON.ProgramSchedule.Title}
              placeholder={configJSON.ProgramSchedule.EnterTitle}
              value={eventTitle}
              handleChange={(e: any) => { setEventTitle(e.target.value) }}
            />
          </Grid>

          <Grid item xs={12}>
            {props.addEvent ?
              <CommonSelect
                className={classes.textField}
                variant="outlined"
                placeholder={configJSON.ProgramSchedule.EnterCourse}
                menuList={props.allCourseList}
                value={selectedCourse}
                handleChange={(e: any) => { setSelectedCourse(e.target.value) }}
              />
              :
              <CommonTextField
                name={configJSON.ProgramSchedule.Course}
                placeholder={configJSON.ProgramSchedule.EnterCourse}
                value={props?.formDetails?.attributes?.course_titile || ""}
                disabled
              />
            }
          </Grid>
          <Grid container item xs={12} spacing={1} style={{ marginTop: props.addEvent ? 24 : 0 }}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                placeholder={configJSON.ProgramSchedule.SelectDate}
                type={configJSON.ProgramSchedule.TypeDate}
                date={selectedDate}
                minDate={new Date()}
                handleDate={(date: any) => { setSelectedDate(date) }}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <DatePicker
                placeholder={configJSON.ProgramSchedule.StartTime}
                type={configJSON.ProgramSchedule.TypeTime}
                minDate={new Date()}
                date={selectedStartTime}
                handleDate={(date: any) => { setSelectedStartTime(date) }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DatePicker
                placeholder={configJSON.ProgramSchedule.EndTime}
                type={configJSON.ProgramSchedule.TypeTime}
                minDate={selectedStartTime}
                date={selectedEndTime}
                handleDate={(date: any) => { setSelectedEndTime(date) }}
              />
            </Grid>
          </Grid>
          <DividerWithText isEnd={false}>{props.addEvent ? configJSON.ProgramSchedule.Add : configJSON.ProgramSchedule.Edit} Guests</DividerWithText>
          <Grid container xs={12} spacing={1}>
            {guestList.filter((g: any) => {
              return g?._destroy !== 1
            }).map((e: any, i: number) => {
              return (
                <Grid container key={i} spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={12} sm={4}>
                    <CommonSelect
                      variant="outlined"
                      placeholder={configJSON.ProgramSchedule.School}
                      value={guestList[i]?.school_id}
                      handleChange={(e1: any) => { changeSchool(i, e1.target.value) }}
                      menuList={schoolList}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CommonSelect
                      variant="outlined"
                      placeholder={configJSON.ProgramSchedule.Grade}
                      value={guestList[i]?.grade_id}
                      handleChange={(e1: any) => { changeGrade(i, e1.target.value) }}
                      disabled={!guestList[i]?.school_id}
                      menuList={schoolList.find((x: any) => x.id == guestList[i]?.school_id)?.grades}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CommonSelect
                      variant="outlined"
                      placeholder={configJSON.ProgramSchedule.Section}
                      value={guestList[i]?.section_id}
                      handleChange={(e1: any) => { changeSection(i, e1.target.value) }}
                      disabled={!guestList[i]?.grade_id}
                      menuList={schoolList.find((x: any) => x.id == guestList[i]?.school_id)?.grades.find((e: any) => e.id == guestList[i]?.grade_id)?.sections}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>

          <DividerWithText isEnd={true}>
            <>
              {guestList.filter((g: any) => {
                return g?._destroy !== 1
              }).length > 1 && <Button onClick={() => removeGuest()} data-test-id="add-btn">
                  <img src={removeFieldsIcon} alt="remove icon" />
                </Button>}
              <Button onClick={() => addGuest()} data-test-id="remove-btn">
                <img src={addFields} alt="add icon" />
              </Button>
            </>
          </DividerWithText>

          <Grid item xs={12}>
            <CommonTextField
              name={configJSON.ProgramSchedule.Location}
              value={selectedLocation}
              placeholder={configJSON.ProgramSchedule.EnterLocation}
              handleChange={(e: any) => { setSelectedLocation(e.target.value) }}
              endAdornmentData={<LocationOn />}
            />
          </Grid>

          <Grid item xs={12}>
            <CommonTextField
              name={configJSON.ProgramSchedule.Notes}
              placeholder={configJSON.ProgramSchedule.AddNotes}
              value={selectedNotes}
              handleChange={(e: any) => { setSelectedNotes(e.target.value) }}
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <CommonTextField
                name={configJSON.ProgramSchedule.Organizer}
                placeholder={configJSON.ProgramSchedule.OrganizerPh}
                value={firstName + " " + lastName}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <CommonSelect
                placeholder={configJSON.ProgramSchedule.Color}
                menuList={colurNames}
                value={selectedColor}
                handleChange={(e: any) => { setSelectedColor(e.target.value) }}
                isColor
              />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 20, marginBottom: 20, width: "100%", backgroundColor: "#0056A3", opacity: 0.5825892686843872 }} />
          <Button onClick={() => editBody()} data-test-id="submit-btn" disabled={validateEventDetails()} className={classes.saveBtn} variant='contained' color='primary'>{configJSON.ButtonTexts.Save}</Button>
        </Grid>
      </Box>
    </Dialog>
  );
};