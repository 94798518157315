import React from 'react'
import { Typography, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme, any>((theme) => ({
    fieldLabel: {
        color: props => props.labelFontColor ? props.labelFontColor : '#3B3B3B',
        fontFamily: 'Poppins',
        fontWeight: props => props.labelFontWeight ? props.labelFontWeight : 600,
        marginBottom: '7px',
        [theme.breakpoints.up("sm")]: {
            fontSize: props => props.labelFontSize ? props.labelFontSize : '20px',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        }
    },
    inputField: {
        '&.MuiFormControl-root': {
            minWidth: props => props.halfWidth ? '40%' : '100%',
            marginBottom: props => props.mb ? props.mb : '24px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        '& .MuiOutlinedInput-input': {
            padding: props => props.multiline ? '0 7px' : '0 14px 0 18px',
            height: '100%'
        },
        "& .MuiOutlinedInput-root": {
            width: "100%",
            height: props => props.multiline ? 'auto' : "56px",
            backgroundColor: 'white',
            borderRadius: '8px',
            marginLeft: 'auto',
            color: 'black',
            fontSize: '14px',
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            paddingRight: '2px'
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: '0',
            marginTop: '5px'
        }
    },
}))

interface Props {
    labelFontSize?: any;
    labelFontWeight?:number;
    multiline?: boolean;
    minRows?: number;
    label?: any;
    disabled?: boolean;
    name?: string;
    value?: any;
    handleChange?: (e: any) => void;
    error?: boolean;
    helperText?: any;
    placeholder?: any;
    type?: string;
    endAdornmentData?: any;
    startAdornment?: any;
    labelFontColor?: string;
    inputProps?: any;
    mb?: string;
    halfWidth?: boolean;
}

const CommonTextField = (props: Props) => {
    const classes = useStyles(props)
    return <>
        {props.label && <Typography className={classes.fieldLabel}>{props.label}</Typography>}
        <TextField
            disabled={props.disabled}
            className={classes.inputField}
            onChange={props.handleChange}
            multiline={props.multiline}
            minRows={props.minRows}
            name={props.name}
            variant='outlined'
            data-test-id='input'
            type={props.type ? props.type : 'text'}
            value={props.value}
            error={props.error}
            helperText={props.helperText}
            placeholder={props.placeholder}
            inputProps={props.inputProps}
            InputProps={{
                startAdornment: props.startAdornment ?
                    <InputAdornment position="start">
                        {props.startAdornment}
                    </InputAdornment> : null,
                endAdornment: (
                    <InputAdornment position="end">
                        {props.endAdornmentData}
                    </InputAdornment>
                ),
            }}
        />
    </>
}
export default CommonTextField;