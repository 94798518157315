import MindMasteryController from "./MindMasteryController.web";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { backArrow, clockIcon, nextVideo, profile } from "../assets";
import React from "react";
import ReactDOM from "react-dom";
import Plyr from "plyr";
import SidebarLayout from "../SidebarLayout.web";
import ReadMore from "../../../../components/src/ReadMore";
import Loader from "../../../../components/src/Loader.web";
import Slider from "react-slick";

const configJSON = require('../config')

const useStyles = makeStyles(() => ({
  courseDetailsText: {
    width: "430px"
  },
  cardWrapper: {
    display: "flex",
    alignItems: "center",
    maxWidth: "321px",
    width: "100%",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    border: "0.2px solid #e3e3e3",
    backgroundColor: 'white',
    "@media(max-width:767px)": {
      marginBottom: "12px"
    }
  },
  cardImgWrapper: {
    height: "90px",
    width: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:575px)": {
      height: "80px",
      weight: "80px"
    }
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    "@media(max-width:575px)": {
      fontSize: '16px',
      lineHeight: '22px'
    }
  },
  cardSubtitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    '@media(max-width:575px)': {
      fontSize: "13px",
    }
  },
  videoCardsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumnGap: '20px',
    justifyItems: 'center',
    "@media(max-width:1200px)": {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    "@media(max-width:1100px)": {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    "@media(max-width:880px)": {
      gridTemplateColumns: '1fr 1fr ',
    },
    "@media(max-width:600px)": {
      gridTemplateColumns: '1fr',
    }
  },
  sessionDetailsCardWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },

  videoTitle: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "30px",
    color: "#0056A3",
    marginBottom: "20px",
    fontFamily: "Poppins",
    "@media(max-width:767px)": {
      fontSize: "18px",
      lineHeight: "30px"
    }
  },
  videoWrapper: {
    minHeight: "444px",
    width: "100%",
    "@media(max-width:767px)": {
      minHeight: "auto"
    }
  },

  cardMedia: {
    "&.MuiCardMedia-root": {
      height: "152px",
      cursor: "pointer"
    }
  },
  videoCard: {
    "&.MuiCard-root": {
      width: "256px",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3",
      marginBottom: "30px"
    },
    "&.MuiPaper-elevation1": {
      boxShadow: "none"
    }
  },
  videoCardTitle: {
    color: "#0056A3",
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    cursor: "pointer",
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp': 1,
    '-webkitBoxOrient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    '&:hover': {
      '-webkitBoxOrient': 'inherit',
      wordBreak: 'break-word',
    },
    '@media(max-width:767px)': {
      fontSize: "16px",
    }
  },
  sessionName: {
    marginBottom: "10px",
    color: '#3b3b3b8a',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: "Poppins",
    fontWeight: 600,
    overflow: 'hidden',
    cursor: "pointer",
    display: '-webkit-box',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    '&:hover': {
      '-webkitBoxOrient': 'inherit',
      wordBreak: 'break-word',
    },
    '@media(max-width:767px)': {
      fontSize: "12px",
    }
  },
  cardDescription: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    minHeight: '60px',
    '@media(max-width:767px)': {
      fontSize: "13px",
    }
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "16px 16px 20px 16px"
    }
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    borderRadius: "12px 12px 0px 0px",
    height: "80px"
  },
  backButton: {
    padding: "0",
    background: "#ffffff7d",
    height: "48px",
    width: "48px !important",
    borderRadius: "8px",
    margin: "0 24px",
    minWidth: "auto",
    "&:hover": {
      background: "#b1b1b1"
    },
    "@media(max-width:767px)": {
      height: "40px",
      maxWidth: "40px !important"
    }
  },
  backArrow: {
    height: "24px",
    width: "24px"
  },
  sessionDetailsCard: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    maxWidth: "233px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "0.2px solid #e3e3e3",
    backgroundColor: 'white',
    "@media(max-width:575px)": {
      maxWidth: "321px"
    }
  },
  sessionDetailsCardImgWrapper: {
    height: "80px",
    width: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:575px)": {
      height: "70px",
      width: "70px"
    }
  },
  sessionDetailsCardText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    '@media(max-width:575px)': {
      fontSize: "14px",
    }
  },
  educatorPic: {
    borderRadius: "50%",
    width: "48px",
    height: "48px"
  }
}));

export const TopBar = (props: any) => {
  const classes = useStyles();
  const handleClick = () => {
    let index = Number(localStorage.getItem(configJSON.MindMasteryTexts.VideoIndex))
    if (index > 0) {
      localStorage.setItem(configJSON.MindMasteryTexts.VideoIndex, `${index - 1}`)
    }
    props.goBack();
  }
  return (
    <Box className={classes.topBar}>
      <Button data-test-id='back' className={classes.backButton} onClick={handleClick}>
        <img className={classes.backArrow} src={backArrow} alt="backIcon" />
      </Button>
      <Typography className={"plyr__videoTitleTextWrapper"}>
        {props.title}
      </Typography>
    </Box>
  );
};

export const handleNextButtonClick = (nextVideos: any, courseId: number, goTo: any) => {

  let index = Number(localStorage.getItem(configJSON.MindMasteryTexts.VideoIndex));

  index = (index + 1) % nextVideos.length;

  localStorage.setItem(configJSON.MindMasteryTexts.VideoIndex, `${index}`)

  const nextVideoId = Number(nextVideos[index].id);
  const nextVideoSessionId = Number(nextVideos[index].attributes.session_id);
  localStorage.setItem(configJSON.MindMasteryTexts.VideoPlyrVideoId, `${nextVideoId}`);
  goTo(configJSON.MindMasteryTexts.VideoPageMindMastery, {
    sessionId: nextVideoSessionId,
    videoId: nextVideoId,
    courseId: courseId
  });
};

export const addNextVideoButton = (nextVideos: any, courseId: number, videoId: any, goTo: any) => {
  if (nextVideos?.length <= 1) return;

  if (!document.querySelector(".plyr__next-video")) {
    let controlsElement = document.querySelector(
      ".plyr__controls"
    ) as HTMLElement;
    // Create a new button element
    let nextVideoButton = document.createElement("button");
    // Set the button's attributes and content
    nextVideoButton.classList.add("plyr__next-video");
    nextVideoButton.setAttribute("type", "button");
    // creating the image element to show the icon
    const img = document.createElement("img");
    img.src = nextVideo;
    nextVideoButton.appendChild(img);
    // Append the button to the plyr__controls element
    controlsElement.appendChild(nextVideoButton);
    if (!localStorage.getItem(configJSON.MindMasteryTexts.VideoIndex)) {
      let currentVideoIndex = nextVideos.findIndex((element: any) => element.id === `${videoId}`);
      localStorage.setItem(configJSON.MindMasteryTexts.VideoIndex, `${currentVideoIndex}`)
    }
  }

  const nextButton = document.querySelector(".plyr__next-video") as HTMLElement;

  const callOnClickFunction = () => {
    handleNextButtonClick(nextVideos, courseId, goTo)
    // Remove the event listener to prevent multiple bindings
    nextButton.removeEventListener("click", callOnClickFunction);
  }
  nextButton.addEventListener("click", callOnClickFunction);
};

interface CourseInstructorCardProps {
  trainer: {
    name: string;
    profile_photo_url?: string;
  };
}

export const CourseInstructorCard = (props: CourseInstructorCardProps) => {
  const classes = useStyles();
  const { trainer } = props;
  const profileImage = trainer?.profile_photo_url ? trainer.profile_photo_url : profile
  return (
    <Box className={classes.cardWrapper} data-test-id='box' >
      <Box className={classes.cardImgWrapper}>
        <img
          className={classes.educatorPic}
          src={profileImage}
          alt="educator_pic"
        />
      </Box>
      <Box className={classes.cardContentWrapper}>
        <Typography className={classes.cardTitle}>{configJSON.MindMasteryTexts.Trainer}</Typography>
        <Typography className={classes.cardSubtitle}>
          {trainer?.name}
        </Typography>
      </Box>
    </Box>
  );
};

interface SessionCardProps {
  image: string;
  cardTitle: string;
  cardDescription: string;
  sessionId: number;
  courseId: number;
  videoId: number;
  sessionName: string;
  nextVideos: Array<{ id: number }>;
  goTo: (path: string, params?: any) => void;
}


export const SessionCard = (props: SessionCardProps) => {
  const classes = useStyles();
  const {
    image,
    cardTitle,
    cardDescription,
    sessionId,
    courseId,
    videoId,
    sessionName,
    nextVideos
  } = props;
  const handleCardClick = () => {
    window.scrollTo(0, 0);
    let currentVideoIndex = nextVideos.findIndex((element: any) => element.id === `${videoId}`);
    localStorage.setItem(configJSON.MindMasteryTexts.VideoIndex, `${currentVideoIndex - 1}`)
    localStorage.setItem(configJSON.MindMasteryTexts.VideoPlyrVideoId, `${videoId}`)
    props.goTo(configJSON.MindMasteryTexts.VideoPageMindMastery, {
      sessionId: sessionId,
      videoId: videoId,
      courseId: courseId
    });
  };
  return (
    <Card className={classes.videoCard} data-test-id='box'>
      <CardMedia
        data-test-id="nextVideoImage"
        onClick={handleCardClick}
        className={classes.cardMedia}
        image={image}
      />
      <CardContent className={classes.cardContent}>
        <Typography
          onClick={handleCardClick}
          className={classes.videoCardTitle}
        >
          {cardTitle}
        </Typography>
        <Typography className={classes.sessionName}>{sessionName}</Typography>
        <Typography variant="body2" className={classes.cardDescription}>
          {cardDescription.length < 50 ? (
            cardDescription
          ) : (
            <ReadMore sliceLength={50} textColor="#3B3B3B">
              {cardDescription}
            </ReadMore>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

interface VideoCardsProps {
  nextVideos: Array<{
    id: number;
    type:string;
    attributes: {
      thumbnail_url: string;
      title: string;
      session_name: string;
      description: string;
      session_id: number;
    };
  }>;
  goTo: (path: string, params?: any) => void;
  courseId: number;
  currentVideoId: number;
}


export const VideoCards = (props: VideoCardsProps) => {
  const classes = useStyles();
  const { nextVideos, goTo, courseId, currentVideoId } = props;
  const filteredVideos = nextVideos?.filter((element: any) => element.id !== currentVideoId);
  const VideoCards = filteredVideos?.map((element: any) => {
    const { attributes, id } = element;
    const sessionCardProps = {
      key: id,
      nextVideos: nextVideos,
      image: attributes?.thumbnail_url,
      cardTitle: attributes?.title,
      sessionName: attributes?.session_name,
      cardDescription: attributes?.description,
      goTo: goTo,
      videoId: id,
      sessionId: attributes?.session_id,
      courseId: courseId,
    }
    return (
      <SessionCard {...sessionCardProps} />
    );
  })

  return (<Box className={'nextVideosSliderWrapper'} data-test-id='box'>
    {nextVideos?.length < 5 ?
      <Box className={classes.videoCardsWrapper}>
        {VideoCards}
      </Box> :
      <Slider {...configJSON.sliderSettings}>
        {VideoCards}
      </Slider>}
  </Box>)
};

export const VideoElement = (props: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.videoWrapper} data-test-id='box'>
      <video ref={props.videoRef} />
    </Box>
  );
};

interface CourseDurationCardProps {
  duration: any;
}

export const CourseDurationCard = (props: CourseDurationCardProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.sessionDetailsCard} data-test-id='box'>
      <Box className={classes.sessionDetailsCardImgWrapper}>
        <img src={clockIcon} alt="time" />
      </Box>
      <Typography className={classes.sessionDetailsCardText}>
        {configJSON.formatDuration(props.duration)}
      </Typography>
    </Box>
  );
};

export const PageHeading = (props: any) => {
  const classes = useStyles();
  return <Typography data-test-id='box' className={classes.videoTitle}>{props.text}</Typography>;
};

export default class VideoView extends MindMasteryController {
  videoRef: any;
  player: Plyr = new Plyr("test");
  constructor(props: any) {
    super(props);
    this.videoRef = React.createRef();
  }

  async LoadVideoPlayer() {
    window.scrollTo(0, 0);
    const sessionId = Number(this.props.navigation.getParam(configJSON.MindMasteryTexts.SessionId));
    const videoId = Number(this.props.navigation.getParam(configJSON.MindMasteryTexts.VideoId));
    if (!localStorage.getItem(configJSON.MindMasteryTexts.VideoPlyrVideoId)) {
      localStorage.setItem(configJSON.MindMasteryTexts.VideoPlyrVideoId, `${videoId}`)
    }
    const data: any = await this.getVideoDetails(sessionId, videoId);
    const attributes = data?.attributes;
    const options = {
      controls: configJSON.MindMasteryTexts.Controls,
      speed: { selected: 1, options: configJSON.MindMasteryTexts.PlayBackSpeed },
      autoplay: true
    };
    this.player = new Plyr(this.videoRef.current, options);

    this.player.source = {
      type: configJSON.MindMasteryTexts.Video,
      sources: [
        {
          src: data?.attributes.video_url,
          size: 1080
        }
      ]
    };
    const nextVideos = attributes?.next_videos;
    const courseId = Number(this.props.navigation.getParam(configJSON.MindMasteryTexts.CourseId));
    const currentVideoId = Number(localStorage.getItem(configJSON.MindMasteryTexts.VideoPlyrVideoId));

    if (videoId === currentVideoId) {
      addNextVideoButton(nextVideos, courseId, videoId, this.goTo);
    }
    let fullUIElement = document.querySelector(".plyr--full-ui") as HTMLElement;
    let fullScreenBtn = document.querySelector(
      'button[data-plyr="fullscreen"]'
    ) as HTMLElement;

    if (!document.querySelector(".plyr__videoTitleVisible")) {
      let videoTitle = document.createElement("span");
      fullUIElement.appendChild(videoTitle);
      videoTitle.classList.add("plyr__videoTitleVisible");
      ReactDOM.render(
        <TopBar
          title={data?.attributes.title}
          goBack={this.goBack}
        />,
        videoTitle
      );
      fullScreenBtn.addEventListener("click", function () {
        // toggle the aria-pressed attribute of the button
        if (fullScreenBtn.getAttribute("aria-pressed") === "true") {
          videoTitle.classList.add("plyr__videoTitleVisible");
          videoTitle.classList.remove("plyr__videoTitle");
        } else {
          videoTitle.classList.add("plyr__videoTitle");
          videoTitle.classList.remove("plyr__videoTitleVisible");
        }
      });
    } else {
      const videoTitle = document.querySelector(
        ".plyr__videoTitleTextWrapper"
      ) as HTMLElement;
      videoTitle.innerText = data?.attributes.title;
      const video = document.querySelector(configJSON.MindMasteryTexts.Video) as HTMLElement;
      video.setAttribute(
        "src",
        data?.attributes.video_url
      );
    }
  }

  async componentDidMount() {
    await this.LoadVideoPlayer();
    const videoElement = document.querySelector(configJSON.MindMasteryTexts.Video) as HTMLVideoElement;
    videoElement.addEventListener('loadedmetadata', () => {
      this.setState({ videoPlayerDuration: videoElement.duration })
    });
  }

  async componentDidUpdate(prevProps: any) {
    if (
      this.props.navigation.getParam(configJSON.MindMasteryTexts.VideoId) !==
      prevProps.navigation.getParam(configJSON.MindMasteryTexts.VideoId)
    ) {
      await this.LoadVideoPlayer();
    }
  }
  async componentWillUnmount(): Promise<void> {
    this.player.destroy();
    localStorage.removeItem(configJSON.MindMasteryTexts.VideoIndex);
    localStorage.removeItem(configJSON.MindMasteryTexts.VideoPlyrVideoId);
  }

  render() {
    const videoViewData = this.state.videoViewPageData;
    const courseId = Number(this.props.navigation.getParam(configJSON.MindMasteryTexts.CourseId));
    const currentVideoId = this.props.navigation.getParam(configJSON.MindMasteryTexts.VideoId)
    const attributes = videoViewData?.attributes;
    const isNextVideoExist = attributes?.next_videos?.length
    return (
      <SidebarLayout
        {...this.props}
        hideSidebar={true}
        courseTitle={attributes?.course_title}
      >
        {this.state.isLoading ? (
          <Loader loading={this.state.isLoading} />
        ) : null}
        <VideoElement videoRef={this.videoRef} />
        <Box style={webStyles.videoPageContentWrapper}>
          <PageHeading text={`${attributes?.title} | ${attributes?.session_name}`} />
          <Box style={webStyles.videoInfoWrapper}>
            <CourseInstructorCard trainer={attributes?.trainer} />
            <CourseDurationCard
              duration={this.state.videoPlayerDuration}
            />
          </Box>
          {isNextVideoExist !== 1 && <PageHeading text={configJSON.MindMasteryTexts.NextVideos} />}
          <VideoCards
            nextVideos={attributes?.next_videos}
            goTo={this.goTo}
            courseId={courseId}
            currentVideoId={currentVideoId}
          />
        </Box>
      </SidebarLayout>
    );
  }
}

const webStyles = {
  videoPageContentWrapper: {
    padding: "24px 32px 24px 32px",
    backgroundColor: 'white',
  },
  videoInfoWrapper: {
    maxWidth: "580px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap" as const,
    marginBottom: "40px"
  }
};
