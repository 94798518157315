import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { Box } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import { apiCall } from '../../../../framework/src/apicall';
import { configJSON } from './SeekHelpController';
import { dateShow } from './PinModal.web';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  listItem: {
    display: "flex",
  },
  listItemText: {
    marginLeft: "4.6rem",
  },
  content: {
    gap: "20px",
    display: "flex",
  },
  date: {
    color: "#7D7D7D",
  },
  pinit: {
    padding: "0.5rem 2rem",
    border: "1px solid #0056A3",
    color: "white",
    background: "#0056A3",
    borderRadius: "5px",
    cursor: "pointer",
  },
  userName: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  header: {
    textAlign: "center",
  },
  unpinText: {
    fontWeight: "bold",
    color: "#0058AA",
    fontSize: "25px",
  },
  queText: {
    color: "#7D7D7D",
    marginTop: "10px",
  },

  listItemBox: {
    borderRadius: "5px",
    boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
    margin: "0rem 2rem 2rem 2rem",
    padding: "0.5rem 0rem 1rem 0rem",
  },

  cancelbtn: {
    padding: "0.5rem 2rem",
    border: "1px solid #0058AA",
    color: "#0058AA",
    borderRadius: "5px",
    cursor: "pointer",
  },

  buttons: {
    gap: "20px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },

  removeit: {
    padding: "0.5rem 2rem",
    border: "1px solid #EB3359",
    cursor: "pointer",
    color: "white",
    borderRadius: "5px",
    background: "#EB3359",
  },
});

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  unpinit: string;
  removeMessage: any;
  onPinIt: () => void;
}

function UnpinModal(props: SimpleDialogProps) {
  const classes = useStyles();
  const { onClose, open, unpinit, removeMessage, onPinIt } = props;
  const selectedData = localStorage.getItem("currentPinMessage");
  const getSelectedData = selectedData ? JSON.parse(selectedData) : null;
  const [selectedMessage] = useState(getSelectedData);

  const handleCloseUnpinned = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleRemove = async () => {
    let user_id = "";
    let role: any = "";
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let admin_id = params.get(configJSON.SeekHelp.AdminId);
    if (admin_id) {
      user_id = admin_id.replace("admin_", "");
      role = "super_admin";
    } else {
      user_id = JSON.parse(`${localStorage.getItem(configJSON.SeekHelp.UserDetails)}`).id;
      role = localStorage.getItem(configJSON.SeekHelp.Role);
    }
    const header = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("user_token"),
    };
    const body = {
      pin_id: removeMessage.pin_id,
      role,
      user_id,
    };

    await apiCall(
      "post",
      "bx_block_chat/chat_pinned_messages/unpin",
      header,
      JSON.stringify(body)
    );

    onClose();
  };

  const renderAvatar = () => {
    const avatarSource =
      unpinit === "unpinIt"
        ? removeMessage?.data?.avatar || ""
        : selectedMessage?.data?.entities?.sender?.entity?.avatar || "";

    const avatarAlt =
      unpinit === "unpinIt"
        ? removeMessage?.data?.sender || ""
        : selectedMessage?.data?.entities?.sender?.entity?.name || "";

    return (
      <Avatar className={classes.avatar}>
        <img src={avatarSource} alt={avatarAlt} />
      </Avatar>
    );
  };

  const renderUserName = () => {
    return (
      <Box className={classes.userName}>
        {unpinit === "unpinIt"
          ? removeMessage?.data?.sender || ""
          : selectedMessage?.data?.entities?.sender?.entity?.name || ""}
      </Box>
    );
  };

  const renderDate = () => {
    return (
      <Box className={classes.date}>
        {dateShow(
          unpinit === "unpinIt"
            ? removeMessage?.data?.date
            : selectedMessage?.sentAt
        )}
      </Box>
    );
  };

  const renderContent = () => {
    if (unpinit === "unpinIt") {
      return removeMessage
        ? removeMessage.content || removeMessage.fileName
        : "";
    } else {
      if (selectedMessage) {
        if (selectedMessage.type === "text") {
          return selectedMessage.text || "";
        } else if (
          selectedMessage?.data?.attachments &&
          selectedMessage?.data?.attachments.length > 0
        ) {
          const attachment = selectedMessage?.data?.attachments[0];
          return <a href={attachment.url}>{attachment.name}</a>;
        }
      }
      return "";
    }
  };

  return (
    <Dialog
      data-testid="onclose"
      onClose={handleCloseUnpinned}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.header}>
        <Box data-testid="unpinit" className={classes.unpinText}>
          {unpinit === configJSON.SeekHelp.Unpinit ? configJSON.SeekHelp.UnpinIt : configJSON.SeekHelp.PinIt}
        </Box>
        <Box className={classes.queText}>
          {unpinit === configJSON.SeekHelp.Unpinit
            ? configJSON.SeekHelp.AreYouSure
            : configJSON.SeekHelp.DoYouWantToPin}
        </Box>
      </DialogTitle>
      <List>
        <Box className={classes.listItemBox}>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
            <Box className={classes.content}>
              {renderUserName()}
              {renderDate()}
            </Box>
          </ListItem>
          <ListItemText
            className={classes.listItemText}
            primary={renderContent()}
          />
        </Box>
        <Box className={classes.buttons}>
          <Box
            data-testid="cancel"
            className={classes.cancelbtn}
            onClick={handleCancel}
          >
            {configJSON.SeekHelp.Cancel}
          </Box>
          <Box
            data-testid="remove"
            className={unpinit === configJSON.SeekHelp.Unpinit ? classes.removeit : classes.pinit}
          >
            {unpinit === configJSON.SeekHelp.Unpinit ? (
              <div data-testid="handleremove" onClick={handleRemove}>
                {configJSON.SeekHelp.RemoveIt}
              </div>
            ) : (
              <div
                data-testid="updateispinned"
                onClick={() => {
                  onPinIt();
                  onClose();
                }}
              >
                {configJSON.SeekHelp.PinIt}
              </div>
            )}
          </Box>
        </Box>
      </List>
    </Dialog>
  );
}

export default UnpinModal;
