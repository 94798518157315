import React from 'react'
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnalysisReportController from "./AnalysisReportController.web";
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CommonSelect from '../../CommonComponents/CommonSelect.web';
import InfoModal from '../../CommonComponents/InformationModal.web';

const configJSON = require("../../config.js");

const useStyles = makeStyles({
    label: {
        color: '#3B3B3B',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px',
        '@media(max-width:600px)': {
            fontSize: "14px"
        }
    },
    required: {
        color: "red"
    },
    heading: {
        background: "#0056A3",
        borderRadius: "8px",
        fontSize: '18px',
        color: "#fff",
        fontWeight: "bold",
        padding: '11px 10px 11px 24px',
        marginBottom: '32px'
    },
    modalDialog: {
        "& div": {
            "& .MuiDialog-paperWidthSm": {
                width: "49.65vw !important"
            }
        }
    }
});

export const AddForm = (props: any) => {
    const classes = useStyles();

    const badgeDropdownList = props.mindId?.map((element: any) => {
        return {
            id: element,
            title: element,
            value: element
        }
    })
    const schooleDropdownList = props.schoolId?.map((element: any) => {
        return {
            id: element,
            title: element,
            value: element
        }
    })
    return (

        <Box p={4}>
            <Formik
                initialValues={{
                    programName: "",
                    schoolName: "",
                    academicYear: "",
                    Executive: "",
                    introduction: "",
                    keyConcerns: "",
                    focusAreas: "",
                    priority: "",
                    skillset: "",
                    Ahead01: "",
                    Ahead02: "",
                    Ahead03: "",
                    Ahead04: "",
                    Conclusion: "",
                    summary1: "",
                    summary2: "",
                }}
                validationSchema={Yup.object().shape({
                    academicYear: Yup.string().required(configJSON.AnalysisReport.AcademicYearRequired),
                    introduction: Yup.string().required(configJSON.AnalysisReport.IntroductionRequired),
                    Executive: Yup.string().required(configJSON.AnalysisReport.ExecutiveSummaryRequired),
                    keyConcerns: Yup.string().required(configJSON.AnalysisReport.KeyConcernsRequired),
                    focusAreas: Yup.string().required(configJSON.AnalysisReport.FocusAreasRequired),
                    priority: Yup.string().required(configJSON.AnalysisReport.PriorityRequired),
                    skillset: Yup.string().required(configJSON.AnalysisReport.SkillsetRequired),
                    Conclusion: Yup.string().required(configJSON.AnalysisReport.ConclusionRequired),
                    Ahead01: Yup.string().required(configJSON.AnalysisReport.TextRequired),
                    Ahead02: Yup.string().required(configJSON.AnalysisReport.TextRequired),
                    Ahead03: Yup.string().required(configJSON.AnalysisReport.TextRequired),
                    Ahead04: Yup.string().required(configJSON.AnalysisReport.TextRequired),
                    summary1: Yup.string().required(configJSON.AnalysisReport.SummaryRequired),
                    summary2: Yup.string().required(configJSON.AnalysisReport.SummaryRequired),
                })}

                onSubmit={(values) => {
                    props.OnCreate(values)
                }}
            >
                <Form>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CommonSelect
                                label={configJSON.AnalysisReport.ProgramNameLabel}
                                menuList={badgeDropdownList}
                                name={configJSON.AnalysisReport.ProgramName}
                                handleChange={props.handleFilterChange}
                                value={props.courseFilter}
                                placeholder={configJSON.AnalysisReport.EnterProgramName}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <CommonSelect
                                        placeholder={configJSON.AnalysisReport.EnterSchoolName}
                                        label={configJSON.AnalysisReport.SchoolNameLabel}
                                        menuList={schooleDropdownList}
                                        name={configJSON.AnalysisReport.SchoolName}
                                        handleChange={props.handleSchoolChange}
                                        value={props.schoolFilter}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <label className={classes.label} htmlFor="academicYear">{configJSON.AnalysisReport.AcademicYearLabel}</label>
                                        <Field
                                            id="academicYear"
                                            type="text"
                                            name={configJSON.AnalysisReport.AcademicYear}
                                            variant="outlined"
                                            as={TextField}
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.EnterAcademicYear}
                                            helperText={
                                                <p className={classes.required}>
                                                    <ErrorMessage name={configJSON.AnalysisReport.AcademicYear} />
                                                </p>
                                            }
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <label className={classes.label} htmlFor="Executive">{configJSON.AnalysisReport.ExecutiveSummaryLabel}</label>
                                <Field
                                    as={TextField}
                                    id="Executive"
                                    name={configJSON.AnalysisReport.Executive}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    placeholder={configJSON.AnalysisReport.EnterExecutiveSummary}
                                    helperText={
                                        <p className={classes.required}>
                                            <ErrorMessage name={configJSON.AnalysisReport.Executive} />
                                        </p>
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <label className={classes.label} htmlFor="introduction">
                                    {configJSON.AnalysisReport.IntroductionLabel}
                                </label>
                                <Field
                                    as={TextField}
                                    id="introduction"
                                    name={configJSON.AnalysisReport.Introduction}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    placeholder={configJSON.AnalysisReport.EnterIntroduction}
                                    helperText={
                                        <p className={classes.required}>
                                            <ErrorMessage name={configJSON.AnalysisReport.Introduction} />
                                        </p>
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={4}>
                        <Box className={classes.heading}>
                            <Typography > {configJSON.AnalysisReport.RecommendationsAndRequirements}</Typography>
                        </Box>

                        <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="keyConcerns">
                                        {configJSON.AnalysisReport.KeyConcernsLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="keyConcerns"
                                        name={configJSON.AnalysisReport.KeyConcerns}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterKeyConcerns}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.KeyConcerns} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="focusAreas">
                                        {configJSON.AnalysisReport.FocusAreasLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="focusAreas"
                                        name={configJSON.AnalysisReport.FocusAreas}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterFocusAreas}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.FocusAreas} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="priority">
                                        {configJSON.AnalysisReport.PriorityModulesLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="priority"
                                        name={configJSON.AnalysisReport.Priority}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterPriorityModules}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.Priority} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="skillset">
                                        {configJSON.AnalysisReport.SkillsetNeededLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="skillset"
                                        name={configJSON.AnalysisReport.Skillset}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterSkillsetNeeded}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.Skillset} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={4}>
                        <Box className={classes.heading}>
                            <Typography > {configJSON.AnalysisReport.WayAhead}</Typography>
                        </Box>
                        <Box mt={4}>
                            <label className={classes.label} htmlFor="programName">
                                {configJSON.AnalysisReport.WayAheadSummaryLabel}
                            </label>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Field
                                            as={TextField}
                                            id="summary1"
                                            name={`${configJSON.AnalysisReport.Summary}1`}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.EnterText}
                                            helperText={
                                                <p className={classes.required}>
                                                    <ErrorMessage name={`${configJSON.AnalysisReport.Summary}1`} />
                                                </p>
                                            }
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box>
                                        <Field
                                            as={TextField}
                                            id="summary2"
                                            name={`${configJSON.AnalysisReport.Summary}2`}
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.EnterText}
                                            helperText={
                                                <p className={classes.required}>
                                                    <ErrorMessage name={`${configJSON.AnalysisReport.Summary}2`} />
                                                </p>
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="Ahead01">01*</label>
                                    <Field
                                        as={TextField}
                                        id="Ahead01"
                                        name={`${configJSON.AnalysisReport.Ahead}01`}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterText}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={`${configJSON.AnalysisReport.Ahead}01`} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="Ahead02">02*</label>
                                    <Field
                                        as={TextField}
                                        id="Ahead02"
                                        name={`${configJSON.AnalysisReport.Ahead}02`}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterText}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={`${configJSON.AnalysisReport.Ahead}02`} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="Ahead03">03*</label>
                                    <Field
                                        as={TextField}
                                        id="Ahead03"
                                        name={`${configJSON.AnalysisReport.Ahead}03`}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterText}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={`${configJSON.AnalysisReport.Ahead}03`} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="Ahead04">04*</label>
                                    <Field
                                        as={TextField}
                                        id="Ahead04"
                                        name={`${configJSON.AnalysisReport.Ahead}04`}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterText}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={`${configJSON.AnalysisReport.Ahead}04`} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={4}>
                        <Box className={classes.heading}>
                            <Typography> {configJSON.AnalysisReport.Conclusion}</Typography>
                        </Box>
                        <Box mt={4}>
                            <Field
                                as={TextField}
                                id="Conclusion"
                                name={configJSON.AnalysisReport.Conclusion}
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                placeholder={configJSON.AnalysisReport.EnterText}
                                helperText={
                                    <p className={classes.required}>
                                        <ErrorMessage name={configJSON.AnalysisReport.Conclusion} />
                                    </p>
                                }
                            />
                        </Box>
                    </Box>
                    <Box
                        mt={4}
                        display={"flex"}
                        style={{ gap: "10px", textAlign: "center" }}
                        justifyContent={"end"}
                    >
                        <Button data-test-id="createbtn" type="submit" style={webStyle.submit} size="large">
                            Create
                        </Button>
                    </Box>
                </Form>
            </Formik>
            <InfoModal
                modalType={configJSON.ModalTypes.Success}
                open={props.createsuccess}
                closeModal={props.handlesuccesClose}
                title={configJSON.AnalysisReport.ThankYou}
                info={configJSON.AnalysisReport.SuccessfulSubmissionMsg}
            />
        </Box>
    )
}

export default class AddReaderForm extends AnalysisReportController {
    render() {
        return <>
            <SidebarLayout {...this.props}>
                <PageContentLayout goBack={this.goBack} title={configJSON.AnalysisReport.NeedAnalysisReport}>
                    <AddForm OnCreate={this.OnCreate}
                        handlesuccesClose={this.handlesuccesClose}
                        createsuccess={this.state.createsuccess}
                        mindId={this.state.mindId}
                        courseFilter={this.state.selectedCourse.name}
                        handleFilterChange={this.handleInputChange}
                        handleSchoolChange={this.handleSchoolInputChange}
                        schoolId={this.state.schoolId}
                        schoolFilter={this.state.selectedSchool.name}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
}

const webStyle = {
    submit: {
        width: "150px",
        background: "#0056A3",
        color: "#fff",
        height: '48px',
        borderRadius: '8px',
        textTransform: "capitalize"
    } as React.CSSProperties,
    diaTitle: {
        color: "#0056A3",
        textAlign: "center",
        fontWeight: 700,
        fontSize: "2.222vw",
        marginTop: "1vw",
        marginBottom: "1vw",
    } as React.CSSProperties,
    diaContent: {
        marginTop: "20px",
        textAlign: "center",
        fontWeight: 600,
        width: "36.181vw",
        fontSize: "1.667vw",
    } as React.CSSProperties,
}
