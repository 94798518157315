import React from "react";
const configJSON = require("../config");
import StudentProgramLayout from "./GenericComponents/StudentProgramLayout.web";
const { programBloomBannerImage } = require("../assets");

const bloomPageContent = configJSON.ProgramsPageContent.bloomPage;

const featuresArr = bloomPageContent.bloomFeatures.map((feature: any) => {
  return {
    heading: feature.bloomheading,
    content: feature.bloomcontent,
    image: feature.bloomimage
  };
});
const content = {
  heading: bloomPageContent.bloomHeading,
  headingSpan: bloomPageContent.bloomHeadingSpan,
  content: bloomPageContent.bloomContent,
  features: featuresArr
};

const BloomPage = () => {
  return (
    <StudentProgramLayout
      programContent={content}
      bannerImage={programBloomBannerImage}
    />
  );
};

export default BloomPage;
