import React from "react";
import ProgramSchedulerController, { configJSON, Guest, Grade } from "./ProgramSchedulerController.web";
import { Box, Theme, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageContentLayout from "../PageContentLayout.web";
import RightLayout from "../RightSection.web";
import SidebarLayoutWrapper from "../SidebarLayout.web";
import { TableView } from "./TableView.web";
import { GuestView } from "./GuestView.web";
import {
  Calendar,
  Views,
  momentLocalizer,
  DateFormatFunction,
  DayPropGetter,
  EventPropGetter,
} from "react-big-calendar";
import moment from "moment";
import CommonPagination from "../../../../components/src/Pagination.web";
import { ProgramModal } from "./ProgramModal.web";
import { ProgramDeleteModal } from "./ProgramDeleteModal.web";
import { ProgramEditModal } from "./ProgramEditModal.web";

const useStyles = makeStyles<Theme, ProgramSchedulerProps>(theme => ({
  mindMasteryWrapper: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px"
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "12px 12px 0px 0px",
    height: "80px"
  },
  backButton: {
    "&.MuiButton-root": {
      padding: "0",
      background: "#010E36",
      height: "48px",
      width: "48px",
      opacity: 0.5,
      borderRadius: "8px",
      margin: "0 24px",
      minWidth: "auto"
    }
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "33px",
    color: "white"
  },
  backArrow: {
    height: "24px",
    width: "24px"
  },
  programSchedulerPageContent: {
    padding: "32px"
  },
  cardMedia: {
    "&.MuiCardMedia-root": {
      height: "185px"
    }
  },
  cardWrapper: {
    "&.MuiCard-root": {
      maxWidth: "328px",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3"
    },
    "&.MuiPaper-elevation1": {
      boxShadow: "none"
    }
  },
  cardTitle: {
    marginBottom: "10px",
    color: "#0056A3",
    fontWeight: 700,
    fontSize: "20px",
    textTransform: "capitalize"
  },
  startCourseBtn: {
    "&.MuiButton-root": {
      color: "white",
      width: "262px",
      height: "36px",
      background: "#0056A3",
      borderRadius: "8px",
      margin: "0 auto 10px auto",
      textTransform: "capitalize"
    }
  },
  cardDescription: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#3B3B3B"
  },
  weekHeader: {
    width: "54px",
    height: "73px",
    display: "flex",
    border: "1px solid rgb(59, 59, 59, 0.76)",
    flexDirection: "column",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    marginTop: "16px",
    marginBottom: "8px"
  },
  weekHeaderDayLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    /* identical to box height */

    textAlign: "center",

    color: "#3B3B3B"
  },
  weekHeaderDateLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    /* identical to box height */

    textAlign: "center",

    color: "#3B3B3B"
  },
  gutterContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "flex-end",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "15px",
    /* identical to box height */
    textAlign: "center",
    color: "#3B3B3B",
    mixBlendMode: "normal",
    opacity: 0.76
  },
  monthEventContent: {
    color: '#3B3B3B',
    fontSize: '10px',
    fontFamily: 'Poppins',
    fontWeight: 500
  },
  monthEventColor: {
    color: "#FFB74C",
    marginRight: 8
  },
  paginationWrapper: {
    marginTop: '30px'
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "20px 24px 32px 24px"
    }
  },
  gridItem: {
    [theme.breakpoints.down("sm")]: {
      "&.MuiGrid-root": {
        display: "flex",
        justifyContent: "center"
      }
    }
  },
  guestViewWrapper: {
    padding: '32px 24px'
  },
  tableWrapper: {
    padding: '32px 24px',
  },
  calendarWrapper: {
    overflowX: 'auto',
    '@media(max-width:767px)': {
      width: 'calc(100vw - 79px)',
    },
    '@media(max-width:600px)': {
      width: 'calc(100vw - 35px)',
    }
  },
  calendarStyle: {
    height: 750,
    '@media(max-width:767px)': {
      minWidth: '767px'
    }
  },
  calendarView: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width:960px)': {
      flexDirection: 'column'
    }
  },
  withoutCalendarView: {},
  calendarPageLayoutWrapper: {
    marginBottom: '30px',
    width: '68.5%',
    '@media(max-width:960px)': {
      width: '100%'
    }
  },
  withoutCalendarPageLayoutWrapper: {
    marginBottom: '30px',
    width: '100%',
  },
}));

const useEventStyle = makeStyles<Theme, any>(_theme => ({
  monthEventContent: {
    color: '#3B3B3B',
    fontSize: '10px',
    fontFamily: 'Poppins',
    fontWeight: 500
  },
  monthEventColor: {
    color: (props: any) => (props.color ? props.color : "#0190FD"),
    marginRight: 8
  },
}));

interface MyGutterContentProps {
  // Define any props specific to your gutter content
}

interface ProgramSchedulerProps {
  eventsData: any;
  listView: boolean;
  calendarView: "month" | "week" | "day";
  selectedDate: Date;
  page: number;
  pageCount: number;
  guests: Array<Guest>;
  guestView: boolean;
  guestPage: number;
  guestPageCount: number;
  schoolList?: Array<number | string>;
  courseList?: Array<number | string>;
  childList?: Array<number | string>;
  gradeList: Array<Grade>;
  selectedSchool: string;
  selectedCourse: string;
  selectedChild: string;
  selectedGrade: string;
  handleGuestPage: (page: number) => void;
  handlePage: (page: number) => void;
  handleSchoolSelectChange: (val: string) => void;
  handleCourseSelectChange: (val: string) => void;
  handleChildSelectChange: (val: string) => void;
  handleGradeChange: (val: string) => void;
  getTimeZone: () => string;
  openModal: (id: string) => void;
  openDeleteModal: (id: string) => void;
  openEditModal: (id: string) => void;
  getEventDetails: (id: string) => void;
}

export const ProgramSchedulerPage = (props: ProgramSchedulerProps) => {
  const classes = useStyles(props);
  const allViews = Object.keys(Views).map(k => (Views as any)[k]);
  const _eventsData = props.eventsData.map((evt: any) => {
    const dateString = evt.attributes.date;
    const [startTimeString, endTimeString] = evt.attributes.time
      .slice(1, -1)
      .split(" - ");

    const dateStartTimeString = `${dateString} ${startTimeString}`;
    const dateEndTimeString = `${dateString} ${endTimeString}`;
    const formatString = "MM-DD-YYYY h:mm a";

    const start = moment(dateStartTimeString, formatString).toDate();
    const end = moment(dateEndTimeString, formatString).toDate();

    return {
      id: evt.id,
      title: evt.attributes.name || evt.type,
      date: evt.attributes.date,
      time: evt.attributes.time,
      color: evt.attributes.colour,
      location: evt.attributes.location,
      start: start,
      end: end,
      school_name: evt.attributes?.school_name || "school",
      course_title: evt.attributes?.course_title
    };
  });
  const localizer = momentLocalizer(moment);
  const MyWeekHeader = ({ label }: any) => {
    const [date, day] = label.split(" ");
    return (
      <Box className={classes.weekHeader}>
        <span className={classes.weekHeaderDayLabel}>{day}</span>
        <span className={classes.weekHeaderDateLabel}>{date}</span>
      </Box>
    );
  };

  const Pagination = () => {

    return (
      <Box className={classes.paginationWrapper}>
        <CommonPagination page={props.page} pageCount={props.pageCount} handlePage={props.handlePage} />
      </Box>
    )
  }

  const GuestPagination = () => {

    return (
      <Box className={classes.paginationWrapper}>
        <CommonPagination page={props.guestPage} pageCount={props.guestPageCount} handlePage={props.handleGuestPage} />
      </Box>
    )
  }

  const isMonthActive = (date: Date) => {
    return moment(date).isSame(moment(), "month");
  };

  const isEventOnSameDay = (date: Date) => {
    return _eventsData.find((e: any) => +new Date(e.date) === +date);
  }

  const dayPropGetter: DayPropGetter = (date: Date) => {
    const eventColor = isEventOnSameDay(date);
    const color = eventColor?.color || 'blue';
    if ((props.calendarView === 'month') && eventColor) {
      return {
        style: {
          backgroundColor: configJSON.ProgramColor[color].secondary
        }
      };
    }
    if (!isMonthActive(date)) {
      return {
        style: {
          backgroundColor: "#FFFFFF" // Change this to your desired color for inactive month days
        }
      };
    }
    return {
      style: {
        backgroundColor: "#FFFFFF" // Change this to your desired color for inactive month days
      }
    };
  };

  const MyGutterContent: React.FC<MyGutterContentProps> = () => {
    const timezoneGMT = props.getTimeZone();
    return <div className={classes.gutterContent}>{timezoneGMT}</div>;
  };

  const CustomEvent = (event: any) => {
    const eventProps = { color: event?.event?.color || 'blue' }
    const classes = useEventStyle(eventProps);
    const startTime: string = moment(event?.event?.start).format('hh:mm A');

    if (props.calendarView === 'month') {
      return (
        <div>
          <span className={classes.monthEventColor}>•</span>
          <span className={classes.monthEventContent}>
            {startTime}, {event.title}
          </span>
        </div>
      );
    }
    else {
      return (
        <>
          <div style={{
            color: '#3B3B3B',
            marginBottom: 10,
            border: 'none',
            fontSize: '12px',
            fontFamily: 'Poppins',
            fontWeight: 500
          }}>
            {event.title}
          </div>
          <div style={{
            color: '#3B3B3B',
            fontSize: '10px',
            fontFamily: 'Poppins',
            lineHeight: '14px',
            letterSpacing: '-0.167px'
          }}>
            {startTime}
          </div>
        </>
      );
    }
  }

  const eventPropGetter: EventPropGetter<any> = (event: any) => {
    let style = {};
    const color = event?.color || 'blue'
    if (props.calendarView === 'week') {
      style = {
        border: 'none',
        backgroundColor: configJSON.ProgramColor[color].secondary,
        minHeight: 60
      }
    }
    if (props.calendarView === 'day') {
      style = {
        border: 'none',
        borderLeft: `8px solid ${configJSON.ProgramColor[color].primary}`,
        backgroundColor: configJSON.ProgramColor[color].secondary,
        minHeight: 60
      }
    }
    return { style };
  }

  const components = {
    timeGutterHeader: MyGutterContent,
    week: {
      header: MyWeekHeader
    },
    day: {
      header: MyWeekHeader
    },
    event: CustomEvent
  };

  const dayHeaderFunction: DateFormatFunction = (
    date: Date,
    culture: any,
    localizer: any
  ) => {
    if (localizer) {
      return localizer.format(date, "MMMDD", culture);
    } else {
      return "";
    }
  };
  const formats = {
    dayHeaderFormat: dayHeaderFunction
  };

  const openModal = async (id: string) => {
    props.openModal(id);
  };
  if (props.guestView) {
    return (
      <Box className={classes.guestViewWrapper} >
        <GuestView guests={props.guests} />
        <GuestPagination />
      </Box>)
  }
  return (
    <div >
      {
        props.listView ? (
          <Box className={classes.tableWrapper}>
            <TableView
              eventsData={_eventsData}
              openModal={openModal}
              selectedSchool={props.selectedSchool}
              selectedCourse={props.selectedCourse}
              selectedChild={props.selectedChild}
              selectedGrade={props.selectedGrade}
              schoolList={props.schoolList}
              courseList={props.courseList}
              childList={props.childList}
              gradeList={props.gradeList}
              handleSchoolSelectChange={props.handleSchoolSelectChange}
              handleCourseSelectChange={props.handleCourseSelectChange}
              handleChildSelectChange={props.handleChildSelectChange}
              handleGradeChange={props.handleGradeChange}
              openDeleteModal={props.openDeleteModal}
              openEditModal={props.openEditModal}
            />
            <Pagination />
          </Box>
        ) : (
          <Box className={classes.calendarWrapper}>
            <Calendar
              localizer={localizer}
              events={_eventsData}
              views={allViews}
              dayPropGetter={dayPropGetter}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={async (e: any) => await openModal(e.id)}
              className={classes.calendarStyle}
              onNavigate={() => { }}
              date={props.selectedDate}
              view={props.calendarView}
              onView={() => { }}
              eventPropGetter={eventPropGetter}
              components={components}
              formats={formats}
            />
          </Box>
        )}
    </div>
  );
};

const ProgramSchedulerUI = (props: any) => {
  const classes = useStyles({ ...props.ProgramSchedulerPageProps });
  return <>
    <Box className={props.WrapperBoxProps.isCalendarView ? classes.calendarView : classes.withoutCalendarView} >
      <Box className={props.WrapperBoxProps.isCalendarView ? classes.calendarPageLayoutWrapper : classes.withoutCalendarPageLayoutWrapper} >
        <PageContentLayout {...props.PageContentProps}>
          <ProgramSchedulerPage {...props.ProgramSchedulerPageProps} />
        </PageContentLayout>
      </Box>
      <RightLayout {...props.RightLayoutProps} />
    </Box>
  </>
}

export default class ProgramScheduler extends ProgramSchedulerController {
  user = JSON.parse(`${localStorage.getItem('userDetails')}`);
  role = this.user?.attributes?.role;
  async componentDidMount(): Promise<void> {
    this.getEvents(this.state.page);
    await this.getUpcomingEvents();
    if (this.role === "Trainer") {
      this.getAddEventDetails();
    }
    if (this.role === "School") {
      this.getGrades();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (prevState.page !== this.state.page) {
      this.getEvents(this.state.page);
    }
  }
  render(): React.ReactNode {
    return (
      <>
        <SidebarLayoutWrapper {...this.props}>
          <Snackbar
            anchorOrigin={{ 'vertical': 'bottom', 'horizontal': 'center' }}
            open={this.state.showToast}
            autoHideDuration={5000}
            message={this.state.toastMessage}
            onClose={() => this.setState({ showToast: false })}
          />
          <ProgramModal
            key={this.state.selectedEvent.id}
            open={this.state.showPopup}
            handleCancel={this.switchPopup}
            openDeleteModal={this.showDeleteModal}
            openEditModal={this.showEditModal}
            selectedEvent={this.state.selectedEvent}
            toggleGuestView={this.showMoreGuests}
            changeColor={this.changeColor}
          />
          <ProgramDeleteModal
            open={this.state.showDeletePopup}
            handleCancel={this.showDeleteModal}
            handleDelete={this.deleteEvents}
          />
          <ProgramEditModal
            formDetails={this.state.editEvent}
            addEvent={this.state.addEvent}
            open={this.state.showEditPopup}
            allSchoolList={this.state.allSchoolList}
            allCourseList={this.state.allCourseList}
            handleCancel={this.state.addEvent ? this.hideAddModal : this.showEditModal}
            changeColor={this.changeColor}
            editProgramSchedule={this.editProgramSchedule}
            addProgramSchedule={this.addProgramSchedule}
          />
          <ProgramSchedulerUI
            WrapperBoxProps={{ isCalendarView: (!this.state.guestView && !this.state.listView) }}
            PageContentProps={{
              goBack: this.state.guestView ? this.toggleGuestView : null,
              title: this.state.guestView ? "Guest List" : "Program Schedule",
              isProgramScheduler: true,
              guestView: this.state.guestView,
              listView: this.state.listView,
              calendarView: this.state.calendarView,
              calendarDate: this.state.calendarDate,
              toggleView: this.toggleView,
              onDateChange: this.onDateChange,
              showAddModal: this.showAddModal,
              hideAddModal: this.hideAddModal,
              handleCalendarView: this.handleCalendarView,
            }}
            ProgramSchedulerPageProps={{
              eventsData: this.state.eventsData,
              guestView: this.state.guestView,
              guests: this.state.guestList,
              guestPage: this.state.guestPage,
              guestPageCount: this.state.guestPageCount,
              listView: this.state.listView,
              calendarView: this.state.calendarView,
              selectedDate: this.state.calendarDate,
              page: this.state.page,
              pageCount: this.state.pageCount,
              schoolList: this.state.schoolList,
              courseList: this.state.courseList,
              childList: this.state.childList,
              gradeList: this.state.gradeList,
              selectedSchool: this.state.selectedSchool,
              selectedCourse: this.state.selectedCourse,
              selectedChild: this.state.selectedChild,
              selectedGrade: this.state.selectedGrade,
              handlePage: this.handlePage,
              handleGuestPage: this.handleGuestPage,
              handleSchoolSelectChange: this.handleSchoolSelectChange,
              handleCourseSelectChange: this.handleCourseSelectChange,
              handleChildSelectChange: this.handleChildSelectChange,
              handleGradeChange: this.handleGradeChange,
              getTimeZone: this.getTimeZone,
              getEventDetails: this.getEventDetails,
              openModal: this.togglePopup,
              openDeleteModal: this.showDeleteModal,
              openEditModal: this.showEditModal,
            }}
            RightLayoutProps={{
              guestView: this.state.guestView,
              listView: this.state.listView,
              upcomingEventsList: this.state.upcomingEvents,
              openModal: this.togglePopup,
            }}
          />
        </SidebarLayoutWrapper>
      </>
    );
  }
}
