import React from "react";
import { Box, Grid, Container, Typography, Link } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import GenericWebButton from "../../../../../components/src/GenericWebButton.web";
const {
  bannerBg,
  rightArrowImage,
  leftTop,
  rightBottom,
  bottomLeftShape
} = require("../../assets");

interface BannerCss {
  top: string;
  marginTop:string;
  padding: string;
}

interface Props {
  content: string;
  heading: string;
  bannerImage: any;
  showBtn: boolean;
  headingSpan: string;
  bannerCss?: BannerCss;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  bannerBg: {
    backgroundImage: `url(${bannerBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: (props)=>props?.bannerCss?.padding ?? "53px 0",
    borderRadius: "0px 0px 64px 64px",
    position: "relative",
    overflow: "hidden",
    display:'flex',
    alignItems:'center',
    
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "60px"
    },
    "&::after": {
      content: '""',
      backgroundImage: `url(${leftTop})`,
      width: "355px",
      height: "400px",
      position: "absolute",
      top: (props)=>props?.bannerCss?.top ?? "-145px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      left: "-35px",
      [theme.breakpoints.down("md")]: {
        backgroundImage: "none"
      }
    },
    "&::before": {
      content: '""',
      backgroundImage: `url(${rightBottom})`,
      height: "400px",
      position: "absolute",
      bottom: "-55px",
      right: "-35px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "355px",
      [theme.breakpoints.down("md")]: {
        backgroundImage: "none"
      }
    }
  },
  containerStyle: {
    justifyContent: "center",
    alignItems:'center',
  },
  contentBox: {
    maxWidth: "493px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 0
    },
    marginTop: (props)=>props?.bannerCss?.marginTop ?? "30%",
    marginBottom: "60px"
  },
  bannerImageBox: {
    display:'flex',
    justifyContent:'end',
    height:'493px',
    [theme.breakpoints.down("sm")]: {
      transform: " translateY(0px)",
      paddingBottom: "60px",
      height:'auto',
    },
    position: "relative",
    "&::after": {
      content: '""',
      width: "80px",
      height: "80px",
      border: "4px solid #fff",
      position: "absolute",
      bottom: "-31px",
      left: "-22px",
      borderRadius: "100%",
      zIndex: "-1",
      [theme.breakpoints.down("xs")]: {
        bottom: "35px",
        left: "-8px"
      },
      [theme.breakpoints.down("md")]: {
        left: "-10px",
        bottom: "26px"
      }
    }
  },
  bannerImage: {
    boxShadow: "8px 8px 35px #424242",
    borderRadius: "46px"
  },
  topSpace: {
    marginTop: "15px"
  },
  containerSpace: {
    [theme.breakpoints.down("sm")]: {
      transform: " translateY(-24px)"
    }
  },
  heading: {
    color: "#FFFFFF",
    fontSize: "48px",
    lineHeight: "50px",
    fontWeight: 600,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px",
      lineHeight: "50px"
    }
  },
  content: {
    color: "#FFFFFF",
    fontSize: "1rem",
    padding: "15px 0",
    mixBlendMode: "normal",
    opacity: 0.74,
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  spanText: {
    fontSize: "24px",
    marginLeft: "10PX",
  },
  bottomLeftBg: {
    width: "200px",
    position: "absolute",
    left: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const BannerWithSpan = (props: Props) => {
  const { heading, content, bannerImage, showBtn, headingSpan } = props;
  const classes = useStyles(props);
   const handleRedirect = () => {
    window.location.href = '/partnership#specific-section';
  };
  return (
    <Box className={classes.bannerBg}>
      <Box className={classes.bottomLeftBg}>
        <img
          src={bottomLeftShape}
          width="100%"
          alt="bottom left background image"
        />
      </Box>
      <Container className={classes.containerSpace}>
        <Grid container className={classes.containerStyle}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.contentBox}>
              <Typography variant="h3" className={classes.heading}>
                  {heading}
                <span className={classes.spanText}>{headingSpan}</span>
              </Typography>
              <Typography variant="body1" className={classes.content}>
                {content}
              </Typography>

              {showBtn ? (
                <Box className={classes.topSpace}>
                  <Link onClick={handleRedirect}>
                    <GenericWebButton
                      icon={rightArrowImage}
                      text={"Contact Us"}
                      background={"white"}
                      color={"#0056A3"}
                      hoverColor={"white"}
                      boxShadow={"2px 2px 33px -2px white"}
                    />
                  </Link>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.bannerImageBox}>
              <img
                className={classes.bannerImage}
                src={bannerImage}
                width="100%"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BannerWithSpan;
