import React from "react";
import ProgramLayout from "./GenericComponents/ProgramLayout.web";
const configJSON = require("../config");
const { programParentBannerImage, programParentImage } = require("../assets");

const parentPageContent = configJSON.ProgramsPageContent.parentPage;
const makeBoldParaNo = 3;
const ParentPage = () => {
  return (
    <ProgramLayout
      makeBoldParaNo={makeBoldParaNo}
      bannerImage={programParentBannerImage}
      contentImage={programParentImage}
      programContent={parentPageContent}
    />
  );
};

export default ParentPage;
