import React from "react";
// Customizable Area Start

// Customizable Area Start
import {
  Typography,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { parent, school, trainer, student, blueBox1, blueBox2 } from "./assets";

const configJSON = require("./config");

interface ChooseAccountProps {
  accountType: string;
  handleAccountType: (e: any) => void;
}

const useStyles = makeStyles(theme => ({
  gridContainer: {
    "& .MuiGrid-container": {
      flexDirection: "column"
    }
  },
  signupWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px"
    }
  },
  customRadioGroup: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    maxWidth: "636px",
    width: "100%",
    [theme.breakpoints.down(600)]: {
      marginRight: '36px',
    },
    "@media(max-width:815px)": {
      maxWidth: "none"
    }
  },
  customRadioButton: {
    background: "#066CD2",
    marginBottom: "24px",
    marginLeft: "0px",
    display: "inline-flex",
    borderRadius: "6px",
    height: "124px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "34px",
    '@media(max-width:600px)': {
      height: "100px",
    }
  },
  radioBtnWrapper: {
    width: "100%",
    position: "relative",
    "@media(max-width:815px)": {
      width: "86%"
    }
  },
  customRadioButtonLabelText: {
    fontSize: "32px",
    fontWeight: 600,
    color: "#fff",
    '@media(max-width:600px)': {
      fontSize: "18px",
    }
  },
  customRadioLabelImgWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    background: "#fff",
    margin: "0 32px",
    '@media(max-width:600px)': {
      width: '60px',
      height: "60px",
      margin: "0 16px",
    }
  },
  customRadioLabelImg: {
    width: "45px",
    height: "45px",
    '@media(max-width:600px)': {
      width: '30px',
      height: "30px"
    }
  },
  formHeaderLabel: {
    color: "#0056A3",
    fontSize: "40px",
    fontWeight: 900,
    margin: "24px auto",
    '@media(max-width:600px)': {
      fontSize: "22px",
    }
  }
}));

const UncheckedIcon = () => {
  return <div style={webStyles.UncheckedIconStyle} />;
};

const CheckedIcon = () => {
  return (
    <div style={webStyles.UncheckedIconStyle}>
      <div style={webStyles.CheckedIconStyle} />
    </div>
  );
};

const ChooseAccount = (props: ChooseAccountProps) => {
  const classes = useStyles();
  const userRoles = [
    { role: configJSON.UserRole.Student, image: student },
    { role: configJSON.UserRole.Parents, image: parent },
    { role: configJSON.UserRole.Trainer, image: trainer },
    { role: configJSON.UserRole.School, image: school }
  ];
  return (
    <Box className={classes.signupWrapper}>
      <Typography className={classes.formHeaderLabel}>
        Choose an Account
      </Typography>
      <RadioGroup
        value={props.accountType}
        className={classes.customRadioGroup}
      >
        {userRoles.map((element, index) => {
          return (
            <div key={element.role} className={classes.radioBtnWrapper}>
              <FormControlLabel
                data-test-id='accountType'
                onClick={props.handleAccountType}
                style={{
                  opacity: element.role !== props.accountType ? 0.6 : 1,
                  justifyContent: "space-between"
                }}
                className={classes.customRadioButton}
                label={
                  <div style={webStyles.customRadioButtonLabel}>
                    <div className={classes.customRadioLabelImgWrapper}>
                      <img
                        className={classes.customRadioLabelImg}
                        src={element.image}
                      />
                    </div>
                    <Typography className={classes.customRadioButtonLabelText}>
                      {element.role}
                    </Typography>
                  </div>
                }
                value={element.role}
                labelPlacement="start"
                control={
                  <Radio
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
              />
              {index % 2 === 0 ? (
                <img style={webStyles.radioBtnImgEven} src={blueBox1} alt="" />
              ) : (
                <img style={webStyles.radioBtnImgOdd} src={blueBox2} alt="" />
              )}
            </div>
          );
        })}
      </RadioGroup>
    </Box>
  );
};

export default ChooseAccount;

const webStyles = {
  radioBtnImgEven: {
    position: "absolute" as "absolute",
    right: "-85px",
    top: "-54px",
    width: "138px"
  },
  radioBtnImgOdd: {
    position: "absolute" as "absolute",
    width: "130px",
    top: "-25px",
    right: "-60px"
  },
  UncheckedIconStyle: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    border: "1px solid #297FCA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  CheckedIconStyle: {
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    borderRadius: "50%"
  },
  customRadioButtonLabel: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-start"
  },



};
