import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import _ from "lodash";
import { runEngine } from "../../../../framework/src/RunEngine";

let config = require("../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface EventAttributes {
  date: string;
  time: string;
  location: string;
  course_title?: string;
  school_name?: Array<string>;
}
interface GuestAttributes {
  email: string;
  profile_photo_url: string;
}

interface Organizer {
  name: string;
  profile_photo_url: string;
}
export interface Guest {
  id: string;
  type: string;
  attributes: GuestAttributes;
}

export interface Grade {
  id: string;
  title: string;
}

export interface E {
  id: string;
  type: string;
  attributes: EventAttributes;
  day_date_time?: string;
  location?: string;
  name?: string;
  notes?: string;
  total_guests?: number;
  organizer?: Organizer;
  program_guests?: Array<Guest>;
}

interface S {
  // Customizable Area Start
  // Customizable Area End
  listView: boolean;
  guestView: boolean;
  eventsData: Array<E>;
  page: number;
  pageCount: number;
  guestPage: number;
  guestPageCount: number;
  calendarView: "month" | "week" | "day";
  calendarDate: Date;
  upcomingEvents: any;
  guestList: Array<Guest>;
  showPopup: boolean;
  addEvent: boolean;
  showDeletePopup: boolean;
  showEditPopup: boolean;
  showToast: boolean;
  selectedDeleteId: string;
  selectedEditId: string;
  selectedSchool: string;
  selectedCourse: string;
  selectedChild: string;
  selectedGrade: string;
  toastMessage: string;
  schoolList: Array<number | string>;
  courseList: Array<number | string>;
  childList: Array<number | string>;
  gradeList: Array<Grade>;
  selectedEvent: E;
  allSchoolList: any;
  allCourseList: any;
  editEvent: any;
}
interface SS { }

export default class ProgramSchedulerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  eventsDataApiCallId: string = "";
  eventsDetailDataApiCallId: string = "";
  deleteEventsDataApiCallId: string = "";
  editEventDetailsApiCallId: string = "";
  addEventDetailsApiCallId: string = "";
  upcomingEventsApiCallId: string = "";
  updateEventsApiCallId: string = "";
  addEventApiCallId: string = "";
  guestsApiCallId: string = "";
  colorChangeApiCallId: string = "";
  getGradesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      listView: true,
      guestView: false,
      eventsData: [],
      page: 1,
      pageCount: 1,
      guestPage: 1,
      guestPageCount: 1,
      calendarView: "month",
      calendarDate: new Date(),
      showPopup: false,
      selectedSchool: "",
      selectedCourse: "",
      selectedChild: "",
      selectedGrade: "",
      selectedDeleteId: "",
      selectedEditId: "",
      toastMessage: "",
      addEvent: false,
      showDeletePopup: false,
      showEditPopup: false,
      showToast: false,
      upcomingEvents: [],
      guestList: [],
      schoolList: [],
      courseList: [],
      childList: [],
      gradeList: [],
      selectedEvent: {} as E,
      editEvent: {},
      allSchoolList: [],
      allCourseList: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          this.handleApiResponse(apiRequestCallId, responseJson);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  getToken = () => {
    return localStorage.getItem("user_token");
  };

  getTimeZone = () => {
    const date = new Date();
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    const timezoneOffsetInHours = Math.abs(timezoneOffsetInMinutes / 60);
    const hours = Math.floor(timezoneOffsetInHours);
    const minutes = Math.floor((timezoneOffsetInHours - hours) * 60);
    const sign = timezoneOffsetInMinutes > 0 ? "-" : "+";

    const timezoneGMT = `GMT${sign}${hours.toString()}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return timezoneGMT;
  };

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.eventsDataApiCallId:
        if (responseJson.message === "No Program Schedule Found") {
          this.setState({ eventsData: [] });
        } else {
          this.setState({ eventsData: responseJson.data });
          this.setState({ pageCount: responseJson.page_options.total_pages });
          let courseList = responseJson?.courses?.map((course: Array<number | string>) => {
            const [courseId, courseName] = course;
            const courseElement = {
              id: courseId,
              value: courseId,
              title: courseName
            }
            return courseElement
          });
          courseList = _.uniqWith(courseList, _.isEqual);
          this.setState({ courseList: courseList });
          let schoolList = responseJson?.schools?.map((school: Array<number | string>) => {
            const [schoolId, schoolName] = school;
            const schoolElement = {
              id: schoolId,
              value: schoolId,
              title: schoolName
            }
            return schoolElement
          });
          schoolList = _.uniqWith(schoolList, _.isEqual);
          this.setState({ schoolList: schoolList });
        }
        let childList = responseJson?.childrens?.map((child: Array<number | string>) => {
          const [childId, childEmail] = child;
          const childElement = {
            id: childId,
            value: childId,
            title: childEmail
          }

          return childElement
        });
        this.setState({ childList: childList });
        break;
      case this.eventsDetailDataApiCallId:
        let _eventsData = this.state.eventsData;
        const responseData = responseJson.data;
        _eventsData = _eventsData.map(item => {
          if (item.id === responseData.id) {
            return {
              ...item,
              ...responseData.attributes
            };
          } else {
            return { ...item };
          }
        });
        this.setState({ eventsData: _eventsData });

        break;
      case this.guestsApiCallId:
        this.setState({ guestList: responseJson.data });
        this.setState({ guestPageCount: responseJson.page_options.total_pages });
        break;
      case this.upcomingEventsApiCallId:
        this.setState({ upcomingEvents: responseJson.data });
        break;
      case this.colorChangeApiCallId:
        this.getEvents(this.state.page);
        break;
      case this.updateEventsApiCallId:
        if (responseJson.error) {
          this.setState({ toastMessage: `Event Not Edited:+ ${JSON.stringify(responseJson.error)}`, showToast: true });
        } else {
          this.getEvents(this.state.page);
          this.setState({ toastMessage: responseJson.data.message, showToast: true });
        }
        break;
      case this.deleteEventsDataApiCallId:
        if (responseJson.error) {
          this.setState({ toastMessage: `Event Not Deleted:+ ${JSON.stringify(responseJson.error)}`, showToast: true });
        } else {
          this.getEvents(this.state.page);
          this.setState({ toastMessage: "Event Deleted Successfully", showToast: true });
        }
        break;
      case this.editEventDetailsApiCallId:
        this.setState({ editEvent: responseJson.data });
        break;
      case this.addEventApiCallId:
        if (responseJson.error) {
          this.setState({ toastMessage: `Event Not Added:+ ${JSON.stringify(responseJson.error)}`, showToast: true });
        } else {
          this.getEvents(this.state.page);
          this.setState({ toastMessage: responseJson.data.message, showToast: true });
        }
        break;
      case this.getGradesApiCallId:
        if (responseJson.data) {
          let gradeList: Array<Grade>;
          gradeList = responseJson.data.map((grade: any) => {
            return {
              id: grade.id,
              title: grade.attributes.name
            }
          }).sort((a: any, b: any) => a.title.localeCompare(b.title));
          this.setState({ gradeList: gradeList });
        }
        break;
      case this.addEventDetailsApiCallId:
        const courses = responseJson?.data?.attributes?.courses.map((c: any) => {
          return {
            id: c?.attributes?.id,
            value: c?.attributes?.id,
            title: c?.attributes?.title
          }
        });
        this.setState({ allCourseList: courses });
        this.setState({ allSchoolList: responseJson.data?.attributes?.schools });
        break;

    }
  }

  handlePage = (page: number) => {
    this.setState({ page: page });
  };

  handleGuestPage = (page: number) => {
    this.getGuests(page);
    this.setState({ guestPage: page });
  };

  handleSchoolSelectChange = (value: string) => {
    this.setState({ selectedSchool: value });
    this.getEvents(this.state.page, value, this.state.selectedCourse);
  }

  handleCourseSelectChange = (value: string) => {
    this.setState({ selectedCourse: value });
    this.getEvents(this.state.page, this.state.selectedSchool, value, this.state.selectedChild, this.state.selectedGrade);
  }

  handleChildSelectChange = (value: string) => {
    this.setState({ selectedChild: value });
    this.getEvents(this.state.page, '', '', value);
  }

  handleGradeChange = (value: string) => {
    this.setState({ selectedGrade: value });
    this.getEvents(this.state.page, this.state.selectedSchool, this.state.selectedCourse, this.state.selectedChild, value);
  }

  showMoreGuests = () => {
    this.getGuests(this.state.guestPage);
    this.toggleGuestView()
  }

  handleCalendarView = (view: "month" | "week" | "day") => {
    this.setState({
      calendarView: view
    });
  };

  toggleView = () => {
    this.setState({
      listView: !this.state.listView
    });
  };

  showDeleteModal = (id: string) => {
    if (id) {
      this.setState({
        selectedDeleteId: id
      });
    }
    this.setState({
      showDeletePopup: !this.state.showDeletePopup
    });
  };

  showEditModal = (id: string) => {
    if (id) {
      this.setState({ selectedEditId: id });
    }
    this.setState({
      showEditPopup: !this.state.showEditPopup
    });
    this.getEditEventDetails(id);
  };

  showAddModal = () => {
    this.setState({
      addEvent: true,
      showEditPopup: true
    });
  };

  hideAddModal = () => {
    this.setState({
      addEvent: false,
      showEditPopup: false
    });
  };

  toggleGuestView = () => {
    this.setState({
      guestView: !this.state.guestView,
      showPopup: !this.state.showPopup,
      guestPage: 1,
      guestPageCount: 1
    });
  };

  togglePopup = async (id: string) => {
    if (!this.state.showPopup) {
      await this.getEventDetails(id);
      const selectedEvent = this.state.eventsData.find((e: E) => {
        return id === e.id;
      });
      if (selectedEvent) {
        this.setState({ selectedEvent: selectedEvent });
      }
    }
    this.switchPopup();
  };

  switchPopup = () => {
    this.setState({
      showPopup: !this.state.showPopup
    });
  };

  getEvents = (page: number, schoolId?: string, courseId?: string, childId?: string, gradeId?: string) => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.eventsDataApiCallId = apiRequest.messageId;
    let filter = "";
    if (schoolId) {
      filter = filter.concat(`&school_id=${schoolId}`);
    }
    if (courseId) {
      filter = filter.concat(`&course_id=${courseId}`);
    }
    if (childId) {
      filter = filter.concat(`&children_id=${childId}`)
    }
    if (gradeId) {
      filter = filter.concat(`&grade_id=${gradeId}`)
    }
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      `program_schedules/?page=${page}&per_page=10${filter}`,
      header
    );
  };

  async getUpcomingEvents() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.upcomingEventsApiCallId = apiRequest.messageId;

    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getUpcomingEventsEndPoint,
      header
    );
  }

  async getGuests(guestPage: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.guestsApiCallId = apiRequest.messageId;

    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `program_schedules/${this.state.selectedEvent.id}/fetch_program_guests?page=${guestPage}&per_page=15`,
      header
    );
  }

  deleteEvents = () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteEventsDataApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      "DELETE",
      `program_schedules/${this.state.selectedDeleteId}`,
      header
    );
    this.setState({ showDeletePopup: false, selectedDeleteId: "" });
  };

  editProgramSchedule = (body: any) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateEventsApiCallId = apiRequest.messageId;

    this.makeApiCall(
      apiRequest.messageId,
      'PUT',
      `program_schedules/${this.state.selectedEditId}`,
      header,
      JSON.stringify(body)
    );

    this.setState({ showEditPopup: false, selectedEditId: "" });
  }

  addProgramSchedule = (body: any) => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addEventApiCallId = apiRequest.messageId;

    this.makeApiCall(
      apiRequest.messageId,
      'POST',
      `program_schedules`,
      header,
      JSON.stringify(body)
    );

    this.setState({ showEditPopup: false });
  }

  getEditEventDetails = (id: string) => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editEventDetailsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      "GET",
      `program_schedules/${id}/edit`,
      header
    );
  };

  getAddEventDetails = () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addEventDetailsApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      "GET",
      `program_schedules/new`,
      header
    );
  };

  getGrades = () => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getGradesApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      "GET",
      `program_schedules/school_grades`,
      header
    );
  };

  getDirection = (selectedDay: number, increment: boolean, days = 1) => {
    if (increment) {
      return selectedDay + days;
    } else {
      return selectedDay - days;
    }
  };

  onDateChange = (increment: boolean) => {
    const d = this.state.calendarDate;
    if (this.state.calendarView === "month") {
      d.setMonth(this.getDirection(d.getMonth(), increment));
    } else if (this.state.calendarView === "week") {
      d.setDate(this.getDirection(d.getDate(), increment, 7));
    } else {
      d.setDate(this.getDirection(d.getDate(), increment));
    }
    this.setState({ calendarDate: d });
  };

  getEventDetails = async (id: string) => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.eventsDetailDataApiCallId = apiRequest.messageId;
    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.programSchedulerGetApiMethod,
      `program_schedules/${id}`,
      header
    );
  };

  changeColor = async (id: string, color: string) => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.colorChangeApiCallId = apiRequest.messageId;
    const body = JSON.stringify({
      id: id,
      colour: color
    });
    await this.makeApiCall(
      apiRequest.messageId,
      "PUT",
      `program_schedules/${id}/update_program_colour`,
      header,
      body
    );
  }


  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    // Customizable Area Start
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();
      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      console.log("Api Response" + JSON.stringify(responseJson));
      // if token expires then redirect to login
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occurred. Please try again later."
      );
    }
    this.send(apiResponseMessage);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
