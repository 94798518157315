import React from "react";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import UtilityInfo from "../../../components/src/UtilityInfo.web";
import ReviewCard from "../../../components/src/ReviewCard.web";
const whyHapchiImage = require("./assets");
const configJSON = require("./config");
const aboutHapchi = configJSON.LandingPageContent.aboutHapchi;
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
}

const content: Content = {
  headerText: aboutHapchi.headerText,
  contentText: aboutHapchi.contentText,
  fontSize: "48px",
  color: "#052B62",
  secondColor: "#5D5A6F",
  paragraphFontSize: "16px"
};

const useStyles = makeStyles(theme => ({
  hapchiContainer: {
    padding: "64px 0",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "64px 10px",
      maxWidth: "100%"
    }
  },
  topSpace: {
    width: "100%",
    paddingTop: "45px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  gridBox: {
    maxWidth: "300px",
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      display: "block !important"
    }
  }
}));

const items = [
  {
    title: "K 10 Child Safety Curriculum",
    content:
      "Curated by a team of experts such as psychologists, educators, child advocates and doctors.",
    cardImage: whyHapchiImage.card1
  },
  {
    title: "Early Identification and Interventions",
    content:
      "Identification of concerning behaviour through professional evaluation and providing individualised support",
    cardImage: whyHapchiImage.card2
  },
  {
    title: "Safe Space for Children",
    content:
      "Innovative technology creates a safe space for children, promoting engagement and empowerment.",
    cardImage: whyHapchiImage.card3
  },
  {
    title: "Connects Key Stakeholders",
    content:
      "Targets all key stakeholders of a child’s education ecosystem, i.e. parents, schools & educators.",
    cardImage: whyHapchiImage.card4
  },
  {
    title: "Guidelines assured",
    content:
      "The programs are meticulously crafted to meet the highest standards of education and child protection, adhering to multiple guidelines, including the NEP, NCPCR, CISCE, and RTE.",
    cardImage: whyHapchiImage.card2
  },
  {
    title: "Age-appropriate programs",
    content:
      "Lays a strong foundation by starting from an early age and continuing till adolescence (3-16 years)",
    cardImage: whyHapchiImage.card1
  }
];

const WhyHapchi = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center" style={{ height: "100%" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <UtilityInfo {...content} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.topSpace}>
            <Box className="landingPageSlider">
              <Slider {...configJSON.LandingPageContent.settings}>
                {items.map((item, index) => {
                  const mod =
                    index % 2 === 0
                      ? {
                          buttonBgColor: "#EFCC53",
                          buttonTextColor: "#0A539E",
                          circleColor: "#EFCC53",
                          background: "#0A539E",
                          contentColor: "#fff",
                          titleColor: "#fff"
                        }
                      : {
                          buttonBgColor: "#0A539E",
                          buttonTextColor: "#fff",
                          circleColor: "#0A539E",
                          background: "#E0EFFF",
                          contentColor: "#0A539E",
                          titleColor: "#0A539E"
                        };
                  return (
                    <Box key={index} className={classes.gridBox}>
                      <ReviewCard
                        buttonBgColor={mod.buttonBgColor}
                        buttonTextColor={mod.buttonTextColor}
                        background={mod.background}
                        circleColor={mod.circleColor}
                        contentColor={mod.contentColor}
                        titleColor={mod.titleColor}
                        title={item.title}
                        content={item.content}
                        imageUrl={item.cardImage}
                      />
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default WhyHapchi;
